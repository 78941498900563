import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2f640b69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-bar action-bar--invoices" }
const _hoisted_2 = { class: "action-bar__content" }
const _hoisted_3 = {
  key: 0,
  class: "action-bar__total invoices-action-bar__total"
}
const _hoisted_4 = { class: "invoices-action-bar__total-label" }
const _hoisted_5 = {
  key: 1,
  class: "invoices-action-bar__total-value invoices-action-bar__not-available",
  "data-test": "invoice-total-na"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCurrencyAmount = _resolveComponent("BaseCurrencyAmount")!
  const _component_TableFilter = _resolveComponent("TableFilter")!
  const _component_TableSearchField = _resolveComponent("TableSearchField")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (
          _ctx.$store.getters['auth/hasAccess']({
            action: 'GET',
            resource: '/open-amount-invoices',
          })
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`invoices.search-bar.${_ctx.totalLabel}`)), 1),
            (_ctx.total.currency)
              ? (_openBlock(), _createBlock(_component_BaseCurrencyAmount, {
                  key: 0,
                  currency: _ctx.total.currency,
                  amount: _ctx.total.amount,
                  "data-test": "invoice-total",
                  class: "invoices-action-bar__total-value"
                }, null, 8, ["currency", "amount"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('general.table.not-available')), 1))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_TableFilter, {
        store: "invoice",
        options: _ctx.filterOptions,
        "option-prefix": "invoices.status",
        field: "AccStatusText",
        "all-label": _ctx.$t('invoices.search-bar.placeholder.all-invoices'),
        placeholder: _ctx.$t('invoices.search-bar.placeholder.filter'),
        class: "action-bar__filter"
      }, null, 8, ["options", "all-label", "placeholder"]),
      _createVNode(_component_TableSearchField, {
        placeholder: _ctx.$t('invoices.search-bar.placeholder.search'),
        store: "invoice",
        class: "action-bar__search action-bar__search--invoices"
      }, null, 8, ["placeholder"])
    ])
  ]))
}