<template>
  <div class="order-tooltip">
    <button class="order-tooltip__trigger" @click="toggle()">
      <OIcon
        v-if="
          action === 'suggest-remove' || action === 'overfill-no-suggestion'
        "
        icon="alert-circle-fill"
        variant="secondary"
      />
      <OIcon v-else icon="info-circle-fill" />
    </button>
    <div class="order-tooltip__popup" v-if="active" data-test="tooltip-popup">
      <div class="order-tooltip__scroll-anchor" ref="scrollAnchor" />
      <button class="order-tooltip__close" @click="close()">
        <OIcon icon="close" variant="secondary" />
      </button>
      <h3 class="order-tooltip__title">
        {{ $t(`orders.new.${action}.tooltip.title`) }}
      </h3>
      <p
        class="order-tooltip__message"
        v-html="
          format($t(`orders.new.${action}.tooltip.message`, { quantity }))
        "
      />
      <div class="order-tooltip__buttons">
        <OButton
          variant="secondary"
          size="medium"
          rounded
          outlined
          @click="close()"
          data-test="cancel-suggestion"
          >{{ $t('orders.new.suggestion.cancel') }}</OButton
        >
        <OButton
          @click="accept()"
          variant="secondary"
          size="medium"
          rounded
          data-test="accept-suggestion"
          >{{ $t('orders.new.suggestion.accept') }}</OButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  OrderSuggestion,
  OrderSuggestionCorrectionSuggestion,
} from '@/types/ApiInterfaces';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      active: true,
    };
  },
  computed: {
    action(): string {
      return this.$store.getters['order/suggestionAction'];
    },
    suggestion(): OrderSuggestion {
      return this.$store.state.order.suggestion;
    },
    product(): OrderSuggestionCorrectionSuggestion | undefined {
      if (this.suggestion.CorrectionSuggestion) {
        return this.suggestion.CorrectionSuggestion;
      }
      return undefined;
    },
    quantity(): number {
      const quantity = this.suggestion.CorrectionSuggestion
        ? this.product?.NumPallets || 0
        : 0;
      return Math.abs(quantity);
    },
  },
  mounted() {
    const ref: any = this.$refs.scrollAnchor;
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth' });
    }
  },
  methods: {
    close() {
      // Cancel the suggestion
      if (this.action !== 'suggest-remove') {
        this.$store.commit('order/setSuggestionCancelled', true);
      }
      this.active = false;
    },
    toggle() {
      this.active = !this.active;
    },
    accept() {
      this.$emit('accept', this.product);
      this.close();
    },
    format(md: string): string {
      return md.replace(/\*\*(.*)\*\*/g, '<strong>$1</strong>');
    },
  },
  props: {
    productNr: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.order-tooltip {
  position: relative;

  &__trigger {
    all: unset;
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    width: 235px;
    background: $white;
    color: $black;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.3));
    bottom: 34px;
    left: -40px;
    z-index: 15;
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    padding: 16px;
    margin-top: 50px;

    @include mobile {
      left: -150px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 47px;
      background: $white;
      width: 13px;
      height: 11px;
      border-radius: 0 0 10px 10px;

      @include mobile {
        left: 157px;
      }
    }
  }

  &__scroll-anchor {
    pointer-events: none;
    position: absolute;
    top: -80px;
  }

  &__close {
    all: unset;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 10px;
    width: 34px;
    height: 34px;
  }

  &__title {
    font-weight: bold;
    color: $primary;
    margin-bottom: 8px;
    margin-right: 20px; // make space for close button
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;

    > :not(:first-child) {
      margin-left: 8px;
    }
  }
}
</style>
