/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateUserSuccessResponse } from '../model';
// @ts-ignore
import { InlineObject2 } from '../model';
// @ts-ignore
import { Success } from '../model';
/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The user\'s login data + all Menu Engineering data for the user will be disabled
         * @summary Disable a user\'s profile (administrators only)
         * @param {string} id User id of the user to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableUserPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableUserPost', 'id', id)
            const localVarPath = `/disable-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user\'s login data + all Menu Engineering data for the user will be enabled
         * @summary Enable a user\'s profile (administrators only)
         * @param {string} id User id of the user to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableUserPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enableUserPost', 'id', id)
            const localVarPath = `/enable-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user\'s mfa preferences will be reset, the user will need to reconfigure this in his/her profile
         * @summary Reset a user\'s multi-factor authentication settings
         * @param {string} id User id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetMfaPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetMfaPost', 'id', id)
            const localVarPath = `/reset-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user\'s password will be reset, the user will receive an invitation via email to set a new password
         * @summary Reset a user\'s password (administrators only)
         * @param {string} id User id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPasswordPost', 'id', id)
            const localVarPath = `/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new user, add it to cognito and fire a event to the profile creations queue
         * @summary Creates a new user
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost: async (inlineObject2: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            assertParamExists('userPost', 'inlineObject2', inlineObject2)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * The user\'s login data + all Menu Engineering data for the user will be disabled
         * @summary Disable a user\'s profile (administrators only)
         * @param {string} id User id of the user to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableUserPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableUserPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The user\'s login data + all Menu Engineering data for the user will be enabled
         * @summary Enable a user\'s profile (administrators only)
         * @param {string} id User id of the user to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableUserPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableUserPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The user\'s mfa preferences will be reset, the user will need to reconfigure this in his/her profile
         * @summary Reset a user\'s multi-factor authentication settings
         * @param {string} id User id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetMfaPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetMfaPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The user\'s password will be reset, the user will receive an invitation via email to set a new password
         * @summary Reset a user\'s password (administrators only)
         * @param {string} id User id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new user, add it to cognito and fire a event to the profile creations queue
         * @summary Creates a new user
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPost(inlineObject2: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPost(inlineObject2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * The user\'s login data + all Menu Engineering data for the user will be disabled
         * @summary Disable a user\'s profile (administrators only)
         * @param {string} id User id of the user to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableUserPost(id: string, options?: any): AxiosPromise<Success> {
            return localVarFp.disableUserPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The user\'s login data + all Menu Engineering data for the user will be enabled
         * @summary Enable a user\'s profile (administrators only)
         * @param {string} id User id of the user to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableUserPost(id: string, options?: any): AxiosPromise<Success> {
            return localVarFp.enableUserPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The user\'s mfa preferences will be reset, the user will need to reconfigure this in his/her profile
         * @summary Reset a user\'s multi-factor authentication settings
         * @param {string} id User id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetMfaPost(id: string, options?: any): AxiosPromise<Success> {
            return localVarFp.resetMfaPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The user\'s password will be reset, the user will receive an invitation via email to set a new password
         * @summary Reset a user\'s password (administrators only)
         * @param {string} id User id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordPost(id: string, options?: any): AxiosPromise<Success> {
            return localVarFp.resetPasswordPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new user, add it to cognito and fire a event to the profile creations queue
         * @summary Creates a new user
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPost(inlineObject2: InlineObject2, options?: any): AxiosPromise<CreateUserSuccessResponse> {
            return localVarFp.userPost(inlineObject2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * The user\'s login data + all Menu Engineering data for the user will be disabled
     * @summary Disable a user\'s profile (administrators only)
     * @param {string} id User id of the user to disable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public disableUserPost(id: string, options?: any) {
        return UserManagementApiFp(this.configuration).disableUserPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user\'s login data + all Menu Engineering data for the user will be enabled
     * @summary Enable a user\'s profile (administrators only)
     * @param {string} id User id of the user to enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public enableUserPost(id: string, options?: any) {
        return UserManagementApiFp(this.configuration).enableUserPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user\'s mfa preferences will be reset, the user will need to reconfigure this in his/her profile
     * @summary Reset a user\'s multi-factor authentication settings
     * @param {string} id User id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public resetMfaPost(id: string, options?: any) {
        return UserManagementApiFp(this.configuration).resetMfaPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user\'s password will be reset, the user will receive an invitation via email to set a new password
     * @summary Reset a user\'s password (administrators only)
     * @param {string} id User id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public resetPasswordPost(id: string, options?: any) {
        return UserManagementApiFp(this.configuration).resetPasswordPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new user, add it to cognito and fire a event to the profile creations queue
     * @summary Creates a new user
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public userPost(inlineObject2: InlineObject2, options?: any) {
        return UserManagementApiFp(this.configuration).userPost(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }
}
