<template>
  <RouterLink
    v-if="isVisibleToUser() && active"
    class="block block--status block--invoices"
    :to="{ name: 'invoices' }"
  >
    <div class="block__top">
      <div class="block__header">
        <OIcon icon="invoice" class="block__icon" />
        <h2 class="block__title">
          {{ $t('dashboard.invoices.your-open-invoices') }}
        </h2>
      </div>
      <div class="block__separator-wrapper">
        <div class="block__separator"></div>
      </div>
      <div class="block__details">
        <div class="block__details-column">
          <h3 class="block__subtitle">
            {{ $t('dashboard.invoices.open-invoices') }}:
          </h3>
          <span class="block__text">{{ totals.total_invoices }}</span>
        </div>
      </div>
      <div class="block__separator-wrapper" v-if="upcoming">
        <div class="block__separator"></div>
      </div>
      <div class="block__details" v-if="upcoming">
        <h3 class="block__subtitle">
          {{ $t('dashboard.invoices.upcoming') }}:
        </h3>
        <InvoiceStatus v-if="upcoming" :invoice="upcoming" />
      </div>
    </div>
    <div class="block__bottom">
      <h2 class="block__title">
        {{ $t('dashboard.invoices.my-invoices') }}
      </h2>
      <span class="block__text">
        {{ $t('dashboard.invoices.go-to-my-invoices') }}
      </span>
      <OIcon icon="arrow_back" class="block__icon" />
    </div>
  </RouterLink>
  <OSkeleton
    v-else-if="isVisibleToUser()"
    :animated="true"
    root-class="is-right"
    width="96%"
    height="320px"
  />
</template>

<script lang="ts">
import { InvoiceApi, InvoiceOpenAmountsResponse, SapInvoice } from '@/api';
import InvoiceStatus from '@/components/invoices/InvoiceStatus.vue';
import getConfiguration from '@/store/getConfiguration';
import { defineComponent } from 'vue';
import DashboardStatusBlock from '../content/blocks/DashboardStatusBlock.vue';

export default defineComponent({
  components: { InvoiceStatus },
  extends: DashboardStatusBlock,
  data() {
    const totals: InvoiceOpenAmountsResponse = {};

    return {
      fetched: false,
      active: false,
      totals,
    };
  },
  computed: {
    upcoming(): SapInvoice | undefined {
      return this.totals?.upcoming_invoice;
    },
  },
  async created() {
    try {
      const configuration = await getConfiguration();
      const api = new InvoiceApi(configuration, '');
      const { data } = await api.openAmountInvoicesGet();
      this.totals = data;
      this.active = true;
    } finally {
      this.fetched = true;
    }
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
