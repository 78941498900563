<template>
  <span class="value-cell" :class="{ 'not-available': !hasValue }">
    <template v-if="hasValue"
      ><slot v-if="$slots.default"></slot
      ><span v-else>{{ value }}</span></template
    >
    <template v-else>
      {{ $t('general.table.not-available') }}
    </template>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    hasValue(): boolean {
      return !!this.value && this.value !== null && this.value !== undefined;
    },
  },
  props: {
    value: { default: undefined },
  },
});
</script>
