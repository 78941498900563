<template>
  <header class="header" :class="`layout__content layout__content--${mod}`">
    <h1 class="header__title" :class="`title title--${titleMod}`">
      <slot />
    </h1>
    <p class="header__intro-text" :class="`header__intro-text--${introMod}`">
      <slot name="intro" />
    </p>
    <MfaWarning v-if="checkMfa && !hasMfa" />
  </header>
</template>

<script lang="ts">
import MfaWarning from '@/components/authentication/MfaWarning.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    MfaWarning,
  },
  computed: {
    hasMfa(): boolean {
      return this.$store.getters['auth/hasMfa'];
    },
  },
  props: {
    mod: { type: String, default: 'large' },
    titleMod: { type: String, default: 'direct' },
    introMod: { type: String, default: 'small' },
    checkMfa: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.header {
  &__intro-text {
    color: $primary;
    font-size: 16px;
    margin-block-end: 24px;

    &--small {
      @include widescreen {
        margin-block-start: -10px;
        font-size: 18px;
      }
    }
  }
}
</style>
