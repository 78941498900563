<template>
  <section>
    <h1 class="title title--h1 onboarding-welcome__title">
      {{ $t('onboarding.welcome.title') }}
    </h1>
    <p class="onboarding-welcome__intro">
      <strong>{{ $t('onboarding.welcome.required-description') }}</strong>
      {{ $t('onboarding.welcome.recommend-description') }}
    </p>
    <section class="onboarding-welcome__security">
      <div class="onboarding-welcome__security-icon">
        <OIcon icon="secure" size="large" variant="primary" />
      </div>
      <div class="onboarding-welcome__security-text">
        <h2 class="onboarding-welcome__security-title">
          {{ $t('onboarding.welcome.explanation-title') }}
        </h2>
        <p class="onboarding-welcome__security-description">
          {{ $t('onboarding.welcome.explanation-description') }}
        </p>
      </div>
    </section>
    <section class="onboarding-welcome__options">
      <OButton
        @click="selectOption(option)"
        v-for="option in MFATypes"
        :key="option"
        :class="`onboarding-welcome__button`"
      >
        <strong>{{ $t(`onboarding.welcome.buttons.${option}.type`) }}</strong>
        {{ $t(`onboarding.welcome.buttons.${option}.label`) }}
      </OButton>
    </section>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  methods: {
    selectOption(key: string) {
      if (this.MFATypes.includes(key)) {
        this.$emit('choice', key);
      }
    },
  },
  props: {
    MFATypes: { type: Array, required: true, default: () => [] },
  },
});
</script>
<style lang="scss" scoped>
.onboarding-welcome {
  &__title {
    margin-top: 0;
  }

  &__security {
    display: flex;
    margin: 24px 0;
    align-items: center;

    @include mobile {
      margin: 15px 0;
      flex-direction: column;
    }
  }

  &__security-icon {
    flex: 0 0 133px;
    width: 133px;
    height: 133px;
    border-radius: 133px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba($primary, 0.28);
    box-shadow: 0px 4px 14px rgba($boulder, 0.22);

    .icon {
      margin-top: -10px;

      &.is-large {
        display: initial;
        font-size: 60px;
      }

      &.is-medium {
        display: none;
      }
    }

    @include mobile {
      flex: 0 0 75px;
      width: 75px;
      height: 75px;
      margin-bottom: 20px;

      .icon {
        &.is-large {
          display: none;
        }

        &.is-medium {
          display: initial;
        }
      }
    }
  }

  &__security-text {
    flex: 1 1 100%;
    margin-left: 24px;
    color: $primary;

    @include mobile {
      margin-left: 0;
    }
  }

  &__security-title {
    font-size: 18px;
    font-weight: bold;
  }

  &__security-description {
    font-size: 14px;
  }

  &__options {
    margin: 24px 0;
  }
}

.onboarding-welcome__button {
  width: 100%;
  min-height: 79px;
  margin: 4px 0;

  span {
    text-align: left;
    white-space: pre-wrap;
  }

  &.button {
    border-width: 1px;
    justify-content: flex-start;
    color: $primary;
    box-shadow: 0 0 0 rgba($black, 0.22);

    &:hover {
      color: $primary;
      border-color: $primary;
      box-shadow: 0 12px 14px rgba($black, 0.22);
    }
  }

  &--active {
    &.button {
      color: $primary;
      border-color: $primary;
    }
  }
}
</style>
