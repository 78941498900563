/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Dish } from '../model';
// @ts-ignore
import { Success } from '../model';
/**
 * DishApi - axios parameter creator
 * @export
 */
export const DishApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Remove a dish
         * @param {string} id Id of the dish to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dishDelete', 'id', id)
            const localVarPath = `/dish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List dish details
         * @param {string} id Id of the dish to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dishGet', 'id', id)
            const localVarPath = `/dish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a dish
         * @param {Dish} dish Details of the dish to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishPut: async (dish: Dish, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dish' is not null or undefined
            assertParamExists('dishPut', 'dish', dish)
            const localVarPath = `/dish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dish, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Dishes will be returned for the logged in (Cognito) user
         * @summary Get overview of all dishes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dishes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DishApi - functional programming interface
 * @export
 */
export const DishApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DishApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Remove a dish
         * @param {string} id Id of the dish to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dishDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dishDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List dish details
         * @param {string} id Id of the dish to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dishGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dish>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dishGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or update a dish
         * @param {Dish} dish Details of the dish to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dishPut(dish: Dish, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dish>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dishPut(dish, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Dishes will be returned for the logged in (Cognito) user
         * @summary Get overview of all dishes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dishesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Dish>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dishesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DishApi - factory interface
 * @export
 */
export const DishApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DishApiFp(configuration)
    return {
        /**
         * 
         * @summary Remove a dish
         * @param {string} id Id of the dish to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishDelete(id: string, options?: any): AxiosPromise<Success> {
            return localVarFp.dishDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List dish details
         * @param {string} id Id of the dish to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishGet(id: string, options?: any): AxiosPromise<Dish> {
            return localVarFp.dishGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a dish
         * @param {Dish} dish Details of the dish to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishPut(dish: Dish, options?: any): AxiosPromise<Dish> {
            return localVarFp.dishPut(dish, options).then((request) => request(axios, basePath));
        },
        /**
         * Dishes will be returned for the logged in (Cognito) user
         * @summary Get overview of all dishes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dishesGet(options?: any): AxiosPromise<Array<Dish>> {
            return localVarFp.dishesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DishApi - object-oriented interface
 * @export
 * @class DishApi
 * @extends {BaseAPI}
 */
export class DishApi extends BaseAPI {
    /**
     * 
     * @summary Remove a dish
     * @param {string} id Id of the dish to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishApi
     */
    public dishDelete(id: string, options?: any) {
        return DishApiFp(this.configuration).dishDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List dish details
     * @param {string} id Id of the dish to obtain details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishApi
     */
    public dishGet(id: string, options?: any) {
        return DishApiFp(this.configuration).dishGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update a dish
     * @param {Dish} dish Details of the dish to add or update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishApi
     */
    public dishPut(dish: Dish, options?: any) {
        return DishApiFp(this.configuration).dishPut(dish, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Dishes will be returned for the logged in (Cognito) user
     * @summary Get overview of all dishes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DishApi
     */
    public dishesGet(options?: any) {
        return DishApiFp(this.configuration).dishesGet(options).then((request) => request(this.axios, this.basePath));
    }
}
