<template>
  <OField class="filter">
    <OSelect
      root-class="select--search"
      icon="filter"
      expanded
      :placeholder="placeholder"
      :modelValue="filterValue"
      @update:modelValue="filter"
      data-test="table-filter"
    >
      <option value="">
        {{ allLabel }}
      </option>
      <option
        v-for="option in options"
        :value="`eq(${field},${option})`"
        :key="option"
      >
        {{ $t(`${optionPrefix}.${option}`) }}
      </option>
    </OSelect>
  </OField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    filterValue(): string {
      return this.$store.state[this.store].filter;
    },
  },
  methods: {
    filter(filter: string) {
      this.$store.dispatch(`${this.store}/filter`, filter);
    },
  },
  props: {
    placeholder: {
      type: String,
    },
    store: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
    },
    field: {
      type: String,
    },
    optionPrefix: {
      type: String,
    },
    allLabel: {
      type: String,
    },
  },
});
</script>
