// Constants to define what menu engineering category a dish falls
// into: winner, runner, sleeper or loser.
enum Score {
  WINNER = 'WINNER',
  RUNNER = 'RUNNER',
  SLEEPER = 'SLEEPER',
  LOSER = 'LOSER',
}

export default Score;
