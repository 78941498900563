import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-42235a7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status-pill__header-content vag-rounded" }
const _hoisted_2 = {
  key: 1,
  class: "status-pill__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["status-pill status-pill__border", `status-pill__border--${_ctx.statusColor}`])
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("header", {
          key: 0,
          class: _normalizeClass(["status-pill__header status-pill__value", `status-pill__value--${_ctx.statusColor}`])
        }, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_OIcon, {
                key: 0,
                icon: _ctx.icon,
                class: _normalizeClass(`status-pill__icon status-pill__icon--${_ctx.icon}`)
              }, null, 8, ["icon", "class"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.main)
      ? (_openBlock(), _createElementBlock("main", _hoisted_2, [
          _renderSlot(_ctx.$slots, "main", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}