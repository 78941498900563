import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b4d84676"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "summary layout__content layout__content--large" }
const _hoisted_2 = { class: "layout__header layout__header--title-only" }
const _hoisted_3 = { class: "summary__title" }
const _hoisted_4 = { class: "title title--direct" }
const _hoisted_5 = { class: "shipping__content" }
const _hoisted_6 = { class: "ship-delivery-date__title" }
const _hoisted_7 = { class: "ship-delivery-date__content" }
const _hoisted_8 = {
  key: 0,
  class: "ship-delivery-date__date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderCard = _resolveComponent("OrderCard")!
  const _component_ShipToAddress = _resolveComponent("ShipToAddress")!
  const _component_OField = _resolveComponent("OField")!
  const _component_NewOrderFooter = _resolveComponent("NewOrderFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('orders.summary.title')), 1)
      ])
    ]),
    _createVNode(_component_OrderCard, { order: _ctx.cart }, null, 8, ["order"]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_OField, { class: "ship-delivery-date ship-delivery-date--summary" }, {
        label: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('orders.summary.delivery-date')), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ShipToAddress, {
              "ship-to": _ctx.shipTo,
              class: "ship-to__address--summary"
            }, null, 8, ["ship-to"]),
            (_ctx.deliveryDate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.deliveryDate), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_NewOrderFooter, { step: "order-summary-and-confirmation" })
  ]))
}