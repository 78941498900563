import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block block--pages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductCard = _resolveComponent("ProductCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (product) => {
      return (_openBlock(), _createBlock(_component_ProductCard, {
        key: product.id,
        product: product
      }, null, 8, ["product"]))
    }), 128))
  ]))
}