<template>
  <div class="dish-form">
    <form @submit.prevent="submit">
      <div class="dish-form__intro layout__content">
        <div class="columns dish-form__restaurant-course" v-if="!isLandingPage">
          <OField
            :label="$t('menuEngineering.label.optionRestaurant')"
            class="column field--restaurant"
          >
            <OSelect
              :modelValue="restaurantId"
              @update:modelValue="setField('restaurantId', $event)"
              expanded
              required
            >
              <option
                v-for="restaurant in restaurants"
                :key="restaurant.id"
                :value="restaurant.id"
              >
                {{ restaurant.restaurantName }}
              </option>
            </OSelect>
          </OField>
          <OField
            :label="$t('menuEngineering.label.selectCourse')"
            class="column field--course"
          >
            <OSelect
              :modelValue="modelValue.course"
              @update:modelValue="setField('course', $event)"
              :placeholder="$t('menuEngineering.label.selectCourseLabel')"
              expanded
              required
            >
              <option v-for="course in courses" :key="course" :value="course">
                {{ $t('menuEngineering.label.course.' + course) }}
              </option>
            </OSelect>
          </OField>
        </div>
        <div v-else>
          <div class="dish-form__group-header">
            <h2 class="title">
              {{ $t('menuEngineering.try-out.restaurant-type.title') }}
            </h2>
            <h3 class="subtitle">
              {{ $t('menuEngineering.try-out.restaurant-type.subtitle') }}
            </h3>
          </div>
          <OField
            :label="$t('menuEngineering.try-out.restaurant-type.label')"
            class="mobile-padding field--restaurant-type"
          >
            <OInput
              :value="modelValue.restaurantType"
              :placeholder="$t('menuEngineering.placeholder.typeOfRestaurant')"
            ></OInput>
          </OField>
        </div>
      </div>

      <div class="dish-form__main">
        <div class="dish-form__dish layout__content">
          <div v-if="isLandingPage" class="dish-form__group-header">
            <h2 class="title">
              {{ $t('menuEngineering.try-out.dish-form.title') }}
            </h2>
            <h3 class="subtitle">
              {{ $t('menuEngineering.try-out.dish-form.subtitle') }}
            </h3>
          </div>

          <OField
            :label="$t('menuEngineering.label.nameOfDish')"
            class="mobile-padding field--name"
          >
            <OInput
              :modelValue="modelValue.name"
              @update:modelValue="setField('name', $event)"
              :placeholder="
                modelValue.course
                  ? $t('menuEngineering.label.course.' + modelValue.course)
                  : $t('menuEngineering.label.nameOfDish')
              "
              required
            ></OInput>
          </OField>

          <OField
            v-if="isLandingPage"
            :label="$t('menuEngineering.label.selectCourse')"
            class="mobile-padding field--course"
          >
            <OSelect
              :modelValue="modelValue.course"
              @update:modelValue="setField('course', $event)"
              :placeholder="$t('menuEngineering.label.selectCourseLabel')"
              expanded
              required
            >
              <option v-for="course in courses" :key="course" :value="course">
                {{ $t('menuEngineering.label.course.' + course) }}
              </option>
            </OSelect>
          </OField>

          <slider
            v-for="(slider, i) in sliders"
            :key="i"
            :label="$t(slider.label)"
            :tooltip="$t(slider.tooltip)"
            :min="slider.min"
            :max="slider.max"
            :value="modelValue[slider.field] || 0"
            @onInput="setField(slider.field, $event)"
            :class="`field--${slider.field}`"
          ></slider>

          <div class="button-row mobile-padding">
            <OButton
              v-if="!isLandingPage"
              rounded
              variant="primary"
              :disabled="saving || !modelValue.name"
              class="button-row__button"
              @click="submit"
            >
              {{ $t(submitLabel) }}
            </OButton>
            <OButton
              v-if="!modelValue.id && !isLandingPage"
              @click="toResults"
              rounded
              outlined
              variant="primary"
              :disabled="!showResultsButton"
              class="button-row__button"
            >
              {{ $t('menuEngineering.overview.showResults') }}
            </OButton>
          </div>
          <p
            class="dish-form__min-dish-notification mobile-padding"
            v-if="!modelValue.id && !isLandingPage"
          >
            {{ $t('menuEngineering.label.goodCalculation') }}
          </p>
        </div>
        <div class="button-row mobile-padding layout__content">
          <OButton
            v-if="isLandingPage"
            rounded
            variant="primary"
            native-type="submit"
            :disabled="saving || !modelValue.name"
            class="button-row__button"
          >
            {{ $t(submitLabel) }}
          </OButton>
        </div>
      </div>
    </form>
    <div class="layout__content"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Slider from '@/components/form/AppSlider.vue';
import MenuEngineeringLogic from '@/logic/menu-engineering/MenuEngineeringLogic';
import { Dish, Restaurant } from '@/api/model';

export default defineComponent({
  components: { Slider },
  props: {
    modelValue: {
      type: Object as () => Dish,
      required: true,
    },
    restaurants: {
      type: Array as () => Restaurant[],
      default: () => [],
    },
    showResultsButton: {
      type: Boolean,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    isLandingPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courses: MenuEngineeringLogic.getCourses(),
    };
  },
  computed: {
    sliders() {
      const sliders = [
        {
          field: 'popularity',
          label: 'menuEngineering.label.popularity',
          tooltip: 'menuEngineering.label.info.popularity',
          max: 300,
        },
        {
          field: 'costPrice',
          label: 'menuEngineering.label.cost',
          tooltip: 'menuEngineering.label.info.cost',
          max: 50,
        },
        {
          field: 'chefTime',
          label: 'menuEngineering.label.time',
          tooltip: 'menuEngineering.label.info.time',
          max: 10,
        },
        {
          field: 'staffTime',
          label: 'menuEngineering.label.helpTime',
          tooltip: 'menuEngineering.label.info.helpTime',
          max: 15,
        },
        {
          field: 'price',
          label: 'menuEngineering.label.price',
          tooltip: 'menuEngineering.label.info.price',
          max: 200,
        },
      ];
      if (this.isLandingPage) {
        sliders.push({
          field: 'companyStaffSize',
          label: 'menuEngineering.label.companyStaffSize',
          tooltip: 'menuEngineering.label.info.companyStaffSize',
          max: 300,
        });
        sliders.push({
          field: 'companySeatCount',
          label: 'menuEngineering.label.companySeatCount',
          tooltip: 'menuEngineering.label.info.companySeatCount',
          max: 300,
        });
      }

      return sliders;
    },
    restaurantId(): string {
      if (this.modelValue.restaurantId) {
        return this.modelValue.restaurantId;
      }
      if (
        this.restaurants &&
        this.restaurants.length &&
        this.restaurants[0].id
      ) {
        this.setField('restaurantId', this.restaurants[0].id);
        return this.restaurants[0].id;
      }

      return '';
    },
    submitLabel(): string {
      if (this.isLandingPage) {
        return 'menuEngineering.overview.calculateNow';
      }

      return this.modelValue.id
        ? 'menuEngineering.overview.save'
        : 'menuEngineering.overview.addAnotherDish';
    },
  },
  methods: {
    setField(name: string, value: string | number) {
      if (name === 'course') {
        this.$store.commit('menuEngineering/setActiveCourse', value);
      }
      if (name === 'restaurantId') {
        this.$store.commit('restaurant/setActiveRestaurantId', value);
      }
      const dish: any = this.modelValue;
      dish[name] = value;
      this.$emit('update:modelValue', dish);
    },
    toResults() {
      this.$router.push('/menu-engineering/results');
    },
    submit() {
      this.$emit('submit');
    },
  },
});
</script>

<style lang="scss" scoped>
.dish-form {
  &__main {
    background: rgba(0, 56, 90, 0.03);
    padding: 16px 0;

    @include tablet {
      padding: 80px 0;
      margin-top: 27px;
    }
  }

  &__dish {
    background: $white;
    box-shadow: 0 24px 44px rgba($black, 0.08);
    border-radius: 8px;
    padding: 16px 0 40px;

    @include tablet {
      padding: 40px 48px;
    }
  }

  &__min-dish-notification {
    font-size: 14px;
  }

  .control .control {
    width: 100%;
  }
}

.mobile-padding {
  margin-left: 16px;
  margin-right: 16px;

  @include tablet {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
