/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SapCustomer
 */
export interface SapCustomer {
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    AddressNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    City?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    ContainerLoadBase?: SapCustomerContainerLoadBaseEnum;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    CountryKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    CustName1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    CustName2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    CustomerNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    FaxNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    Id?: string;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    IncoTerms1?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SapCustomer
     */
    IsContainerCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    Language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    PaymTerms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    PoBox?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    PoBoxPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    PostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    Region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    SalesAreaId?: string;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    ShippingConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    ShippingConditionsDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    StreetAndNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    TelephoneNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer
     */
    VatRegNr?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SapCustomerContainerLoadBaseEnum {
    Pallet = 'PALLET',
    Sheet = 'SHEET'
}



