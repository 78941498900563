<template>
  <div class="ship-to__address">
    <div class="ship-to__value ship-to__value--name">
      {{ address[0] }}
      <div v-if="shipTo.IncoTerms1" class="ship-to__incoterm">
        {{ shipTo.IncoTerms1 }}
      </div>
    </div>

    <div class="ship-to__value ship-to__value--address1">
      {{ address[1] }}
    </div>
    <div class="ship-to__value ship-to__value--city-postalcode">
      {{ address[2] }}
    </div>
  </div>
</template>
<script lang="ts">
import PostalAddress from 'i18n-postal-address';
import { SapCustomerPartner } from '@/api/model/sap-customer-partner';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    address() {
      const address = new PostalAddress();
      address
        .setCompanyName(this.shipTo.PartnerName)
        .setAddress1(this.shipTo.StreetAndNumber)
        .setCity(this.shipTo.City)
        .setCountry(this.shipTo.CountryKey)
        .setPostalCode(this.shipTo.PostalCode);
      return address.output().map((line: string[]) => line.join(' '));
    },
  },
  props: {
    shipTo: {
      type: Object as () => SapCustomerPartner,
      required: true,
    },
  },
});
</script>
