<template>
  <section>
    <p class="onboarding__wrapper">{{ $t('onboarding.none.description') }}</p>
    <div class="button-row">
      <OButton variant="primary" rounded @click="disableMFA()">{{
        $t('onboarding.none.finish')
      }}</OButton>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  methods: {
    disableMFA() {
      this.$store.dispatch('auth/disableMFAOnboarding');
    },
  },
});
</script>
