<template>
  <ONotification
    :closable="closable"
    rootClass="base-message"
    :class="`base-message--${variant}`"
  >
    <slot />
  </ONotification>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {},
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
});
</script>
<style scoped lang="scss">
.base-message {
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 4px;

  @each $name, $tints in $colors {
    @if length($tints) > 3 {
      &--#{$name} {
        background-color: nth($tints, 3);
        border-color: nth($tints, 1);
        color: nth($tints, 4);
      }
    }
  }
}
</style>
