/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Dish
 */
export interface Dish {
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    chefTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    costPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    course?: DishCourseEnum;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    deletedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    popularity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    price?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    restaurantId?: string;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    staffTime?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DishCourseEnum {
    Breakfast = 'breakfast',
    Lunch = 'lunch',
    Inbetween = 'inbetween',
    Dinner = 'dinner',
    SnackTime = 'snack_time'
}



