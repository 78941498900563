<template>
  <div class="summary layout__content layout__content--large">
    <header class="layout__header layout__header--title-only">
      <div class="summary__title">
        <h1 class="title title--direct">{{ $t('orders.summary.title') }}</h1>
      </div>
    </header>

    <OrderCard :order="cart" />

    <div class="shipping__content">
      <OField class="ship-delivery-date ship-delivery-date--summary">
        <template #label>
          <div class="ship-delivery-date__title">
            {{ $t('orders.summary.delivery-date') }}
          </div>
        </template>
        <div class="ship-delivery-date__content">
          <ShipToAddress :ship-to="shipTo" class="ship-to__address--summary" />
          <div v-if="deliveryDate" class="ship-delivery-date__date">
            {{ deliveryDate }}
          </div>
        </div>
      </OField>
    </div>
    <NewOrderFooter step="order-summary-and-confirmation" />
  </div>
</template>

<script lang="ts">
import { format } from 'date-fns';
import { SalesOrder } from '@/api/model';
import { SapCustomerPartner } from '@/api/model/sap-customer-partner';
import OrderCard from '@/components/orders/OrderCard.vue';
import ShipToAddress from '@/components/orders/new-order/ShipToAddress.vue';
import Helpers from '@/logic/orders/helpers';
import NewOrderFooter from '@/components/orders/new-order/NewOrderFooter.vue';
import { DateFnsLocales } from '@/DateFnsLocales';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ShipToAddress, OrderCard, NewOrderFooter },
  computed: {
    cart(): SalesOrder {
      return this.$store.state.order.cart;
    },
    shipTo(): SapCustomerPartner {
      return this.$store.state.order.shipToPartners.find(
        (p: SapCustomerPartner) => p.CustomerNr === this.cart.ShipToParty,
      );
    },
    deliveryDate(): string | null {
      if (this.cart.ReqDlvDate === undefined || this.cart.ReqDlvDate === null) {
        return null;
      }
      const date = Helpers.getDateFromSapDate(this.cart.ReqDlvDate);
      if (date instanceof Date && !Number.isNaN(date.getDate())) {
        return format(date, 'PPP', {
          locale: DateFnsLocales[this.$i18n.locale],
        });
      }
      return null;
    },
  },
});
</script>

<style lang="scss" scoped>
.shipping {
  display: flex;
  justify-content: space-between;
}
</style>
