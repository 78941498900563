<template>
  <RouterLink v-if="link" class="product-card" :to="link">
    <img
      v-if="image"
      :src="$helpers.getApiImageThumbnail(image)"
      class="product-card__image"
      :alt="image.title"
    />
    <div class="product-card__description">
      <span v-if="category" class="product-card__category">
        {{ category }}
      </span>
      <div v-if="title" class="product-card__title">
        {{ title }}
      </div>
    </div>
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    const $helpers: any = undefined;

    return {
      $helpers,
    };
  },
  computed: {
    link(): string {
      if (this.product.content.url && this.product.content.url.length) {
        const url = this.product.content.url
          .replace('/', '')
          .split('/')
          .splice(1, this.product.content.url.split('/').length);

        return `/${url.join('/')}`;
      }

      return '';
    },
    image(): string {
      if (
        this.product.content.excerptImages &&
        this.product.content.excerptImages.length &&
        this.product.content.excerptImages[0]
      ) {
        return this.product.content.excerptImages[0];
      }

      return '';
    },
    category(): string {
      if (
        this.product.content.excerptCategories &&
        this.product.content.excerptCategories.length &&
        this.product.content.excerptCategories[0].name
      ) {
        return this.product.content.excerptCategories[0].name;
      }

      return '';
    },
    title(): string {
      if (
        this.product.content.excerptTitle &&
        this.product.content.excerptTitle.length
      ) {
        return this.product.content.excerptTitle;
      }

      if (this.product.content.title && this.product.content.title.length) {
        return this.product.content.title;
      }

      return '';
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $white;
  box-shadow: 0 0 50px rgba($black, 0.15);
  padding: 32px;

  &:hover {
    cursor: pointer;
  }

  &__image {
    padding: 0 68px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__category {
    padding: 27px 0 5px 0;
    color: $dusty-gray;
    text-transform: lowercase;
  }

  &__title {
    font-size: 24px;
    color: $secondary;
  }
}
</style>
