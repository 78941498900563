import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createVNode(_component_OField, { class: "search-bar__search" }, {
        default: _withCtx(() => [
          _createVNode(_component_OInput, {
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search($event))),
            type: "search",
            "icon-right": "search",
            "icon-right-clickable": "",
            onIconRightClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('#results'))),
            size: "medium",
            "root-class": "input--search",
            placeholder: _ctx.$t('orders.search-bar.placeholder.search'),
            expanded: ""
          }, null, 8, ["placeholder"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}