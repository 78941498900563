import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-62bed3c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-tooltip" }
const _hoisted_2 = {
  key: 0,
  class: "order-tooltip__popup",
  "data-test": "tooltip-popup"
}
const _hoisted_3 = {
  class: "order-tooltip__scroll-anchor",
  ref: "scrollAnchor"
}
const _hoisted_4 = { class: "order-tooltip__title" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "order-tooltip__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "order-tooltip__trigger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle()))
    }, [
      (
          _ctx.action === 'suggest-remove' || _ctx.action === 'overfill-no-suggestion'
        )
        ? (_openBlock(), _createBlock(_component_OIcon, {
            key: 0,
            icon: "alert-circle-fill",
            variant: "secondary"
          }))
        : (_openBlock(), _createBlock(_component_OIcon, {
            key: 1,
            icon: "info-circle-fill"
          }))
    ]),
    (_ctx.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, null, 512),
          _createElementVNode("button", {
            class: "order-tooltip__close",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
          }, [
            _createVNode(_component_OIcon, {
              icon: "close",
              variant: "secondary"
            })
          ]),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t(`orders.new.${_ctx.action}.tooltip.title`)), 1),
          _createElementVNode("p", {
            class: "order-tooltip__message",
            innerHTML: 
          _ctx.format(_ctx.$t(`orders.new.${_ctx.action}.tooltip.message`, { quantity: _ctx.quantity }))
        
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_OButton, {
              variant: "secondary",
              size: "medium",
              rounded: "",
              outlined: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close())),
              "data-test": "cancel-suggestion"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('orders.new.suggestion.cancel')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_OButton, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.accept())),
              variant: "secondary",
              size: "medium",
              rounded: "",
              "data-test": "accept-suggestion"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('orders.new.suggestion.accept')), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}