<template>
  <div>
    <BaseHeader :check-mfa="false">
      1.2 {{ $t('claims.new.steps.proof.header') }}
    </BaseHeader>
    <div class="layout__content layout__content--large">
      <BaseFieldUpload
        name="attachments"
        :label="$t('claims.new.steps.proof.attachment.label')"
        :placeholder="$t('claims.new.steps.proof.attachment.placeholder')"
        horizontal
        :modelValue="$store.state.claim.claimData.uploadedProof"
        @update:modelValue="
          $store.commit('claim/setNewClaimUploadedProof', $event)
        "
        @remove-file="
          $store.commit('claim/setRemoveClaimUploadedProof', $event)
        "
        :rules="uploadRules"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFieldUpload from '@/components/form/BaseFieldUpload.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseHeader,
    BaseFieldUpload,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    uploadRules(): any {
      return {
        required: true,
        ext: ['pdf', 'jpg', 'jpeg', 'png', 'msg', 'docx', 'xlsx'],
        totalSize: 25, // Upload limit of 25Mb
      };
    },
  },
});
</script>
