import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f2e77ac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "onboarding__wrapper" }
const _hoisted_2 = { class: "button-row button-row--flex" }
const _hoisted_3 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneSelect = _resolveComponent("PhoneSelect")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OInput = _resolveComponent("OInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.setPhoneNumber()), ["prevent"]))
      }, [
        _createVNode(_component_OField, { label: "Phonenumber" }, {
          default: _withCtx(() => [
            _createVNode(_component_PhoneSelect, {
              modelValue: _ctx.phone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
              required: true,
              onValidate: _ctx.onValidatePhone,
              disabled: _ctx.step !== _ctx.steps.UNKNOWN
            }, null, 8, ["modelValue", "onValidate", "disabled"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.step === _ctx.steps.UNVERIFIED)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "onboarding__reset-phone",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step = _ctx.steps.UNKNOWN))
              }, _toDisplayString(_ctx.$t('onboarding.sms.change-phone')), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_OButton, {
            variant: "primary",
            rounded: "",
            "native-type": "submit",
            disabled: !_ctx.valid || _ctx.step !== _ctx.steps.UNKNOWN,
            class: "onboarding__button",
            loading: _ctx.loading.request
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('onboarding.sms.request-code')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ])
      ], 32),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.checkCode()), ["prevent"]))
      }, [
        _createVNode(_component_OField, { label: "MFA-field" }, {
          default: _withCtx(() => [
            _createVNode(_component_OInput, {
              modelValue: _ctx.code,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.code) = $event)),
              placeholder: 'Verify MFA',
              disabled: _ctx.step !== _ctx.steps.UNVERIFIED
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_OButton, {
            variant: "primary",
            rounded: "",
            "native-type": "submit",
            disabled: _ctx.code.length !== 6 || _ctx.step !== _ctx.steps.UNVERIFIED,
            class: "onboarding__button",
            loading: _ctx.loading.check
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('onboarding.sms.finish')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading"])
        ])
      ], 32)
    ])
  ]))
}