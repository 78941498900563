<template>
  <svg class="color-box" viewBox="0 0 30 10" preserveAspectRatio="none">
    <rect
      class="color-box__box color-box__box--1"
      x="0"
      y="0"
      width="10"
      height="10"
    />
    <rect
      class="color-box__box color-box__box--2"
      x="10"
      y="0"
      width="10"
      height="10"
    />
    <rect
      class="color-box__box color-box__box--3"
      x="20"
      y="0"
      width="10"
      height="10"
    />
  </svg>
</template>

<style lang="scss" scoped>
.color-box {
  display: block;
  width: 100%;
  height: 2px;
  position: fixed;
  z-index: 3;

  @include widescreen {
    display: none;
  }

  &__box {
    &--1 {
      fill: $prussian-blue;
    }

    &--2 {
      fill: $trinidad;
    }

    &--3 {
      fill: $web-orange;
    }
  }
}
</style>
