<template>
  <div class="totals">
    <div class="totals__label">
      {{ $t('claims.new.footer.total-billback-amount') }}
    </div>
    <span class="totals__item totals__item--volume">
      {{ totals.volume }}
      {{ $t('claims.kg') }}
    </span>
    <span class="totals__item totals__item--amount">
      <BaseCurrencyAmount
        v-if="agreement?.currency"
        :currency="agreement.currency"
        :amount="totals.billbackAmount"
      />
    </span>
  </div>
</template>

<script lang="ts">
import BaseCurrencyAmount from '@/components/BaseCurrencyAmount.vue';
import { SalesQuote } from '@/api';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { BaseCurrencyAmount },
  computed: {
    agreement(): SalesQuote {
      return this.claimAgreement || this.$store.getters['claim/agreement'];
    },
    totals(): any {
      return (
        this.claimTotals || this.$store.getters['claim/calculateClaimTotals']
      );
    },
  },
  props: {
    claimAgreement: {
      type: Object as () => SalesQuote,
      default: () => undefined,
    },
    claimTotals: { type: Object as any, default: () => undefined },
  },
});
</script>

<style lang="scss" scoped>
.totals {
  display: flex;
  flex-direction: column;
  color: $primary;
  font-weight: bold;
  background-color: $wild-sand;
  padding: 20px 16px;
  border-radius: 0 0 8px 8px;

  @include tablet {
    flex-direction: row;
  }

  &__label {
    flex: 1;
  }

  // Align the totals with columns in table above
  &__item {
    &--volume {
      @include tablet {
        $offset: 240px - 2 * 16px;
        flex: 0 0 $offset;
      }
    }

    &--amount {
      @include tablet {
        text-align: right;
        flex: 0 0 160px;
      }
    }
  }
}
</style>
