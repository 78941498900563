import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8454a710"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block.double_columns, (columns, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "column"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.column, (blocks, indexBlocks) => {
          return (_openBlock(), _createElementBlock("div", {
            key: indexBlocks,
            class: "column__item"
          }, [
            (blocks.type === 'textimage')
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(blocks.type + 'Block'), {
                  key: 0,
                  block: blocks
                }, null, 8, ["block"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}