<template>
  <section class="search-bar">
    <div class="search-bar__content layout__content layout__content--large">
      <div
        class="search-bar__top"
        v-if="$store.getters['order/warehouseCount'] > 1 && type === 'order'"
      >
        <ProductWarehouseFilter />
      </div>
      <div class="search-bar__top">
        <ProductGroupFilter :type="type" />
        <div
          class="search-bar__toggle-icon"
          :class="{ 'search-bar__toggle-icon--active': active }"
          @click="toggleSearchBar"
        >
          <OIcon :icon="active ? 'close' : 'search'" size="small" />
        </div>
      </div>
      <ProductSearchField v-if="active" :type="type" />
    </div>
  </section>
</template>

<script lang="ts">
import ProductGroupFilter from '@/components/orders/ProductGroupFilter.vue';
import ProductSearchField from '@/components/products/ProductSearchField.vue';
import { defineComponent } from 'vue';
import ProductWarehouseFilter from '@/components/orders/ProductWarehouseFilter.vue';

export default defineComponent({
  components: {
    ProductGroupFilter,
    ProductSearchField,
    ProductWarehouseFilter,
  },
  data() {
    return {
      active: false,
      hasClicked: false,
      screenWidth: 0,
    };
  },
  created() {
    window.addEventListener('resize', this.getScreenWidth);
    this.getScreenWidth();
  },
  methods: {
    getScreenWidth() {
      const { innerWidth } = window;
      const { clientWidth } = document.documentElement;

      this.screenWidth = innerWidth || clientWidth || document.body.clientWidth;
      this.active = this.screenWidth >= 768 || this.hasClicked;
    },
    toggleSearchBar() {
      this.hasClicked = !this.hasClicked;
      if (this.screenWidth < 768) {
        this.active = this.hasClicked;
      }
    },
  },
  props: {
    type: { type: String, default: 'order' },
  },
});
</script>

<style lang="scss" scoped>
.search-bar {
  .field:not(:last-child) {
    @include tablet {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }

  &__search {
    width: 100%;
  }

  &__toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 45px;
    height: 45px;
    border-radius: 100%;
    margin-left: 16px;
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    @include tablet {
      display: none;
    }

    &--active {
      background-color: $white;
      color: $primary;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
</style>
