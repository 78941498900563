import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0351099b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-test"]
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "table__empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_OrderStatus = _resolveComponent("OrderStatus")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OrderOverviewTableDetails = _resolveComponent("OrderOverviewTableDetails")!
  const _component_o_table = _resolveComponent("o-table")!

  return (_openBlock(), _createElementBlock("div", {
    class: "order-overview-table",
    "data-test": 
      _ctx.orders && _ctx.orders.length > 0 ? 'order-overview-table-has-orders' : ''
    
  }, [
    _createVNode(_component_o_table, {
      data: _ctx.orders,
      striped: "",
      "show-detail-icon": false,
      "opened-detailed": _ctx.openedDetails,
      detailed: "",
      paginated: "",
      "per-page": _ctx.perPage,
      "detail-key": "Id"
    }, {
      detail: _withCtx((props) => [
        _createVNode(_component_OrderOverviewTableDetails, {
          order: props.row
        }, null, 8, ["order"])
      ]),
      empty: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('orders.overview.empty')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_o_table_column, {
          field: "OrderNr",
          sortable: "",
          label: _ctx.$t('orders.overview.header.order-no')
        }, {
          default: _withCtx((props) => [
            _createTextVNode(_toDisplayString(props.row.OrderNumber), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_o_table_column, {
          field: "PurchOrder",
          sortable: "",
          label: _ctx.$t('orders.overview.header.order-ref')
        }, {
          default: _withCtx((props) => [
            _createTextVNode(_toDisplayString(props.row.PurchOrder), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_o_table_column, {
          field: "City",
          sortable: "",
          label: _ctx.$t('orders.overview.header.order-city')
        }, {
          default: _withCtx((props) => [
            _createTextVNode(_toDisplayString(props.row.City), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_o_table_column, {
          field: "CreatedOn",
          sortable: "",
          label: _ctx.$t('orders.overview.header.order-date')
        }, {
          default: _withCtx((props) => [
            _createElementVNode("span", {
              title: _ctx.Helpers.getReadableCreatedFullDate(props.row)
            }, _toDisplayString(_ctx.Helpers.getReadableCreatedDate(props.row)), 9, _hoisted_2)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_o_table_column, {
          field: "OrderStatus",
          sortable: "",
          label: _ctx.$t('orders.overview.header.order-status'),
          "td-attrs": () => ({ class: 'table__cell--no-padding' })
        }, {
          default: _withCtx((props) => [
            _createVNode(_component_OrderStatus, {
              status: props.row.OrderStatus,
              "delivery-date": props.row.DeliveryDate,
              eta: _ctx.Helpers.eta(props.row),
              delay: _ctx.Helpers.delay(props.row, _ctx.$i18n.locale, _ctx.$t),
              "is-container": _ctx.$store.getters['profile/isContainer']
            }, null, 8, ["status", "delivery-date", "eta", "delay", "is-container"])
          ]),
          _: 1
        }, 8, ["label"]),
        (_ctx.$store.getters['profile/isContainer'])
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_o_table_column, {
                label: _ctx.$t('orders.overview.header.eta-booked')
              }, {
                default: _withCtx((props) => [
                  (props.row.OriginalEta)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.Helpers.formatEtaDate(props.row.OriginalEta)), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_o_table_column, {
                label: _ctx.$t('orders.overview.header.eta-actual')
              }, {
                default: _withCtx((props) => [
                  (props.row.UpdatedEta)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(["eta", _ctx.etaActualClass(props.row)])
                      }, _toDisplayString(_ctx.Helpers.formatEtaDate(props.row.UpdatedEta)), 3))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
                ]),
                _: 1
              }, 8, ["label"])
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_o_table_column, {
          label: _ctx.$t('orders.overview.header.more'),
          "td-attrs": () => ({ class: 'table__cell--no-padding' }),
          width: 80,
          "root-class": "is-centered"
        }, {
          default: _withCtx((props) => [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.toggleActive(props.row)),
              class: "toggle-details"
            }, [
              _createVNode(_component_OIcon, {
                class: _normalizeClass(["toggle-details__icon", {
              'toggle-details__icon--active': _ctx.isDetailed(props.row),
            }]),
                icon: "arrow_back"
              }, null, 8, ["class"])
            ], 8, _hoisted_6)
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["data", "opened-detailed", "per-page"])
  ], 8, _hoisted_1))
}