import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-04a5caef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block form-card" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "block__video-preview",
  controls: ""
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(this.block.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.block.downloadlist, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "block__downloaditem-wrapper"
      }, [
        (item.file && item.file.url && item.file.isVideo)
          ? (_openBlock(), _createElementBlock("video", _hoisted_3, [
              _createElementVNode("source", {
                src: _ctx.$helpers.getFileUrl(item.file.url),
                type: "video/mp4"
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_resolveDynamicComponent(item.file ? 'a' : 'div'), {
          class: _normalizeClass(["block__downloaditem", { 'block__downloaditem--disabled': !item.file }]),
          href: item.file ? _ctx.$helpers.getFileUrl(item.file.url) : '',
          target: "_blank",
          download: item.label
        }, {
          default: _withCtx(() => [
            _createVNode(_component_OIcon, {
              class: "block__download-icon",
              icon: "download"
            }),
            _createTextVNode(" " + _toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["class", "href", "download"]))
      ]))
    }), 128))
  ]))
}