<template>
  <div class="button-row">
    <OButton
      rounded
      outlined
      variant="primary"
      class="button-row__button"
      @click="cancel"
      >{{ $t('profile.label.cancel') }}</OButton
    >
    <OButton
      variant="primary"
      rounded
      native-type="submit"
      class="button-row__button"
      :disabled="!valid"
      >{{ $t('profile.label.save') }}</OButton
    >
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    group: {
      type: String,
      required: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
});
</script>
