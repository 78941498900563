import getConfiguration from '@/store/getConfiguration';
import { AgreementsApi, SalesQuote } from '@/api';

async function getApi() {
  const configuration = await getConfiguration();
  return new AgreementsApi(configuration, '');
}

const initialState = () => ({
  agreements: [] as SalesQuote[],
  isLoading: false,
  detailActive: new Set(),
});
export type AgreementState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: AgreementState) {
      Object.assign(state, initialState());
    },

    setAgreements(state: AgreementState, agreements: SalesQuote[]) {
      state.agreements = agreements;
    },

    setLoading(state: AgreementState, isLoading: boolean) {
      state.isLoading = isLoading;
    },

    toggleActive(
      state: AgreementState,
      options: { id: string; active: boolean },
    ) {
      const { id, active } = options;
      if (active) {
        state.detailActive.add(id);
      } else {
        state.detailActive.delete(id);
      }
      state.detailActive = new Set(state.detailActive);
    },
  },
  actions: {
    async fetchAgreements({
      commit,
      state,
    }: {
      commit: Function;
      state: AgreementState;
    }) {
      if (state.agreements.length > 0) {
        return;
      }
      try {
        commit('setLoading', true);
        const api = await getApi();
        const { data } = await api.agreementsGet();
        commit('setAgreements', data);
      } finally {
        commit('setLoading', false);
      }
    },
  },
  getters: {
    isDetailActive:
      (state: AgreementState) =>
      (id: string): boolean =>
        state.detailActive.has(id),
    findAgreementById:
      (state: AgreementState) =>
      (agreementId: string): SalesQuote | undefined =>
        state.agreements.find(
          (agreement: SalesQuote) => agreement.id === agreementId,
        ),
    findAgreementBySapId:
      (state: AgreementState) =>
      (sapId: string): SalesQuote | undefined =>
        state.agreements.find(
          (agreement: SalesQuote) => agreement.sales_quote_sap_id === sapId,
        ),
  },
};
