<script lang="ts">
import { defineComponent } from 'vue';
import DashboardBlock from './DashboardBlock.vue';

export default defineComponent({
  extends: DashboardBlock,
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
