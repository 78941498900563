<template>
  <a
    v-if="link && isVisibleToUser()"
    class="block form-card"
    :href="link"
    :target="linkTarget"
  >
    <h2 v-if="block.title" class="title title--h2 block__title">
      {{ block.title }}
    </h2>
    <p v-if="block.text" class="block__text">
      {{ block.text }}
    </p>
    <OIcon icon="arrow_back" class="block__icon" />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardBlock from './DashboardBlock.vue';

export default defineComponent({
  extends: DashboardBlock,
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
.block {
  &__icon {
    align-self: flex-end;
    color: $prussian-blue;
    flex-basis: 10%;
    font-size: 18px;
    height: 18px;
    margin: 0;
    transform: rotate(180deg);
  }

  &:hover {
    .block__icon {
      color: $trinidad;
    }
  }

  &__text {
    flex-basis: 90%;
  }
}
</style>
