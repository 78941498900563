<template>
  <div
    class="layout layout--default"
    :class="isImpersonating ? 'layout--impersonating' : ''"
  >
    <Navigation class="layout__navigation" />
    <main class="layout__main">
      <slot />
      <FooterBar class="layout__footer" />
    </main>
  </div>
</template>

<script lang="ts">
import Navigation from '@/components/sidebar/TheNavigation.vue';
import FooterBar from '@/components/FooterBar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Navigation, FooterBar },
  computed: {
    isImpersonating(): boolean {
      return this.$store.getters['profile/isImpersonated'];
    },
  },
});
</script>

<style lang="scss" scoped>
$footer-height: 80px;

.layout {
  display: flex;
  flex-direction: column;

  &--impersonating {
    margin-top: 65px;

    @include until($tablet) {
      margin-top: 92px;
    }
  }

  @include widescreen {
    flex-direction: row;
  }

  &__navigation {
    @include widescreen {
      flex: 0 0 300px;
    }
  }

  &__main {
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    @include until($widescreen) {
      margin-top: 50px; // make space for top bar
    }

    > :deep(:first-child) {
      flex: 1;
    }

    :deep() {
      .layout__content {
        position: relative;
        max-width: 828px;
        margin-left: 16px;
        margin-right: 16px;

        &--small {
          max-width: 719px;
        }

        &--large {
          @include widescreen {
            max-width: 996px;
          }
        }

        @include tablet {
          margin-left: auto;
          margin-right: auto;
          padding-left: 16px;
          padding-right: 16px;
        }
      }

      .layout__intro-text {
        font-size: 16px;
        line-height: 1.75;
        color: $primary;
        margin-bottom: 30px;

        @include widescreen {
          font-size: 24px;
          line-height: 1.5;
          margin-bottom: 22px;
        }

        &--orders {
          letter-spacing: 0.21px;
          color: $tundora;
          margin: 0 0 14px;

          @include tablet {
            font-size: 16px;
            margin: 4px 33px 24px 0;
          }
        }
      }

      .layout__header {
        display: flex;
        flex-direction: column;

        @include tablet {
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
        }

        &--title-only {
          @include tablet {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  &__footer {
    flex: 0 0 $footer-height;
  }
}
</style>
