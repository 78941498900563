<template>
  <div class="authenticator-custom authenticator-set-mfa">
    <div class="authenticator-set-mfa__header" v-if="!displayTotpSetup">
      <div class="form-card__label">{{ options.header }}</div>
      <span class="authenticator-set-mfa__header-subtitle">{{
        options.mfaDescription
      }}</span>
    </div>
    <div class="authenticator-set-mfa__header" v-if="displayTotpSetup">
      <div class="form-card__label">{{ $t('amplify.Verify') }}</div>
      <span class="authenticator-set-mfa__header-subtitle">{{
        options.tokenInstructions
      }}</span>
    </div>

    <div class="authenticator-set-mfa__choice-field" v-if="!displayTotpSetup">
      <div
        class="authenticator-set-mfa__choice-field-option"
        v-if="options.mfaTypes.includes('SMS')"
      >
        <input
          type="radio"
          name="mfaPreference"
          value="SMS"
          v-model="mfaPreference"
        />
        {{ options.smsDescription }}
      </div>
      <div
        class="authenticator-set-mfa__choice-field-option"
        v-if="options.mfaTypes.includes('TOTP')"
      >
        <input
          type="radio"
          name="mfaPreference"
          value="TOTP"
          v-model="mfaPreference"
        />
        {{ options.totpDescription }}
      </div>
      <div
        class="authenticator-set-mfa__choice-field-option"
        v-if="options.mfaTypes.includes('None')"
      >
        <input
          type="radio"
          name="mfaPreference"
          value="NOMFA"
          v-model="mfaPreference"
        />
        {{ options.noMfaDescription }}
      </div>
    </div>

    <div v-if="displayTotpSetup && token">
      <div class="authenticator-set-mfa__qr-container">
        <qrcode-vue :value="token" :size="300" level="H"></qrcode-vue>
      </div>
      <div>
        <div>{{ $t('amplify.Verification Code') }} *</div>
        <OInput
          v-model="code"
          :placeholder="$t('amplify.Verification Code')"
          autofocus
        />
      </div>
    </div>

    <div class="authenticator-set-mfa__actions">
      <span>
        <button
          id="setMfa"
          class="amplify-button amplify-button--primary"
          @click="setMFA"
          v-if="!displayTotpSetup"
        >
          {{ $t('amplify.Set MFA') }}
        </button>
        <button
          id="verify"
          class="amplify-button amplify-button--primary"
          @click="verifyTotpToken"
          v-if="displayTotpSetup"
        >
          {{ $t('amplify.Verify Token') }}
        </button>
      </span>
      <span>
        <a @click="cancel">{{ $t('amplify.Cancel') }}</a>
      </span>
    </div>

    <div class="error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import {
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
  UpdateMFAPreferenceInput,
} from '@aws-amplify/auth';
import { AuthTOTPSetupDetails } from '@aws-amplify/auth/dist/esm/types';
import { ConsoleLogger } from 'aws-amplify/utils';
import QrcodeVue from 'qrcode.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SetMfa',
  props: {
    mfaConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      user: null as any,
      mfaPreference: null as any,
      code: '',
      token: '',
      error: '' as string,
      displayTotpSetup: false,
      logger: {} as ConsoleLogger,
    };
  },
  components: {
    QrcodeVue,
  },
  mounted(): void {
    this.logger = new ConsoleLogger(this.$options.name || '');
    this.user = this.$store.state.auth.user;
  },
  computed: {
    options(): any {
      const defaults = {
        header: 'Multifactor Authentication Preference',
        mfaDescription:
          'AWS Multi-Factor Authentication (MFA) adds an extra layer of protection on top of your user name and password.',
        tokenInstructions:
          'Scan the QR Code with your phone camera or authentication app to get the MFA code.',
        smsDescription:
          'SMS text messaging (receive a code on your mobile device)',
        totpDescription:
          'One-time password (use a QR code and MFA app to save a token on your mobile device)',
        noMfaDescription: 'Do not enable MFA',
        mfaTypes: [],
      };
      return Object.assign(defaults, this.mfaConfig || {});
    },
  },
  watch: {
    mfaPreference(value): void {
      if (value === 'TOTP') {
        this.setup();
      }
    },
  },
  methods: {
    setup(): void {
      setUpTOTP()
        .then((data: AuthTOTPSetupDetails) => {
          this.logger.info('setTOTP success');
          this.token = `otpauth://totp/AWSCognito:${this.user.username}?secret=${data.sharedSecret}&issuer=AWSCognito`;
        })
        .catch((e) => this.setError(e));
    },
    setMFA(): void {
      const mfaPreferenceInput: UpdateMFAPreferenceInput =
        this.mfaPreference === 'NOMFA'
          ? { sms: 'DISABLED', totp: 'DISABLED' }
          : {
              [this.mfaPreference?.toLowerCase()]: 'PREFERRED',
            };

      updateMFAPreference(mfaPreferenceInput)
        .then(() => {
          this.$emit('close');
        })
        .catch((e: Error) => {
          if (e.message === 'User has not verified software token mfa') {
            this.displayTotpSetup = true;
            return;
          }
          this.setError(e);
        });
    },
    verifyTotpToken(): void {
      verifyTOTPSetup({ code: this.code })
        .then(() => {
          this.logger.info('verifyTotpToken success');
          this.setMFA();
        })
        .catch((e) => this.setError(e));
    },
    setError(e: Error): void {
      this.error = e.message || e.toString();
      this.logger.error(this.error);
    },
    cancel(): void {
      return this.options.cancelHandler ? this.options.cancelHandler() : null;
    },
  },
});
</script>

<style lang="scss" scoped>
.authenticator-set-mfa {
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba($black, 0.15);
  padding: 35px 40px;
  position: relative;

  &__header {
    margin-bottom: 24px;
  }

  &__header-subtitle {
    color: $gray;
  }

  &__choice-field-option {
    margin-bottom: 22px;
  }

  &__actions {
    margin-top: 36px;
  }

  &__qr-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .amplify-button--primary {
    bottom: 36px;
  }
}
</style>
