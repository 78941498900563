import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5b7fd5b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "show-sales-order"
}
const _hoisted_2 = { class: "show-sales-order__label" }
const _hoisted_3 = {
  class: "modal-card",
  style: {"width":"auto"}
}
const _hoisted_4 = { class: "modal-card-head" }
const _hoisted_5 = { class: "modal-card-title" }
const _hoisted_6 = { class: "modal-card-body" }
const _hoisted_7 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OrderCard = _resolveComponent("OrderCard")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OModal = _resolveComponent("OModal")!

  return (_ctx.invoice && _ctx.invoice.SalesOrderId)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("a", {
          class: "show-sales-order__link",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSalesOrder && _ctx.showSalesOrder(...args)))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
          _createVNode(_component_OIcon, {
            class: "show-sales-order__icon",
            icon: "arrow_back"
          })
        ]),
        _createVNode(_component_OModal, {
          active: _ctx.showSalesOrderModal,
          "trap-focus": "",
          "aria-role": "dialog"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("header", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('invoices.detail.sales-order.title', {
                  invoice: _ctx.invoice.BillDoc,
                })), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "delete",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
                })
              ]),
              _createElementVNode("section", _hoisted_6, [
                (_ctx.salesOrder)
                  ? (_openBlock(), _createBlock(_component_OrderCard, {
                      key: 0,
                      order: _ctx.salesOrder,
                      type: "modal"
                    }, null, 8, ["order"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("footer", _hoisted_7, [
                _createVNode(_component_OButton, {
                  label: _ctx.$t('invoices.detail.sales-order.order-overview'),
                  variant: "primary",
                  rounded: "",
                  outlined: "",
                  tag: "router-link",
                  to: { name: 'orders' }
                }, null, 8, ["label"]),
                _createVNode(_component_OButton, {
                  label: _ctx.$t('invoices.detail.sales-order.close'),
                  variant: "primary",
                  rounded: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
                }, null, 8, ["label"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["active"])
      ]))
    : _createCommentVNode("", true)
}