import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.block && _ctx.link)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "block button is-primary is-rounded",
        href: _ctx.link,
        target: _ctx.block.target_blank ? '_blank' : '_self'
      }, _toDisplayString(_ctx.block.button_label), 9, _hoisted_1))
    : _createCommentVNode("", true)
}