import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e0c3f8b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-list__label" }
const _hoisted_2 = { class: "container-list__numbers" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "container-list__remaining"
}
const _hoisted_5 = { class: "container-list__detail-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_BaseMessage = _resolveComponent("BaseMessage")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", {
      class: "container-list",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.detailActive = !_ctx.detailActive))
    }, [
      _createElementVNode("strong", _hoisted_1, [
        _createVNode(_component_OIcon, {
          icon: "container",
          class: "container-list__icon"
        }),
        _createTextVNode(_toDisplayString(_ctx.$t('orders.card.header.container-numbers-short')), 1)
      ]),
      _createElementVNode("span", null, [
        _createElementVNode("span", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shortList, (container) => {
            return (_openBlock(), _createElementBlock("span", {
              key: container.id,
              class: "container-list__number"
            }, _toDisplayString(container.container_number), 1))
          }), 128))
        ]),
        (_ctx.remainingCount > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "…"))
          : _createCommentVNode("", true)
      ]),
      (_ctx.remainingCount > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "+" + _toDisplayString(_ctx.remainingCount), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_BaseMessage, {
      modelValue: _ctx.detailActive,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailActive) = $event)),
      class: "container-list__detail",
      title: 
        _ctx.$t('orders.card.header.container-numbers-long', _ctx.containers.length)
      ,
      size: "small"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.containers, (container) => {
            return (_openBlock(), _createElementBlock("span", {
              key: container.id,
              class: "container-list__detail-item"
            }, _toDisplayString(container.container_number), 1))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}