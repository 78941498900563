<template>
  <router-link
    v-if="label"
    :to="'/menu-engineering/category/' + label + 's'"
    @click="setRestaurantId()"
    class="category"
  >
    <div class="category__icon">
      <img
        :src="`/img/me/chef_${label}.svg`"
        :alt="$t('menuEngineering.imageAlt.' + label)"
      />
    </div>
    <div class="category__text">
      <span class="category__title">
        {{ $t(`menuEngineering.label.${label}s`) }}
      </span>
      <span class="category__amount"> ({{ dishCount }}) </span>
      <ul class="category__attributes">
        <li class="category__attribute">
          {{ $t(`menuEngineering.label.${label}.profit`) }}
        </li>
        <li class="category__attribute">
          {{ $t(`menuEngineering.label.${label}.demand`) }}
        </li>
      </ul>
    </div>
  </router-link>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    label(): string | undefined {
      const label = this.category === 'LOSER' ? 'LAGGARD' : this.category;
      return label?.toLowerCase();
    },
  },
  methods: {
    setRestaurantId() {
      this.$store.commit('restaurant/setActiveRestaurantId', this.restaurantId);
    },
  },
  props: {
    category: {
      type: String,
    },
    restaurantId: {
      type: String,
    },
    dishCount: {
      type: Number,
    },
  },
});
</script>

<style lang="scss" scoped>
.category {
  background: $white;
  box-shadow: $card-box-shadow;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0;

  &__icon {
    align-self: flex-end;
    margin-top: 18px;
    margin-left: 20px;
    flex: 0 1 140px;
    display: flex;
    align-items: flex-end;
  }

  &__title {
    color: $primary;
    line-height: 28px;
    font-weight: bold;
  }

  &__amount {
    color: $secondary;
    font-weight: bold;
  }

  &__attribute {
    font-size: 16px;
    line-height: 1.33;
    color: $gray-steel;
    transform: translateX(-14px);

    &::before {
      content: '•';
      display: inline-block;
      width: 12px;
    }
  }
}
</style>
