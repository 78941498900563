import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_OButton, {
      rounded: "",
      outlined: "",
      variant: "primary",
      class: "button-row__button",
      onClick: _ctx.cancel
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('profile.label.cancel')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_OButton, {
      variant: "primary",
      rounded: "",
      "native-type": "submit",
      class: "button-row__button",
      disabled: !_ctx.valid
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('profile.label.save')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}