<template>
  <div class="block">
    <video
      v-if="block"
      class="block__video"
      :src="block.file && block.file.url"
      oncontextmenu="return false;"
      controls
    />
  </div>
</template>

<script lang="ts">
import { SuluBlock } from '@/logic/cms/sulu';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Object as () => SuluBlock[],
    },
  },
});
</script>

<style lang="scss" scoped>
.block {
  &__video {
    border-radius: 8px;
  }
}
</style>
