<template>
  <div id="app">
    <ImpersonateBar />
    <RouterView />
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue';
import { Hub } from 'aws-amplify/utils';
import ImpersonateBar from '@/components/user-management/ImpersonateBar.vue';
import { defineComponent } from 'vue';
import { LocationQueryValue, RouteLocationRaw } from 'vue-router';

interface OrugaLoading {
  close: () => void;
}

export default defineComponent({
  components: {
    ImpersonateBar,
  },
  data() {
    return {
      loading: null as OrugaLoading | null,
    };
  },
  async created() {
    Hub.listen('auth', this.onAuth);

    this.$store.dispatch('auth/getUser');
  },
  methods: {
    async onAuth(data: any) {
      const { payload } = data;
      if (payload.event === 'signedIn') {
        // Open loader when signIn event is active
        this.loading = this.$oruga.loading.open({});

        await this.$store.dispatch('auth/getUser');

        // Remember the device if the option was set during login
        this.rememberDevice(payload.data);

        // Get the profile
        await this.$store.dispatch('profile/getProfile');
        await this.redirectOnSignIn();
      }
      if (payload.event === 'signedOut') {
        await this.$store.dispatch('resetState');
        await this.$router.push('/auth');
      }
    },
    rememberDevice(user: any) {
      if (!this.$store.state.auth.rememberDevice) {
        return;
      }

      user.getCachedDeviceKeyAndPassword();
      user.setDeviceStatusRemembered({
        onFailure(err: any) {
          Sentry.captureException(err);
        },
      });
    },
    onLocaleChange(newVal?: string): void {
      if (newVal) {
        document.documentElement.setAttribute('lang', newVal);
      }
    },
    async redirectOnSignIn() {
      // Close loader when profile is available and redirect to home
      if (this.loading) {
        this.loading.close();
      }

      const redirect: LocationQueryValue[] | LocationQueryValue =
        this.$route.query.redirect || '/dashboard';
      await this.$router.push(redirect as RouteLocationRaw);
    },
  },
  watch: {
    '$i18n.locale': [{ immediate: true, handler: 'onLocaleChange' }],
  },
});
</script>
