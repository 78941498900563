<template>
  <OField>
    <div class="control is-clearfix">
      <input
        class="input"
        :class="{ 'input--clamped': clamped }"
        type="number"
        :value="value"
        @input="onInput"
        step="1"
        :disabled="$attrs.disabled"
      />
    </div>
  </OField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      min: 0,
      clamped: false,
    };
  },
  methods: {
    onInput(event: any) {
      const newValue = parseInt(event.target.value, 10) || 0;
      const clampedValue =
        Math.max(Math.min(newValue, this.max), this.min) || 0;
      this.$emit('update:modelValue', clampedValue);
      if (clampedValue !== newValue) {
        this.clamped = true;
        setTimeout(() => {
          this.clamped = false;
        }, 600);
      } else {
        this.clamped = false;
      }
      this.$forceUpdate();
    },
  },
  props: {
    value: {
      type: String,
    },
    max: { type: Number, default: 99999 },
  },
});
</script>

<style lang="scss" scoped>
@keyframes blink {
  50% {
    border-color: $red;
    outline: $red;
    color: $red;
  }
}
.input--clamped {
  animation: blink 0.2s;
  animation-iteration-count: 3;
}
</style>
