import { getCurrentUser } from 'aws-amplify/auth';
import { ProfileApi } from '@/api';
import { Profile, ProfileManagement } from '@/api/model';
import TrackingService from '@/logic/profile/TrackingService';
import getConfiguration from '@/store/getConfiguration';
import AuthGroup from '@/logic/auth/AuthGroup';
import i18n from '@/i18n';

const initialState = () => ({
  profile: {} as ProfileManagement, // profile of logged in user
});
export type ProfileState = ReturnType<typeof initialState>;

async function addProfile(commit: Function, dispatch: Function) {
  const user: any = await getCurrentUser();
  let newProfile: Profile = {
    email: user.attributes.email,
    locale: user.attributes.locale,
  };
  if (user.attributes.phone) {
    newProfile.phone = user.attributes.phone;
  }

  const utmProps = TrackingService.getUtmProperties();
  if (utmProps) {
    newProfile = { ...newProfile, ...utmProps };
  }
  commit('setProfile', newProfile);
  dispatch('saveProfile', newProfile);
}

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: ProfileState) {
      Object.assign(state, initialState());
    },

    setProfile(state: ProfileState, profile: Profile | ProfileManagement) {
      state.profile = profile;

      if (profile.locale) {
        i18n.global.locale.value = profile.locale;
      }
    },

    deleteProfile(state: ProfileState) {
      state.profile = {};
    },

    setProfileField(state: ProfileState, { fieldName, value }: any) {
      state.profile[fieldName as keyof ProfileManagement] = value;
    },
  },

  actions: {
    async getProfile({
      commit,
      dispatch,
    }: {
      commit: Function;
      dispatch: Function;
    }) {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');

      let profile = {};
      try {
        const response = await api.profileGet();
        const jsonResponse = JSON.parse(JSON.stringify(response));
        profile = jsonResponse.data;
      } catch (e: any) {
        if (
          e.response?.status === 400 &&
          e.response?.data?.message?.toString().includes('clear impersonation')
        ) {
          dispatch('stopImpersonate');
        }
      }

      if (Object.keys(profile).length === 0) {
        await addProfile(commit, dispatch);

        // run getUser again to fetch roles for the new created profile.
        dispatch('auth/getUser', { root: true });
      } else {
        commit('setProfile', profile);
      }
    },

    // Save own profile
    async saveProfile({ commit }: { commit: Function }, profile: Profile) {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      const { data } = await api.profilePut(profile);
      commit('setProfile', data);
    },

    async deleteProfile({ commit }: { commit: Function }, profile: Profile) {
      commit('deleteProfile', profile);
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      await api.profilePut({} as Profile);
    },

    async changeSapId(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      sapId: string,
    ) {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      await api.profileSapCustomerSwitchPost(sapId);
      commit('resetState');
      dispatch('getProfile');

      // eslint-disable-next-line no-restricted-globals
      if (location) {
        // We need to reload the client to empty out all the user dependent data
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    },

    // eslint-disable-next-line no-empty-pattern
    async impersonate({}, clientId: string): Promise<void> {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');

      await api.impersonatePost(clientId);
      window.location.href = '/dashboard';
    },

    // eslint-disable-next-line no-empty-pattern
    async stopImpersonate({}): Promise<void> {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');

      await api.impersonatePost();
      window.location.href = '/user-management';
    },
  },

  getters: {
    fullName: (state: ProfileState) => {
      const { firstName, lastName } = state.profile;
      const first = firstName || '';
      const last = lastName || '';
      const space = first && last ? ' ' : '';
      return `${first}${space}${last}`;
    },

    getSapCompany: (state: ProfileState): string => {
      const profile = state.profile as ProfileManagement; // Use read model
      return profile?.sapCustomer?.CustName1
        ? profile?.sapCustomer?.CustName1
        : '-';
    },

    getSapCompanies: (state: ProfileState): any => {
      const profile = state.profile as ProfileManagement; // Use read model
      return profile.enabledSapCustomerNumbers;
    },

    group: (state: ProfileState): AuthGroup | undefined => {
      if (state.profile.group) {
        return state.profile.group as AuthGroup;
      }
      return undefined;
    },

    email: (state: ProfileState): string | null | undefined =>
      state.profile.email,

    isContainer: (state: ProfileState): boolean => {
      const profile = state.profile as ProfileManagement; // Use read model
      return !!profile?.sapCustomer?.IsContainerCustomer;
    },

    isImpersonated: (state: ProfileState): boolean => {
      if (!state.profile) {
        return false;
      }

      return !!state.profile.isImpersonated;
    },
  },
};
