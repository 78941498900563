<template>
  <div class="slider">
    <div class="slider__upper">
      <div class="slider__label">
        <span class="slider__label-text">{{ label }}</span>
        <OTooltip v-if="tooltip" :label="tooltip">
          <img
            :src="`/img/icons/info.svg`"
            :alt="'More information'"
            class="slider__info"
          />
        </OTooltip>
        <OIcon icon="info" />
      </div>
      <OInput
        size="small"
        type="number"
        :modelValue="value"
        @update:modelValue="input($event)"
        :min="min"
        :max="max"
        pattern="\d+"
        root-class="slider__input"
      ></OInput>
    </div>
    <div class="slider__lower">
      <OIcon
        @click="subtract"
        class="slider__button slider__button--subtract"
        variant="white"
        icon="minus"
      />
      <OSlider
        @update:modelValue="input($event)"
        :modelValue="value"
        rounded
        :min="min"
        :max="max"
        bigger-slider-focus
        variant="secondary"
      >
        <OSliderTick :value="min">{{ min }}</OSliderTick>
        <OSliderTick :value="max">{{ max }}</OSliderTick>
      </OSlider>
      <OIcon
        @click="add"
        class="slider__button slider__button--add"
        variant="white"
        icon="plus"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  },
  methods: {
    add() {
      if (this.value < this.max) {
        this.input(this.value + 1);
      }
    },
    subtract() {
      if (this.value > this.min) {
        this.input(this.value - 1);
      }
    },
    input(value: number) {
      this.$emit('onInput', value);
    },
  },
});
</script>

<style lang="scss" scoped>
.slider {
  margin-bottom: 32px;

  &__upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 56, 90, 0.05);
    min-height: 66px;
    padding: 5px 16px;
    color: $primary;
    border-radius: 4px 4px 0 0;

    @include tablet {
      min-height: 55px;
    }
  }

  &__label {
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
  }

  &__label-text {
    flex: 0 1;
    white-space: nowrap;
  }

  &__info {
    align-self: flex-start;
    margin-left: 5px;
    cursor: pointer;
  }

  &__input {
    flex: 0 0 70px;
    margin-left: 16px;

    @include desktop {
      flex: 0 0 75px;
    }

    :deep(.input) {
      height: 2.5em;
      border-color: $gallery;
    }
  }

  &__lower {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px;
  }

  &__button {
    background: $primary;
    flex: 0 0 24px;
    height: 24px;
    border-radius: 24px;
    font-size: 10px;
    cursor: pointer;

    &--subtract {
      font-size: 2px;
      margin-right: 16px;
    }

    &--add {
      margin-left: 16px;
    }
  }
}
</style>
