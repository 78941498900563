<template>
  <div v-if="block.youtube_identifier != ''" class="block block--youtube">
    <h2 v-if="block.title" class="title title--h2 block__title">
      {{ block.title }}
    </h2>
    <figure class="image is-16by9">
      <iframe
        :src="`https://www.youtube.com/embed/${block.youtube_identifier}`"
        title="YouTube"
        class="has-ratio"
        width="100%"
        height="100%"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </figure>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
