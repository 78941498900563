import { RoleApi } from '@/api';
import getConfiguration from '@/store/getConfiguration';
import { Role, Policy, RoleAttributes } from '@/api/model';

export async function getApi() {
  const configuration = await getConfiguration();
  return new RoleApi(configuration, '');
}

const initialState = () => ({
  roles: [] as Role[],
  roleAttributes: {} as RoleAttributes,
});
export type RoleState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: RoleState) {
      Object.assign(state, initialState());
    },

    setRoles(state: RoleState, roles: Role[]) {
      state.roles = roles;
    },

    setRoleAttributes(state: RoleState, roleAttributes: RoleAttributes) {
      state.roleAttributes = roleAttributes;
    },

    updateRole(state: RoleState, updateRole: Role) {
      const index = state.roles.findIndex(
        (role: Role) => role.id === updateRole.id,
      );
      if (index !== -1) {
        state.roles[index] = updateRole;
      } else {
        state.roles.push(updateRole);
      }
      state.roles = [...state.roles];
    },
  },

  actions: {
    async fetchRoles({ commit }: { commit: Function }) {
      const api = await getApi();
      const { data } = await api.rolesGet();
      commit('setRoles', data);
    },

    async getRoleAttributes({ commit }: { commit: Function }) {
      const api = await getApi();
      const { data } = await api.roleAttributesGet();
      commit('setRoleAttributes', data);
    },

    async savePolicy(
      { dispatch, state }: { dispatch: Function; state: any },
      policy: Policy,
    ) {
      const putPolicy = { ...policy };
      const foundRole = state.roles.find(
        (role: Role) => role.id === putPolicy.roleId,
      );
      const updateRole = { ...foundRole };
      if (putPolicy.id) {
        const index = updateRole.policies.findIndex(
          (p: Policy) => p.id === putPolicy.id,
        );
        updateRole.policies[index] = putPolicy;
      } else {
        updateRole.policies.push(putPolicy);
      }
      await dispatch('saveRole', updateRole);
    },

    async saveRole({ commit }: { commit: Function }, role: Role) {
      const putRole = { ...role };
      const api = await getApi();
      const { data } = await api.rolePut(putRole);
      commit('updateRole', data);
      return data;
    },
  },
  getters: {
    endpoints: (state: RoleState): string[] => {
      if (state.roleAttributes && state.roleAttributes.endpoints) {
        return Object.keys(state.roleAttributes.endpoints).sort();
      }
      return [];
    },
  },
};
