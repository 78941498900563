<template>
  <div class="invoice-status" :class="modifier">
    <span class="invoice-status__label">{{ status }}</span>
    <span class="invoice-status__days" v-if="showDays">
      ({{ $t('invoices.status.days-till-due-date', invoice.DaysTillDueDate) }})
    </span>
  </div>
</template>
<script lang="ts">
import { SapInvoice } from '@/api/model';
import InvoiceStatusEnum from '@/types/invoices/InvoiceStatusEnum';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    status(): string {
      if (
        Object.values(InvoiceStatusEnum).includes(
          this.invoice.AccStatusText as InvoiceStatusEnum,
        )
      ) {
        return this.$t(
          `invoices.status.${this.invoice.AccStatusText}`,
        ) as string;
      }
      return '-';
    },
    modifier(): string {
      const days = this.invoice.DaysTillDueDate || 0;

      let modifier = '';
      switch (this.invoice.AccStatusText) {
        case InvoiceStatusEnum.BEFORE_DUE:
          modifier = days <= 7 ? 'less-than-week' : 'more-than-week';
          break;
        case InvoiceStatusEnum.DUE_DATE:
          modifier = 'less-than-week';
          break;
        case InvoiceStatusEnum.OVERDUE:
          modifier = 'overdue';
          break;
        case InvoiceStatusEnum.CLEARED:
          modifier = 'paid';
          break;
        case InvoiceStatusEnum.REVERSED:
          modifier = 'reversed';
          break;
        default:
          // All cases are covered already
          break;
      }
      return modifier ? `invoice-status--${modifier}` : '';
    },
    showDays(): boolean {
      const { DaysTillDueDate, AccStatusText } = this.invoice;
      if (DaysTillDueDate === undefined || AccStatusText === undefined) {
        return false;
      }
      return [
        InvoiceStatusEnum.BEFORE_DUE,
        InvoiceStatusEnum.DUE_DATE,
      ].includes(AccStatusText as InvoiceStatusEnum);
    },
  },
  props: {
    invoice: {
      type: Object as () => SapInvoice,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.invoice-status {
  color: $white;
  border-radius: 6px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $jelly-bean;
  padding: 0 6px;
  min-width: 77px;
  font-size: 14px;
  white-space: nowrap;

  &--less-than-week {
    background-color: $orange;
  }

  &--overdue {
    background-color: $red;
  }

  &--paid {
    background-color: $green-approx;
  }

  &__label {
    font-weight: bold;
  }

  &__days {
    margin-left: 6px;
  }
}
</style>
