import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1dd589d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ship-to" }
const _hoisted_2 = { class: "ship-to__title" }
const _hoisted_3 = { class: "ship-to__content" }
const _hoisted_4 = {
  key: 0,
  class: "partner--skeleton"
}
const _hoisted_5 = { class: "check" }
const _hoisted_6 = { class: "ship-to__address" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShipToAddress = _resolveComponent("ShipToAddress")!
  const _component_ORadio = _resolveComponent("ORadio")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('orders.shipping.ship-to.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
        return (_openBlock(), _createBlock(_component_ORadio, {
          key: partner.Id,
          "root-class": "radio--partner",
          modelValue: _ctx.shipToParty,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShipToParty($event))),
          "native-value": partner.CustomerNr,
          required: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ShipToAddress, { "ship-to": partner }, null, 8, ["ship-to"])
          ]),
          _: 2
        }, 1032, ["modelValue", "native-value"]))
      }), 128)),
      (_ctx.loadingShipToPartners)
        ? (_openBlock(), _createElementBlock("label", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_OSkeleton, {
                circle: "",
                width: "16",
                height: "16"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2], (index) => {
                return _createVNode(_component_OSkeleton, {
                  class: "ship-to__value",
                  key: index
                })
              }), 64))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}