import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-117cb079"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-group__content layout__content layout__content--large" }
const _hoisted_2 = { class: "toggle-button__label" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "product-group__cell-wrapper" }
const _hoisted_5 = {
  key: 1,
  class: "product-group__suggestion-button"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "product-group__count"
}
const _hoisted_8 = { class: "product-group__cell-wrapper" }
const _hoisted_9 = { class: "product-group__cell-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_BaseFieldQuantity = _resolveComponent("BaseFieldQuantity")!
  const _component_NewOrderTooltip = _resolveComponent("NewOrderTooltip")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_BaseCurrencyAmount = _resolveComponent("BaseCurrencyAmount")!
  const _component_o_table = _resolveComponent("o-table")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(`product-group product-group--${_ctx.type}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.groupLabel)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "toggle-button toggle-button--product-group",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleActive(!_ctx.active))),
            role: "button",
            "data-test": "toggle-product-group"
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.groupLabel), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["toggle-button__icon", { 'toggle-button__icon--active': _ctx.active }])
            }, [
              _createVNode(_component_OIcon, { icon: "chevron_up" })
            ], 2)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "slide" }, {
        default: _withCtx(() => [
          (_ctx.active)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.sortedProducts)
                  ? (_openBlock(), _createBlock(_component_o_table, {
                      key: 0,
                      data: _ctx.sortedProducts,
                      "root-class": "product-item-list",
                      striped: "",
                      "row-class": (row) => _ctx.isDisabled(row) && 'is-disabled'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_o_table_column, {
                          label: _ctx.$t('orders.order-item-list.header.product')
                        }, {
                          default: _withCtx((props) => [
                            _createTextVNode(_toDisplayString(_ctx.customText && props.row.CustomProductDesc
                  ? props.row.CustomProductDesc
                  : props.row.ProductText), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_o_table_column, {
                          label: _ctx.$t('orders.order-item-list.header.code'),
                          width: 110
                        }, {
                          default: _withCtx((props) => [
                            _createTextVNode(_toDisplayString(props.row.ShortCode), 1)
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        (_ctx.type === 'order')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 0,
                              label: _ctx.$t('orders.order-item-list.header.warehouse'),
                              width: 120
                            }, {
                              default: _withCtx((props) => [
                                _createTextVNode(_toDisplayString(props.row.DeliveryPlantCity), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type !== 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 1,
                              label: _ctx.$t('orders.order-item-list.header.art'),
                              width: 100
                            }, {
                              default: _withCtx((props) => [
                                _createTextVNode(_toDisplayString(_ctx.customText && props.row.CustomProductNr
                  ? props.row.CustomProductNr
                  : props.row.ProductNr), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'container-calculation')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 2,
                              label: _ctx.$t('orders.order-item-list.header.boxes'),
                              width: 120
                            }, {
                              default: _withCtx((props) => [
                                _createTextVNode(_toDisplayString(_ctx.calculateBoxes(props.row) || ''), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type !== 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 3,
                              label: _ctx.$t('orders.order-item-list.header.pallets'),
                              width: _ctx.type === 'container-calculation' ? 209 : 90,
                              position: _ctx.type !== 'container-calculation' ? 'centered' : null,
                              "td-attrs": () => ({ class: 'cell--input' })
                            }, {
                              default: _withCtx((props) => [
                                _createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_BaseFieldQuantity, {
                                    value: _ctx.getQuantity(props.row, 'PAL'),
                                    "onUpdate:modelValue": ($event: any) => (_ctx.setQuantity(props.row, $event, 'PAL')),
                                    disabled: _ctx.isDisabled(props.row),
                                    max: 45,
                                    class: _normalizeClass({
                    'product-group__suggestion':
                      _ctx.hasContainerSuggestion(props.row) ||
                      _ctx.hasTruckSuggestion(props.row) ||
                      _ctx.fillStatus === 'overfilled-no-product',
                    'product-group__suggestion--fill':
                      _ctx.hasTruckSuggestion(props.row) &&
                      _ctx.$store.getters['order/suggestionAction'].startsWith(
                        'suggest-fill',
                      ),
                  }),
                                    "data-test": "input-pallets"
                                  }, null, 8, ["value", "onUpdate:modelValue", "disabled", "class"]),
                                  (_ctx.hasTruckSuggestion(props.row))
                                    ? (_openBlock(), _createBlock(_component_NewOrderTooltip, {
                                        key: 0,
                                        "product-nr": props.row.ProductNr,
                                        onAccept: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTruckSuggestion($event)))
                                      }, null, 8, ["product-nr"]))
                                    : _createCommentVNode("", true),
                                  (_ctx.hasContainerSuggestion(props.row))
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                        _createVNode(_component_OButton, {
                                          rounded: "",
                                          variant: "secondary",
                                          size: "small",
                                          onClick: ($event: any) => (_ctx.setContainerSuggestion(props.row)),
                                          loading: _ctx.$store.state.container.isCalculating
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.containerSuggestionLabel), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick", "loading"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }, 8, ["label", "width", "position"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'order')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 4,
                              label: _ctx.$t('orders.order-item-list.header.layers'),
                              width: 90,
                              "root-class": "is-centered",
                              "td-attrs": () => ({ class: 'cell--input' })
                            }, {
                              default: _withCtx((props) => [
                                (props.row.AltUnit !== 'PAL')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                      _createVNode(_component_BaseFieldQuantity, {
                                        value: _ctx.getQuantity(props.row, 'LAY'),
                                        "onUpdate:modelValue": ($event: any) => (_ctx.setQuantity(props.row, $event, 'LAY')),
                                        disabled: _ctx.isDisabled(props.row),
                                        max: 750
                                      }, null, 8, ["value", "onUpdate:modelValue", "disabled"])
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.calculateLayers(props.row) || ''), 1))
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'order')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 5,
                              label: _ctx.$t('orders.order-item-list.header.boxes'),
                              width: 100,
                              "root-class": "is-centered"
                            }, {
                              default: _withCtx((props) => [
                                _createTextVNode(_toDisplayString(_ctx.calculateBoxes(props.row) || ''), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 6,
                              label: _ctx.$t('claims.new.items.bags'),
                              width: 90,
                              "td-attrs": () => ({ class: 'cell--input' })
                            }, {
                              default: _withCtx((props) => [
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_BaseFieldQuantity, {
                                    value: _ctx.getQuantity(props.row, 'BAG'),
                                    "onUpdate:modelValue": ($event: any) => (_ctx.setQuantity(props.row, $event, 'BAG')),
                                    disabled: _ctx.getQuantity(props.row, 'CAR') > 0,
                                    "data-test": "input-bags"
                                  }, null, 8, ["value", "onUpdate:modelValue", "disabled"])
                                ])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 7,
                              label: _ctx.$t('claims.new.items.boxes'),
                              width: 90,
                              "td-attrs": () => ({ class: 'cell--input' })
                            }, {
                              default: _withCtx((props) => [
                                _createElementVNode("div", _hoisted_9, [
                                  _createVNode(_component_BaseFieldQuantity, {
                                    value: _ctx.getQuantity(props.row, 'CAR'),
                                    "onUpdate:modelValue": ($event: any) => (_ctx.setQuantity(props.row, $event, 'CAR')),
                                    disabled: _ctx.getQuantity(props.row, 'BAG') > 0,
                                    "data-test": "input-cartons"
                                  }, null, 8, ["value", "onUpdate:modelValue", "disabled"])
                                ])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 8,
                              label: _ctx.$t('claims.new.items.volume'),
                              width: 90
                            }, {
                              default: _withCtx((props) => [
                                _createTextVNode(_toDisplayString(_ctx.calculateClaim(props.row).volume) + " " + _toDisplayString(_ctx.$t('claims.kg')), 1)
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 9,
                              label: _ctx.$t('claims.new.items.billback-rate'),
                              numeric: "",
                              width: 120
                            }, {
                              default: _withCtx(({ row }) => [
                                _createVNode(_component_BaseCurrencyAmount, {
                                  currency: _ctx.agreement.currency,
                                  amount: _ctx.agreementItem(row).billback_rate
                                }, null, 8, ["currency", "amount"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.type === 'claim')
                          ? (_openBlock(), _createBlock(_component_o_table_column, {
                              key: 10,
                              label: _ctx.$t('claims.new.items.billback-amount'),
                              numeric: "",
                              width: 155
                            }, {
                              default: _withCtx((props) => [
                                _createVNode(_component_BaseCurrencyAmount, {
                                  currency: _ctx.agreement.currency,
                                  amount: _ctx.calculateClaim(props.row).billbackAmount
                                }, null, 8, ["currency", "amount"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["data", "row-class"]))
                  : (_openBlock(), _createBlock(_component_OSkeleton, {
                      key: 1,
                      animated: true,
                      height: "194px"
                    }))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 2))
}