<template>
  <div
    class="layout__content layout__content--large order-overview"
    v-if="hasOrders"
  >
    <section class="order-overview__search-bar action-bar">
      <div class="action-bar__content">
        <OField class="filter action-bar__filter">
          <OSelect
            root-class="select--search"
            icon="filter"
            expanded
            :placeholder="$t('orders.overview.search-bar.status.placeholder')"
            v-model="statusFilter"
          >
            <option value="">
              {{ $t('orders.overview.search-bar.status.all') }}
            </option>
            <optgroup
              v-for="group in Object.keys(statusOptions)"
              :label="$t(`orders.overview.${group}-orders-title`)"
              :key="group"
            >
              <option
                v-for="option in statusOptions[group]"
                :value="option"
                :key="option"
              >
                {{ getStatusLabel(option) }}
              </option>
            </optgroup>
          </OSelect>
        </OField>
        <OField class="action-bar__search">
          <OInput
            v-model="searchQuery"
            type="search"
            icon-right="search"
            size="medium"
            root-class="input--search"
            :placeholder="$t('orders.overview.search-bar.query.placeholder')"
          />
        </OField>
      </div>
    </section>

    <OrderOverviewTable :orders="orders" :per-page="20" :loading="loading" />
  </div>
</template>

<script lang="ts">
import { SalesOrder, SalesOrderOrderStatusEnum } from '@/api/model';
import Helpers from '@/logic/orders/helpers';
import OrderOverviewTable from '@/components/orders/OrderOverviewTable.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { OrderOverviewTable },
  data() {
    return {
      currentPage: 1,
      perPage: 3,
      loading: false,
      searchQuery: '',
      statusFilter: '',
    };
  },
  computed: {
    orders(): SalesOrder[] {
      let orders = [...this.$store.getters['order/sortedOrders']];

      if (this.searchQuery) {
        // All fields that are full text searchable
        const textFields = [
          'OrderNumber',
          'PurchOrder',
          'City',
          'BillOfLading',
          'CreatedOn',
        ];
        orders = orders.filter((item: any) =>
          textFields.some((field: string) => {
            if (!item[field]) {
              return false;
            }
            const query = this.searchQuery.toLowerCase();
            const value =
              field !== 'CreatedOn'
                ? item[field].toLowerCase()
                : Helpers.getReadableCreatedDate(item);
            return value.includes(query);
          }),
        );
      }

      if (this.statusFilter) {
        orders = orders.filter(
          (item: any) => item.OrderStatus === this.statusFilter,
        );
      }

      return orders;
    },
    hasOrders(): boolean {
      return this.$store.getters['order/hasOrders'];
    },
    paginatedOrders(): SalesOrder[] {
      return this.orders.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
    statusOptions(): any {
      return {
        open: [
          SalesOrderOrderStatusEnum.AwsCreated,
          SalesOrderOrderStatusEnum.SapCreated,
          SalesOrderOrderStatusEnum.SapProcessing,
          SalesOrderOrderStatusEnum.SapProcessingPart,
        ],
        closed: [
          SalesOrderOrderStatusEnum.SapShipped,
          SalesOrderOrderStatusEnum.SapShippedPart,
          SalesOrderOrderStatusEnum.Delivered,
          SalesOrderOrderStatusEnum.SapCancelled,
          SalesOrderOrderStatusEnum.SapUnknown,
        ],
      };
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.$store.dispatch('order/fetchOrders');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    filterStatus(group: 'open' | 'closed'): SalesOrder[] {
      const filters = this.statusOptions;
      return this.orders.filter(
        (order) =>
          order.OrderStatus && filters[group].includes(order.OrderStatus),
      );
    },
    getStatusLabel(status: SalesOrderOrderStatusEnum): string {
      const label = this.$t(`orders.status.${status}`);
      const showOnStatus = [
        SalesOrderOrderStatusEnum.SapProcessingPart,
        SalesOrderOrderStatusEnum.SapShippedPart,
      ];
      const partly = showOnStatus.includes(status)
        ? ` | ${this.$t('orders.status.partly')}`
        : '';
      return `${label}${partly}`;
    },
    routeChanged(newVal: any) {
      this.currentPage = parseInt(newVal.hash.replace(/#page/g, ''), 10) || 1;
    },
  },
  watch: {
    $route: [{ immediate: true, handler: 'routeChanged' }],
  },
});
</script>

<style lang="scss">
.order-overview {
  margin-bottom: 56px;

  &--open {
    margin-bottom: 56px;
  }

  &__search-bar {
    margin-bottom: 24px;
  }

  .order-overview__pagination {
    margin-top: 38px;
  }

  .input--search {
    width: 100%;
  }
}
</style>
