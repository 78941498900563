import { InvoiceApi } from '@/api';
import getConfiguration from '@/store/getConfiguration';
import { Invoices } from '@/types/invoices/Invoices';

async function getApi() {
  const configuration = await getConfiguration();
  return new InvoiceApi(configuration, '');
}

const initialState = () => ({
  invoices: {
    current_page: 1,
    items: [],
    pages: 0,
    per_page: 7,
    total: 0,
  } as Invoices,
  query: '',
  filter: '',
  currentPage: 1,
  perPage: 20,
  sort: 'BillDate+desc',
  isLoading: false,
});
export type InvoiceState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: InvoiceState) {
      Object.assign(state, initialState());
    },

    setInvoices(state: InvoiceState, invoices: Invoices) {
      state.invoices = invoices;
    },

    setCurrentPage(state: InvoiceState, currentPage: number) {
      state.currentPage = currentPage;
    },

    setQuery(state: InvoiceState, query: string) {
      const trimmed = query.trim();
      if (trimmed === state.query) {
        return;
      }

      state.query = trimmed.length >= 3 ? trimmed : '';
      state.currentPage = 1;
    },

    setFilter(state: InvoiceState, filter: string) {
      state.filter = filter;
      state.currentPage = 1;
    },

    setSort(state: InvoiceState, sort: string) {
      state.sort = sort;
    },

    setLoading(state: InvoiceState, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },

  actions: {
    async fetchInvoices(
      { commit, state }: { commit: Function; state: InvoiceState },
      options?: any,
    ) {
      commit('setLoading', true);
      const { perPage, currentPage, filter, query, sort } = options || {};
      if (currentPage !== undefined) {
        commit('setCurrentPage', currentPage);
      }

      const api = await getApi();
      const { data } = await api.invoicesGet(
        perPage || state.perPage,
        state.currentPage,
        filter || state.filter,
        query || state.query,
        sort || state.sort,
      );
      commit('setLoading', false);
      commit('setInvoices', data);
    },

    async search(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      query: string,
    ) {
      commit('setQuery', query);
      dispatch('fetchInvoices');
    },

    async filter(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      filter: string,
    ) {
      commit('setFilter', filter);
      dispatch('fetchInvoices');
    },

    async sort(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      sort: string,
    ) {
      commit('setSort', sort);
      dispatch('fetchInvoices');
    },
  },
  getters: {
    isLoading: (state: InvoiceState): boolean => state.isLoading,
  },
};
