<template>
  <div class="message is-info">
    <div class="message-header">{{ $t('profile.mfa.totp-info.title') }}</div>
    <div class="message-body">
      <div class="content">
        <ul>
          <li>
            {{ $t('profile.mfa.totp-info.1-select-option') }}
          </li>
          <i18n
            tag="li"
            path="profile.mfa.totp-info.2-get-app"
            for="profile.mfa.totp-info.2-get-app-link"
          >
            <a
              href="https://support.google.com/accounts/answer/1066447"
              rel="noopener noreferrer"
              target="_blank"
            >
              {{ $t('profile.mfa.totp-info.2-get-app-link') }}</a
            >
          </i18n>
          <li>{{ $t('profile.mfa.totp-info.3-press-plus') }}</li>
          <li>{{ $t('profile.mfa.totp-info.4-select-scan') }}</li>
          <li>{{ $t('profile.mfa.totp-info.5-enter-code') }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
