<template>
  <LayoutAuthentication>
    <div class="authentication">
      <div class="authentication__bg" />
      <div class="authentication__topbar">
        <LinkCorporate />
        <SwitchLocale />
      </div>
      <div key="not-signed-in">
        <AuthenticatorCustom
          :key="`amp-${$i18n.locale}`"
          class="authentication__authenticator"
        />
      </div>
    </div>
  </LayoutAuthentication>
</template>

<script lang="ts">
import { I18n } from '@aws-amplify/core';
import LayoutAuthentication from '@/layouts/LayoutAuthentication.vue';
import AmplifyI18n from '@/components/authentication/AmplifyI18n';
import AuthenticatorCustom from '@/components/authentication/AuthenticatorCustom.vue';
import LinkCorporate from '@/components/LinkCorporate.vue';
import SwitchLocale from '@/components/SwitchLocale.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    AuthenticatorCustom,
    LinkCorporate,
    SwitchLocale,
    LayoutAuthentication,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user(): any {
      return this.$store.state.auth.user;
    },
    signedIn(): boolean {
      return this.$store.getters['auth/signedIn'];
    },
  },
  async created(): Promise<void> {
    await this.$store.dispatch('auth/getUser');

    // If user is authenticated and redirect is set, do redirect immediately
    if (this.$store.getters['auth/signedIn']) {
      if (this.$route.query.redirect) {
        await this.$router.push(this.$route.query.redirect as string);
      }
    }
  },
  mounted() {
    I18n.putVocabularies(AmplifyI18n);

    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        I18n.setLanguage(newLocale);
      },
      { immediate: true },
    );
  },
});
</script>

<style lang="scss">
.authentication {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary;
  height: 100vh;
  padding: 0 20px;

  &__topbar {
    align-self: stretch;
    min-height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__authenticator {
    margin-top: 20px;
    max-width: 368px;

    @media screen and (min-width: $amplify-medium) {
      max-width: 535px;
    }
  }

  &__bg {
    display: none;

    @include desktop {
      display: block;
      background-image: url('~/public/img/fries.jpg');
      background-size: cover;
      background-position: center bottom;
      position: absolute;
      top: 0;
      right: -5px;
      clip-path: polygon(9% 0%, 100% 0%, 100% 100%, 0% 100%);
      width: 50vw;
      height: 100vh;
    }
  }
}
</style>
