<template>
  <Field :name="$attrs.name" :rules="$attrs.rules" v-slot="{ errors, field }">
    <OField
      :label="$attrs.label"
      :message="errors.length ? errors[0] : ''"
      root-class="date-range"
      v-bind="$attrs"
      :class="classNames(errors)"
      :variant="errors.length ? 'danger' : ''"
      :horizontal="$attrs.horizontal"
    >
      <ODatepicker
        v-bind="field"
        :modelValue="value"
        @update:modelValue="onInput"
        :placeholder="$attrs.placeholder"
        :disabled="$attrs.disabled"
        expanded
        icon="calendar"
        icon-prev="chevron_left"
        icon-next="chevron_right"
        type="month"
        trap-focus
        range
        :min-date="minDate"
        :max-date="maxDate"
        :focused-date="minDate"
        :close-on-click="true"
        :date-formatter="dateFormatter"
        @range-start="rangeStart($event)"
      >
        <template #footer>
          <a
            role="button"
            :label="$t('claims.new.steps.details-form.period.clear')"
            @click="clear"
            class="date-range__clear"
          >
            {{ $t('claims.new.steps.details-form.period.clear') }}
          </a>
        </template>
      </ODatepicker>
      <transition name="fade" v-if="!$attrs.disabled">
        <OIcon
          v-if="value && value.length"
          class="date-range__clear-icon"
          icon="close_circle"
          role="button"
          @click="clear"
        />
      </transition>
    </OField>
  </Field>
</template>

<script lang="ts">
import { Field } from 'vee-validate';
import { addMonths, subDays } from 'date-fns';
import Helpers from '@/logic/orders/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Field },
  computed: {
    rules(): any {
      return this.$attrs.rules;
    },
  },
  methods: {
    dateFormatter(dates: Date[]): string {
      if (
        !Array.isArray(dates) ||
        !dates.length ||
        !dates.every((d) => d instanceof Date)
      ) {
        return '';
      }

      return dates
        .map((d, i) => {
          // Add a month and subtract a day for end date
          const date = i === 0 ? d : subDays(addMonths(d, 1), 1);
          return Helpers.formatDate(date);
        })
        .join(' - ');
    },
    classNames(errors: any[]): any {
      return {
        'field--has-error': errors.length,
        'field--required': this.hasRule('required'),
      };
    },
    rangeStart(date: Date): void {
      this.onInput([date]);
    },
    onInput(value: Date[]): void {
      const emit = this.nullable && !value ? null : value;
      this.$emit('update:modelValue', emit);
    },
    clear(): void {
      this.onInput([]);
    },
    hasRule(rule: string): boolean {
      return this.rules && this.rules.includes(rule);
    },
  },
  props: {
    value: { type: Array, default: () => [] },
    nullable: { type: Boolean, default: false },
    minDate: { type: Date, default: () => {} },
    maxDate: { type: Date, default: () => {} },
  },
});
</script>

<style lang="scss" scoped>
.date-range {
  &__clear-icon {
    align-self: center;
    position: absolute;
    right: 17px;
    font-size: 20px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    color: $primary;
    z-index: 2;

    &:hover {
      color: $secondary;
    }
  }

  &__clear {
    display: block;
    color: $primary;
    text-align: center;

    &:hover {
      color: $secondary;
    }
  }

  :deep(.datepicker) {
    width: 100%;
  }
}
</style>
