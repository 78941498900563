import { createStore } from 'vuex';

import AgreementStore from '@/store/AgreementStore';
import AuthStore from '@/store/AuthStore';
import ClaimStore from '@/store/ClaimStore';
import ContainerCalculationStore from '@/store/ContainerCalculationStore';
import ContentStore from '@/store/ContentStore';
import ELearningStore from '@/store/ELearningStore';
import InvoiceStore from '@/store/InvoiceStore';
import MenuEngineeringStore from '@/store/MenuEngineeringStore';
import OrderStore from '@/store/OrderStore';
import ProfileStore from '@/store/ProfileStore';
import RestaurantStore from '@/store/RestaurantStore';
import RoleStore from '@/store/RoleStore';
import UserMgtStore from '@/store/UserMgtStore';

const store = createStore({
  state: {},
  mutations: {},
  actions: {
    // Reset state that is dependent on user
    resetState({ commit }: { commit: Function }) {
      commit('agreement/resetState');
      commit('auth/resetState');
      commit('claim/resetState');
      commit('container/resetState');
      commit('content/resetState');
      commit('eLearning/resetState');
      commit('invoice/resetState');
      commit('menuEngineering/resetState');
      commit('order/resetState');
      commit('profile/resetState');
      commit('restaurant/resetState');
      commit('role/resetState');
      commit('userMgt/resetState');
    },
  },
  modules: {
    agreement: AgreementStore,
    auth: AuthStore,
    claim: ClaimStore,
    container: ContainerCalculationStore,
    content: ContentStore,
    eLearning: ELearningStore,
    invoice: InvoiceStore,
    menuEngineering: MenuEngineeringStore,
    order: OrderStore,
    profile: ProfileStore,
    restaurant: RestaurantStore,
    role: RoleStore,
    userMgt: UserMgtStore,
  },
});

export default store;
