import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c520e44c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "impersonate-bar"
}
const _hoisted_2 = { class: "impersonate-bar__label" }
const _hoisted_3 = { class: "impersonate-bar__email" }
const _hoisted_4 = { class: "impersonate-bar__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_ctx.isImpersonating)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('user-management.impersonate.bar.impersonating_as')) + " ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.email), 1)
        ]),
        _createElementVNode("div", {
          class: "impersonate-bar__stop",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stopImpersonate()))
        }, [
          _createVNode(_component_OIcon, {
            icon: "close_circle",
            class: "impersonate-bar__icon"
          }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('user-management.impersonate.bar.stop')), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}