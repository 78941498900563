import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1ff0ebd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-message__content layout__content layout__content--large" }
const _hoisted_2 = { class: "footer-message__title-text" }
const _hoisted_3 = { class: "footer-message__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.showSuggestion)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["footer-message", `footer-message--${_ctx.action}`])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_OIcon, {
                icon: "info_circle",
                variant: 
            _ctx.action === 'suggest-remove' || _ctx.action === 'overfill-no-suggestion'
              ? 'secondary'
              : 'primary'
          ,
                class: "footer-message__icon"
              }, null, 8, ["variant"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t(`orders.new.${_ctx.action}.footer.title`, {
                quantity: _ctx.quantity,
                exceededWeight: _ctx.exceededWeight,
              })), 1),
                _withDirectives(_createElementVNode("p", { class: "footer-message__text" }, _toDisplayString(_ctx.$t(`orders.new.${_ctx.action}.footer.message`)), 513), [
                  [_vShow, !_ctx.minimized]
                ])
              ]),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.minimized = !_ctx.minimized)),
                class: "footer-message__toggle"
              }, [
                _createVNode(_component_OIcon, {
                  icon: "chevron_up",
                  class: _normalizeClass(["footer-message__toggle-icon", { 'footer-message__toggle-icon--minimized': _ctx.minimized }]),
                  role: "button"
                }, null, 8, ["class"])
              ])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}