import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OSelect = _resolveComponent("OSelect")!

  return (_openBlock(), _createBlock(_component_OSelect, _mergeProps({
    modelValue: _ctx.value,
    "onUpdate:modelValue": _ctx.input
  }, { ..._ctx.$attrs }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
        return (_openBlock(), _createElementBlock("option", {
          value: locale.value,
          key: locale.value
        }, _toDisplayString(locale.label), 9, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }, 16, ["modelValue", "onUpdate:modelValue"]))
}