<template>
  <Component
    v-if="link && isVisibleToUser()"
    class="block form-card"
    :is="linkTarget === '_self' ? 'router-link' : 'a'"
    :to="link"
    :href="link"
    :target="linkTarget"
  >
    <div class="block__top" :style="`background-image: url(${image});`" />
    <div class="block__bottom">
      <h2 class="block__title" v-if="block && block.title">
        {{ block.title }}
      </h2>
      <span class="block__text vag-rounded" v-if="block && block.text">
        {{ block.text }}
      </span>
      <OIcon icon="arrow_back" class="block__icon" />
    </div>
  </Component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardBlock from './DashboardBlock.vue';

export default defineComponent({
  extends: DashboardBlock,
  computed: {
    image(): string {
      return !this.block?.multinational
        ? // @ts-ignore
          this.$helpers.getApiImageThumbnail(this.block.image)
        : this.block?.image;
    },
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
.block {
  border: none;
  box-shadow: none;
  display: block;
  padding: 0;

  &__top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 244px;
    width: 100%;
    background-color: $primary;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__bottom {
    border: 1px solid $gallery;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    @include tablet {
      padding: 20px;
    }

    .block__title {
      flex-basis: 100%;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 0 8px;

      @include tablet {
        font-size: 20px;
      }
    }

    .block__text {
      color: $primary;
      flex-basis: 90%;
      font-size: 16px;
      line-height: 24px;

      @include tablet {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .block__icon {
      align-self: flex-end;
      color: $prussian-blue;
      flex-basis: 10%;
      height: 18px;
      margin: 0;
      transform: rotate(180deg);
    }
  }
}
</style>
