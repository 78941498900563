import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_BaseCurrencyAmount = _resolveComponent("BaseCurrencyAmount")!
  const _component_OrderTotals = _resolveComponent("OrderTotals")!
  const _component_ContainerCalculationTotals = _resolveComponent("ContainerCalculationTotals")!
  const _component_NewClaimOverviewProductsTotals = _resolveComponent("NewClaimOverviewProductsTotals")!
  const _component_OTable = _resolveComponent("OTable")!

  return (_openBlock(), _createBlock(_component_OTable, {
    data: _ctx.itemsData,
    "root-class": `order-item-list ${_ctx.details ? 'b-table--details-table' : ''} ${
      _ctx.orderCardItemsIsNew ? 'order-card__items--is-new' : ''
    } ${
      _ctx.type === 'default' || _ctx.type === 'claim' ? 'b-table--no-rounded-top' : ''
    } ${_ctx.type === 'claim' ? 'order-item-list--claim' : ''}`,
    striped: _ctx.striped,
    "default-sort": _ctx.defaultSort,
    "sort-icon": "chevron_up",
    "sort-icon-size": "is-small"
  }, {
    footer: _withCtx(() => [
      (_ctx.type === 'default')
        ? (_openBlock(), _createBlock(_component_OrderTotals, {
            key: 0,
            items: _ctx.items,
            products: _ctx.relatedProducts,
            label: _ctx.$t('orders.order-item-list.totals.grand-total'),
            class: "order-item-list__totals"
          }, null, 8, ["items", "products", "label"]))
        : (_ctx.type === 'container-calculation')
          ? (_openBlock(), _createBlock(_component_ContainerCalculationTotals, {
              key: 1,
              mod: "summary"
            }))
          : (_ctx.type === 'claim')
            ? (_openBlock(), _createBlock(_component_NewClaimOverviewProductsTotals, {
                key: 2,
                "claim-totals": _ctx.claimTotals,
                "claim-agreement": _ctx.agreement
              }, null, 8, ["claim-totals", "claim-agreement"]))
            : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_o_table_column, {
        label: _ctx.$t('orders.order-item-list.header.product'),
        field: "ItemText",
        sortable: ""
      }, {
        default: _withCtx((props) => [
          _createTextVNode(_toDisplayString(props.row.ItemText), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_o_table_column, {
        label: _ctx.$t('orders.order-item-list.header.code'),
        width: 102,
        field: "ShortCode",
        sortable: ""
      }, {
        default: _withCtx((props) => [
          _createTextVNode(_toDisplayString(props.row.ShortCode), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      (_ctx.type !== 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 0,
            label: _ctx.$t('orders.order-item-list.header.art'),
            width: 100,
            field: "ProductNr",
            sortable: ""
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(props.row.ProductNr), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'default')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 1,
            label: _ctx.$t('orders.order-item-list.header.unit-type'),
            width: 110,
            field: "SalesUnit"
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(_ctx.$t(`orders.order-item-list.unit.${props.row.SalesUnit.toLowerCase()}`)), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'container-calculation')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 2,
            label: _ctx.$t('orders.order-item-list.header.boxes'),
            width: 100,
            "root-class": "is-centered"
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(_ctx.calculateBoxes(props.row)), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type !== 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 3,
            label: 
        _ctx.type === 'container-calculation'
          ? _ctx.$t('orders.order-item-list.header.pallets')
          : _ctx.$t('orders.order-item-list.header.quantity')
      ,
            width: 70,
            numeric: "",
            field: "Quantity",
            position: "right"
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(props.row.Quantity), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 4,
            label: _ctx.$t('claims.new.items.bags'),
            width: 70,
            "root-class": "is-centered",
            field: "Quantity"
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(props.row.SalesUnit === 'BAG' ? props.row.Quantity : ''), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 5,
            label: _ctx.$t('claims.new.items.boxes'),
            width: 70,
            "root-class": "is-centered",
            field: "Quantity"
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(props.row.SalesUnit === 'CAR' ? props.row.Quantity : ''), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 6,
            label: _ctx.$t('claims.new.items.volume'),
            width: "100"
          }, {
            default: _withCtx((props) => [
              _createTextVNode(_toDisplayString(_ctx.calculateClaim(props.row).volume) + " " + _toDisplayString(_ctx.$t('claims.kg')), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 7,
            label: _ctx.$t('claims.new.items.billback-rate'),
            numeric: "",
            width: "140",
            position: "right"
          }, {
            default: _withCtx((props) => [
              _createVNode(_component_BaseCurrencyAmount, {
                currency: _ctx.agreement.currency,
                amount: 
          props.row.billbackRate || _ctx.agreementItem(props.row).billback_rate
        
              }, null, 8, ["currency", "amount"])
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'claim')
        ? (_openBlock(), _createBlock(_component_o_table_column, {
            key: 8,
            label: _ctx.$t('claims.new.items.billback-amount'),
            numeric: "",
            width: "160",
            position: "right"
          }, {
            default: _withCtx((props) => [
              _createVNode(_component_BaseCurrencyAmount, {
                currency: _ctx.agreement.currency,
                amount: _ctx.calculateClaim(props.row).billbackAmount
              }, null, 8, ["currency", "amount"])
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["data", "root-class", "striped", "default-sort"]))
}