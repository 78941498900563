<template>
  <LayoutPublic>
    <div class="try-out-result layout__content">
      <h1 class="title">
        {{ $t('menuEngineering.overview.categoryCompare') }}
        <span class="dish-category"
          >"{{ $t('menuEngineering.categories.' + category) }}"</span
        >
      </h1>

      <ContentBlocks :blocks="blocks" />

      <OButton
        data-test="try-out-result-sign-up"
        rounded
        variant="primary"
        tag="router-link"
        :to="{
          path: '/auth',
          query: {
            redirect: '/menu-engineering/add-dish',
            state: 'signUp',
          },
        }"
        >{{ $t('menuEngineering.try-out.result.sign-up') }}</OButton
      >
    </div>
  </LayoutPublic>
</template>

<script lang="ts">
import LayoutPublic from '@/layouts/LayoutPublic.vue';
import ContentBlocks from '@/components/content/ContentBlocks.vue';
import CmsContentMixin from '@/mixins/CmsContentMixin';
import GoogleTagManagerLogic from '@/logic/google-tag-manager/GoogleTagManagerLogic';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { LayoutPublic, ContentBlocks },
  mixins: [CmsContentMixin],
  computed: {
    category(): string {
      return this.$route.params.category.toString();
    },
  },
  mounted() {
    GoogleTagManagerLogic.pushEvent(
      'menu-engineering-completed',
      this.$route.fullPath,
    );
    this.fetchContent();
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        this.fetchContent();
      },
      { immediate: true },
    );
  },
});
</script>

<style lang="scss" scoped>
.try-out-result {
  margin: 40px 0 54px 0;

  @include tablet {
    margin: 80px 0;
  }

  .button {
    margin-top: 54px;
  }
}
</style>
