<template>
  <section
    class="contentblocks"
    :class="{
      'contentblocks--has-backgroundcolor': hasBackgroundColor,
      layout__content: !hasBackgroundColor,
      'contentblocks--multinational': multinational,
    }"
  >
    <div class="contentblocks-wrapper columns">
      <div
        v-for="(block, index) in blocks"
        :key="index"
        :class="{
          layout__content: hasLayoutContent,
          'is-half': block.type === 'dashboardcalltoaction',
          'is-full': block.type !== 'dashboardcalltoaction',
          'column--no-top-margin': noTopMargin,
        }"
        class="column"
      >
        <Component :is="`${block.type}Block`" :block="block" />
      </div>
    </div>
    <div v-if="pages" :class="{ layout__content: hasLayoutContent }">
      <Component :is="'pagesblock'" :pages="pages" />
    </div>
  </section>
</template>

<script lang="ts">
import ButtonBlock from '@/components/content/blocks/ButtonBlock.vue';
import DashboardCallToActionBlock from '@/components/content/blocks/DashboardCallToActionBlock.vue';
import DashboardTextBlock from '@/components/content/blocks/DashboardTextBlock.vue';
import DoubleColumnsTextBlock from '@/components/content/blocks/DoubleColumnsTextBlock.vue';
import DownloadListBlock from '@/components/content/blocks/DownloadListBlock.vue';
import EatThisDownloadBlock from '@/components/content/blocks/EatThisDownloadBlock.vue';
import ImageCarouselBlock from '@/components/content/blocks/ImageCarouselBlock.vue';
import IntroBlock from '@/components/content/blocks/IntroBlock.vue';
import NewsCardBlock from '@/components/content/blocks/NewsCardBlock.vue';
import PagesBlock from '@/components/content/blocks/PagesBlock.vue';
import TextimageBlock from '@/components/content/blocks/TextimageBlock.vue';
import YoutubeBlock from '@/components/content/blocks/YoutubeBlock.vue';
import VideoBlock from '@/components/content/blocks/VideoBlock.vue';
import { defineComponent } from 'vue';
import ContactBlock from './blocks/ContactBlock.vue';

export default defineComponent({
  components: {
    buttonBlock: ButtonBlock,
    contactBlock: ContactBlock,
    dashboardcalltoactionBlock: DashboardCallToActionBlock,
    dashboardtextBlock: DashboardTextBlock,
    double_columns_textBlock: DoubleColumnsTextBlock,
    downloadlistBlock: DownloadListBlock,
    eatthisdownloadBlock: EatThisDownloadBlock,
    imagecarouselBlock: ImageCarouselBlock,
    introBlock: IntroBlock,
    newsBlock: NewsCardBlock,
    pagesblock: PagesBlock,
    textimageBlock: TextimageBlock,
    youtubeBlock: YoutubeBlock,
    videoBlock: VideoBlock,
  },
  props: {
    blocks: {
      type: Array as () => any[],
    },
    pages: {
      type: Array as () => any[],
    },
    hasBackgroundColor: { type: Boolean, default: false },
    hasLayoutContent: { type: Boolean, default: true },
    multinational: { type: Boolean, default: false },
    noTopMargin: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.contentblocks.contentblocks--multinational {
  @include until($tablet) {
    margin: 0;
  }

  .columns .column:first-child {
    margin-top: 24px;
  }
}

.contentblocks-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.columns {
  @include until($tablet) {
    flex-direction: column;
  }

  .column {
    padding-bottom: 0;
    padding-top: 0;

    &.is-half {
      @include tablet {
        margin-left: 0;
      }
    }

    &:first-child {
      margin-top: 40px;

      &.column--no-top-margin {
        margin-top: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.contentblocks--has-backgroundcolor {
  background: rgba(0, 56, 90, 0.03);
  margin-top: 40px;
  padding: 10px 0 110px;

  .block.intro-text {
    background-color: transparent;
    border: none;
  }

  .intro-text,
  .intro-text p,
  .intro-text ul {
    color: $tundora;
    font-size: 18px;
    letter-spacing: 0.21px;
    line-height: 32px;

    ul > li::before {
      color: $tundora;
    }
  }
}

.block {
  $self: &;

  margin: 16px 0;

  h2 {
    font-weight: normal;
    font-size: 25px;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 10px;
    color: $primary;

    @include desktop {
      font-size: 28px;
      line-height: 40px;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-size: 20px;
    line-height: 39px;
    color: $primary;
  }

  &__bottom {
    #{$self}__icon i {
      font-size: 18px;
    }
  }

  &:hover {
    #{$self}__bottom {
      #{$self}__icon {
        color: $trinidad;
      }
    }
  }

  &__icon {
    transition: all 0.2s ease-in-out;
  }
}
</style>
