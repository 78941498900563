<template>
  <div class="steps-bar">
    <ul class="steps-bar__steps">
      <li
        class="steps-bar__step"
        :class="{ 'steps-bar__step--active': active(step) }"
        v-for="(step, index) in steps"
        :key="index"
      >
        <span class="steps-bar__step__index">{{ index + 1 }}:</span>
        <span class="steps-bar__step__label">{{
          $t(`${labelPrefix}.new.steps.${step}`)
        }}</span>
      </li>
    </ul>
    <transition name="fade">
      <router-link
        v-if="active(firstStep())"
        class="steps-bar__overview-button"
        :to="overviewLink"
      >
        <span class="is-hidden-mobile">
          <span>
            {{ $t(`${labelPrefix}.new.repeat-button.large1`) }}<br />
            {{ $t(`${labelPrefix}.new.repeat-button.large2`) }}
          </span>
        </span>
        <span class="is-hidden-tablet">{{
          $t(`${labelPrefix}.new.repeat-button.small`)
        }}</span>
        <OIcon icon="arrow_back" />
      </router-link>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    firstStep(): string {
      return this.steps && this.steps.length > 0 ? this.steps[0] : '';
    },
    active(step: string): boolean {
      return this.activeStep === step;
    },
  },
  props: {
    labelPrefix: {
      type: String,
    },
    steps: {
      type: Array as () => string[],
    },
    overviewLink: {
      type: Object,
    },
    activeStep: {
      type: String,
    },
  },
});
</script>
