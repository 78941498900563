<script lang="ts">
import AuthGroup from '@/logic/auth/AuthGroup';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    isVisibleToUser(): boolean {
      return this.$store.getters['auth/memberOf'](AuthGroup.Direct);
    },
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
