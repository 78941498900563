import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-00d50f42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "authenticator-custom authenticator-set-mfa" }
const _hoisted_2 = {
  key: 0,
  class: "authenticator-set-mfa__header"
}
const _hoisted_3 = { class: "form-card__label" }
const _hoisted_4 = { class: "authenticator-set-mfa__header-subtitle" }
const _hoisted_5 = {
  key: 1,
  class: "authenticator-set-mfa__header"
}
const _hoisted_6 = { class: "form-card__label" }
const _hoisted_7 = { class: "authenticator-set-mfa__header-subtitle" }
const _hoisted_8 = {
  key: 2,
  class: "authenticator-set-mfa__choice-field"
}
const _hoisted_9 = {
  key: 0,
  class: "authenticator-set-mfa__choice-field-option"
}
const _hoisted_10 = {
  key: 1,
  class: "authenticator-set-mfa__choice-field-option"
}
const _hoisted_11 = {
  key: 2,
  class: "authenticator-set-mfa__choice-field-option"
}
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { class: "authenticator-set-mfa__qr-container" }
const _hoisted_14 = { class: "authenticator-set-mfa__actions" }
const _hoisted_15 = {
  key: 4,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")!
  const _component_OInput = _resolveComponent("OInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.displayTotpSetup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.options.header), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.options.mfaDescription), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.displayTotpSetup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('amplify.Verify')), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.options.tokenInstructions), 1)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.displayTotpSetup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.options.mfaTypes.includes('SMS'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  name: "mfaPreference",
                  value: "SMS",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mfaPreference) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.mfaPreference]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.options.smsDescription), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.options.mfaTypes.includes('TOTP'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  name: "mfaPreference",
                  value: "TOTP",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mfaPreference) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.mfaPreference]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.options.totpDescription), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.options.mfaTypes.includes('None'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  name: "mfaPreference",
                  value: "NOMFA",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mfaPreference) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.mfaPreference]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.options.noMfaDescription), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.displayTotpSetup && _ctx.token)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_qrcode_vue, {
              value: _ctx.token,
              size: 300,
              level: "H"
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('amplify.Verification Code')) + " *", 1),
            _createVNode(_component_OInput, {
              modelValue: _ctx.code,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.code) = $event)),
              placeholder: _ctx.$t('amplify.Verification Code'),
              autofocus: ""
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("span", null, [
        (!_ctx.displayTotpSetup)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: "setMfa",
              class: "amplify-button amplify-button--primary",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setMFA && _ctx.setMFA(...args)))
            }, _toDisplayString(_ctx.$t('amplify.Set MFA')), 1))
          : _createCommentVNode("", true),
        (_ctx.displayTotpSetup)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              id: "verify",
              class: "amplify-button amplify-button--primary",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.verifyTotpToken && _ctx.verifyTotpToken(...args)))
            }, _toDisplayString(_ctx.$t('amplify.Verify Token')), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", null, [
        _createElementVNode("a", {
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
        }, _toDisplayString(_ctx.$t('amplify.Cancel')), 1)
      ])
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}