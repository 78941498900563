<template>
  <div class="table__details invoice-details">
    <header class="invoice-details__header-container columns">
      <div class="invoice-details__header column">
        <DownloadInvoicePdf
          v-if="
            $store.getters['auth/hasAccess']({
              action: 'GET',
              resource: '/invoice-download-link',
            })
          "
          class="invoice-details__header-button"
          :invoice="invoice"
        >
          {{ $t('invoices.detail.pdf-download') }}
        </DownloadInvoicePdf>

        <ShowSalesOrder
          class="invoice-details__header-button"
          :label="$t('invoices.detail.show-sales-order')"
          :invoice="invoice"
        ></ShowSalesOrder>

        <div class="invoice-details__status is-hidden-mobile">
          <InvoiceStatus :invoice="invoice"></InvoiceStatus>
        </div>
      </div>
    </header>
    <div class="invoice-details__section invoice-details__body columns">
      <div class="column" v-for="column in [1, 2]" :key="column">
        <div
          v-for="field in fields.filter((f) => f.col === column)"
          :key="field.label"
          class="invoice-details__field"
        >
          <label
            class="invoice-details__label"
            :class="{
              'invoice-details__label--separator': field.label === 'separator',
            }"
            >{{
              field.label !== 'separator'
                ? $t(`invoices.detail.label.${field.label}`)
                : ''
            }}</label
          >
          <span
            class="invoice-details__value"
            v-if="field.label !== 'separator'"
            >{{ field.value || '-' }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { format } from 'date-fns';
import { SapCustomer, SapInvoice } from '@/api/model';
import Helpers from '@/logic/orders/helpers';
import ShowSalesOrder from '@/components/invoices/ShowSalesOrder.vue';
import DownloadInvoicePdf from '@/components/invoices/DownloadInvoicePdf.vue';
import InvoiceStatus from '@/components/invoices/InvoiceStatus.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { DownloadInvoicePdf, InvoiceStatus, ShowSalesOrder },
  computed: {
    fields(): any[] {
      const inv = this.invoice;

      return [
        {
          label: 'type-commercial',
          value: inv.BillTypeText,
          col: 1,
        },
        {
          label: 'invoice-number',
          value: inv.BillDoc,
          col: 1,
        },
        {
          label: 'separator',
          value: '',
          col: 1,
        },
        {
          label: 'sales-order-ship-to',
          value: this.getCustomerValue(inv.ShipToSapCustomer),
          col: 1,
        },
        {
          label: 'relevant-bill-to',
          value: this.getRelevantBillTo(),
          col: 1,
        },
        {
          label: 'relevant-payer',
          value: this.getCustomerValue(inv.PayerSapCustomer),
          col: 1,
        },
        {
          label: 'order-reference',
          value: inv.AssignmentNo,
          col: 1,
        },
        {
          label: 'posting-date',
          value: this.getDate(inv.BillDate),
          col: 2,
        },
        {
          label: 'expire-date',
          value: this.getDate(inv.DueDate),
          col: 2,
        },
        {
          label: 'amount',
          value: inv.NetValue
            ? this.$n(parseFloat(inv.NetValue), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '',
          col: 2,
        },
        {
          label: 'currency',
          value: inv.Currency,
          col: 2,
        },
        {
          label: 'payment-terms-code',
          value: inv.Pmnttrms,
          col: 2,
        },
        {
          label: 'payment-terms-description',
          value: inv.PmnttrmsText,
          col: 2,
        },
      ];
    },
  },
  methods: {
    getCustomerValue(customer: SapCustomer | undefined): string {
      return customer ? `${customer.CustName1} - ${customer.City}` : '-';
    },
    getDate(sapDate: string | null | undefined): string | undefined {
      if (sapDate === null || sapDate === undefined) {
        return undefined;
      }
      const date = Helpers.getDateFromSapDate(sapDate);
      if (date instanceof Date && !Number.isNaN(date.getDate())) {
        return format(date, 'dd-MM-yyyy');
      }
      return undefined;
    },
    getRelevantBillTo(): string {
      return this.getCustomerValue(
        this.$store.state.profile.profile.sapCustomer,
      );
    },
  },
  props: {
    invoice: {
      type: Object as () => SapInvoice,
      default: () => {},
    },
  },
});
</script>

<style lang="scss" scoped>
.invoice-details {
  &__header-container {
    @include mobile {
      margin: -16px;
    }
  }

  &__header {
    display: flex;
    height: 54px;
    align-items: stretch;
    padding: 0;

    @include mobile {
      height: auto;
      flex-direction: column;
    }
  }

  &__header-button {
    display: flex;
    align-items: center;
    color: $prussian-blue-light;
    border-right: 1px solid $heather;
    height: 54px;
    padding: 0 16px 0 16px;

    &:first-child {
      padding: 0 16px 0 0;
    }

    @include mobile {
      background-color: $gallery;
      flex: 0 0 50px;
      padding: 0 16px;
      border-right-width: 0;
      border-bottom: 1px solid $heather;

      &:first-child {
        padding: 0 16px;
      }
    }
  }

  &__status {
    display: flex;
    height: 50px;
    align-items: center;
    margin-left: auto;
  }

  &__body {
    display: flex;
    flex-direction: column;

    @include mobile {
      padding: 16px;
    }

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }

    > * {
      flex: 0 1 46%;
    }
  }

  &__field {
    display: flex;
  }

  &__label {
    flex: 0 1 160px;

    &::after {
      content: ': ';
    }

    &--separator::after {
      content: '\a0';
    }
  }

  &__value {
    flex: 1;
  }

  &__section {
    &:not(:first-child) {
      padding-top: 21px;

      @include tablet {
        border-top: 1px solid $heather;
      }
    }

    &:not(:last-child) {
      padding-bottom: 16px;
    }

    .column {
      padding: 0;
    }
  }
}
</style>
