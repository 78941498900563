<template>
  <LayoutDefault>
    <div class="my-profile">
      <ProfileForm />
      <ProfileRestaurants v-if="canUseMenuEngineering" />
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import ProfileRestaurants from '@/components/profile/ProfileRestaurants.vue';
import ProfileForm from '@/components/profile/ProfileForm.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ProfileForm, ProfileRestaurants, LayoutDefault },
  computed: {
    canUseMenuEngineering(): boolean {
      return this.$store.getters['auth/hasAccess']({
        action: 'PUT',
        resource: '/restaurant',
      });
    },
  },
  created() {
    this.$store.dispatch('auth/getUser');
  },
});
</script>

<style lang="scss" scoped>
.my-profile {
  background: rgba(0, 56, 90, 0.03);
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>
