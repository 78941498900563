<template>
  <a
    v-if="block && link"
    class="block button is-primary is-rounded"
    :href="link"
    :target="block.target_blank ? '_blank' : '_self'"
  >
    {{ block.button_label }}
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    link(): string | undefined {
      return this.block?.button_link;
    },
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
