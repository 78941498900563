<template>
  <component :is="layout">
    <div class="error-page">
      <Hero v-if="message('title')" :title="message('title')" />
      <h1 class="title layout__content">{{ message('subtitle') }}</h1>
      <p v-if="message" class="intro-text layout__content">
        {{ message('message') }}
      </p>
    </div>
  </component>
</template>
<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutPublic from '@/layouts/LayoutPublic.vue';
import Hero from '@/components/AppHero.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { LayoutDefault, LayoutPublic, Hero },
  data() {
    return {
      statusCode: 404,
    };
  },
  computed: {
    layout(): string {
      return this.$store.getters['auth/signedIn']
        ? 'LayoutDefault'
        : 'LayoutPublic';
    },
  },
  methods: {
    message(element: string): string {
      const transKey = `error.error_${this.statusCode}.${element}`;
      if (this.$te(transKey)) {
        return this.$t(transKey) as string;
      }
      if (this.$te(`error.general.${element}`)) {
        return this.$t(`error.general.${element}`) as string;
      }
      return '';
    },
  },
});
</script>
