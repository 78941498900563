import { RouteLocationNormalized } from 'vue-router';

export default class TrackingService {
  static utmKeys: string[] = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'url_registered',
  ];

  // Check current route for present utm values.
  static storeUtmParameters(route: RouteLocationNormalized) {
    const { query } = route;
    if (Object.keys(query).filter((x) => TrackingService.utmKeys.includes(x))) {
      this.storeUtmValues(query);
    }
  }

  // Return the utm properties from local storage.
  static getUtmProperties(): any {
    const { utmKeys } = TrackingService;
    const properties: any = {};
    utmKeys.forEach((utmKey) => {
      const key = TrackingService.toCamelCase(utmKey);
      const storageItem = localStorage.getItem(key);
      if (storageItem !== undefined) {
        properties[key] = storageItem;
      }
    });

    return properties;
  }

  // Convert given snake_case to camelCase.
  static toCamelCase(snakeCase: string): string {
    return snakeCase.replace(/(_\w)/g, (match: string): string =>
      match[1].toUpperCase(),
    );
  }

  // Store the utm values in local storage.
  private static storeUtmValues(params: any) {
    TrackingService.utmKeys.forEach((utmKey: string) => {
      if (utmKey in params) {
        localStorage.setItem(
          TrackingService.toCamelCase(utmKey),
          params[utmKey],
        );
      }
    });
  }
}
