import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ship-to__address" }
const _hoisted_2 = { class: "ship-to__value ship-to__value--name" }
const _hoisted_3 = {
  key: 0,
  class: "ship-to__incoterm"
}
const _hoisted_4 = { class: "ship-to__value ship-to__value--address1" }
const _hoisted_5 = { class: "ship-to__value ship-to__value--city-postalcode" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.address[0]) + " ", 1),
      (_ctx.shipTo.IncoTerms1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.shipTo.IncoTerms1), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.address[1]), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.address[2]), 1)
  ]))
}