<template>
  <div class="base-dialog">
    <header class="base-dialog__header">
      <div class="base-dialog__title">{{ title }}</div>
    </header>
    <div class="base-dialog__message">
      <OIcon
        v-if="icon"
        class="base-dialog__icon"
        :icon="icon"
        variant="danger"
      />{{ message }}
      <OInput
        v-if="inputField"
        @update:modelValue="modelValue = $event"
        v-bind="mergedInputAttrs"
      ></OInput>
    </div>
    <footer class="base-dialog__footer">
      <OButton
        v-if="cancelText !== ''"
        rounded
        outlined
        rootClass="base-dialog__button"
        @click="
          () => {
            $emit('close');
            onCancel();
          }
        "
        variant="primary"
      >
        {{ cancelText }}
      </OButton>
      <OButton
        v-if="confirmText !== ''"
        rounded
        rootClass="base-dialog__button"
        @click="handleInputConfirm"
        variant="primary"
      >
        {{ confirmText }}
      </OButton>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      modelValue: '',
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    closeOnConfirm: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: '',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    cancelText: {
      type: String,
      default: '',
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    icon: {
      type: String,
      default: '',
    },
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
    inputField: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    mergedInputAttrs() {
      return {
        type: 'text',
        placeholder: '',
        value: '',
        ...this.inputAttrs,
      };
    },
  },

  methods: {
    handleInputConfirm(): void {
      if (this.modelValue) {
        this.onConfirm(this.modelValue, { close: this.closeDialog });
        return;
      }

      this.onConfirm({ close: this.closeDialog });
      if (this.closeOnConfirm) {
        this.closeDialog();
      }
    },
    closeDialog(): void {
      this.$emit('close');
    },
  },
});
</script>

<style scoped lang="scss">
.base-dialog {
  background-color: $white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  max-width: 428px;
  margin: 0 auto;
  max-height: 95vh;

  &__header {
    background-color: $primary;
    padding: 24px;
    border-radius: 4px 4px 0 0;
  }

  &__button {
    width: fit-content;
  }

  &__title {
    color: $white;
    font-size: 18px;
    flex-shrink: 1;
    font-weight: 600;
    line-height: 18px;
  }

  &__message {
    padding: 20px;
    color: $tundora;
    font-weight: 400;
    position: relative;

    &:has(.base-dialog__icon) {
      padding-left: 80px;
    }
  }

  &__icon {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 36px;
  }

  &__footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}
</style>
