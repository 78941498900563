import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "custom-label-switch__action" }
const _hoisted_2 = { class: "custom-label-switch__popup-header" }
const _hoisted_3 = { class: "custom-label-switch__popup-desciption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_checkbox = _resolveComponent("o-checkbox")!
  const _component_o_field = _resolveComponent("o-field")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["custom-label-switch__button", { 'custom-label-switch__button--active': _ctx.open }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePopup && _ctx.togglePopup(...args)))
    }, [
      _createVNode(_component_o_icon, { icon: "text_edit" }),
      _createVNode(_component_o_icon, { icon: "more_vert" })
    ], 2),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["custom-label-switch__popup", { 'custom-label-switch__popup--left': _ctx.positionLeft }])
        }, [
          _createElementVNode("button", {
            class: "custom-label-switch__close-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false))
          }, [
            _createVNode(_component_o_icon, { icon: "close" })
          ]),
          _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('orders.custom-label-switch.title')), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('orders.custom-label-switch.description')), 1),
          _createVNode(_component_o_field, { class: "custom-label-switch__popup-checkbox" }, {
            default: _withCtx(() => [
              _createVNode(_component_o_checkbox, {
                modelValue: _ctx.enabled,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.enabled) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('orders.custom-label-switch.checkbox')), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true)
  ])), [
    [_directive_on_click_outside, _ctx.closePopup]
  ])
}