import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.$attrs.name,
    rules: _ctx.$attrs.rules
  }, {
    default: _withCtx(({ errors, field }) => [
      _createVNode(_component_OField, _mergeProps({
        label: _ctx.$attrs.label,
        message: errors.length ? errors[0] : '',
        class: _ctx.classNames(errors)
      }, _ctx.$attrs, {
        variant: errors.length ? 'danger' : '',
        horizontal: _ctx.$attrs.horizontal
      }), {
        default: _withCtx(() => [
          _createVNode(_component_OInput, _mergeProps(field, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _ctx.onInput,
            type: _ctx.inputType,
            placeholder: _ctx.$attrs.placeholder,
            disabled: _ctx.$attrs.disabled,
            step: "1",
            min: _ctx.min,
            expanded: "",
            "password-reveal": _ctx.$attrs.passwordReveal,
            "icon-pack": _ctx.$attrs.iconPack,
            "icon-right": _ctx.unit !== '' ? 'unit' : undefined,
            style: _ctx.unit ? { '--unit': `'${_ctx.unit}'` } : undefined
          }), null, 16, ["modelValue", "onUpdate:modelValue", "type", "placeholder", "disabled", "min", "password-reveal", "icon-pack", "icon-right", "style"])
        ]),
        _: 2
      }, 1040, ["label", "message", "class", "variant", "horizontal"])
    ]),
    _: 1
  }, 8, ["name", "rules"]))
}