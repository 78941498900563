<template>
  <OField class="action-bar__search">
    <OInput
      :placeholder="placeholder"
      type="search"
      root-class="input--search"
      @input="debounceSearch($event)"
      icon-right="search"
      size="medium"
    />
  </OField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  data() {
    const debounce: any | null = null;

    return {
      debounce,
    };
  },
  created() {
    this.$store.commit(`${this.store}/setQuery`, '');
  },
  methods: {
    debounceSearch(query: string, delay = 400): void {
      if (this.debounce !== null) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => this.search(query), delay);
    },
    search(query: string) {
      this.$store.dispatch(`${this.store}/search`, query);
    },
  },
  props: {
    placeholder: {
      type: String,
    },
    store: {
      type: String,
    },
  },
});
</script>
