<template>
  <div class="ship-to">
    <div class="ship-to__title">{{ $t('orders.shipping.ship-to.title') }}</div>
    <div class="ship-to__content">
      <ORadio
        v-for="partner in partners"
        :key="partner.Id"
        root-class="radio--partner"
        :modelValue="shipToParty"
        @update:modelValue="setShipToParty($event)"
        :native-value="partner.CustomerNr"
        required
      >
        <ShipToAddress :ship-to="partner" />
      </ORadio>
      <label class="partner--skeleton" v-if="loadingShipToPartners">
        <div class="check">
          <OSkeleton circle width="16" height="16" />
        </div>
        <div class="ship-to__address">
          <OSkeleton
            class="ship-to__value"
            v-for="index in [0, 1, 2]"
            :key="index"
          />
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import ShipToAddress from '@/components/orders/new-order/ShipToAddress.vue';
import { SapCustomerPartner } from '@/api/model/sap-customer-partner';
import { SalesOrderAll as SalesOrder } from '@/logic/orders/SalesOrderAll';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ShipToAddress },
  computed: {
    shipToParty(): string | null | undefined {
      return this.cart?.ShipToParty;
    },
    partners(): SapCustomerPartner[] {
      const partners = this.shipToPartners;
      if (
        !this.cart?.ShipToParty &&
        partners.length === 1 &&
        partners[0].CustomerNr
      ) {
        this.setShipToParty(partners[0].CustomerNr);
      }
      return partners;
    },
    cart(): SalesOrder | undefined {
      return this.$store.state.order.cart;
    },
    shipToPartners(): SapCustomerPartner[] | [] {
      return this.$store.state.order.shipToPartners || [];
    },
    loadingShipToPartners(): boolean {
      return this.$store.state.order.loadingShipToPartners;
    },
  },
  mounted() {
    this.$store.dispatch('order/fetchShipToPartners');
  },
  methods: {
    setShipToParty(customerNr: string) {
      this.$store.commit('order/setCartShipToParty', {
        CustomerNr: customerNr,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.ship-to {
  &__content {
    position: relative;
  }
}

.partner--skeleton {
  display: flex;
  justify-content: flex-start;

  .check {
    flex: 0 0 28px;
  }

  .ship-to__address {
    flex: 1;
  }
}
</style>
