<template>
  <Form
    :name="$attrs.name"
    :vid="$attrs.name"
    :rules="$attrs.rules"
    v-slot="{ errors }"
    slim
  >
    <div class="field-body">
      <div class="field">
        <OField
          :label="$attrs.label"
          :message="errors.length ? errors[0] : ''"
          class="date-range"
          :class="classNames(errors)"
          :type="errors.length ? 'is-danger' : ''"
        >
          <OSelect
            :placeholder="$attrs.placeholder"
            @update:modelValue="onInput"
            :modelValue="getValue"
          >
            <option
              v-for="option in yearArray"
              :value="option.id"
              :key="option.id"
            >
              {{ option.value }}
            </option>
          </OSelect>
        </OField>
      </div>
    </div>
  </Form>
</template>
<script lang="ts">
import { Form } from 'vee-validate';
import { format } from 'date-fns';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Form },
  computed: {
    getValue(): any | undefined {
      if (this.value && this.value.length) {
        return this.yearArray.find((year) => year.value[0] === this.value[0])
          .id;
      }
      return undefined;
    },
    rules(): any {
      return this.$attrs.rules;
    },
    yearArray(): any[] {
      const today = new Date(Date.now());

      // if the agreement is active, the end date is set as today so you can't claim in the future.
      // when the registration period is annual this means you cannot register for this year.
      const activeAgreement =
        format(new Date(this.endDate), 'yyyy-MM-dd') ===
        format(today, 'yyyy-MM-dd');

      const beginYear = new Date(this.beginDate).getFullYear();
      const endYear = new Date(this.endDate).getFullYear();

      const years = Array.from(
        { length: endYear - (activeAgreement ? 1 : 0) - beginYear + 1 },
        (_, index) => index + beginYear,
      );

      return years.map((year, index) => {
        const startDate =
          year === beginYear
            ? format(this.beginDate, 'yyyy-MM-dd')
            : `${year}-01-01`;
        const endDate =
          year === endYear
            ? format(this.endDate, 'yyyy-MM-dd')
            : `${year}-12-31`;

        return {
          id: index + 1,
          value: year,
          dates: [new Date(startDate), new Date(endDate)],
        };
      });
    },
  },
  methods: {
    onInput(value: number) {
      const dates = this.yearArray.find((year) => year.id === value)?.dates;
      this.$emit('input', dates);
    },
    classNames(errors: any[]): any {
      return {
        'field--has-error': errors.length,
        'field--required': this.hasRule('required'),
      };
    },
    hasRule(rule: string): boolean {
      return this.rules && this.rules.includes(rule);
    },
  },
  props: {
    value: { type: Array as () => Date[], default: () => [] },
    beginDate: { type: Date, required: true },
    endDate: { type: Date, required: true },
  },
});
</script>
<style scoped lang="scss">
.field-body :deep(.select) {
  height: 2.5em;
}

.field-body :deep(.select),
.field-body :deep(select) {
  width: 100%;
}
</style>
