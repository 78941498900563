/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SalesQuoteItem } from './sales-quote-item';

/**
 * 
 * @export
 * @interface SalesQuote
 */
export interface SalesQuote {
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesQuote
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    cancellation_status_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    claim_period?: SalesQuoteClaimPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    claim_period_from?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    claim_period_to?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    client_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    credit_reference?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    date_time_valid_from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    date_time_valid_to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    id?: string;
    /**
     * 
     * @type {Array<SalesQuoteItem>}
     * @memberof SalesQuote
     */
    items?: Array<SalesQuoteItem>;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    lwm_contact?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SalesQuote
     */
    open_for_claim_submission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    pdf_key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    sales_quote_sap_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    sap_customer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    sap_reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    updated_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuote
     */
    wholesale_party?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SalesQuoteClaimPeriodEnum {
    Annual = 'ANNUAL',
    Quarterly = 'QUARTERLY',
    Monthly = 'MONTHLY'
}



