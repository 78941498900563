/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SalesQuoteClaimAttachment } from './sales-quote-claim-attachment';
import { SalesQuoteClaimItem } from './sales-quote-claim-item';

/**
 * 
 * @export
 * @interface SalesQuoteClaim
 */
export interface SalesQuoteClaim {
    /**
     * 
     * @type {Array<SalesQuoteClaimAttachment>}
     * @memberof SalesQuoteClaim
     */
    attachments?: Array<SalesQuoteClaimAttachment>;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    claim_period_from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    claim_period_to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    credit_reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    id?: string;
    /**
     * 
     * @type {Array<SalesQuoteClaimItem>}
     * @memberof SalesQuoteClaim
     */
    items?: Array<SalesQuoteClaimItem>;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    sales_quote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    sales_quote_sap_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    status?: SalesQuoteClaimStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    submission_date?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    volume?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaim
     */
    wholesale_party?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SalesQuoteClaimStatusEnum {
    Failed = 'FAILED',
    Submitted = 'SUBMITTED'
}



