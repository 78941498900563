<template>
  <div class="modal-card modal-card--small-content">
    <section class="modal-card-body">
      <OnboardingFinished v-if="finished" :MFAType="MFAType" />
      <OnboardingWelcome
        :MFATypes="MFATypes"
        @choice="chooseMFAType"
        v-else-if="isActiveType('')"
      />
      <div v-else>
        <h1 class="title title--h1 onboarding__title">
          {{ $t(`onboarding.${MFAType}.title`) }}
        </h1>
        <h2 class="subtitle">
          {{ $t('onboarding.mfa-preference') }}
        </h2>
        <div class="onboarding__current">
          <div class="onboarding__current-selection">
            <div class="onboarding__current-title">
              {{ $t(`onboarding.welcome.buttons.${MFAType}.type`) }}
            </div>
            <div class="onboarding__current-description">
              {{ $t(`onboarding.welcome.buttons.${MFAType}.label`) }}
            </div>
          </div>
          <div class="onboarding__current-change">
            <a @click="MFAType = ''">{{ $t('onboarding.change-mfa') }}</a>
          </div>
        </div>
        <div class="onboarding__step-wrapper">
          <h3 v-if="$te(`onboarding.${MFAType}.step-title`)">
            {{ $t(`onboarding.${MFAType}.step-title`) }}
          </h3>
          <ul class="onboarding__steps">
            <li
              class="onboarding__step"
              v-for="step in MFATypeSteps"
              :key="step"
            >
              {{ step }}
            </li>
          </ul>
        </div>
        <OnboardingAuthenticator
          v-if="isActiveType('authenticator')"
          @finished="setFinished"
        />
        <OnboardingSms v-if="isActiveType('sms')" @finished="setFinished" />
        <OnboardingNoMFA v-if="isActiveType('none')" />
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import OnboardingWelcome from '@/components/onboarding/OnboardingWelcome.vue';
import OnboardingAuthenticator from '@/components/onboarding/OnboardingAuthenticator.vue';
import OnboardingNoMFA from '@/components/onboarding/OnboardingNoMFA.vue';
import OnboardingFinished from '@/components/onboarding/OnboardingFinished.vue';
import OnboardingSms from '@/components/onboarding/OnboardingSms.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    OnboardingSms,
    OnboardingNoMFA,
    OnboardingFinished,
    OnboardingAuthenticator,
    OnboardingWelcome,
  },
  data() {
    return {
      MFATypes: ['sms', 'authenticator', 'none'],
      MFAType: '',
      finished: false,
    };
  },
  computed: {
    MFATypeSteps(): string[] {
      if (this.$te(`onboarding.${this.MFAType}.steps`)) {
        return this.$t(`onboarding.${this.MFAType}.steps`)
          .toString()
          .split('|');
      }
      return [];
    },
  },
  methods: {
    isActiveType(type: string): boolean {
      return type === this.MFAType;
    },
    chooseMFAType(type: string) {
      this.MFAType = type;
    },
    setFinished() {
      this.finished = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.modal-card-body {
  max-width: 767px;
  border-radius: 8px;
  padding: 48px 48px 32px 48px;

  @include mobile {
    padding: 20px;
  }
}

:deep(.onboarding__button) {
  min-width: 241px;
  @include mobile {
    min-width: auto;
  }
}

:deep(.onboarding__wrapper) {
  margin: 20px 0;
  padding: 40px;
  border: 1px solid $gallery;
  box-shadow: 10px 14px 14px rgba($silver, 0.25);
  border-radius: 8px;

  @include mobile {
    padding: 20px;
  }
}

:deep(.button-row) {
  text-align: right;
}

.onboarding {
  &__title {
    margin-top: 0;
  }

  &__current {
    border: 1px solid rgba($primary, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
  }

  &__current-selection {
    color: $primary;
    flex: 1 1 100%;
  }

  &__current-title {
    font-weight: bold;
  }

  &__current-change {
    flex: 0 1 0px;
    font-size: 16px;
    margin-left: 30px;

    a {
      color: $primary;
    }
  }

  &__step-wrapper {
    margin-top: 32px;
  }

  &__steps {
    margin: 20px 5px;
    padding-left: 20px;
    list-style: disc;
  }

  &__step {
    font-size: 16px;
  }
}
</style>
