import { fetchAuthSession } from 'aws-amplify/auth';
import { Configuration } from '@/api';

export default async function getConfiguration() {
  try {
    const { tokens } = (await fetchAuthSession()) as any; // Can't import AuthSession type
    const { idToken } = tokens;

    const baseOptions = {
      headers: {
        authorization: idToken,
      },
    };

    return new Configuration({ baseOptions });
  } catch (e) {
    return new Configuration({});
  }
}
