<template>
  <OTable
    :data="itemsData"
    :root-class="`order-item-list ${details ? 'b-table--details-table' : ''} ${
      orderCardItemsIsNew ? 'order-card__items--is-new' : ''
    } ${
      type === 'default' || type === 'claim' ? 'b-table--no-rounded-top' : ''
    } ${type === 'claim' ? 'order-item-list--claim' : ''}`"
    :striped="striped"
    :default-sort="defaultSort"
    sort-icon="chevron_up"
    sort-icon-size="is-small"
  >
    <o-table-column
      :label="$t('orders.order-item-list.header.product')"
      v-slot="props"
      field="ItemText"
      sortable
    >
      {{ props.row.ItemText }}
    </o-table-column>
    <o-table-column
      :label="$t('orders.order-item-list.header.code')"
      v-slot="props"
      :width="102"
      field="ShortCode"
      sortable
      >{{ props.row.ShortCode }}</o-table-column
    >
    <o-table-column
      v-if="type !== 'claim'"
      :label="$t('orders.order-item-list.header.art')"
      v-slot="props"
      :width="100"
      field="ProductNr"
      sortable
      >{{ props.row.ProductNr }}</o-table-column
    >
    <o-table-column
      v-if="type === 'default'"
      :label="$t('orders.order-item-list.header.unit-type')"
      v-slot="props"
      :width="110"
      field="SalesUnit"
    >
      {{
        $t(`orders.order-item-list.unit.${props.row.SalesUnit.toLowerCase()}`)
      }}
    </o-table-column>
    <o-table-column
      v-if="type === 'container-calculation'"
      :label="$t('orders.order-item-list.header.boxes')"
      v-slot="props"
      :width="100"
      root-class="is-centered"
      >{{ calculateBoxes(props.row) }}</o-table-column
    >
    <o-table-column
      v-if="type !== 'claim'"
      :label="
        type === 'container-calculation'
          ? $t('orders.order-item-list.header.pallets')
          : $t('orders.order-item-list.header.quantity')
      "
      v-slot="props"
      :width="70"
      numeric
      field="Quantity"
      position="right"
      >{{ props.row.Quantity }}</o-table-column
    >
    <!-- start claim columns -->
    <o-table-column
      v-if="type === 'claim'"
      :label="$t('claims.new.items.bags')"
      v-slot="props"
      :width="70"
      root-class="is-centered"
      field="Quantity"
    >
      {{ props.row.SalesUnit === 'BAG' ? props.row.Quantity : '' }}
    </o-table-column>
    <o-table-column
      v-if="type === 'claim'"
      :label="$t('claims.new.items.boxes')"
      v-slot="props"
      :width="70"
      root-class="is-centered"
      field="Quantity"
    >
      {{ props.row.SalesUnit === 'CAR' ? props.row.Quantity : '' }}
    </o-table-column>
    <o-table-column
      v-if="type === 'claim'"
      :label="$t('claims.new.items.volume')"
      v-slot="props"
      width="100"
    >
      {{ calculateClaim(props.row).volume }} {{ $t('claims.kg') }}
    </o-table-column>
    <o-table-column
      v-if="type === 'claim'"
      :label="$t('claims.new.items.billback-rate')"
      v-slot="props"
      numeric
      width="140"
      position="right"
    >
      <BaseCurrencyAmount
        :currency="agreement.currency"
        :amount="
          props.row.billbackRate || agreementItem(props.row).billback_rate
        "
      />
    </o-table-column>
    <o-table-column
      v-if="type === 'claim'"
      :label="$t('claims.new.items.billback-amount')"
      v-slot="props"
      numeric
      width="160"
      position="right"
    >
      <BaseCurrencyAmount
        :currency="agreement.currency"
        :amount="calculateClaim(props.row).billbackAmount"
      />
    </o-table-column>
    <!-- end claim columns -->

    <template #footer>
      <OrderTotals
        v-if="type === 'default'"
        :items="items"
        :products="relatedProducts"
        :label="$t('orders.order-item-list.totals.grand-total')"
        class="order-item-list__totals"
      />
      <ContainerCalculationTotals
        v-else-if="type === 'container-calculation'"
        mod="summary"
      />
      <NewClaimOverviewProductsTotals
        v-else-if="type === 'claim'"
        :claim-totals="claimTotals"
        :claim-agreement="agreement"
      />
    </template>
  </OTable>
</template>

<script lang="ts">
import { OrderItem, SalesQuote, SalesQuoteItem, SapProduct } from '@/api/model';
import OrderTotals from '@/components/orders/OrderTotals.vue';
import ContainerCalculationTotals from '@/components/container-calculation/ContainerCalculationTotals.vue';
import BaseCurrencyAmount from '@/components/BaseCurrencyAmount.vue';
import NewClaimOverviewProductsTotals from '@/components/claims/new-claim/NewClaimOverviewProductsTotals.vue';
import { ClaimTotals } from '@/types/ClaimTotals';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    NewClaimOverviewProductsTotals,
    OrderTotals,
    ContainerCalculationTotals,
    BaseCurrencyAmount,
  },
  computed: {
    defaultSort(): any[] {
      return this.sortByCart ? [] : ['ItemText', 'asc'];
    },
    itemsData(): any[] {
      return this.items;
    },
    relatedProducts(): SapProduct[] {
      return this.$store.state.order.products.filter((product: SapProduct) =>
        (this.items as OrderItem[]).some(
          (item: OrderItem) => item.ProductNr === product.ProductNr,
        ),
      );
    },
  },
  methods: {
    calculateBoxes(item: OrderItem): number {
      return this.$store.getters['container/calculateBoxes'](item);
    },
    getProductFromOrderItem(orderItem: OrderItem): SapProduct | undefined {
      let product;
      // eslint-disable-next-line no-unused-expressions
      this.agreement?.items?.some((agrItem) => {
        const p = agrItem.sap_product;
        if (p?.ProductNr === orderItem.ProductNr) {
          product = p;
          return true;
        }
        return false;
      });
      return product;
    },
    agreementItem(orderItem: OrderItem): SalesQuoteItem {
      const product = this.getProductFromOrderItem(orderItem);
      return this.agreement?.items?.find(
        (item) => item.sap_product === product,
      ) as SalesQuoteItem;
    },
    calculateClaim(orderItem: any): ClaimTotals {
      if (orderItem.volume) {
        return {
          volume: orderItem.volume,
          billbackAmount: orderItem.billbackAmount,
        };
      }
      const product = this.getProductFromOrderItem(orderItem);
      return this.$store.getters['claim/getItemCalculation'](product);
    },
  },
  props: {
    items: { type: Array, default: () => [] },
    type: { type: String, default: 'default' },
    sortByCart: { type: Boolean, default: false },
    striped: { type: Boolean, default: true },
    agreement: { type: Object as () => SalesQuote, default: () => undefined },
    claimTotals: { type: Object, default: () => undefined },
    details: { type: Boolean, default: true },
    orderCardItemsIsNew: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.order-item-list {
  width: 100%;

  &__totals {
    @include mobile {
      padding: 11px 6px;
    }
  }
}
</style>
