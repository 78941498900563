<template>
  <div class="product-select">
    <ProductSearchBar class="select-products__search" type="claim" />

    <div class="layout__content layout__content--large">
      <o-table
        :data="[]"
        root-class="product-item-list product-item-list--no-body"
      >
        <o-table-column :label="$t('orders.order-item-list.header.product')" />
        <o-table-column
          :label="$t('orders.order-item-list.header.code')"
          :width="110"
        />
        <o-table-column :label="$t('claims.new.items.bags')" :width="90" />
        <o-table-column :label="$t('claims.new.items.boxes')" :width="90" />
        <o-table-column :label="$t('claims.new.items.volume')" :width="90" />
        <o-table-column
          :label="$t('claims.new.items.billback-rate')"
          :width="120"
        />
        <o-table-column
          :label="$t('claims.new.items.billback-amount')"
          :width="155"
        />
      </o-table>
    </div>

    <SelectProductGroup
      v-for="groupLabel in groupLabels"
      :key="groupLabel"
      :group-label="groupLabel"
      :products="groups[groupLabel]"
      :agreement="agreement"
      type="claim"
      cart-type="claim"
    />
  </div>
</template>

<script lang="ts">
import { SalesQuote, SapProduct } from '@/api';
import SelectProductGroup from '@/components/orders/SelectProductGroup.vue';
import { ProductGroup } from '@/store/OrderStore';
import ProductSearchBar from '@/components/orders/ProductSearchBar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ProductSearchBar, SelectProductGroup },
  computed: {
    agreement(): SalesQuote {
      return this.$store.getters['claim/agreement'];
    },
    products(): SapProduct[] {
      return this.$store.getters['claim/productSearchResult'];
    },
    groups(): ProductGroup[] {
      return this.$store.getters['claim/agreementProductGroups'](true);
    },
    groupLabels(): string[] {
      const all = Object.keys(this.groups);
      const filter = this.$store.state.claim.products.filterGroups;
      if (filter.size === 0) {
        return all;
      }
      return all.filter((group) => filter.has(group));
    },
  },
});
</script>
