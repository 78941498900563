<template>
  <div
    class="order-card"
    :class="{
      'order-card--is-new': isNewOrder,
      'order-card--is-modal': type === 'modal',
    }"
  >
    <template v-if="!isNewOrder">
      <header class="order-card__header">
        <div
          class="order-card__header-item order-card__header-item--orderno"
          v-if="orderNo"
        >
          <span class="order-card__nowrap">{{
            $t('orders.card.header.order-no')
          }}</span>
          <span class="order-card__header-item-value">{{ orderNo }}</span>
        </div>
        <div
          class="order-card__header-item order-card__header-item--ref"
          v-if="orderRef"
        >
          <span class="order-card__nowrap">{{
            $t('orders.card.header.order-ref')
          }}</span>
          <span class="order-card__header-item-value">{{ orderRef }}</span>
        </div>
        <div
          class="order-card__header-item order-card__header-item--city"
          v-if="orderCity"
        >
          <span
            class="order-card__header-item-value"
            :title="$t('orders.card.header.order-city') + ' ' + orderCity"
            >{{ orderCity }}</span
          >
        </div>
        <div
          class="order-card__header-item order-card__header-item--date"
          v-if="orderDate"
          :title="fullOrderDate"
        >
          <span
            class="order-card__header-item-value order-card__nowrap"
            :title="$t('orders.card.header.order-date') + ' ' + orderDate"
            >{{ orderDate }}</span
          >
        </div>
        <div
          v-if="type !== 'modal'"
          @click="toggleActive(!active)"
          class="order-card__toggle order-card__header-item order-card__toggle--desktop"
        >
          <span class="order-card__toggle-text">{{ toggleText }}</span>
          <div
            class="order-card__toggle-icon"
            :class="{ 'order-card__toggle-icon--active': active }"
          >
            <OIcon icon="chevron_up" />
          </div>
        </div>
      </header>

      <header class="order-card__subheader">
        <OrderStatus
          :status="order.OrderStatus"
          :delivery-date="order.DeliveryDate"
          :eta="Helpers.eta(order)"
          :delay="Helpers.delay(order, $i18n.locale, $t)"
          class="order-card__status"
        />
        <div v-if="order.BillOfLading" class="order-card__bol">
          <OIcon icon="text-document" />
          <strong>{{ $t('orders.card.header.bill-of-lading') }} </strong
          >{{ order.BillOfLading }}
        </div>
        <OrderContainerList
          v-if="order.OrderContainers && order.OrderContainers.length"
          :containers="order.OrderContainers"
          class="order-card__containers"
        />
      </header>
    </template>

    <!-- New order -->
    <header class="order-card__header order-card__header--summary" v-else>
      <div class="order-card__header-item order-card__header-item--summary">
        <h2 class="order-card__title">
          {{ $t('orders.card.header.products-to-order') }}
        </h2>
      </div>
      <div class="order-card__header-item" v-if="orderRef">
        <span>{{ $t('orders.card.header.order-reference') }}&nbsp;</span>
        <span class="order-card__header-item-value">{{ orderRef }}</span>
      </div>
    </header>
    <transition name="slide">
      <div class="order-card__content" v-if="active">
        <main class="order-card__details">
          <OrderItemList
            v-if="order.OrderItems"
            :items="order.OrderItems"
            :details="false"
            :sort-by-cart="true"
            :order-card-items-is-new="isNewOrder"
          />
          <OSkeleton v-else :animated="true" height="194px" />
        </main>
        <footer
          class="order-card__footer"
          v-if="
            !isNewOrder &&
            this.type !== 'modal' &&
            $store.getters['auth/hasAccess']({
              action: 'POST',
              resource: '/new-order',
            })
          "
        >
          <span class="order-card__footer-text">
            {{ $t('orders.card.footer.reorder') }}
          </span>
          <OButton
            rounded
            outlined
            variant="primary"
            @click="reOrder()"
            :disabled="loading"
          >
            {{ $t('orders.card.footer.order-again') }}
          </OButton>
        </footer>
        <footer
          class="order-card__footer order-card__footer--is-new"
          v-if="isNewOrder"
        >
          {{ $t('orders.card.footer.summary-info') }}
        </footer>
      </div>
    </transition>
    <div
      v-if="!isNewOrder"
      @click="toggleActive(!active)"
      class="order-card__toggle order-card__toggle--mobile"
    >
      <span class="order-card__toggle-text">{{ toggleText }}</span>
      <div
        class="order-card__toggle-icon"
        :class="{ 'order-card__toggle-icon--active': active }"
      >
        <OIcon icon="chevron_up" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Helpers from '@/logic/orders/helpers';
import OrderContainerList from '@/components/orders/OrderContainerList.vue';
import OrderItemList from '@/components/orders/OrderItemList.vue';
import OrderStatus from '@/components/orders/OrderStatus.vue';
import { SalesOrderAll as SalesOrder } from '@/logic/orders/SalesOrderAll';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { OrderContainerList, OrderItemList, OrderStatus },
  data() {
    return {
      loading: false,
      Helpers,
    };
  },
  computed: {
    orderNo(): string | undefined {
      return this.order.OrderNumber || undefined;
    },
    orderRef(): string | undefined {
      return this.order.PurchOrder || undefined;
    },
    orderCity(): string | undefined {
      return this.order.City || undefined;
    },
    fullOrderDate(): string | null {
      return Helpers.getReadableCreatedFullDate(this.order);
    },
    orderDate(): string | null {
      return Helpers.getReadableCreatedDate(this.order);
    },
    active(): boolean {
      if (this.isNewOrder || this.type === 'modal') {
        return true;
      }
      return this.$store.getters['order/isActive'](this.order.Id);
    },
    toggleText(): string {
      const key = this.active ? 'close' : 'more';
      return this.$t(`orders.card.header.${key}`) as string;
    },
    isNewOrder(): boolean {
      return this.order.Id === undefined;
    },
  },
  methods: {
    toggleActive(active: boolean) {
      this.$store.commit('order/toggleActive', {
        id: this.order.Id,
        active,
      });
    },
    async reOrder() {
      this.loading = true;
      await this.$store.dispatch('order/reOrder', this.order).then(() => {
        this.loading = false;
        this.$router.push({ name: 'new-order' });
      });
    },
    activeChanged(active: boolean) {
      if (active) {
        if (this.order.Id) {
          this.$store.dispatch('order/fetchOrder', this.order.Id);
        }
      }
    },
  },
  props: {
    order: {
      type: Object as () => SalesOrder,
      required: true,
    },
    type: { default: 'default', type: String as PropType<'default' | 'modal'> },
  },
  watch: {
    active: [{ immediate: true, handler: 'activeChanged' }],
  },
});
</script>

<style lang="scss">
$header-label-breakpoint: $navbar-breakpoint;

.order-card {
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid $alto;

  @include tablet {
    &:not(.order-card--is-modal) {
      box-shadow: $card-box-shadow;
    }
  }

  &--is-modal {
    margin-bottom: 0;

    // Overwrite the default table styling for modal
    .b-table,
    .table {
      border-radius: 0 0 8px 8px !important;
    }

    .table {
      border-bottom: 0 solid $white !important;
    }
  }

  > :first-child {
    border-radius: 8px 8px 0 0;
  }

  > :last-child {
    border-radius: 0 0 8px 8px;
  }

  &__nowrap {
    white-space: nowrap;
  }

  &__hide-label {
    @include until($header-label-breakpoint) {
      display: none;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    background-color: $wild-sand;
    color: $swamp;
    min-height: 55px;
    padding: 6px 16px 0;
    border-radius: 8px 8px 0 0;
    position: relative;

    @include tablet {
      flex-direction: row;
      align-items: center;
      padding: 0 10px 0 16px;
    }

    &--summary {
      border-bottom: 1px solid $alto;
    }
  }

  &__header-item {
    display: flex;
    justify-content: space-between;
    padding: 9px 0;

    @include mobile {
      border-bottom: 1px solid $alto;

      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
    }

    @include tablet {
      display: inline-block;
      line-height: 1.2;
      padding: 0 9px;
      align-items: center;
      flex-direction: row;
    }

    @include tablet {
      &:first-child {
        padding-left: 0;
      }
    }

    &:not(:first-child)::before {
      @include tablet {
        content: '';
        display: inline-block;
        position: absolute;
        background: $alto;
        height: 47px;
        width: 1px;
        top: 4px;
        transform: translateX(-10px);
      }
    }

    &--summary {
      font-size: 20px;
    }

    &--hover {
      cursor: pointer;
    }

    @include tablet {
      &--orderno {
        flex: 0 1 130px;
      }

      &--ref {
        flex: 0 1 270px;
      }

      &--city {
        flex: 1;
      }

      &--date {
        flex: 0 1 100px;
      }
    }
  }

  &__header-item-value {
    white-space: nowrap;
  }

  &__items--is-new {
    .totals {
      background: $wild-sand;
      height: 78px;

      @include mobile {
        height: auto;
      }

      &__label {
        font-weight: bold;
        color: $primary;

        &::after {
          content: ' *';
        }
      }

      &__unit {
        color: $primary;
      }
    }
  }

  &__subheader {
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    > * {
      &:not(:first-child)::before {
        @include tablet {
          content: '';
          display: inline-block;
          position: absolute;
          background: $alto;
          height: 50px;
          width: 1px;
          top: 9px;
        }
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }

  @include tablet {
    &__status {
      flex: 0 1 415px;
    }

    &__bol {
      flex: 0 1 150px;
    }

    &__containers {
      flex: 1;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 8px 8px;

    @include mobile {
      margin: 16px;
      font-size: 16px;
      border-top: 0;
    }

    @include tablet {
      height: 98px;
      padding: 0 24px;
      flex-direction: row;
      align-items: center;
    }

    &--is-new {
      background: $wild-sand;
      color: $primary;
      font-size: 16px;

      @include mobile {
        padding: 16px;
        margin: -1px 1px 0 1px;
      }

      @include tablet {
        min-height: 78px;
      }
    }
  }

  &__footer-text {
    font-size: 16px;
    color: $boulder;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__toggle {
    align-items: center;
    margin-left: auto;
    font-size: 16px;
    cursor: pointer;
    transition: 200ms ease-in-out;

    @include mobile {
      height: 45px;
      padding: 0 16px;
      background: $concrete;
      justify-content: space-between;
      border-top: 1px solid $alto;
    }

    @include tablet {
      margin-right: -5px;
      padding: 0 0 0 16px;
      font-size: 18px;
    }

    &--mobile {
      display: flex;

      @include tablet {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include tablet {
        display: flex;
      }
    }
  }

  &__toggle-text {
    @include tablet {
      width: 47px;
      text-align: right;
    }
  }

  &__toggle-icon {
    background-color: $concrete;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    transition: 200ms ease-in-out;

    @include tablet {
      margin-left: 4px;
    }

    .icon {
      transition: all 0.2s ease;
      transform: rotate(180deg);
    }

    &--active {
      .icon {
        position: relative;
        top: -1px;
        transform: rotate(0);
      }
    }
  }

  &__title {
    font-weight: bold;
  }
}
</style>
