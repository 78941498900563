import { computed, ComputedRef } from 'vue';
import { useWindowSize } from '@vueuse/core';

// scss variables breakpoints
const TABLET_BREAKPOINT = 769;
const WIDESCREEN_BREAKPOINT = 1216;

export default function useScreenWidths() {
  const { width } = useWindowSize();

  const isWidescreen: ComputedRef<boolean> = computed(
    () => width.value > WIDESCREEN_BREAKPOINT,
  );

  return {
    isWidescreen,
    TABLET_BREAKPOINT,
  };
}
