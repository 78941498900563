<template>
  <div class="custom-label-switch__action" v-on-click-outside="closePopup">
    <button
      class="custom-label-switch__button"
      :class="{ 'custom-label-switch__button--active': open }"
      @click="togglePopup"
    >
      <o-icon icon="text_edit" /><o-icon icon="more_vert" />
    </button>
    <div
      class="custom-label-switch__popup"
      :class="{ 'custom-label-switch__popup--left': positionLeft }"
      v-if="open"
    >
      <button class="custom-label-switch__close-button" @click="open = false">
        <o-icon icon="close" />
      </button>
      <h4 class="custom-label-switch__popup-header">
        {{ $t('orders.custom-label-switch.title') }}
      </h4>
      <p class="custom-label-switch__popup-desciption">
        {{ $t('orders.custom-label-switch.description') }}
      </p>
      <o-field class="custom-label-switch__popup-checkbox">
        <o-checkbox v-model="enabled"
          >{{ $t('orders.custom-label-switch.checkbox') }}
        </o-checkbox>
      </o-field>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

export default defineComponent({
  data() {
    return {
      enabled: false,
      open: false,
    };
  },
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  methods: {
    closePopup() {
      this.open = false;
    },
    togglePopup(event: MouseEvent) {
      event.preventDefault();
      this.open = !this.open;
    },
  },
  watch: {
    enabled() {
      this.$emit('changed', this.enabled);
    },
  },
  props: {
    positionLeft: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
.custom-label-switch {
  &__action {
    position: relative;
    white-space: normal;
  }

  &__button {
    border: 1px solid $dusty-gray;
    background-color: transparent;
    border-radius: 5px;
    padding: 2px;
    color: $prussian-blue;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    width: 46px;
    height: 32px;

    &--active {
      background-color: $prussian-blue;
      border: 1px solid $prussian-blue;
      color: $white;
    }

    .icon {
      font-size: 24px !important;
      margin: 0 !important;
      padding: 0 !important;

      .lwm-text_edit {
        padding-left: 4px;
        font-size: 20px;
      }
    }
  }

  &__popup {
    position: absolute;
    font-size: 14px;
    left: -20px;
    top: 38px;
    width: 100vw;
    max-width: 305px;
    background-color: $white;
    padding: 16px;
    z-index: 1;
    box-shadow: 0 2px 10px 0 rgba($black, 0.3);
    color: $prussian-blue-light;

    &--left {
      left: unset;
      right: 0;
    }

    &-header {
      margin-bottom: 4px;
      font-weight: bold;
    }

    &-desciption {
      margin-bottom: 10px;
    }

    &-checkbox .b-checkbox.checkbox {
      align-items: flex-start;

      input[type='checkbox'] ~ {
        .check {
          width: 18px;
          height: 18px;
          border-radius: 5px;
        }

        .control-label {
          padding-left: 8px;
        }
      }
    }
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 5px;
    background-color: transparent;
    color: $trinidad;
    border: 0;

    .icon {
      font-size: 16px !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
</style>
