import { createI18n } from 'vue-i18n';

function loadLocaleMessages(): any {
  let locales: any;

  try {
    locales = require.context('./locales', true, /[\w\s-]+\.json$/i);
  } catch (error) {
    if (process.env.NODE_ENV === 'test') {
      // Handle the mocked require.context in tests
      locales = {
        keys: () => [],
        resolve: (key: any) => key,
        id: (key: any) => key,
      };
    } else {
      throw error;
    }
  }

  const messages: any = {};
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-GB',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-GB',
  messages: loadLocaleMessages(),
});
