import { Dish } from '@/api/model';
import { ScoredDish } from '@/logic/menu-engineering/ScoredDish';
import Score from '@/logic/menu-engineering/Score';

// Constants to indicate what profit contribution a dish has on the total
// profit; high, low or none.
enum ProfitContribution {
  HIGH = 16,
  LOW = 4,
  NONE = 1,
}

// Constants to indicate how populair a dish is compared to all dishes; high, low or none.
enum Popularity {
  HIGH = 8,
  LOW = 2,
  NONE = 0,
}

// Define the score of a dish when grouped in winner, runner, sleeper and loser.
// A winner has high profit and popularity contribution.
// A runner has low profit contribution, but high popularity.
// A sleeper has high profit contribution, but is not populair.
// A loser has low profit contribution and low popularity.
enum ScoreMatrix {
  WINNER = ProfitContribution.HIGH + Popularity.HIGH,
  RUNNER = ProfitContribution.LOW + Popularity.HIGH,
  SLEEPER = ProfitContribution.HIGH + Popularity.LOW,
  LOSER = ProfitContribution.LOW + Popularity.LOW,
}

export default class Calculator {
  // The labour cost per minute in euro for a chef
  static chefLabourCost = 0.53;

  // The labour cost per minute in euro for a employee
  static employeeLabourCost = 0.28;

  // The popularity norm
  static popularityNorm = 0.7;

  // Dishes used to enrich data for single dish calculation
  static enrichDishes: Dish[] = [
    {
      id: 'DUMMY',
      name: 'Grilled tuna with seaweed',
      popularity: 34,
      costPrice: 4.15,
      chefTime: 1,
      staffTime: 2,
      price: 16.274,
    },
    {
      id: 'DUMMY',
      name: 'Pasta Bolognese',
      popularity: 35,
      costPrice: 2.6,
      chefTime: 0,
      staffTime: 6,
      price: 11.792,
    },
    {
      id: 'DUMMY',
      name: 'Filled Aubergine',
      popularity: 29,
      costPrice: 3.75,
      chefTime: 0,
      staffTime: 5,
      price: 14.151,
    },
    {
      id: 'DUMMY',
      name: 'Entrecote',
      popularity: 60,
      costPrice: 2.8,
      chefTime: 0.5,
      staffTime: 1.5,
      price: 15.094,
    },
    {
      id: 'DUMMY',
      name: 'Salmon',
      popularity: 66,
      costPrice: 2.99,
      chefTime: 0.5,
      staffTime: 1.5,
      price: 16.981,
    },
    {
      id: 'DUMMY',
      name: 'Steak Frites',
      popularity: 86,
      costPrice: 4.1,
      chefTime: 2,
      staffTime: 4,
      price: 13.915,
    },
    {
      id: 'DUMMY',
      name: 'Rib Eye Bearnaise',
      popularity: 91,
      costPrice: 4.9,
      chefTime: 4,
      staffTime: 2,
      price: 16.981,
    },
    {
      id: 'DUMMY',
      name: 'Saltimbocca',
      popularity: 36,
      costPrice: 4.5,
      chefTime: 4,
      staffTime: 2,
      price: 16.509,
    },
    {
      id: 'DUMMY',
      name: 'Sauerkraut with pastrami',
      popularity: 61,
      costPrice: 3.5,
      chefTime: 2,
      staffTime: 4,
      price: 14.623,
    },
    {
      id: 'DUMMY',
      name: 'Fried codfish with mustard sauce',
      popularity: 52,
      costPrice: 5.05,
      chefTime: 2,
      staffTime: 4,
      price: 15.566,
    },
  ];

  // Categorizes the dishes in the given list into the menu engineering categories.
  public static calculate(dishes: Dish[]): ScoredDish[] {
    const enriched = Calculator.enrichList(dishes);
    const scores = Calculator.getScores(enriched);
    if (scores.length === 1) {
      return scores.map((dish) => ({
        ...dish,
        score: Calculator.mapScore(dish),
      }));
    }
    return scores;
  }

  // Enrich the list with extra dishes to compare the users dishes against when they
  // did not add enough dishes yet.
  private static enrichList(dishes: Dish[]): Dish[] {
    const enrich = Calculator.enrichDishes;
    if (dishes.length < 5 && enrich.length >= 5) {
      return [...dishes, ...enrich];
    }

    return dishes;
  }

  // Change the score of the result of a single dish
  private static mapScore(dish: ScoredDish): Score {
    if (dish.score === Score.LOSER || dish.score === Score.WINNER) {
      if (
        // prettier-ignore
        Calculator.getRelativePopularity(50, 50, dish)
          + Calculator.getRelativeProfitContribution(1.2, dish)
        === ScoreMatrix.SLEEPER
      ) {
        return Score.SLEEPER;
      }

      return Score.RUNNER;
    }

    if (dish.score) {
      return dish.score;
    }

    return Score.LOSER;
  }

  //
  // DishScoreList
  //

  public static getScores(dishes: Dish[]): any[] {
    const averageProfitContribution =
      Calculator.getAverageProfitContribution(dishes);
    const totalSold = Calculator.getTotalSold(dishes);
    const averagePopularity = Calculator.getAveragePopularity(dishes);

    const scoredDishes = dishes.map((dish: Dish): ScoredDish => {
      const scoredDish: ScoredDish = { ...dish };
      scoredDish.score = Calculator.getScore(
        dish,
        averageProfitContribution,
        totalSold,
        averagePopularity,
      );
      scoredDish.priority = Calculator.getTurnoverMinusCost(dish);
      return scoredDish;
    });

    return [...scoredDishes]
      .sort((a, b) => ((a.priority || 0) > (b.priority || 0) ? -1 : 1))
      .filter((dish) => dish.id !== 'DUMMY');
  }

  // Return the popularity norm
  private static getAverageProfitContribution(dishes: Dish[]): number {
    if (Calculator.getTotalSold(dishes) === 0) {
      return 0;
    }

    // prettier-ignore
    return Calculator.getTotalTurnoverMinusCost(dishes) / Calculator.getTotalSold(dishes);
  }

  private static getTotalSold(dishes: Dish[]): number {
    let total = 0;
    dishes.forEach((dish) => {
      if (dish.popularity) {
        total += dish.popularity;
      }
    });
    return total;
  }

  // Return the total turnover minus cost for the dishScores in currency
  private static getTotalTurnoverMinusCost(dishes: Dish[]): number {
    let total = 0;
    dishes.forEach((dish) => {
      total += Calculator.getDishTurnoverMinusCost(dish);
    });
    return total;
  }

  // Return the popularity norm
  private static getAveragePopularity(dishes: Dish[]): number {
    if (Calculator.getTypeOfDishScoresSold(dishes) === 0) {
      return 0;
    }

    // prettier-ignore
    return Calculator.getPopularityNorm() * (100 / Calculator.getTypeOfDishScoresSold(dishes));
  }

  // Return the number of dishes sold per type of dish.
  private static getTypeOfDishScoresSold(dishes: Dish[]): number {
    let total = 0;
    dishes.forEach((dish) => {
      if (dish.popularity && dish.popularity !== 0) {
        total += 1;
      }
    });
    return total;
  }

  // Get the score category for a dish
  private static getScore(
    dish: ScoredDish,
    averageProfitContribution: number,
    totalSold: number,
    averagePopularity: number,
  ): any {
    const contribution = Calculator.getRelativeProfitContribution(
      averageProfitContribution,
      dish,
    );
    const popularity = Calculator.getRelativePopularity(
      totalSold,
      averagePopularity,
      dish,
    );
    const score = popularity + contribution;

    if (score === ScoreMatrix.WINNER) {
      return Score.WINNER;
    }

    if (score === ScoreMatrix.RUNNER) {
      return Score.RUNNER;
    }

    if (score === ScoreMatrix.SLEEPER) {
      return Score.SLEEPER;
    }

    if (score === ScoreMatrix.LOSER) {
      return Score.LOSER;
    }

    return Score.LOSER;
  }

  //
  // Settings
  //

  private static getPopularityNorm(): number {
    return Calculator.popularityNorm;
  }

  //
  // Dish
  //

  // Return the turnover minus cost for the given dish in currency
  private static getDishTurnoverMinusCost(dish: Dish): number {
    // prettier-ignore
    return Calculator.getDishTurnover(dish)
      - Calculator.getTheoreticalPurchasingAndLabourCost(dish);
  }

  // Return the turnover for a given dish (excl. VAT) in currency.
  //
  // @Column W
  // @Formulua ( K * C )
  public static getDishTurnover(dish: Dish): number {
    if (dish.price && dish.popularity) {
      return dish.price * dish.popularity;
    }
    return 0;
  }

  // Return the theoretical purchasing and labour cost for the given dish in currency
  public static getTheoreticalPurchasingAndLabourCost(dish: Dish): number {
    if (!dish.popularity) {
      return 0;
    }

    return Calculator.getTotalCost(dish) * dish.popularity;
  }

  // Get the total cost to the dish
  private static getTotalCost(dish: Dish): number {
    const labourCost = Calculator.getTotalLabourCostPerItem(dish);
    return labourCost + (dish.costPrice ? dish.costPrice : 0);
  }

  // Get the total labour cost per item of this type of dish
  private static getTotalLabourCostPerItem(dish: Dish): number {
    const chefCost = dish.chefTime
      ? dish.chefTime * Calculator.chefLabourCost
      : 0;
    const employeeCost = dish.staffTime
      ? dish.staffTime * Calculator.employeeLabourCost
      : 0;
    return chefCost + employeeCost;
  }

  // Get the profit contribution relative to average of dishes in high/low for the given dish
  public static getRelativeProfitContribution(
    averageProfitContribution: number,
    dish: Dish,
  ): number {
    const profitContribution = Calculator.getProfitContribution(dish);

    if (profitContribution === 0.0) {
      return ProfitContribution.NONE;
    }

    if (profitContribution <= averageProfitContribution) {
      return ProfitContribution.LOW;
    }

    return ProfitContribution.HIGH;
  }

  // Get the profit contribution for the dish
  private static getProfitContribution(dish: Dish): number {
    const price = dish.price ? dish.price : 0;
    let contribution = price - Calculator.getTotalCost(dish);
    if (contribution < 0) {
      contribution = 0.0;
    }
    return contribution;
  }

  // Get the popularity of the given dish relative to the average popularity of all the dishes
  public static getRelativePopularity(
    totalSold: number,
    averagePopularity: number,
    dish: Dish,
  ): number {
    const popularity = Calculator.getPopularityPercentage(totalSold, dish);

    if (popularity === 0) {
      return Popularity.NONE;
    }

    if (popularity <= averagePopularity) {
      return Popularity.LOW;
    }

    return Popularity.HIGH;
  }

  // Get the popularity in percentage for the given dish
  private static getPopularityPercentage(totalSold: number, dish: Dish) {
    if (totalSold === 0 || !dish.popularity) {
      return 0;
    }

    return (dish.popularity / totalSold) * 100;
  }

  // Return the turnover minus cost for the given dish in currency
  public static getTurnoverMinusCost(dish: Dish): number {
    // prettier-ignore
    return (
      Calculator.getDishTurnover(dish)
      - Calculator.getTheoreticalPurchasingAndLabourCost(dish)
    );
  }

  // Return the purchasing cost for the type of dish in currency
  public static getDishPurchasingCost(dish: Dish): number {
    if (dish.costPrice && dish.popularity) {
      return dish.costPrice * dish.popularity;
    }
    return 0;
  }
}
