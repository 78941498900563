import {
  CourseProgressApi,
  ProfileCourseProgressProgressStatusEnum,
} from '@/api';
import { ProfileCourseProgress } from '@/api/model';
import getConfiguration from '@/store/getConfiguration';

const initialState = () => ({
  statusses: [] as ProfileCourseProgress[],
});
export type ELearningState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: ELearningState) {
      Object.assign(state, initialState());
    },

    setStatusses(state: ELearningState, statusses: ProfileCourseProgress[]) {
      state.statusses = statusses;
    },

    updateStatus(state: ELearningState, updateStatus: ProfileCourseProgress) {
      const index = state.statusses.findIndex(
        (status: ProfileCourseProgress) =>
          status.course_id === updateStatus.course_id,
      );
      if (index !== -1) {
        state.statusses[index] = updateStatus;
      } else {
        state.statusses.push(updateStatus);
      }
      state.statusses = JSON.parse(JSON.stringify(state.statusses));
    },
  },

  actions: {
    async fetchStatusses({ commit }: { commit: Function }) {
      const configuration = await getConfiguration();
      const api = new CourseProgressApi(configuration, '');
      const { data } = await api.courseProgressGet();
      commit('setStatusses', data);
    },

    async saveStatus(
      { commit }: { commit: Function },
      status: ProfileCourseProgress,
    ) {
      const configuration = await getConfiguration();
      const api = new CourseProgressApi(configuration, '');
      await api.courseProgressPost(status);
      commit('updateStatus', status);
    },
  },

  getters: {
    status:
      (state: ELearningState) =>
      (courseId: string): ProfileCourseProgressProgressStatusEnum | '' => {
        const status = state.statusses.find((s) => s.course_id === courseId);
        if (status === undefined || status.progress_status === undefined) {
          return '';
        }

        return status.progress_status;
      },
  },
};
