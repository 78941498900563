<template>
  <LayoutDefault>
    <div class="m-e-results">
      <section class="results__intro layout__content">
        <h1 class="title">{{ $t('menuEngineering.results.title') }}</h1>
        <p class="results__intro-text">
          {{ $t('menuEngineering.label.categoryResult.intro') }}
          {{ $t('menuEngineering.label.categoryResult.link') }}.
          {{ $t('menuEngineering.label.categoryResult.outro') }}
        </p>
        <div class="button-row">
          <OButton
            tag="router-link"
            class="button button-row__button"
            rounded
            variant="primary"
            to="/menu-engineering/add-dish"
          >
            {{ $t('menuEngineering.overview.addDish') }}
          </OButton>
        </div>
      </section>
      <section
        class="results__restaurants"
        v-for="restaurant in restaurants"
        :key="restaurant.id"
      >
        <div class="results__restaurant layout__content">
          <h2 class="subtitle">
            {{ restaurant.restaurantName }}
          </h2>
          <OField>
            <OSelect
              :modelValue="course"
              :placeholder="$t('menuEngineering.label.selectCourseLabel')"
              @update:modelValue="setCourse($event)"
              expanded
              required
            >
              <option v-for="course in courses" :key="course" :value="course">
                {{ $t(`menuEngineering.label.course.${course}`) }}
              </option>
            </OSelect>
          </OField>
          <div
            class="results__matrix"
            v-if="getCourseDishCount(restaurant.id) >= 5"
          >
            <ResultCategory
              v-for="category in categories"
              :key="category"
              :category="category"
              :restaurant-id="restaurant.id"
              :dish-count="getDishCount(restaurant.id, category)"
              class="results__category"
            />
          </div>
          <div v-else>
            {{
              $t('menuEngineering.label.insufficientDishes', {
                count: 5 - getCourseDishCount(restaurant.id),
              })
            }}
          </div>
        </div>
      </section>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import { Dish, DishCourseEnum, Restaurant } from '@/api/model';
import MenuEngineeringLogic from '@/logic/menu-engineering/MenuEngineeringLogic';
import Calculator from '@/logic/menu-engineering/Calculator';
import { ScoredDish } from '@/logic/menu-engineering/ScoredDish';
import Score from '@/logic/menu-engineering/Score';
import ResultCategory from '@/components/menu-engineering/ResultCategory.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { LayoutDefault, ResultCategory },
  computed: {
    state() {
      return this.$store.state.menuEngineering;
    },
    course(): DishCourseEnum {
      return this.state.activeCourse;
    },
    dishes(): ScoredDish[] {
      return Calculator.calculate(this.state.dishes);
    },
    restaurants(): Restaurant[] {
      return this.$store.state.restaurant.restaurants;
    },
    courses(): string[] {
      return MenuEngineeringLogic.getCourses();
    },
    categories(): string[] {
      return MenuEngineeringLogic.allCategories();
    },
  },
  created() {
    this.getRestaurants();
    this.getDishes();
  },
  methods: {
    async getRestaurants() {
      await this.$store.dispatch('restaurant/getRestaurants');
    },
    getDishes() {
      this.$store.dispatch('menuEngineering/getDishes');
    },
    getCurrentCourseDishes(restaurantId: string): Dish[] {
      return MenuEngineeringLogic.filterDishes(
        this.dishes,
        restaurantId,
        this.course,
      );
    },
    getCourseDishCount(restaurantId: string): number {
      return this.getCurrentCourseDishes(restaurantId).length;
    },
    getDishCount(restaurantId: string, category: Score): number {
      const categoryDishes = this.getCurrentCourseDishes(restaurantId).filter(
        (dish: ScoredDish) => dish.score === category,
      );
      return categoryDishes.length;
    },
    setCourse(course: DishCourseEnum) {
      this.$store.commit('menuEngineering/setActiveCourse', course);
    },
  },
});
</script>

<style lang="scss" scoped>
.results {
  &__intro {
    padding-bottom: 54px;
  }

  &__intro-text {
    font-size: 16px;
    line-height: 1.75;
    color: $primary;
    margin-bottom: 30px;

    @include desktop {
      font-size: 24px;
      line-height: 2;
      margin-bottom: 22px;
    }
  }

  &__restaurants {
    background: rgba(0, 56, 90, 0.03);
    padding-top: 60px;
    padding-bottom: 113px;
  }

  &__matrix {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @include desktop {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
