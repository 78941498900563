<template>
  <LayoutDefault>
    <div class="new-claim">
      <StepsBar
        labelPrefix="claims"
        :steps="steps"
        :overview-link="{ name: 'claims' }"
        :active-step="step"
      />
      <Form ref="form" v-slot="{ meta }">
        <div
          v-if="step === 'claim-details' && agreement"
          class="new-claim__claim-details"
        >
          <NewClaimDetailForm />
          <NewClaimProductSelect
            v-if="agreement"
            :agreement="agreement"
            class="new-claim__product-select"
          />
          <NewClaimProofUpload />
        </div>

        <div v-if="step === 'claim-summary'" class="new-claim__claim-summary">
          <NewClaimOverview />
        </div>
        <NewClaimFooter
          :step="step"
          :next-active="meta.valid"
          v-if="agreement"
          :agreement="agreement"
        />
      </Form>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import StepsBar from '@/components/StepsBar.vue';
import { Form } from 'vee-validate';
import NewClaimDetailForm from '@/components/claims/new-claim/NewClaimDetailForm.vue';
import NewClaimProofUpload from '@/components/claims/new-claim/NewClaimProofUpload.vue';
import NewClaimProductSelect from '@/components/claims/new-claim/NewClaimProductSelect.vue';
import NewClaimOverview from '@/components/claims/new-claim/NewClaimOverview.vue';
import NewClaimFooter from '@/components/claims/new-claim/NewClaimFooter.vue';
import { SalesQuote } from '@/api';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    StepsBar,
    LayoutDefault,
    Form,
    NewClaimDetailForm,
    NewClaimProofUpload,
    NewClaimProductSelect,
    NewClaimOverview,
    NewClaimFooter,
  },
  data() {
    const steps: string[] = ['claim-details', 'claim-summary'];

    return {
      step: 'claim-details',
      steps,
    };
  },
  computed: {
    agreement(): SalesQuote | undefined {
      return this.$store.getters['claim/agreement'];
    },
  },
  async beforeCreate() {
    this.$store.commit('claim/resetCart');
    await this.$store.dispatch('agreement/fetchAgreements');
  },
  watch: {
    '$route.params': [{ immediate: true, handler: 'onUrlChange' }],
  },
  methods: {
    onUrlChange(): void {
      const { step, agreementId } = this.$route.params;
      this.step = step.toString();
      this.$store.commit('claim/setAgreementId', agreementId);
    },
  },
});
</script>

<style lang="scss" scoped>
.new-claim {
  &__product-select {
    margin-top: 30px;
  }
}
</style>
