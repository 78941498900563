<template>
  <LayoutDefault>
    <div class="new-order">
      <StepsBar
        labelPrefix="orders"
        :steps="steps"
        :overview-link="{ name: 'orders' }"
        :activeStep="$store.state.order.orderStep"
      />
      <Form v-slot="{ meta }">
        <SelectProducts v-if="step === 'select-products'" />
        <OrderReferenceAndShipping
          v-if="step === 'order-reference-and-shipping'"
          :is-invalid="!meta.valid"
        />
        <OrderSummaryAndConfirmation
          v-if="step === 'order-summary-and-confirmation'"
        />
      </Form>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import SelectProducts from '@/components/orders/new-order/SelectProducts.vue';
import OrderReferenceAndShipping from '@/components/orders/new-order/OrderReferenceAndShipping.vue';
import OrderSummaryAndConfirmation from '@/components/orders/new-order/OrderSummaryAndConfirmation.vue';
import StepsBar from '@/components/StepsBar.vue';
import { Form } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LayoutDefault,
    SelectProducts,
    OrderReferenceAndShipping,
    OrderSummaryAndConfirmation,
    StepsBar,
    Form,
  },
  data() {
    const steps: string[] = [
      'select-products',
      'order-reference-and-shipping',
      'order-summary-and-confirmation',
    ];

    return {
      steps,
    };
  },
  computed: {
    step(): string {
      return this.$store.state.order.orderStep;
    },
  },
  created() {
    // Prefetch data needed by the order steps
    this.$store.dispatch('order/fetchProducts');
    this.$store.dispatch('order/fetchOrders');
    this.$store.dispatch('order/fetchShipToPartners');
  },
  watch: {
    $route: [{ immediate: true, handler: 'onUrlChange' }],
  },
  methods: {
    onUrlChange() {
      const { step } = this.$route.params;
      this.$store.commit('order/setOrderStep', step);
    },
  },
});
</script>
