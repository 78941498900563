import * as de from '@/locales/de.json';
import * as enGb from '@/locales/en-GB.json';
import * as es from '@/locales/es.json';
import * as fr from '@/locales/fr.json';
import * as it from '@/locales/it.json';
import * as nl from '@/locales/nl.json';

const getAmplifyLanguage = (language: any): any => {
  if (Object.prototype.hasOwnProperty.call(language.default, 'amplify')) {
    return language.default.amplify;
  }

  return {};
};

/* eslint-disable */
// This dictionary is based on the dictionary of amplify react-native
// The Vue component does not contain default translations.
//
// This dictionary is not yet complete.
const AmplifyI18n: any = {
  de: getAmplifyLanguage(de),
  'en-GB': getAmplifyLanguage(enGb),
  es: getAmplifyLanguage(es),
  fr: getAmplifyLanguage(fr),
  it: getAmplifyLanguage(it),
  nl: getAmplifyLanguage(nl)
};

export default AmplifyI18n;
