<template>
  <div v-if="isVisibleToUser()" class="block form-card">
    <h2 v-if="block && block.title" class="title title--h2 block__title">
      {{ block.title }}
    </h2>
    <div v-if="block && block.text" class="block__text" v-html="block.text" />
  </div>
</template>

<script lang="ts">
import AuthGroup from '@/logic/auth/AuthGroup';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    link(): string | undefined {
      return this.block?.link ? this?.block.link : undefined;
    },
    linkTarget(): string | undefined {
      if (this.block?.multinational) {
        return '_self';
      }

      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      return this.link && this.link.startsWith(baseUrl) ? '_self' : '_blank';
    },
  },
  methods: {
    isVisibleToUser(): boolean {
      const memberOf = this.$store.getters['auth/memberOf'];
      const direct =
        this.block?.direct === true &&
        (memberOf('direct-customers') || memberOf('end-users'));
      const indirect =
        this.block?.indirect === true && memberOf('indirect-customers');
      const multinational =
        this.block?.multinational === true && memberOf(AuthGroup.Multinational);

      return direct || indirect || multinational;
    },
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss">
.dashboard--content .block {
  flex-direction: column;
  background-color: $white;
  border: 1px solid $mercury;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  &--orders,
  &--invoices {
    padding: 0;
    border: none;
  }

  &__top {
    min-height: 230px;
  }

  &__title,
  &__text {
    color: $prussian-blue-light;
  }

  &__title.title.title--h2 {
    flex-basis: 100%;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 0.5em;

    @include tablet {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__text {
    line-height: 26px;

    @include tablet {
      font-size: 20px;
      line-height: 38px;
    }
  }
}
</style>
