<template>
  <section>
    <template v-for="block in blocks">
      <div
        :key="block.link"
        v-if="hasAccess({ action: 'GET', resource: multinationalSlug(block) })"
      >
        <DashboardCallToActionBlock :block="block" />
      </div>
    </template>
  </section>
</template>

<script lang="ts">
import DashboardCallToActionBlock from '@/components/content/blocks/DashboardCallToActionBlock.vue';
import { Endpoint } from '@/logic/auth/Endpoint';
import { defineComponent } from 'vue';

const genericMultinationalPages = [
  'lwm-company-information',
  'trend-information',
  'sustainability',
];

export default defineComponent({
  components: { DashboardCallToActionBlock },
  computed: {
    blocks(): any[] {
      return [
        'lwm-company-information',
        'partnership',
        'approved-products',
        'market-activations',
        'trend-information',
        'new-products',
        'training',
        'sustainability',
        'lwm-contact',
        'best-practices',
        'eat-this',
      ].map((route) => ({
        link: `/${route}`,
        text: this.$t(`navigation.${route}`, {
          company:
            this.$store.state.content.multinationalContent?.content?.title,
        }),
        image: this.getImage(route),
        multinational: true,
        meta: { generic: genericMultinationalPages.includes(route) },
      }));
    },
  },
  methods: {
    hasAccess(endpoint?: Endpoint, group?: string): boolean {
      return this.$store.getters['auth/hasAccess'](endpoint, group);
    },
    getImage(route: string): string {
      const thumbKey = `thumb_${route.replace(/-/g, '_')}`;
      if (this.$store.state.content.multinationalContent?.content[thumbKey]) {
        return this.$store.state.content.multinationalContent?.content[thumbKey]
          .url;
      }

      return `/img/cta/${route}.jpg`;
    },
    multinationalSlug(block: any): string {
      const slug = block.meta?.generic
        ? 'generic'
        : this.$store.getters['auth/multinationalSlug'];
      return `/content/multinational_portal/*/${slug}${block.link}`;
    },
  },
});
</script>
