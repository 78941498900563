import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-dish" }
const _hoisted_2 = {
  class: "menu-engineering",
  key: "add-dish"
}
const _hoisted_3 = { class: "title layout__content" }
const _hoisted_4 = { key: "add-restaurant" }
const _hoisted_5 = { key: "loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DishForm = _resolveComponent("DishForm")!
  const _component_DishList = _resolveComponent("DishList")!
  const _component_RestaurantNameForm = _resolveComponent("RestaurantNameForm")!
  const _component_OLoading = _resolveComponent("OLoading")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.restaurants.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('menuEngineering.overview.addDishEquation')) + " " + _toDisplayString(_ctx.$t('general.for')) + " “" + _toDisplayString(_ctx.restaurant.restaurantName) + "” ", 1),
                  _createVNode(_component_DishForm, {
                    modelValue: _ctx.dish,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dish) = $event)),
                    restaurants: _ctx.restaurants,
                    "show-results-button": _ctx.minimumAmountOfDishesReached,
                    onSubmit: _ctx.save,
                    saving: _ctx.saving
                  }, null, 8, ["modelValue", "restaurants", "show-results-button", "onSubmit", "saving"]),
                  _createVNode(_component_DishList, {
                    dishes: _ctx.filteredDishes,
                    "restaurant-name": _ctx.restaurant.restaurantName,
                    course: _ctx.course,
                    "show-title": true,
                    onDeleteDish: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDish($event)))
                  }, null, 8, ["dishes", "restaurant-name", "course"])
                ]))
              : (!_ctx.loadingRestaurants)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_RestaurantNameForm)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_OLoading, {
                      active: _ctx.loadingRestaurants,
                      "full-page": false
                    }, null, 8, ["active"])
                  ]))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}