<template>
  <LayoutDefault>
    <div class="my-invoices">
      <header class="layout__content layout__content--large">
        <h1 class="title title--direct">
          {{ $t('invoices.header.title') }}
        </h1>
        <p class="layout__intro-text">
          {{ $t('invoices.header.intro') }}
        </p>
        <MfaWarning v-if="!hasMfa" />
      </header>

      <main v-if="hasMfa" class="layout__content layout__content--large">
        <InvoicesActionBar />

        <o-table
          :data="invoices.items"
          :show-detail-icon="false"
          :opened-detailed="defaultOpenedDetails"
          detailed
          :detail-key="detailKey"
          @details-open="(row, index) => closeAllOtherDetails(row, index)"
          striped
          backend-sorting
          :default-sort="[sortField, sortOrder]"
          default-sort-direction="'desc'"
          @sort="onSort"
          class="table--detail-column"
          sort-icon="chevron_up"
          sort-icon-size="is-small"
        >
          <o-table-column
            :label="$t('invoices.column.invoice-nr')"
            v-slot="props"
            sortable
            field="BillDoc"
          >
            <ValueCell :value="props.row.BillDoc" />
          </o-table-column>
          <o-table-column
            header-class="is-hidden-mobile"
            :td-attrs="() => ({ class: 'is-hidden-mobile' })"
            :label="$t('invoices.column.posting-date')"
            v-slot="props"
            sortable
            field="BillDate"
          >
            <ValueCell :value="props.row.BillDate">
              {{ Helpers.formatSapDate(props.row.BillDate) }}
            </ValueCell>
          </o-table-column>
          <o-table-column
            header-class="is-hidden-mobile"
            :td-attrs="() => ({ class: 'is-hidden-mobile' })"
            :label="$t('invoices.column.expire-date')"
            v-slot="props"
            sortable
            field="DueDate"
          >
            <ValueCell :value="props.row.DueDate">
              {{ Helpers.formatSapDate(props.row.DueDate) }}
            </ValueCell>
          </o-table-column>
          <o-table-column
            :label="$t('invoices.column.amount')"
            v-slot="props"
            sortable
            field="NetValue"
          >
            <ValueCell
              :value="
                props.row.Currency !== undefined &&
                props.row.NetValue !== undefined
              "
            >
              <BaseCurrencyAmount
                :currency="props.row.Currency"
                :amount="props.row.NetValue"
              />
            </ValueCell>
          </o-table-column>
          <o-table-column
            header-class="is-hidden-mobile"
            :td-attrs="() => ({ class: 'is-hidden-mobile' })"
            :label="$t('invoices.column.sales-order')"
            v-slot="props"
          >
            <ValueCell :value="props.row.SalesOrderId">
              <ShowSalesOrder
                :label="$t('invoices.column.sales-order-show')"
                :invoice="props.row"
              />
            </ValueCell>
          </o-table-column>
          <o-table-column
            :label="$t('invoices.column.status')"
            v-slot="props"
            :td-attrs="() => ({ class: 'table__cell--no-padding' })"
            sortable
            field="AccStatusText"
          >
            <ValueCell :value="props.row.AccStatusText">
              <div class="my-invoices__status">
                <InvoiceStatus :invoice="props.row" />
              </div>
            </ValueCell>
          </o-table-column>
          <o-table-column
            :label="$t('invoices.column.details')"
            v-slot="props"
            :td-attrs="() => ({ class: 'table__cell--no-padding' })"
          >
            <a @click="props.toggleDetails(props.row)" class="toggle-details">
              <OIcon
                class="toggle-details__icon"
                :class="{
                  'toggle-details__icon--active': isDetailed(props.row.Id),
                }"
                icon="arrow_back"
              />
            </a>
          </o-table-column>

          <template #detail="props">
            <InvoiceDetails :invoice="props.row" />
          </template>

          <template #empty>
            <div class="table__empty">{{ $t('invoices.empty') }}</div>
          </template>
        </o-table>
        <OLoading :active="isLoading" :full-page="false" />

        <OPagination
          root-class="table__pagination"
          :total="invoices.total"
          :range-after="3"
          :range-before="3"
          :current="currentPage"
          :per-page="perPage"
          @update:current="changePage"
        />
      </main>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import MfaWarning from '@/components/authentication/MfaWarning.vue';
import Helpers from '@/logic/orders/helpers';
import ValueCell from '@/components/table/ValueCell.vue';
import TableMixin from '@/mixins/TableMixin';
import InvoiceDetails from '@/components/invoices/InvoiceDetails.vue';
import ShowSalesOrder from '@/components/invoices/ShowSalesOrder.vue';
import InvoiceStatus from '@/components/invoices/InvoiceStatus.vue';
import InvoicesActionBar from '@/components/invoices/InvoicesActionBar.vue';
import BaseCurrencyAmount from '@/components/BaseCurrencyAmount.vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseCurrencyAmount,
    InvoicesActionBar,
    InvoiceStatus,
    LayoutDefault,
    MfaWarning,
    ValueCell,
    InvoiceDetails,
    ShowSalesOrder,
  },
  setup() {
    const store = useStore();

    const invoices = computed(() => store.state.invoice.invoices);
    const perPage = computed(() => store.state.invoice.perPage);
    const currentPage = computed(() => store.state.invoice.currentPage);

    return { invoices, perPage, currentPage };
  },
  computed: {
    sort(): any {
      return this.$store.state.invoice.sort.split('+');
    },
    sortField(): string {
      return this.sort[0];
    },
    sortOrder(): string {
      return this.sort[1] === 'desc' ? 'desc' : 'asc';
    },
    hasMfa(): boolean {
      return this.$store.getters['auth/hasMfa'];
    },
    isLoading(): boolean {
      return this.$store.getters['invoice/isLoading'];
    },
  },
  mixins: [TableMixin],
  data() {
    return {
      detailKey: 'Id',
      fetchAction: 'invoice/fetchInvoices',
      Helpers,
    };
  },
  methods: {
    changePage(page: number): void {
      this.$store.commit('invoice/setCurrentPage', page);
      this.$store.dispatch('invoice/fetchInvoices');
    },
    onSort(field: any, order: string): void {
      const sort = field + (order === 'desc' ? '+desc' : '');
      this.$store.dispatch('invoice/sort', sort);
    },
  },
  beforeUnmount(): void {
    this.$store.commit('invoice/resetState');
  },
});
</script>

<style lang="scss">
.my-invoices {
  &__status {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px;

    > * {
      flex: 1;
    }
  }

  .b-table .table-wrapper {
    .table {
      tbody tr:not(.detail):not(.is-empty):not(.table-footer) td {
        color: $swamp;
      }
    }
  }

  .control {
    width: 100%;
  }
}
</style>
