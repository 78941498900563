import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a7e40bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "restaurants layout__content" }
const _hoisted_2 = {
  key: 0,
  class: "form-card"
}
const _hoisted_3 = { class: "form-card__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RestaurantForm = _resolveComponent("RestaurantForm")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restaurants, (restaurant, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_RestaurantForm, {
          restaurant: restaurant,
          "init-active": !restaurant.id,
          active: _ctx.isActive(restaurant.id)
        }, null, 8, ["restaurant", "init-active", "active"])
      ]))
    }), 128)),
    (
        _ctx.$store.getters['auth/hasAccess']({
          action: 'PUT',
          resource: '/restaurant',
        })
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_OButton, {
              rounded: "",
              outlined: "",
              variant: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addRestaurant())),
              "data-test": "add-restaurant"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('profile.restaurant.add')), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}