<template>
  <div class="filter" v-on-click-outside="closeFilter">
    <div
      class="toggle-button toggle-button--expanded toggle-button--select"
      :class="{ 'toggle-button--disabled': disabled }"
      @click="open = !open"
      role="button"
      data-test="warehouse-filter-toggle"
    >
      <o-icon class="toggle-button__icon-left" icon="filter" />
      <span class="toggle-button__label">
        {{
          activeLabel ||
          $t('orders.search-bar.placeholder.set-preferred-warehouse')
        }}
      </span>
      <div
        class="toggle-button__icon"
        :class="{ 'toggle-button__icon--active': open }"
      >
        <o-icon icon="chevron_up" />
      </div>
    </div>
    <transition name="fade">
      <div v-if="open" class="filter__dropdown">
        <button
          class="filter__button"
          :class="{ 'filter__button--active': activeValue === warehouse.key }"
          v-for="warehouse in warehouses"
          :key="warehouse.key"
          @click="setValue(warehouse.key, $event)"
        >
          <span class="filter__name">{{ warehouse.value }}</span>
          <span class="filter__key">{{ warehouse.key }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

export default defineComponent({
  data: () => ({
    open: false,
  }),
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  computed: {
    activeValue(): string {
      return this.$store.getters['order/preferredWarehouse'];
    },
    activeLabel(): string {
      const activePreferredWarehouse =
        this.$store.getters['order/activePreferredWarehouse'];
      return this.$store.getters['order/getWarehouseLabel'](
        activePreferredWarehouse,
      );
    },
    disabled(): boolean {
      return this.$store.getters['order/orderLockedWarehouse'] !== '';
    },
    warehouses(): { key: string; value: string }[] {
      return Object.entries(this.$store.getters['order/availableWarehouses'])
        .map(([key, value]) => ({
          key,
          value: value as string,
        }))
        .sort((a, b) => a.value.localeCompare(b.value));
    },
  },
  methods: {
    setValue(key: string, event: MouseEvent): void {
      event.preventDefault();
      const value = key !== this.activeValue ? key : '';
      this.$store.dispatch('order/setPreferredWarehouseFromSelect', value);
      this.closeFilter();
    },
    closeFilter(): void {
      this.open = false;
    },
  },
});
</script>

<style lang="scss">
.filter {
  width: 100%;

  .toggle-button {
    $button: &;

    &__label {
      margin-right: auto;
      text-wrap: nowrap;
    }

    &--disabled {
      pointer-events: none;
      background-color: $wild-sand;

      .toggle-button__label {
        color: $dusty-gray;
      }
    }
  }

  &__dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 45px + 26px;
    left: 0;
    max-height: 450px;
    width: 100%;
    padding-inline: 6px;
    overflow-y: auto;
    background-color: $white;
    border: 1px solid $alto;
    box-sizing: border-box;
    box-shadow: 0 70px 34px -50px rgba($black, 0.0863746);
    border-radius: 8px;
    z-index: 60;

    > * {
      margin: 10px 0;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    &--active,
    &:hover {
      .filter__name {
        color: $secondary;
      }
    }
  }

  &__name {
    font-size: 18px;
    color: $black;
  }

  &__key {
    font-size: 12px;
    color: $dusty-gray;
  }
}
</style>
