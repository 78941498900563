<template>
  <a
    href="https://lambweston.eu/"
    target="_blank"
    class="link-corporate"
    rel="noopener noreferrer"
  >
    <OIcon icon="arrow_back" class="link-corporate__icon" />
    <span class="link-corporate__label">
      {{ $t('authentication.link-corporate') }}
      <span class="link-corporate__url">lambweston.eu</span>
    </span>
  </a>
</template>

<style lang="scss" scoped>
.link-corporate {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 14px;
  line-height: 17px;

  @include desktop {
    font-size: 16px;
    line-height: 19px;
  }

  &:hover {
    color: $white;

    .link-corporate__icon {
      transform: translateX(-5px);
    }
  }

  &,
  & * {
    transition: all 0.15s ease;
  }

  &__icon {
    margin-right: 10px;
    font-size: 16px;

    @include from($desktop) {
      font-size: 20px;
    }
  }

  &__label {
    padding-top: 0.25em;
  }

  &__url {
    text-decoration: underline;
  }
}
</style>
