import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    const defaultOpenedDetails: string[] = [];

    return {
      defaultOpenedDetails,
      detailKey: 'Id',
      fetchAction: '',
      loading: false,
    };
  },
  methods: {
    closeAllOtherDetails(row: any) {
      const id = row[this.detailKey];
      if (id === undefined) {
        return;
      }
      this.defaultOpenedDetails = [id];
    },
    isDetailed(id: string) {
      return this.defaultOpenedDetails.includes(id);
    },
    routeChanged(newVal: any) {
      if (!this.fetchAction) {
        return;
      }
      const currentPage = parseInt(newVal.hash.replace(/#page/g, ''), 10) || 1;
      this.$store.dispatch(this.fetchAction, { currentPage });
    },
  },
  watch: {
    $route: [{ immediate: true, handler: 'routeChanged' }],
  },
});
