<template>
  <div class="layout layout--public">
    <main class="layout__main">
      <slot />
    </main>
    <FooterBar class="layout__footer"></FooterBar>
  </div>
</template>

<script lang="ts">
import FooterBar from '@/components/FooterBar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { FooterBar },
});
</script>

<style lang="scss" scoped>
$footer-height: 80px;

.layout {
  @include mobile {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__main {
    flex: 1;
    min-height: calc(100vh - #{$footer-height});

    :deep(.layout__content) {
      max-width: 783px;
      margin-left: 16px;
      margin-right: 16px;

      @include tablet {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__footer {
    height: $footer-height;
    flex: 100%;
    z-index: 30;
  }
}

.layout--public {
  position: relative;
}
</style>
