<template>
  <form @submit.prevent="submit" class="form form--profile">
    <div v-for="field in group.fields" :key="field.name">
      <OField
        :label="$t(`profile.label.${field.name}`)"
        :data-test="`field-${field.name}`"
      >
        <Component
          :is="getInputComponent(field)"
          :modelValue="profile[field.name]"
          @update:modelValue="setField(field.name, $event)"
          @validate="onValidate(field.name, $event)"
          :type="field.type ? field.type : 'text'"
          :class="field.name"
        />
      </OField>
    </div>
    <button-row group="profile" @cancel="cancel" :valid="valid"></button-row>
  </form>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import ButtonRow from '@/components/profile/ButtonRow.vue';
import CountrySelect from '@/components/form/CountrySelect.vue';
import LocaleField from '@/components/form/LocaleField.vue';
import RestaurantTypeSelect from '@/components/form/RestaurantTypeSelect.vue';
import PhoneSelect from '@/components/form/PhoneSelect.vue';

export default defineComponent({
  components: {
    ButtonRow,
    CountrySelect,
    PhoneSelect,
    LocaleField,
    RestaurantTypeSelect,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    },
    setField(name: string, value: string) {
      this.$emit('setField', { name, value });
    },
    onValidate(name: string, valid: boolean) {
      this.valid = valid;
    },
    getInputComponent(field: any) {
      switch (field.type) {
        case 'country':
          return 'country-select';
        case 'tel':
          return 'phone-select';
        case 'locale':
          return 'locale-field';
        case 'restaurant-type':
          return 'restaurant-type-select';
        default:
          return 'OInput';
      }
    },
  },
});
</script>
