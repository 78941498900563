import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-698c497c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "try-out" }
const _hoisted_2 = { class: "topbar" }
const _hoisted_3 = { class: "try-out__intro layout__content" }
const _hoisted_4 = { class: "title title--h1" }
const _hoisted_5 = { class: "try-out__intro--text intro-text" }
const _hoisted_6 = { class: "try-out__intro--label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkCorporate = _resolveComponent("LinkCorporate")!
  const _component_SwitchLocale = _resolveComponent("SwitchLocale")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_DishForm = _resolveComponent("DishForm")!
  const _component_LayoutPublic = _resolveComponent("LayoutPublic")!

  return (_openBlock(), _createBlock(_component_LayoutPublic, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LinkCorporate),
          _createVNode(_component_SwitchLocale, { "limit-languages": ['en-GB', 'it'] })
        ]),
        _createVNode(_component_Hero, {
          class: "try-out__banner",
          title: _ctx.$t('menuEngineering.try-out.hero.title'),
          subtitle: _ctx.$t('menuEngineering.try-out.hero.subtitle'),
          image: '/img/hero-image-landingpage-menuengineering.jpg'
        }, null, 8, ["title", "subtitle"]),
        _createElementVNode("section", _hoisted_3, [
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('menuEngineering.try-out.intro.title')), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('menuEngineering.try-out.intro.text')), 1),
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('menuEngineering.try-out.intro.try')), 1)
        ]),
        _createVNode(_component_DishForm, {
          modelValue: _ctx.dish,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dish) = $event)),
          "show-results-button": false,
          onSubmit: _ctx.showResult,
          saving: _ctx.saving,
          "is-landing-page": true
        }, null, 8, ["modelValue", "onSubmit", "saving"])
      ])
    ]),
    _: 1
  }))
}