<template>
  <div class="order-reference">
    <Field
      name="order-reference"
      rules="noSpecialCharacters"
      v-slot="{ errors, field }"
    >
      <header class="layout__content layout__content--large">
        <div class="order-reference__title">
          <h1 class="title title--direct">
            {{ $t('orders.reference.title') }}
          </h1>
          <p class="layout__intro-text--orders">
            {{ $t('orders.reference.intro') }}
          </p>
        </div>
        <OField
          class="order-reference__number"
          :variant="errors.length ? 'danger' : ''"
          :message="errors.length ? errors[0] : ''"
        >
          <template #label>
            {{ $t('orders.reference.number.title') }}
            <span class="order-reference__label">
              {{ $t('orders.reference.number.max-characters') }}
            </span>
          </template>
          <OInput
            v-bind="field"
            :modelValue="orderReference"
            @update:modelValue="setOrderReference($event)"
            :placeholder="$t('orders.reference.number.placeholder')"
            class="input--order-reference"
            maxlength="20"
          />
          <div
            class="order-reference__validation-icon"
            :class="{
              'order-reference__validation-icon--active': active,
              'order-reference__validation-icon--danger': errors.length,
            }"
          >
            <OIcon v-if="!errors.length" icon="check" />
            <OIcon v-else icon="close" variant="danger" />
          </div>
        </OField>
      </header>
    </Field>
  </div>
</template>

<script lang="ts">
import { Field } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Field },
  computed: {
    orderReference(): string {
      return this.$store.state.order.cart.PurchOrder;
    },
    active(): boolean {
      return !!this.orderReference;
    },
  },
  methods: {
    setOrderReference(reference: string) {
      this.$store.commit('order/setOrderReference', reference);
    },
  },
});
</script>

<style lang="scss" scoped>
.order-reference {
  .control {
    width: 100%;
  }

  &__number {
    margin: 15px 0 25px 0;
    font-size: 16px;

    @include tablet {
      flex: 0 0 50%;
      margin-top: 20px;
    }
  }

  &__label {
    color: $dusty-gray;
  }

  &__validation-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 50px;
    background-color: $gallery;
    color: $bombay;
    border: 1px solid $dusty-gray;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 0.8rem;
    transition: all 0.2s ease-in-out;

    @include tablet {
      width: 57px;
    }

    &--active {
      background-color: rgba($green, 0.2);
      color: $green;
    }

    &--danger {
      background-color: rgba($red, 0.2);
      color: $red;
    }
  }
}
</style>
