<template>
  <div class="category-header">
    <div class="category-header__icon">
      <img
        :class="`category-header__icon--${score.toLowerCase()} category-header__icon--chef`"
        :src="`/img/me/chef_${score.toLowerCase()}.svg`"
        :alt="$t('menuEngineering.imageAlt.icon')"
      />
    </div>
    <div class="category-header__attributes">
      <span
        v-for="(attribute, index) in attributes"
        :key="index"
        :class="`category-header__attribute category-header__attribute--${attribute.score}`"
      >
        {{ attribute.text }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import MenuEngineeringLogic from '@/logic/menu-engineering/MenuEngineeringLogic';
import Score from '@/logic/menu-engineering/Score';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    attributes(): any {
      return this.getCategoryAttributes(this.score);
    },
  },
  methods: {
    getCategoryAttributes(score: Score): any[] {
      const label = MenuEngineeringLogic.getCategoryLabel(score);

      // Determine value
      let value = { text: '', score: '' };
      const profitLabel = this.$t(`menuEngineering.label.${label}.profit`);
      // eslint-disable-next-line default-case
      switch (score) {
        case Score.WINNER:
        case Score.SLEEPER:
          value = {
            text: `+ ${profitLabel}`,
            score: 'high',
          };
          break;
        case Score.RUNNER:
        case Score.LOSER:
          value = {
            text: `- ${profitLabel}`,
            score: 'low',
          };
          break;
      }

      // Determine demand
      let demand = { text: '', score: '' };
      const demandLabel = this.$t(`menuEngineering.label.${label}.demand`);
      // eslint-disable-next-line default-case
      switch (score) {
        case Score.WINNER:
        case Score.RUNNER:
          demand = {
            text: `+ ${demandLabel}`,
            score: 'high',
          };
          break;
        case Score.SLEEPER:
        case Score.LOSER:
          demand = {
            text: `- ${demandLabel}`,
            score: 'low',
          };
          break;
      }

      return [value, demand];
    },
  },
  props: {
    score: {
      type: String as () => Score,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.category-header {
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
  margin: 60px 0 8px 0;
  background: $white;
  border: 1px solid $gallery;
  border-radius: 8px;

  &__icon {
    position: absolute;
    left: 20px;
    bottom: 0;
  }

  &__attributes {
    position: absolute;
    top: 18px;
    right: 20px;
  }

  &__attribute {
    display: block;
    text-align: right;
    font-size: 16px;

    &--high {
      color: $trinidad;
    }

    &--low {
      color: $primary;
    }
  }
}
</style>
