<template>
  <span class="currency">{{ value }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    value(): string | undefined {
      const amount: number | undefined =
        typeof this.amount === 'string' ? parseFloat(this.amount) : this.amount;
      if (amount === undefined) {
        return undefined;
      }
      const amountLocale = this.$n(amount, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return `${this.currency} ${amountLocale}`;
    },
  },
  props: {
    currency: {
      type: String,
    },
    amount: { type: [String, Number] },
  },
});
</script>

<style lang="scss" scoped>
.currency {
  white-space: nowrap;
}
</style>
