/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasePaginationResponse } from '../model';
// @ts-ignore
import { InlineResponse2002 } from '../model';
// @ts-ignore
import { Profile } from '../model';
// @ts-ignore
import { ProfileManagement } from '../model';
// @ts-ignore
import { Success } from '../model';
/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Start or end impersonation by the logged in user, user has to be administrator. If the query string parameter \'id\' is provided, impersonation will be initiated, otherwise it will be cleared.
         * @summary Start or end impersonation
         * @param {string} [id] User id of the profile to be impersonated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonatePost: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/impersonate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user\'s login data + all Menu Engineering data for the logged in (Cognito) user will be deleted
         * @summary Remove a user from the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Profile will be returned for the requested or logged in (Cognito) user
         * @summary List profile details
         * @param {string} [id] User id of the requested profile (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGet: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update a user\'s profile. Only administrators can update other profiles (including sapId)
         * @param {Profile} profile Details of the profile to add or update
         * @param {string} [id] User id of the profile to update (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePut: async (profile: Profile, id?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('profilePut', 'profile', profile)
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It will update the active SAP customer on the customer profile according to request
         * @summary Update SAP customer in profile
         * @param {string} sapId SAP id requested by current profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSapCustomerSwitchPost: async (sapId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sapId' is not null or undefined
            assertParamExists('profileSapCustomerSwitchPost', 'sapId', sapId)
            const localVarPath = `/profile/sap_customer_switch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (sapId !== undefined) {
                localVarQueryParameter['sap_id'] = sapId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It will be provided a list with SAP customers data related to active profile
         * @summary List SAP customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSapCustomersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/sap_customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * It will update the enabled SAP customers numbers on the customer profile according to request
         * @summary Update enabled SAP customers list
         * @param {string} clientId Client id from profile to be updated
         * @param {Array<string>} sapIds List with sap customer numbers to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateEnabledSapCustomersPost: async (clientId: string, sapIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('profileUpdateEnabledSapCustomersPost', 'clientId', clientId)
            // verify required parameter 'sapIds' is not null or undefined
            assertParamExists('profileUpdateEnabledSapCustomersPost', 'sapIds', sapIds)
            const localVarPath = `/profile/update_enabled_sap_customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (sapIds) {
                localVarQueryParameter['sapIds[]'] = sapIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Overview of profiles
         * @summary Get an overview of profiles
         * @param {number} [perPage] Number of items per page (default&#x3D;25)
         * @param {number} [page] Requested page (default&#x3D;1)
         * @param {boolean} [disabled] Only return disabled/enabled profiles
         * @param {string} [filter] Filter profile fields, parameter may be repeated to add multiple filters.
         * @param {string} [search] Search profile fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilesGet: async (perPage?: number, page?: number, disabled?: boolean, filter?: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (disabled !== undefined) {
                localVarQueryParameter['disabled'] = disabled;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Start or end impersonation by the logged in user, user has to be administrator. If the query string parameter \'id\' is provided, impersonation will be initiated, otherwise it will be cleared.
         * @summary Start or end impersonation
         * @param {string} [id] User id of the profile to be impersonated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonatePost(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonatePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The user\'s login data + all Menu Engineering data for the logged in (Cognito) user will be deleted
         * @summary Remove a user from the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Profile will be returned for the requested or logged in (Cognito) user
         * @summary List profile details
         * @param {string} [id] User id of the requested profile (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGet(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileManagement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add or update a user\'s profile. Only administrators can update other profiles (including sapId)
         * @param {Profile} profile Details of the profile to add or update
         * @param {string} [id] User id of the profile to update (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilePut(profile: Profile, id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilePut(profile, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * It will update the active SAP customer on the customer profile according to request
         * @summary Update SAP customer in profile
         * @param {string} sapId SAP id requested by current profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileSapCustomerSwitchPost(sapId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileSapCustomerSwitchPost(sapId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * It will be provided a list with SAP customers data related to active profile
         * @summary List SAP customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileSapCustomersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InlineResponse2002>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileSapCustomersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * It will update the enabled SAP customers numbers on the customer profile according to request
         * @summary Update enabled SAP customers list
         * @param {string} clientId Client id from profile to be updated
         * @param {Array<string>} sapIds List with sap customer numbers to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateEnabledSapCustomersPost(clientId: string, sapIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateEnabledSapCustomersPost(clientId, sapIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Overview of profiles
         * @summary Get an overview of profiles
         * @param {number} [perPage] Number of items per page (default&#x3D;25)
         * @param {number} [page] Requested page (default&#x3D;1)
         * @param {boolean} [disabled] Only return disabled/enabled profiles
         * @param {string} [filter] Filter profile fields, parameter may be repeated to add multiple filters.
         * @param {string} [search] Search profile fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilesGet(perPage?: number, page?: number, disabled?: boolean, filter?: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePaginationResponse & object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilesGet(perPage, page, disabled, filter, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * Start or end impersonation by the logged in user, user has to be administrator. If the query string parameter \'id\' is provided, impersonation will be initiated, otherwise it will be cleared.
         * @summary Start or end impersonation
         * @param {string} [id] User id of the profile to be impersonated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonatePost(id?: string, options?: any): AxiosPromise<Success> {
            return localVarFp.impersonatePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The user\'s login data + all Menu Engineering data for the logged in (Cognito) user will be deleted
         * @summary Remove a user from the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileDelete(options?: any): AxiosPromise<Success> {
            return localVarFp.profileDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Profile will be returned for the requested or logged in (Cognito) user
         * @summary List profile details
         * @param {string} [id] User id of the requested profile (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGet(id?: string, options?: any): AxiosPromise<ProfileManagement> {
            return localVarFp.profileGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update a user\'s profile. Only administrators can update other profiles (including sapId)
         * @param {Profile} profile Details of the profile to add or update
         * @param {string} [id] User id of the profile to update (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePut(profile: Profile, id?: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.profilePut(profile, id, options).then((request) => request(axios, basePath));
        },
        /**
         * It will update the active SAP customer on the customer profile according to request
         * @summary Update SAP customer in profile
         * @param {string} sapId SAP id requested by current profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSapCustomerSwitchPost(sapId: string, options?: any): AxiosPromise<void> {
            return localVarFp.profileSapCustomerSwitchPost(sapId, options).then((request) => request(axios, basePath));
        },
        /**
         * It will be provided a list with SAP customers data related to active profile
         * @summary List SAP customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileSapCustomersGet(options?: any): AxiosPromise<Array<InlineResponse2002>> {
            return localVarFp.profileSapCustomersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * It will update the enabled SAP customers numbers on the customer profile according to request
         * @summary Update enabled SAP customers list
         * @param {string} clientId Client id from profile to be updated
         * @param {Array<string>} sapIds List with sap customer numbers to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateEnabledSapCustomersPost(clientId: string, sapIds: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.profileUpdateEnabledSapCustomersPost(clientId, sapIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Overview of profiles
         * @summary Get an overview of profiles
         * @param {number} [perPage] Number of items per page (default&#x3D;25)
         * @param {number} [page] Requested page (default&#x3D;1)
         * @param {boolean} [disabled] Only return disabled/enabled profiles
         * @param {string} [filter] Filter profile fields, parameter may be repeated to add multiple filters.
         * @param {string} [search] Search profile fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilesGet(perPage?: number, page?: number, disabled?: boolean, filter?: string, search?: string, options?: any): AxiosPromise<BasePaginationResponse & object> {
            return localVarFp.profilesGet(perPage, page, disabled, filter, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * Start or end impersonation by the logged in user, user has to be administrator. If the query string parameter \'id\' is provided, impersonation will be initiated, otherwise it will be cleared.
     * @summary Start or end impersonation
     * @param {string} [id] User id of the profile to be impersonated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public impersonatePost(id?: string, options?: any) {
        return ProfileApiFp(this.configuration).impersonatePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user\'s login data + all Menu Engineering data for the logged in (Cognito) user will be deleted
     * @summary Remove a user from the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileDelete(options?: any) {
        return ProfileApiFp(this.configuration).profileDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Profile will be returned for the requested or logged in (Cognito) user
     * @summary List profile details
     * @param {string} [id] User id of the requested profile (can only be used by administrators)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGet(id?: string, options?: any) {
        return ProfileApiFp(this.configuration).profileGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or update a user\'s profile. Only administrators can update other profiles (including sapId)
     * @param {Profile} profile Details of the profile to add or update
     * @param {string} [id] User id of the profile to update (can only be used by administrators)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profilePut(profile: Profile, id?: string, options?: any) {
        return ProfileApiFp(this.configuration).profilePut(profile, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It will update the active SAP customer on the customer profile according to request
     * @summary Update SAP customer in profile
     * @param {string} sapId SAP id requested by current profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileSapCustomerSwitchPost(sapId: string, options?: any) {
        return ProfileApiFp(this.configuration).profileSapCustomerSwitchPost(sapId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It will be provided a list with SAP customers data related to active profile
     * @summary List SAP customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileSapCustomersGet(options?: any) {
        return ProfileApiFp(this.configuration).profileSapCustomersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * It will update the enabled SAP customers numbers on the customer profile according to request
     * @summary Update enabled SAP customers list
     * @param {string} clientId Client id from profile to be updated
     * @param {Array<string>} sapIds List with sap customer numbers to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateEnabledSapCustomersPost(clientId: string, sapIds: Array<string>, options?: any) {
        return ProfileApiFp(this.configuration).profileUpdateEnabledSapCustomersPost(clientId, sapIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Overview of profiles
     * @summary Get an overview of profiles
     * @param {number} [perPage] Number of items per page (default&#x3D;25)
     * @param {number} [page] Requested page (default&#x3D;1)
     * @param {boolean} [disabled] Only return disabled/enabled profiles
     * @param {string} [filter] Filter profile fields, parameter may be repeated to add multiple filters.
     * @param {string} [search] Search profile fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profilesGet(perPage?: number, page?: number, disabled?: boolean, filter?: string, search?: string, options?: any) {
        return ProfileApiFp(this.configuration).profilesGet(perPage, page, disabled, filter, search, options).then((request) => request(this.axios, this.basePath));
    }
}
