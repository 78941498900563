<template>
  <div class="claim-details">
    <BaseHeader :check-mfa="true">
      2.2 {{ $t('claims.new.steps.details.header') }}
    </BaseHeader>
    <div class="layout__content layout__content--large">
      <ClaimDetailsCard
        v-if="agreement"
        :claim="{ wholesale_party: claim.wholesaleParty }"
        :agreement="agreement"
        class="claim-details__card"
      />
    </div>
  </div>
</template>

<script lang="ts">
import BaseHeader from '@/components/BaseHeader.vue';
import Helpers from '@/logic/orders/helpers';
import { SalesQuote } from '@/api';
import ClaimDetailsCard from '@/components/claims/ClaimDetailsCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseHeader,
    ClaimDetailsCard,
  },
  data() {
    return {
      Helpers,
    };
  },
  computed: {
    agreement(): SalesQuote | undefined {
      return this.$store.getters['claim/agreement'];
    },
    claim(): any {
      return this.$store.state.claim.claimData;
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-details {
  line-height: 1.75;
  margin-block-end: 50px;

  &__card {
    margin-block-end: 50px;

    @include tablet {
      margin-block-end: 100px;
    }
  }
}
</style>
