import { SuluMedia } from '@/logic/cms/sulu';

const CMS_BASE_PATH = process.env.VUE_APP_CMS_BASE_PATH;

export default {
  getApiImageThumbnail(image: SuluMedia, format = 'fullhd'): string {
    if (
      !image ||
      Array.isArray(image) ||
      !image.formatUri ||
      !image.formatPreferredExtension
    ) {
      return '';
    }

    // SVGs can't be formatted, so take the original url
    const isSvg =
      image.mimeType === 'image/svg+xml' || image.mimeType === 'image/svg';
    const path = isSvg
      ? image.url
      : image.formatUri
          .replace('{format}', format)
          .replace('{extension}', image.formatPreferredExtension);

    if (path.startsWith('http') || !image.id) {
      return path;
    }
    return `${CMS_BASE_PATH}${path}`;
  },

  getApiImageFocuspoint(image: SuluMedia): string {
    const positionXMap = ['left', 'center', 'right'];
    const positionYMap = ['top', 'center', 'bottom'];
    if (!image || ![0, 1, 2].includes(image.focusPointX)) {
      return 'center center';
    }
    const { focusPointX: x, focusPointY: y } = image;
    return `${positionXMap[x]} ${positionYMap[y]}`;
  },

  getFileUrl(path: string): string {
    if (path.startsWith('http')) {
      return path;
    }
    return `${CMS_BASE_PATH}${path}`;
  },

  // Strip the webspace prefix from a routing link
  stripWebspace(html: string, prefixBasePath = true): string {
    const hostname = prefixBasePath ? `${CMS_BASE_PATH}/` : '';
    const webspaceRegexp = new RegExp(`${hostname}api/([^/]+)/([^/]+)/`);
    return html.replace(webspaceRegexp, prefixBasePath ? '/' : '');
  },

  parseRichText(html: string): string {
    const media = this.parseMediaLinks(html);
    return this.stripWebspace(media);
  },

  parseMediaLinks(html: string): string {
    return html.replace(
      /href="\/media\//,
      `target="_blank" href="${CMS_BASE_PATH}/media/`,
    );
  },
};
