import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b7b006e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "claim-details__card" }
const _hoisted_2 = { class: "claim-details__column" }
const _hoisted_3 = { class: "claim-details__segment" }
const _hoisted_4 = { class: "claim-details__title" }
const _hoisted_5 = { class: "claim-details__strong" }
const _hoisted_6 = {
  key: 0,
  class: "claim-details__value"
}
const _hoisted_7 = {
  key: 1,
  class: "claim-details__value"
}
const _hoisted_8 = { class: "claim-details__segment" }
const _hoisted_9 = { class: "claim-details__title" }
const _hoisted_10 = { class: "claim-details__strong" }
const _hoisted_11 = {
  key: 0,
  class: "claim-details__column"
}
const _hoisted_12 = { class: "claim-details__title" }
const _hoisted_13 = { class: "claim-details__strong" }
const _hoisted_14 = { class: "claim-details__value" }
const _hoisted_15 = { class: "claim-details__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('claims.new.steps.details.applicant')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$store.getters['profile/getSapCompany']), 1),
        (_ctx.showProfile && _ctx.$store.state.profile.profile.email)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$store.state.profile.profile.email), 1))
          : _createCommentVNode("", true),
        (_ctx.showProfile && _ctx.$store.state.profile.profile.phone)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$store.state.profile.profile.phone), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('claims.new.steps.details.wholesale-party')), 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.claim.wholesale_party), 1)
      ])
    ]),
    (_ctx.agreement)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('claims.new.steps.details.agreement-details')), 1),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(_ctx.agreement.type), 1),
            _createTextVNode(" | "),
            _createElementVNode("span", null, _toDisplayString(_ctx.agreement.sales_quote_sap_id), 1)
          ]),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.agreement.description) + " | " + _toDisplayString(_ctx.$t('claims.new.steps.details.currency')) + " " + _toDisplayString(_ctx.agreement.currency), 1),
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('claims.new.steps.details.valid-range', {
            from: _ctx.Helpers.formatEtaDate(_ctx.agreement.date_time_valid_from),
            to: _ctx.Helpers.formatEtaDate(_ctx.agreement.date_time_valid_to),
          })), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}