import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "error-page" }
const _hoisted_2 = { class: "title layout__content" }
const _hoisted_3 = {
  key: 1,
  class: "intro-text layout__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.message('title'))
          ? (_openBlock(), _createBlock(_component_Hero, {
              key: 0,
              title: _ctx.message('title')
            }, null, 8, ["title"]))
          : _createCommentVNode("", true),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.message('subtitle')), 1),
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.message('message')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}