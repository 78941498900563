<template>
  <OField class="ship-delivery-date">
    <template #label>
      <div class="ship-delivery-date__title">
        {{ $t('orders.shipping.delivery-date.title') }}
        <span class="ship-delivery-date__label">
          {{ $t('orders.shipping.delivery-date.label') }}
        </span>
      </div>
    </template>
    <div class="ship-delivery-date__content">
      <ODatepicker
        v-if="shipToParty()"
        inline
        :modelValue="orderDeliveryDate"
        @update:modelValue="setDeliveryDate($event)"
        :min-date="minDate"
        :max-date="maxDate"
        :unselectable-days-of-week="[0, 6]"
        :locale="$i18n.locale"
        icon-next="chevron_right"
        icon-prev="chevron_left"
        root-class="datepicker--shipping"
        :first-day-of-week="1"
        required
      />
      <ODatepicker
        v-else
        inline
        :min-date="minDate"
        :max-date="maxDate"
        :unselectable-days-of-week="[0, 6]"
        :locale="$i18n.locale"
        icon-next="chevron_right"
        icon-prev="chevron_left"
        root-class="datepicker--shipping datepicker--disabled"
        :first-day-of-week="1"
      />
    </div>
  </OField>
</template>

<script lang="ts">
import { parse, format, isBefore, isSaturday, isSunday } from 'date-fns';
import { SapCustomerPartner } from '@/api/model';
import { SalesOrderAll } from '@/logic/orders/SalesOrderAll';
import DeliveryDates from '@/logic/orders/DeliveryDates';
import { defineComponent } from 'vue';

const datePart = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export default defineComponent({
  computed: {
    cart(): SalesOrderAll {
      return this.$store.state.order.cart;
    },
    minDate(): Date {
      const country = this.getCountry();
      return datePart(DeliveryDates.getMin(country));
    },
    maxDate(): Date | undefined {
      const country = this.getCountry();
      const max = DeliveryDates.getMax(country);
      if (max === undefined) {
        return max;
      }
      return datePart(max);
    },
    orderDeliveryDate(): Date {
      const dateString = this.$store.state.order.cart.ReqDlvDate;
      if (!dateString) {
        return this.minDate;
      }
      let date = parse(`${dateString}12+0000`, 'yyyyMMddHHXX', this.minDate);
      if (date instanceof Date && !Number.isNaN(date.getDate())) {
        if (isBefore(date, this.minDate)) {
          date = this.minDate;
        }

        // Weekend check
        if (isSaturday(date)) {
          date = DeliveryDates.addDays(date, 2);
        } else if (isSunday(date)) {
          date = DeliveryDates.addDays(date, 1);
        }

        return date;
      }

      return this.minDate;
    },
  },
  methods: {
    setDeliveryDate(date: Date) {
      const formatted = format(date, 'yyyyMMdd');
      this.$store.commit('order/setRequestedDeliveryDate', formatted);
    },
    shipToParty(): boolean {
      return !!this.$store.state.order.cart.ShipToParty;
    },
    getCountry(): string | undefined {
      if (!this.cart.ShipToParty) {
        return undefined;
      }
      const shipTo = this.$store.state.order.shipToPartners.find(
        (p: SapCustomerPartner) => p.CustomerNr === this.cart.ShipToParty,
      );

      if (!shipTo.CountryKey) {
        return undefined;
      }
      return shipTo.CountryKey;
    },
  },
  mounted() {
    this.setDeliveryDate(this.orderDeliveryDate);
  },
});
</script>
