import { Dish, DishCourseEnum } from '@/api/model';
import Score from '@/logic/menu-engineering/Score';

export default class MenuEngineeringLogic {
  public static minimumAmountOfDishesReached(dishes: Dish[]): boolean {
    return dishes.length >= 5;
  }

  public static filterDishes(
    dishes: Dish[],
    restaurant?: string,
    course?: string,
  ): Dish[] {
    let filtered = dishes;
    if (restaurant) {
      filtered = MenuEngineeringLogic.filterDishesByRestaurant(
        filtered,
        restaurant,
      );
    }

    if (course) {
      filtered = MenuEngineeringLogic.filterDishesByCourse(filtered, course);
    }

    return filtered;
  }

  public static getCourses(): string[] {
    return Object.values(DishCourseEnum);
  }

  public static allCategories(): string[] {
    return Object.values(Score);
  }

  public static getCategoryLabel(score: Score): string {
    if (score === Score.LOSER) {
      return 'laggard';
    }

    return score.toLowerCase();
  }

  private static filterDishesByRestaurant(
    dishes: Dish[],
    restaurantId: string,
  ): Dish[] {
    if (!dishes) {
      return [];
    }

    return dishes.filter((dish) => dish.restaurantId === restaurantId);
  }

  private static filterDishesByCourse(dishes: Dish[], course: string): Dish[] {
    if (!dishes) {
      return [];
    }

    return dishes.filter((dish) => dish.course === course);
  }
}
