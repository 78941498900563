<template>
  <div class="layout__content layout__content--large">
    <div class="claim-products">
      <div class="claim-products__header">
        {{ $t('claims.new.steps.summary.your-billback-claim') }}
      </div>
      <OrderItemList
        :items="$store.state.claim.claimData.items"
        sort-by-cart
        type="claim"
        :agreement="agreement"
        :details="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { SalesQuote } from '@/api';
import OrderItemList from '@/components/orders/OrderItemList.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    OrderItemList,
  },
  computed: {
    agreement(): SalesQuote | undefined {
      return this.$store.getters['claim/agreement'];
    },
    claim(): any {
      return this.$store.state.claim.claimData;
    },
  },
});
</script>

<style lang="scss" scoped>
.claim-products {
  border: 1px solid $mercury;
  border-radius: 8px;

  &__header {
    background-color: $wild-sand;
    color: $black;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
    padding: 22px 16px 19px;
    border-bottom: 1px solid $mercury;
  }
}
</style>
