import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "menu-engineering" }
const _hoisted_2 = { class: "title layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DishForm = _resolveComponent("DishForm")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, [
          _createTextVNode(" Edit "),
          _createElementVNode("span", null, _toDisplayString(_ctx.dish.name), 1)
        ]),
        _createVNode(_component_DishForm, {
          modelValue: _ctx.dish,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dish) = $event)),
          restaurants: _ctx.restaurants,
          "show-results-button": false,
          onSubmit: _ctx.save,
          saving: _ctx.saving
        }, null, 8, ["modelValue", "restaurants", "onSubmit", "saving"])
      ])
    ]),
    _: 1
  }))
}