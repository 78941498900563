<template>
  <section class="action-bar action-bar--invoices">
    <div class="action-bar__content">
      <div
        class="action-bar__total invoices-action-bar__total"
        v-if="
          $store.getters['auth/hasAccess']({
            action: 'GET',
            resource: '/open-amount-invoices',
          })
        "
      >
        <div class="invoices-action-bar__total-label">
          {{ $t(`invoices.search-bar.${totalLabel}`) }}
        </div>
        <BaseCurrencyAmount
          v-if="total.currency"
          :currency="total.currency"
          :amount="total.amount"
          data-test="invoice-total"
          class="invoices-action-bar__total-value"
        />
        <div
          v-else
          class="invoices-action-bar__total-value invoices-action-bar__not-available"
          data-test="invoice-total-na"
        >
          {{ $t('general.table.not-available') }}
        </div>
      </div>
      <TableFilter
        store="invoice"
        :options="filterOptions"
        option-prefix="invoices.status"
        field="AccStatusText"
        :all-label="$t('invoices.search-bar.placeholder.all-invoices')"
        :placeholder="$t('invoices.search-bar.placeholder.filter')"
        class="action-bar__filter"
      />

      <TableSearchField
        :placeholder="$t('invoices.search-bar.placeholder.search')"
        store="invoice"
        class="action-bar__search action-bar__search--invoices"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { InvoiceApi, InvoiceOpenAmountsResponse } from '@/api';
import getConfiguration from '@/store/getConfiguration';
import TableSearchField from '@/components/table/TableSearchField.vue';
import TableFilter from '@/components/table/TableFilter.vue';
import BaseCurrencyAmount from '@/components/BaseCurrencyAmount.vue';
import InvoiceStatusEnum from '@/types/invoices/InvoiceStatusEnum';
import { defineComponent } from 'vue';

interface CurrencyValue {
  currency?: string;
  amount?: number;
}

export default defineComponent({
  components: { BaseCurrencyAmount, TableFilter, TableSearchField },
  data() {
    const totals: InvoiceOpenAmountsResponse = {};
    const filterOptions: InvoiceStatusEnum[] = Object.values(InvoiceStatusEnum);

    return {
      filterOptions,
      totals,
      fetchedTotals: false,
    };
  },
  computed: {
    total(): CurrencyValue | null {
      const mapping: { [key: string]: string } = {
        'eq(AccStatusText,before due)': 'before_due_totals',
        'eq(AccStatusText,due date)': 'due_date_totals',
        'eq(AccStatusText,overdue)': 'overdue_totals',
        'eq(AccStatusText,cleared)': '',
        'eq(AccStatusText,reversed)': '',
      };
      const { filter } = this.$store.state.invoice;
      const key = filter in mapping ? mapping[filter] : 'total_amounts';
      if (key === '') {
        return {};
      }
      const values: any = this.totals;
      const totalAmounts: any = values[key];
      if (totalAmounts) {
        const currencies = Object.keys(totalAmounts);
        if (currencies.length) {
          const currency: string | undefined = currencies.pop();
          const amount: number = currency ? totalAmounts[currency] : 0;
          return { currency, amount };
        }
      }
      return {};
    },
    totalLabel(): string {
      return this.$store.state.invoice.filter ? 'total-filtered' : 'total';
    },
  },
  async created() {
    if (!this.fetchedTotals) {
      await this.fetchTotals();
      this.fetchedTotals = true;
    }
  },
  methods: {
    async fetchTotals() {
      const configuration = await getConfiguration();
      const api = new InvoiceApi(configuration, '');
      const response = await api.openAmountInvoicesGet();
      this.totals = response?.data || {};
    },
  },
});
</script>

<style lang="scss" scoped>
.invoices-action-bar {
  &__total-label {
    border-inline-end: 1px solid $jagged-ice;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px - 16px;
    padding-right: 8px;
    font-size: 14px;
    line-height: 1.15;
  }

  &__total-value {
    padding-left: 16px;
  }

  &__not-available {
    color: $dusty-gray;
  }
}
</style>
