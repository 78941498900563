import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block form-card"
}
const _hoisted_2 = {
  key: 0,
  class: "title title--h2 block__title"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisibleToUser())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.block && _ctx.block.title)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.block.title), 1))
          : _createCommentVNode("", true),
        (_ctx.block && _ctx.block.text)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "block__text",
              innerHTML: _ctx.block.text
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}