<template>
  <div class="modal-card modal-card--small-content">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t(`updates-modal.scopes.${scope}.title`) }}
      </p>
    </header>
    <section class="modal-card-body update-dialog">
      <div
        v-for="(item, index) in $tm(`updates-modal.scopes.${scope}.content`)"
        class="update-dialog__paragraph"
        :key="index"
      >
        <h2 class="subtitle update-dialog__subtitle" v-if="item.title">
          {{ item.title }}
        </h2>
        <p class="update-dialog__text" v-if="item.content">
          {{ item.content }}
        </p>
        <p class="update-dialog__image" v-if="item.image">
          <img :src="item.image" alt="" />
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <OButton
        rounded
        variant="primary"
        class="button-row__button"
        @click="$emit('close')"
        >{{ $t('updates-modal.general.close') }}</OButton
      >
    </footer>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    scope: { type: String, required: true },
  },
});
</script>
<style lang="scss" scoped>
.update-dialog {
  &__paragraph {
    margin-bottom: 30px;
  }

  &__text {
    margin-block: 10px;
  }

  &__image {
    box-shadow: 0 2px 10px 0 rgba($black, 0.3);
    background-color: $white;
    padding: 20px;

    @include mobile {
      padding: 10px;
    }

    img {
      display: flex;
      margin-inline: auto;
      width: 600px;
    }
  }
}
</style>
