import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-305759be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title title--h1 onboarding-finished__title" }
const _hoisted_2 = { class: "onboarding-finished__text-wrapper" }
const _hoisted_3 = { class: "onboarding-finished__icon" }
const _hoisted_4 = { class: "onboarding-finished__text" }
const _hoisted_5 = { class: "onboarding-finished__intro" }
const _hoisted_6 = { class: "onboarding-finished__feates-enables" }
const _hoisted_7 = { class: "button-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('onboarding.finished.title')), 1),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_OIcon, {
          icon: "check",
          size: "large",
          variant: "primary"
        }),
        _createVNode(_component_OIcon, {
          icon: "check",
          size: "small",
          variant: "primary"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(`onboarding.finished.intro.${_ctx.MFAType}`)), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('onboarding.finished.features-enabled')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_OButton, {
        variant: "primary",
        rounded: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disableMFAPopup()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.finished.finish')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}