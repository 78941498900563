<template>
  <Field
    :name="$attrs.name"
    :rules="$attrs.rules"
    v-slot="{ errors, validate, field }"
  >
    <OField
      :label="$attrs.label"
      :message="errors.length ? errors[0] : ''"
      :class="classNames(errors)"
      class="field-upload"
      :variant="errors.length ? 'danger' : ''"
      :horizontal="$attrs.horizontal"
    >
      <OUpload
        :name="field.name"
        :modelValue="field.value"
        @update:modelValue="onInput"
        :disabled="$attrs.disabled"
        expanded
        :icon-pack="$attrs.iconPack"
        multiple
        drag-drop
      >
        <div class="content has-text-centered">
          <p class="field-upload__placeholder uniek">
            {{ $attrs.placeholder }}
          </p>
        </div>
      </OUpload>
    </OField>
    <div
      class="field-upload__files-list"
      :horizontal="$attrs.horizontal"
      v-if="$attrs.modelValue.length"
    >
      <div
        class="field-upload__file"
        v-for="(file, index) in $attrs.modelValue"
        :key="index"
        @close="removeFile(index, validate)"
      >
        <OIcon icon="attachment" class="field-upload__icon" />
        <span class="field-upload__file-name">{{ file.name }}</span>
        <button
          class="field-upload__remove-button"
          @click="removeFile(index, validate)"
        >
          {{ $t('general.interface.remove') }}
        </button>
      </div>
    </div>
  </Field>
</template>

<script lang="ts">
import { Field } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Field },
  computed: {
    rules(): any {
      return this.$attrs.rules;
    },
  },
  methods: {
    classNames(errors: any[]): any {
      return {
        'field--has-error': errors.length,
        'field--required': this.$attrs.rules && this.hasRule('required'),
      };
    },
    onInput(value: string | number) {
      this.$emit('update:modelValue', value);
    },
    hasRule(rule: string): boolean {
      if (!this.rules) {
        return false;
      }
      if (typeof this.rules === 'object') {
        return !!this.rules[rule];
      }
      return this.rules.includes(rule);
    },
    removeFile(index: number, validate: () => any): void {
      const modelValue = this.$attrs.modelValue as Array<any>;
      this.$emit('remove-file', modelValue.splice(index, 1));

      validate();
    },
  },
  props: {
    value: { type: Array, default: () => [] },
  },
});
</script>
<style scoped lang="scss">
.field-upload {
  flex-direction: column;
  align-items: flex-start;

  &__files-list {
    margin: 14px 0;
    background: $white;
    border: 1px solid $gallery;
    box-shadow: 0 20px 39px rgba($alto, 0.6);
    border-radius: 8px;
  }

  &__file {
    padding: 17px 24px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid $gallery;
    }
  }

  &__remove-button {
    font-size: 14px !important;
    background: none;
    color: $secondary;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-left: auto;
  }

  :deep(.field-body) {
    width: 100%;
    margin-bottom: 14px;

    .upload-draggable {
      padding: 20px;
      border-color: $alto;
      background-color: $wild-sand;
      color: $dusty-gray;
      width: 100%;
      min-height: 114px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
