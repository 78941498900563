import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ship-delivery-date__title" }
const _hoisted_2 = { class: "ship-delivery-date__label" }
const _hoisted_3 = { class: "ship-delivery-date__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ODatepicker = _resolveComponent("ODatepicker")!
  const _component_OField = _resolveComponent("OField")!

  return (_openBlock(), _createBlock(_component_OField, { class: "ship-delivery-date" }, {
    label: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('orders.shipping.delivery-date.title')) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('orders.shipping.delivery-date.label')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.shipToParty())
          ? (_openBlock(), _createBlock(_component_ODatepicker, {
              key: 0,
              inline: "",
              modelValue: _ctx.orderDeliveryDate,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDeliveryDate($event))),
              "min-date": _ctx.minDate,
              "max-date": _ctx.maxDate,
              "unselectable-days-of-week": [0, 6],
              locale: _ctx.$i18n.locale,
              "icon-next": "chevron_right",
              "icon-prev": "chevron_left",
              "root-class": "datepicker--shipping",
              "first-day-of-week": 1,
              required: ""
            }, null, 8, ["modelValue", "min-date", "max-date", "locale"]))
          : (_openBlock(), _createBlock(_component_ODatepicker, {
              key: 1,
              inline: "",
              "min-date": _ctx.minDate,
              "max-date": _ctx.maxDate,
              "unselectable-days-of-week": [0, 6],
              locale: _ctx.$i18n.locale,
              "icon-next": "chevron_right",
              "icon-prev": "chevron_left",
              "root-class": "datepicker--shipping datepicker--disabled",
              "first-day-of-week": 1
            }, null, 8, ["min-date", "max-date", "locale"]))
      ])
    ]),
    _: 1
  }))
}