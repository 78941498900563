import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1fc8fe11"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "try-out-result layout__content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "dish-category" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentBlocks = _resolveComponent("ContentBlocks")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_LayoutPublic = _resolveComponent("LayoutPublic")!

  return (_openBlock(), _createBlock(_component_LayoutPublic, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('menuEngineering.overview.categoryCompare')) + " ", 1),
          _createElementVNode("span", _hoisted_3, "\"" + _toDisplayString(_ctx.$t('menuEngineering.categories.' + _ctx.category)) + "\"", 1)
        ]),
        _createVNode(_component_ContentBlocks, { blocks: _ctx.blocks }, null, 8, ["blocks"]),
        _createVNode(_component_OButton, {
          "data-test": "try-out-result-sign-up",
          rounded: "",
          variant: "primary",
          tag: "router-link",
          to: {
          path: '/auth',
          query: {
            redirect: '/menu-engineering/add-dish',
            state: 'signUp',
          },
        }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('menuEngineering.try-out.result.sign-up')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}