<template>
  <LayoutPublic>
    <div class="try-out">
      <div class="topbar">
        <LinkCorporate />
        <SwitchLocale :limit-languages="['en-GB', 'it']" />
      </div>
      <Hero
        class="try-out__banner"
        :title="$t('menuEngineering.try-out.hero.title')"
        :subtitle="$t('menuEngineering.try-out.hero.subtitle')"
        :image="'/img/hero-image-landingpage-menuengineering.jpg'"
      />
      <section class="try-out__intro layout__content">
        <h1 class="title title--h1">
          {{ $t('menuEngineering.try-out.intro.title') }}
        </h1>
        <p class="try-out__intro--text intro-text">
          {{ $t('menuEngineering.try-out.intro.text') }}
        </p>
        <label class="try-out__intro--label">
          {{ $t('menuEngineering.try-out.intro.try') }}
        </label>
      </section>
      <DishForm
        v-model="dish"
        :show-results-button="false"
        @submit="showResult"
        :saving="saving"
        :is-landing-page="true"
      />
    </div>
  </LayoutPublic>
</template>

<script lang="ts">
import LayoutPublic from '@/layouts/LayoutPublic.vue';
import { DishCourseEnum } from '@/api/model';
import Hero from '@/components/AppHero.vue';
import DishForm from '@/components/menu-engineering/DishForm.vue';
import Calculator from '@/logic/menu-engineering/Calculator';
import { LandingPageDish } from '@/logic/menu-engineering/LandingPageDish';
import LinkCorporate from '@/components/LinkCorporate.vue';
import SwitchLocale from '@/components/SwitchLocale.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Hero, LayoutPublic, DishForm, LinkCorporate, SwitchLocale },
  data() {
    const dish: LandingPageDish = {
      restaurantType: '',
      course: DishCourseEnum.Breakfast,
      name: '',
      popularity: 0,
      costPrice: 0,
      chefTime: 0,
      staffTime: 0,
      price: 0,
      companyStaffSize: 0,
      companyStaffSeatCount: 0,
    };

    return {
      saving: false,
      dish,
    };
  },
  methods: {
    showResult() {
      const results = Calculator.calculate([this.dish]);
      const { score } = results[0];
      window.scrollTo(0, 0);
      this.$router.push(`/menu-engineering/result/${score?.toLowerCase()}`);
    },
  },
});
</script>
<style lang="scss" scoped>
.topbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  min-height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  z-index: 10;
}

.try-out {
  &__intro {
    color: $primary;

    &--text {
      margin: 24px 0;
    }

    &--label {
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;

      @include tablet {
        font-size: 26px;
        line-height: 28px;
      }
    }
  }
}
</style>
<style lang="scss">
.try-out {
  .dish-form {
    background: rgba(0, 56, 90, 0.03);
    margin-top: 40px;
    padding-top: 16px;

    @include tablet {
      margin-top: 80px;
      padding-top: 80px;
    }

    &__main {
      background: none;
      padding-top: 0;
      margin-top: 18px;
    }

    &__intro {
      background: $white;
      box-shadow: 0 24px 44px rgba($black, 0.08);
      border-radius: 8px;
      padding: 16px 0;

      @include tablet {
        padding: 40px 48px;
      }
    }

    &__dish {
      padding-bottom: 5px;
      margin-bottom: 60px;
    }

    .title {
      @include mobile {
        margin: 16px;
        padding-bottom: 16px;
      }

      @include tablet {
        margin-top: 0;
        font-size: 40px;
        line-height: 68px;
      }
    }

    .subtitle {
      margin: 16px;

      @include tablet {
        margin: 0 0 20px 0;
      }
    }
  }
}
</style>
