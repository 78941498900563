import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3c67e8b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter" }
const _hoisted_2 = { class: "toggle-button__label" }
const _hoisted_3 = {
  key: 0,
  class: "filter__dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OCheckbox = _resolveComponent("OCheckbox")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "toggle-button toggle-button--expanded toggle-button--select",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle(!_ctx.active))),
      role: "button",
      "data-test": "filter-toggle"
    }, [
      _createVNode(_component_OIcon, {
        class: "toggle-button__icon-left",
        icon: "filter"
      }),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('orders.search-bar.placeholder.filter-products')), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["toggle-button__icon", { 'toggle-button__icon--active': _ctx.active }])
      }, [
        _createVNode(_component_OIcon, { icon: "chevron_up" })
      ], 2)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupLabels, (group) => {
                return (_openBlock(), _createBlock(_component_OCheckbox, {
                  key: group,
                  "native-value": group,
                  modelValue: _ctx.groupActive(group),
                  "onUpdate:modelValue": ($event: any) => (_ctx.toggleGroupActive(group)),
                  "data-test": "filter-checkbox"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(group), 1)
                  ]),
                  _: 2
                }, 1032, ["native-value", "modelValue", "onUpdate:modelValue"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_on_click_outside, _ctx.onClickOutside]
  ])
}