import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-edd8212a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title title--h1 onboarding-welcome__title" }
const _hoisted_2 = { class: "onboarding-welcome__intro" }
const _hoisted_3 = { class: "onboarding-welcome__security" }
const _hoisted_4 = { class: "onboarding-welcome__security-icon" }
const _hoisted_5 = { class: "onboarding-welcome__security-text" }
const _hoisted_6 = { class: "onboarding-welcome__security-title" }
const _hoisted_7 = { class: "onboarding-welcome__security-description" }
const _hoisted_8 = { class: "onboarding-welcome__options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('onboarding.welcome.title')), 1),
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('onboarding.welcome.required-description')), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('onboarding.welcome.recommend-description')), 1)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OIcon, {
          icon: "secure",
          size: "large",
          variant: "primary"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('onboarding.welcome.explanation-title')), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('onboarding.welcome.explanation-description')), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MFATypes, (option) => {
        return (_openBlock(), _createBlock(_component_OButton, {
          onClick: ($event: any) => (_ctx.selectOption(option)),
          key: option,
          class: _normalizeClass(`onboarding-welcome__button`)
        }, {
          default: _withCtx(() => [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`onboarding.welcome.buttons.${option}.type`)), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(`onboarding.welcome.buttons.${option}.label`)), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ])
  ]))
}