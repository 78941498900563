<template>
  <transition name="fade">
    <OField class="search-bar__search">
      <OInput
        @input="search($event)"
        type="search"
        icon-right="search"
        icon-right-clickable
        @icon-right-click="$router.push('#results')"
        size="medium"
        root-class="input--search"
        :placeholder="$t('orders.search-bar.placeholder.search')"
        expanded
      />
    </OField>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    search(query: string) {
      this.$store.dispatch(`${this.type}/productSearch`, query);
    },
  },
  beforeUnmount(): void {
    // Empty the search query so results are reset
    this.search('');
  },
  props: {
    type: { type: String, default: 'order' },
  },
});
</script>
