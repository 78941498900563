<template>
  <div>
    <OSelect :modelValue="value" @update:modelValue="input">
      <option :value="''"></option>
      <option v-for="type in types" :key="type" :value="type">
        {{ $t(`profile.restaurant.type.${type}`) }}
      </option>
    </OSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RestaurantRestaurantTypeEnum } from '@/api/model';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    input(value: string) {
      this.$emit('input', value);
    },
  },
  computed: {
    types(): string[] {
      return Object.values(RestaurantRestaurantTypeEnum);
    },
  },
});
</script>
