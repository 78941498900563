import {
  InlineResponse200,
  InlineResponse200CorrectionSuggestion,
  OrderItem,
  SalesOrder,
  SapCustomerContainerLoadBaseEnum,
  SapProduct,
} from '@/api';

const initialState = () => ({
  result: null as InlineResponse200 | null,
  loadBase: SapCustomerContainerLoadBaseEnum.Pallet,
  isCalculating: false,
});
export type ContainerCalculationState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: ContainerCalculationState) {
      Object.assign(state, initialState());
    },

    setCalculationResult(
      state: ContainerCalculationState,
      result: InlineResponse200,
    ) {
      state.result = result;
    },

    setLoadBase(
      state: ContainerCalculationState,
      loadBase: SapCustomerContainerLoadBaseEnum,
    ) {
      state.loadBase = loadBase;
    },

    setIsCalculating(state: ContainerCalculationState, isCalculating: boolean) {
      state.isCalculating = isCalculating;
    },
  },
  actions: {},
  getters: {
    suggestion(
      state: ContainerCalculationState,
    ): InlineResponse200CorrectionSuggestion | undefined {
      return state.result?.CorrectionSuggestion;
    },

    summary(
      state: ContainerCalculationState,
      getters: any,
      rootState: any,
    ): SalesOrder {
      return JSON.parse(JSON.stringify(rootState.order.cart));
    },

    status(state: ContainerCalculationState, getters: any): string {
      if (!state.result) {
        return 'initial';
      }
      if (state.result?.Filled) {
        return 'filled';
      }
      if (getters.suggestion !== undefined) {
        if (getters.suggestion.NumPallets === undefined) {
          return 'error';
        }
        if (getters.suggestion.NumPallets < 0) {
          return getters.suggestion.ProductNumber === null
            ? 'overfilled-no-product'
            : 'overfilled';
        }
        if (getters.suggestion.NumPallets > 0) {
          return 'underfilled';
        }
      }
      return 'error';
    },

    calculateBoxes:
      (state: any, getters: any, rootState: any) => (item: OrderItem) => {
        let boxes = 0;
        if (!item || !item.Quantity) {
          return boxes;
        }
        const product = rootState.order.products.find(
          (p: SapProduct) => p.ProductNr === item.ProductNr,
        );
        if (!product) {
          return boxes;
        }
        const { BoxesPerPallet } = product;
        if (BoxesPerPallet !== undefined && BoxesPerPallet !== null) {
          const perPallet = parseInt(BoxesPerPallet, 10);
          boxes += perPallet * parseInt(item.Quantity, 10);
        }
        return boxes;
      },
  },
};
