import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-54000fb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.block)
      ? (_openBlock(), _createElementBlock("video", {
          key: 0,
          class: "block__video",
          src: _ctx.block.file && _ctx.block.file.url,
          oncontextmenu: "return false;",
          controls: ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}