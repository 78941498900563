<template>
  <div class="download" v-if="hasDownload">
    <a class="download__link" @click.prevent="download">
      <OIcon class="download__icon" icon="download" />
      <span class="download__label"><slot /></span>
    </a>
    <a ref="download-link" class="download__hidden" download :href="s3Url" />
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/vue';
import { SapInvoice } from '@/api/model';
import { InvoiceApi } from '@/api';
import getConfiguration from '@/store/getConfiguration';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      s3Url: '',
      isLoading: false,
    };
  },
  computed: {
    hasDownload(): boolean {
      return !!this.invoice.Bucket && !!this.invoice.Key && !!this.invoice.Id;
    },
  },
  methods: {
    async download() {
      if (!this.s3Url) {
        await this.fetchS3Url();
      }
      (this.$refs['download-link'] as HTMLElement).click();
    },
    async fetchS3Url() {
      if (this.invoice.Id === undefined) {
        return;
      }
      try {
        this.isLoading = true;
        const configuration = await getConfiguration();
        const api = new InvoiceApi(configuration, '');
        const { data } = await api.invoiceDownloadLinkGet(this.invoice.Id);
        if (data.s3_url) {
          this.s3Url = data.s3_url;
        }
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        this.isLoading = false;
        setTimeout(() => {
          this.s3Url = '';
        }, 300 * 1000);
      }
    },
  },
  props: {
    invoice: {
      type: Object as () => SapInvoice,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.download {
  &__link {
    color: $primary;
  }

  &__icon {
    font-size: 19px;
    margin-right: 8px;
  }

  &__label {
    text-decoration: underline;
  }

  &__hidden {
    display: none;
  }
}
</style>
