import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!

  return (_openBlock(), _createBlock(_component_OField, { class: "action-bar__search" }, {
    default: _withCtx(() => [
      _createVNode(_component_OInput, {
        placeholder: _ctx.placeholder,
        type: "search",
        "root-class": "input--search",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.debounceSearch($event))),
        "icon-right": "search",
        size: "medium"
      }, null, 8, ["placeholder"])
    ]),
    _: 1
  }))
}