/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SalesQuoteClaimSubmit
 */
export interface SalesQuoteClaimSubmit {
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    claim_period_from?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    claim_period_to?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    credit_reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    sales_quote_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    sales_quote_sap_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    sap_customer_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    status?: SalesQuoteClaimSubmitStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    submission_date?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesQuoteClaimSubmit
     */
    value?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SalesQuoteClaimSubmit
     */
    wholesale_party?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SalesQuoteClaimSubmitStatusEnum {
    Failed = 'FAILED',
    Submitted = 'SUBMITTED'
}



