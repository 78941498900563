import {
  fetchAuthSession,
  getCurrentUser,
  fetchMFAPreference,
} from 'aws-amplify/auth';
import AuthGroup from '@/logic/auth/AuthGroup';
import { Endpoint } from '@/logic/auth/Endpoint';
import Authhelpers from '@/logic/auth/AuthHelpers';
import { Policy, Role } from '@/api/model';
import getConfiguration from '@/store/getConfiguration';
import { RoleApi } from '@/api';
import MFAGroups from '@/logic/auth/MFAGroups';
import i18n from '@/i18n';

export async function getApi() {
  const configuration = await getConfiguration();
  return new RoleApi(configuration, '');
}

const initialState = () => ({
  user: null as unknown as any,
  session: null as unknown as any,
  mfaPreference: null as unknown as any,
  userRoles: [] as Role[],
  rememberDevice: false,
  MFAOnboarding: JSON.parse(localStorage.getItem('MFAOnboarding') || 'null'),
  fetchingUser: false,
});
export type AuthState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: AuthState) {
      Object.assign(state, initialState());
    },

    setUser(state: AuthState, user: any) {
      state.user = user;
    },

    setSession(state: AuthState, session: any) {
      state.session = session;
    },

    setMfaPreference(state: AuthState, mfaPreference: any) {
      state.mfaPreference = mfaPreference;
    },

    setUserRoles(state: AuthState, roles: Role[]) {
      state.userRoles = roles;
    },

    setRememberDevice(state: AuthState, rememberDevice: boolean) {
      state.rememberDevice = rememberDevice;
    },

    setMFAOnboarding(state: AuthState, showState: boolean) {
      if (
        (showState && localStorage.getItem('MFAOnboarding') === null) ||
        showState === false
      ) {
        state.MFAOnboarding = showState;
        localStorage.setItem(
          'MFAOnboarding',
          JSON.stringify(state.MFAOnboarding),
        );
      }
    },

    setFetchingUser(state: AuthState, fetching: boolean) {
      state.fetchingUser = fetching;
    },
  },
  actions: {
    async getUser({
      commit,
      getters,
      state,
    }: {
      commit: Function;
      getters: any;
      state: any;
    }) {
      try {
        const { tokens: session } = await fetchAuthSession();
        const user = await getCurrentUser();
        const mfaPreference = await fetchMFAPreference();
        const { username } = user;

        // Check if user has changed
        if (state.user && state.user.username === username) {
          if (
            session?.idToken?.toString() === state.session.idToken.toString()
          ) {
            return;
          }
        }
        commit('setUser', user);
        commit('setSession', session);
        commit('setMfaPreference', mfaPreference);
        if (!state.fetchingUser) {
          commit('setFetchingUser', true);
          const api = await getApi();
          const { data } = await api.userRolesGet();
          commit('setUserRoles', data);

          if (
            !mfaPreference.enabled ||
            (mfaPreference.enabled.length === 0 &&
              MFAGroups.some((group: AuthGroup) => getters.memberOf(group)))
          ) {
            commit('setMFAOnboarding', true);
          }
        }
      } catch (e) {
        commit('setUser', null);
        commit('setSession', null);
        commit('setMfaPreference', null);
        commit('setUserRoles', []);
        commit('setFetchingUser', false);
      }
    },

    async disableMFAOnboarding({ commit }: { commit: Function }) {
      commit('setMFAOnboarding', false);
    },
  },
  getters: {
    groups: (
      state: AuthState,
      getters: any,
      rootState: any,
      rootGetters: any,
    ) => {
      if (!state.user) {
        return [];
      }
      // Assign virtual group 'multinationals'
      if (getters.isMultinational) {
        return [AuthGroup.Multinational];
      }

      return [rootGetters['profile/group']];
    },

    memberOf: (state: AuthState, getters: any) => (checkGroup: AuthGroup) => {
      const { groups } = getters;
      if (!groups) {
        return checkGroup === AuthGroup.None;
      }
      if (checkGroup === AuthGroup.Any) {
        return groups.length > 0;
      }
      const checkGroups: AuthGroup[] = Array.isArray(checkGroup)
        ? checkGroup
        : [checkGroup];

      return (
        groups.filter((group: AuthGroup) => checkGroups.includes(group))
          .length > 0
      );
    },

    signedIn: (state: AuthState): boolean => state.user !== null,

    hasMfa: (state: AuthState): boolean => {
      if (!state.mfaPreference) {
        return false;
      }
      return state.mfaPreference.enabled?.length > 0 ?? false;
    },

    showMFAOnboarding: (state: AuthState): boolean => !!state.MFAOnboarding,

    // Get the slug for multinational content endpoints
    multinationalSlug: (state: AuthState): string => {
      let slug = '';
      state.userRoles.some((role: Role) => {
        if (role && role.policies) {
          role.policies.some((p) => {
            if (
              p.resource.startsWith('/content/multinational_portal/') &&
              p.effect === 'allow' &&
              p.resource.endsWith('*')
            ) {
              if (p.resource.indexOf('/generic/') !== -1) {
                return false;
              }
              const matches = p.resource.match(
                /^\/content\/multinational_portal\/[^/]+\/([^/]+)/,
              );
              if (matches) {
                // eslint-disable-next-line prefer-destructuring
                slug = matches[1];
                return true;
              }
            }
            return false;
          });
        }
        return !!slug;
      });
      return slug;
    },

    hasAccess:
      (state: AuthState, getters: any) =>
      (endpoint?: Endpoint, checkGroup?: AuthGroup | undefined): boolean => {
        if (endpoint !== undefined) {
          const { policies } = getters;

          // Replace {locale} with current locale
          endpoint.resource = endpoint.resource.replace(
            '{locale}',
            i18n.global.locale.value.toLowerCase(),
          );

          const formattedEndpointAction = Authhelpers.formatEndpointActions(
            endpoint.action,
          );

          // check 1; does a policy contain a deny effect?
          const hasDenyPolicy = policies.find((policy: Policy) => {
            if (policy.effect !== 'deny') {
              return false;
            }
            const actions = policy.action.split('|');
            return (
              policy.resource === endpoint.resource &&
              actions.includes(formattedEndpointAction)
            );
          });
          if (hasDenyPolicy) {
            return false;
          }

          // check 2; is a "super" wildcard resource (*) present for in the policies?
          // check 3; does the given endpoint meet the policy' action & resource?
          const hasEndpointPrivileges = policies.find((policy: Policy) => {
            if (policy.effect !== 'allow') {
              return false;
            }
            const actions = policy.action.split('|');
            return (
              ((policy.resource === endpoint.resource ||
                policy.resource === '*') &&
                actions.includes(formattedEndpointAction)) ||
              (endpoint.resource.startsWith('/content/') &&
                (policy.resource.endsWith('/*') ||
                  policy.resource.endsWith('/{path+}')) &&
                actions.includes(formattedEndpointAction))
            );
          });

          if (hasEndpointPrivileges) {
            return true;
          }
        }

        // check 4; does the user belong to an allowed Cognito group?
        if (checkGroup && getters.memberOf(checkGroup)) {
          return true;
        }

        return false;
      },

    policies: (state: AuthState): Policy[] => {
      const { userRoles } = state;
      const policies: Policy[] = [];

      if (!userRoles) {
        return [];
      }

      userRoles.forEach((role) => {
        // eslint-disable-next-line no-unused-expressions
        role.policies?.forEach((policy) => policies.push(policy));
      });

      return policies;
    },

    isMultinational: (state: AuthState, getters: any): boolean =>
      !!getters.multinationalSlug,
  },
};
