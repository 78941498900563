<template>
  <section>
    <h1 class="title title--h1 onboarding-finished__title">
      {{ $t('onboarding.finished.title') }}
    </h1>
    <section class="onboarding-finished__text-wrapper">
      <div class="onboarding-finished__icon">
        <OIcon icon="check" size="large" variant="primary" />
        <OIcon icon="check" size="small" variant="primary" />
      </div>
      <div class="onboarding-finished__text">
        <div class="onboarding-finished__intro">
          {{ $t(`onboarding.finished.intro.${MFAType}`) }}
        </div>

        <div class="onboarding-finished__feates-enables">
          {{ $t('onboarding.finished.features-enabled') }}
        </div>
      </div>
    </section>
    <div class="button-row">
      <OButton variant="primary" rounded @click="disableMFAPopup()">{{
        $t('onboarding.finished.finish')
      }}</OButton>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  methods: {
    disableMFAPopup() {
      this.$store.dispatch('auth/disableMFAOnboarding');
    },
  },
  props: {
    MFAType: { type: String, required: true, default: () => '' },
  },
});
</script>
<style lang="scss" scoped>
.onboarding-finished {
  &__title {
    margin: 0 !important;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    margin: 24px;

    @include mobile {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__intro {
    font-weight: bold;
    line-height: 32px;
    color: $primary;

    @include mobile {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      margin: 16px 0;
    }
  }

  &__icon {
    flex: 0 0 133px;
    width: 133px;
    height: 133px;
    border-radius: 133px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba($primary, 0.28);
    box-shadow: 0px 4px 14px rgba($boulder, 0.22);

    .icon {
      &.is-large {
        display: initial;
        transform: translate(-7%, -1%);
      }

      &.is-small {
        transform: translate(-15%, -10%);
        display: none;
      }
    }

    @include mobile {
      flex: 0 0 75px;
      width: 75px;
      height: 75px;
      margin-bottom: 20px;

      .icon {
        &.is-large {
          display: none;
        }

        &.is-small {
          display: initial;
        }
      }
    }
  }
}
</style>
