import { DishApi } from '@/api';
import { Dish, DishCourseEnum } from '@/api/model';
import getConfiguration from '@/store/getConfiguration';

const initialState = () => ({
  dishes: [] as Dish[],
  activeCourse: DishCourseEnum.Breakfast,
});
export type MenuEngineeringState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: MenuEngineeringState) {
      Object.assign(state, initialState());
    },

    setDishes(state: MenuEngineeringState, dishes: Dish[]) {
      state.dishes = dishes;
    },

    updateDish(state: MenuEngineeringState, updateDish: Dish) {
      const index = state.dishes.findIndex(
        (dish: Dish) => dish.id === updateDish.id,
      );
      if (index !== -1) {
        state.dishes[index] = updateDish;
      } else {
        state.dishes.push(updateDish);
      }
    },

    deleteDish(state: MenuEngineeringState, deleteDish: Dish) {
      state.dishes = state.dishes.filter(
        (dish: Dish) => dish.id !== deleteDish.id,
      );
    },

    setActiveCourse(state: MenuEngineeringState, course: DishCourseEnum) {
      state.activeCourse = course;
    },
  },

  actions: {
    async getDishes({ commit }: { commit: Function }) {
      const configuration = await getConfiguration();
      const api = new DishApi(configuration, '');
      const { data } = await api.dishesGet();
      commit('setDishes', data);
    },

    async saveDish({ commit }: { commit: Function }, dish: Dish) {
      const configuration = await getConfiguration();
      const api = new DishApi(configuration, '');
      const { data } = await api.dishPut(dish);
      commit('updateDish', data);
    },

    async deleteDish({ commit }: { commit: Function }, dish: Dish) {
      if (!dish.id) {
        return;
      }

      commit('deleteDish', dish);
      const configuration = await getConfiguration();
      const api = new DishApi(configuration, '');
      await api.dishDelete(dish.id);
    },
  },
};
