/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateOrderSuccessResponse } from '../model';
// @ts-ignore
import { InlineObject1 } from '../model';
// @ts-ignore
import { InlineResponse2001 } from '../model';
// @ts-ignore
import { SalesOrder } from '../model';
// @ts-ignore
import { SalesOrderCreate } from '../model';
// @ts-ignore
import { SalesOrderDetail } from '../model';
// @ts-ignore
import { SapCustomerPartner } from '../model';
// @ts-ignore
import { SapCustomerProduct } from '../model';
// @ts-ignore
import { UNKNOWN_BASE_TYPE } from '../model';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new sales order for Sap customer associated with the logged in Cognito user
         * @summary Create sales order
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Details of the order to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrderPost: async (uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uNKNOWNBASETYPE' is not null or undefined
            assertParamExists('newOrderPost', 'uNKNOWNBASETYPE', uNKNOWNBASETYPE)
            const localVarPath = `/new-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculates a filled truck load based on requested products and their number of layers & pallets
         * @summary Calculates a truck load
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCalculatePost: async (inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('orderCalculatePost', 'inlineObject1', inlineObject1)
            const localVarPath = `/order/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sales order with all order items
         * @param {string} id Id of the sales order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderGet', 'id', id)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Orders will be returned for the logged in (Cognito) user
         * @summary Get overview of all sales orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Products will be returned for the logged in (Cognito) user
         * @summary Get overview of all available products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Addresses will be returned for the logged in (Cognito) user
         * @summary Get all available ship-to partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipToPartnersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ship-to-partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new sales order for Sap customer associated with the logged in Cognito user
         * @summary Create sales order
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Details of the order to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newOrderPost(uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newOrderPost(uNKNOWNBASETYPE, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calculates a filled truck load based on requested products and their number of layers & pallets
         * @summary Calculates a truck load
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderCalculatePost(inlineObject1: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderCalculatePost(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sales order with all order items
         * @param {string} id Id of the sales order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesOrderDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Orders will be returned for the logged in (Cognito) user
         * @summary Get overview of all sales orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesOrder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Products will be returned for the logged in (Cognito) user
         * @summary Get overview of all available products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SapCustomerProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Addresses will be returned for the logged in (Cognito) user
         * @summary Get all available ship-to partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipToPartnersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SapCustomerPartner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipToPartnersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * Create a new sales order for Sap customer associated with the logged in Cognito user
         * @summary Create sales order
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Details of the order to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrderPost(uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<CreateOrderSuccessResponse> {
            return localVarFp.newOrderPost(uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculates a filled truck load based on requested products and their number of layers & pallets
         * @summary Calculates a truck load
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCalculatePost(inlineObject1: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.orderCalculatePost(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sales order with all order items
         * @param {string} id Id of the sales order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGet(id: string, options?: any): AxiosPromise<SalesOrderDetail> {
            return localVarFp.orderGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Orders will be returned for the logged in (Cognito) user
         * @summary Get overview of all sales orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGet(options?: any): AxiosPromise<Array<SalesOrder>> {
            return localVarFp.ordersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Products will be returned for the logged in (Cognito) user
         * @summary Get overview of all available products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet(options?: any): AxiosPromise<Array<SapCustomerProduct>> {
            return localVarFp.productsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Addresses will be returned for the logged in (Cognito) user
         * @summary Get all available ship-to partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipToPartnersGet(options?: any): AxiosPromise<Array<SapCustomerPartner>> {
            return localVarFp.shipToPartnersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * Create a new sales order for Sap customer associated with the logged in Cognito user
     * @summary Create sales order
     * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Details of the order to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public newOrderPost(uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any) {
        return OrderApiFp(this.configuration).newOrderPost(uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculates a filled truck load based on requested products and their number of layers & pallets
     * @summary Calculates a truck load
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderCalculatePost(inlineObject1: InlineObject1, options?: any) {
        return OrderApiFp(this.configuration).orderCalculatePost(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sales order with all order items
     * @param {string} id Id of the sales order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGet(id: string, options?: any) {
        return OrderApiFp(this.configuration).orderGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Orders will be returned for the logged in (Cognito) user
     * @summary Get overview of all sales orders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public ordersGet(options?: any) {
        return OrderApiFp(this.configuration).ordersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Products will be returned for the logged in (Cognito) user
     * @summary Get overview of all available products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public productsGet(options?: any) {
        return OrderApiFp(this.configuration).productsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Addresses will be returned for the logged in (Cognito) user
     * @summary Get all available ship-to partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public shipToPartnersGet(options?: any) {
        return OrderApiFp(this.configuration).shipToPartnersGet(options).then((request) => request(this.axios, this.basePath));
    }
}
