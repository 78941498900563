<template>
  <div
    :class="`calculation-totals calculation-totals--${mod} calculation-totals--${status}`"
  >
    <div
      class="calculation-totals__content"
      :class="
        mod === 'select-products'
          ? 'layout__content layout__content--large'
          : ''
      "
    >
      <strong class="calculation-totals__title">{{
        $t('container-calculation.result.totals.title')
      }}</strong>
      <span class="calculation-totals__weight">
        <span>{{ $t('container-calculation.result.totals.weight') }}</span>
        <strong
          >{{ grossWeight ? $n(grossWeight) : '-'
          }}{{ $t('container-calculation.result.totals.kg') }}</strong
        ></span
      >
      <span class="calculation-totals__boxes">
        <span>{{ $t('container-calculation.result.totals.boxes') }}</span>
        <strong class="calculation-totals__value">{{
          boxes ? $n(boxes) : '-'
        }}</strong>
      </span>
      <span class="calculation-totals__pallets"
        ><span>{{ $t('container-calculation.result.totals.pallets') }}</span
        ><strong class="calculation-totals__value">{{ pallets || '-' }}</strong>
      </span>
      <span class="calculation-totals__button button-row" v-if="$slots.default"
        ><slot
      /></span>
    </div>
  </div>
</template>

<script lang="ts">
import { InlineResponse200, InlineResponse200Totals } from '@/api';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    result(): InlineResponse200 {
      return this.$store.state.container.result;
    },
    status(): string {
      return this.$store.getters['container/status'];
    },
    totals(): InlineResponse200Totals | undefined {
      return this.result?.Totals;
    },
    grossWeight(): number | undefined {
      return this.totals?.WeightLoading;
    },
    boxes(): number | undefined {
      return this.totals?.NumBoxes;
    },
    pallets(): number | undefined {
      return this.totals?.NumPallets;
    },
  },
  props: {
    mod: { type: String, default: 'select-products' },
  },
});
</script>

<style lang="scss">
.calculation-totals {
  background: $wild-sand;
  border: 1px solid $border;
  border-bottom-width: 0;

  &__content {
    display: flex;

    @include mobile {
      flex-wrap: wrap;
      padding: 6px 0;
      font-size: 14px;

      > * {
        flex: 1 1 100%;
      }
    }

    &,
    strong {
      color: $primary;
    }
  }

  &__title {
    padding: 5px 20px;
    flex-basis: 40%;

    @include tablet {
      padding: 20px;
      flex: 1;
      flex-basis: auto;
    }
  }

  &__weight,
  &__boxes,
  &__pallets {
    padding: 4px 16px;
    font-weight: normal;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    @include tablet {
      padding: 20px 16px;
      border-right: 1px solid $border;

      > :not(:first-child) {
        margin-left: 5px;
      }
    }
  }

  &__weight {
    @include mobile {
      flex-basis: 60%;
    }
  }

  &__boxes {
    flex-basis: 40%;

    @include tablet {
      flex: 0 0 160px;
      text-align: center;
    }
  }

  &__pallets {
    flex-basis: 60%;

    @include tablet {
      flex: 0 1 140px;
      text-align: center;
    }
  }

  &__unit {
    @include mobile {
      font-weight: bold;
    }
  }

  &__button {
    @include tablet {
      flex: 0 1 260px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--summary {
    background: $white;
    border-radius: 0 0 8px 8px;
    border: 0;
    border-top: 1px solid $gallery;

    .calculation-totals__weight {
      @include tablet {
        border-right: 0;
      }
    }

    .calculation-totals__boxes,
    .calculation-totals__pallets {
      @include tablet {
        border-right: 0;
        flex: 0 1 100px;
      }
    }
  }

  &--underfilled,
  &--overfilled,
  &--overfilled-no-product {
    .calculation-totals__value {
      color: $secondary;
    }
  }
}
</style>
