<template>
  <div class="totals">
    <span class="totals__label" v-if="label">
      {{ label }}
    </span>
    <div class="totals__values">
      <span v-if="totals.pal" class="totals__value">
        <span class="totals__count">{{ totals.pal }}</span>
        <span class="totals__unit">{{
          $t('orders.order-item-list.totals.total-pallets', totals.pal)
        }}</span>
      </span>
      <span v-if="totals.lay" class="totals__value">
        <span class="totals__count">{{ totals.lay }}</span>
        <span class="totals__unit">{{
          $t('orders.order-item-list.totals.total-layers', totals.lay)
        }}</span>
      </span>
      <span v-if="totals.car" class="totals__value">
        <span class="totals__count">{{ totals.car }}</span>
        <span class="totals__unit">{{
          $t('orders.order-item-list.totals.total-cartons', totals.car)
        }}</span>
      </span>
      <span v-if="totals.grossWeight && showWeight" class="totals__value">
        <span class="totals__count"
          >{{ totals.grossWeight }}
          {{ $t('orders.order-item-list.totals.kg') }}</span
        >
        <span class="totals__unit">
          {{ $t('orders.order-item-list.totals.total-gross-weight') }}</span
        >
      </span>
      <span v-if="totals.netWeight && showWeight" class="totals__value">
        <span class="totals__count"
          >{{ totals.netWeight }}
          {{ $t('orders.order-item-list.totals.kg') }}</span
        >
        <span class="totals__unit">
          {{ $t('orders.order-item-list.totals.total-net-weight') }}</span
        >
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { OrderItem, SapProduct } from '@/api/model';
import { defineComponent } from 'vue';

interface Totals {
  [key: string]: number;
}

export default defineComponent({
  data() {
    return {
      showWeight: true,
    };
  },
  computed: {
    totals(): any {
      const totals: Totals = {
        pal: 0,
        lay: 0,
        car: 0,
        netWeight: 0,
        grossWeight: 0,
      };
      const reducer = (acc: Totals, item: OrderItem) => {
        if (item.SalesUnit !== undefined && item.SalesUnit !== null) {
          const unit = item.SalesUnit.toLowerCase();
          const weightUnits = this.weightUnits(item.ProductNr, item.SalesUnit);
          if (weightUnits.netWeight === 0 || weightUnits.grossWeight === 0) {
            this.setShowWeight(false);
          }
          if (item.Quantity !== undefined && item.Quantity !== null) {
            const quantity = parseInt(item.Quantity, 10);
            acc[unit] += quantity;
            acc.netWeight += weightUnits.netWeight * quantity;
            acc.grossWeight += weightUnits.grossWeight * quantity;
          }
        }
        return acc;
      };
      this.setShowWeight(true);
      return this.items.reduce(reducer, totals);
    },
  },
  methods: {
    setShowWeight(value: boolean) {
      this.showWeight = value;
    },
    weightUnits(
      productNr: any,
      salesUnit: string,
    ): {
      netWeight: number;
      grossWeight: number;
    } {
      if (this.products) {
        const product = this.products.find(
          (prod) => prod.ProductNr === productNr,
        );
        if (product && product.Units) {
          const selectedUnit = product.Units.find(
            (unit) => unit.UnitOfMeasure === salesUnit,
          );
          if (selectedUnit) {
            return {
              netWeight: selectedUnit.NetWeight
                ? parseInt(selectedUnit.NetWeight, 10)
                : 0,
              grossWeight: selectedUnit.GrossWeight
                ? parseInt(selectedUnit.GrossWeight, 10)
                : 0,
            };
          }
        }
      }

      return {
        netWeight: 0,
        grossWeight: 0,
      };
    },
  },
  props: {
    items: {
      type: Array as () => OrderItem[],
      default: () => [],
    },
    products: {
      type: Array as () => SapProduct[],
    },
    label: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.totals {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  margin: 0;
  padding: 0;
  border: none;
  color: $prussian-blue;

  .table__details & {
    border-color: $heather;
    padding: 16px 0;
  }

  @include mobile {
    margin: 0 0 10px 0;
    font-size: 16px;
  }

  @include tablet {
    border: 0;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__values {
    display: flex;

    @include mobile {
      margin-top: 18px;
    }
  }

  &__value {
    display: flex;
    margin: 0 20px 0 0;
    flex-direction: row-reverse;

    @include mobile {
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    @include tablet {
      margin: 0 16px 0 0;
    }
  }

  &__unit {
    margin-right: 10px;
    margin-left: 0;

    &::after {
      content: ':';
    }
  }

  &__count {
    font-weight: bold;

    @include mobile {
      margin-right: 5px;
    }
  }
}
</style>
