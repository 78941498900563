<template>
  <div class="block block--pages">
    <ProductCard
      v-for="product in pages"
      :key="product.id"
      :product="product"
    />
  </div>
</template>

<script lang="ts">
import ProductCard from '@/components/products/ProductCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ProductCard },
  props: {
    pages: {
      type: Array,
    },
  },
});
</script>
