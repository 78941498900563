<template>
  <div class="claim-details__card">
    <div class="claim-details__column">
      <div class="claim-details__segment">
        <div class="claim-details__title">
          {{ $t('claims.new.steps.details.applicant') }}
        </div>
        <div class="claim-details__strong">
          {{ $store.getters['profile/getSapCompany'] }}
        </div>
        <div
          v-if="showProfile && $store.state.profile.profile.email"
          class="claim-details__value"
        >
          {{ $store.state.profile.profile.email }}
        </div>
        <div
          v-if="showProfile && $store.state.profile.profile.phone"
          class="claim-details__value"
        >
          {{ $store.state.profile.profile.phone }}
        </div>
      </div>
      <div class="claim-details__segment">
        <div class="claim-details__title">
          {{ $t('claims.new.steps.details.wholesale-party') }}
        </div>
        <div class="claim-details__strong">{{ claim.wholesale_party }}</div>
      </div>
    </div>
    <div v-if="agreement" class="claim-details__column">
      <div class="claim-details__title">
        {{ $t('claims.new.steps.details.agreement-details') }}
      </div>
      <div class="claim-details__strong">
        <span>{{ agreement.type }}</span>
        |
        <span>{{ agreement.sales_quote_sap_id }}</span>
      </div>
      <div class="claim-details__value">
        {{ agreement.description }}
        |
        {{ $t('claims.new.steps.details.currency') }}
        {{ agreement.currency }}
      </div>
      <div class="claim-details__value">
        {{
          $t('claims.new.steps.details.valid-range', {
            from: Helpers.formatEtaDate(agreement.date_time_valid_from),
            to: Helpers.formatEtaDate(agreement.date_time_valid_to),
          })
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SalesQuote, SalesQuoteClaim } from '@/api';
import Helpers from '@/logic/orders/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      Helpers,
    };
  },
  props: {
    claim: { type: Object as () => SalesQuoteClaim, required: true },
    agreement: { type: Object as () => SalesQuote, required: true },
    showProfile: { type: Boolean, default: true },
  },
});
</script>

<style lang="scss" scoped>
.claim-details {
  &__card {
    line-height: 1.75;
    background-color: $white;
    border: 1px solid $mercury;
    box-shadow: 0px 20px 39px rgba(217, 217, 217, 0.7);
    border-radius: 8px;
    display: flex;

    @include until($tablet) {
      flex-direction: column;
    }
  }

  &__column {
    padding: 24px;

    @include until($tablet) {
      border-block-start: 1px solid $mercury;
    }

    @include tablet {
      flex: 0 0 50%;
      border-inline-start: 1px solid $mercury;
    }
  }

  &__segment {
    &:not(:first-child) {
      margin-block-start: 16px;
    }
  }

  &__title {
    font-size: 14px;
    color: $primary;
  }

  &__strong {
    font-size: 18px;
    font-weight: bold;
  }

  &__value {
    font-size: 16px;
    margin-block-start: 4px;
  }
}
</style>
