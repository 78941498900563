import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      isLoading: false,
      webspace: 'customer_portal',
    };
  },
  computed: {
    multinationalDashboard(): boolean {
      return false;
    },
    contentStore(): any {
      return this.$store.state.content;
    },
    content(): any {
      if (
        this.multinationalDashboard &&
        this.contentStore.multinationalContent
      ) {
        return this.contentStore.multinationalContent.content;
      }
      if (this.contentStore && this.contentStore.content) {
        return this.contentStore.content.content;
      }
      return undefined;
    },
    title(): string {
      if (this.content) {
        return this.content.title;
      }
      return '';
    },
    headerTitle(): string {
      if (this.content) {
        return this.content.header_title;
      }
      return '';
    },
    headerImage(): string {
      if (this.content) {
        return this.content.header_image;
      }
      return '';
    },
    introText(): string {
      if (this.content) {
        return this.content.header_text;
      }
      return '';
    },
    blocks(): any[] {
      if (this.content) {
        if (this.multinationalDashboard) {
          return this.content.blocks.map((b: any) => ({
            ...b,
            multinational: true,
          }));
        }
        return this.content.blocks;
      }
      return [];
    },
    pages(): any[] {
      if (!this.content) {
        return [];
      }

      if (this.multinationalDashboard) {
        return this.content.pages.map((b: any) => ({
          ...b,
          multinational: true,
        }));
      }

      return this.content.pages;
    },
  },
  methods: {
    async fetchContent(slug = '') {
      return this.$store.dispatch('content/getContent', {
        route: this.$route,
        i18n: this.$i18n,
        webspace: this.webspace,
        slug,
      });
    },
  },
});
