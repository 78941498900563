<template>
  <transition name="fade">
    <div
      class="footer-message"
      :class="`footer-message--${action}`"
      v-if="showSuggestion"
    >
      <div
        class="footer-message__content layout__content layout__content--large"
      >
        <OIcon
          icon="info_circle"
          :variant="
            action === 'suggest-remove' || action === 'overfill-no-suggestion'
              ? 'secondary'
              : 'primary'
          "
          class="footer-message__icon"
        />
        <div class="footer-message__title-text">
          <h2 class="footer-message__title">
            {{
              $t(`orders.new.${action}.footer.title`, {
                quantity,
                exceededWeight,
              })
            }}
          </h2>
          <p class="footer-message__text" v-show="!minimized">
            {{ $t(`orders.new.${action}.footer.message`) }}
          </p>
        </div>
        <button @click="minimized = !minimized" class="footer-message__toggle">
          <OIcon
            icon="chevron_up"
            class="footer-message__toggle-icon"
            :class="{ 'footer-message__toggle-icon--minimized': minimized }"
            role="button"
          />
        </button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { OrderSuggestion } from '@/types/ApiInterfaces';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      minimized: false,
    };
  },
  computed: {
    action(): string {
      return this.$store.getters['order/suggestionAction'];
    },
    suggestion(): OrderSuggestion {
      return this.$store.state.order.suggestion;
    },
    quantity(): number {
      const quantity = this.suggestion.CorrectionSuggestion
        ? this.suggestion.CorrectionSuggestion.NumPallets || 0
        : 0;
      return Math.abs(quantity);
    },
    exceededWeight(): number {
      const exceededWeight = this.suggestion.ExceededWeight
        ? this.suggestion.ExceededWeight || 0
        : 0;
      return exceededWeight;
    },
    showSuggestion(): boolean {
      return (
        this.action !== 'next-step' &&
        !this.$store.state.order.suggestionAccepted &&
        !this.$store.state.order.suggestionCancelled
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.footer-message {
  font-size: 14px;
  line-height: 1.5;
  padding-block: 16px;
  background: $black-squeeze;

  @include tablet {
    padding-block: 36px;
    font-size: 16px;
  }

  &--suggest-remove,
  &--overfill-no-suggestion {
    background: $bridesmaid;
    outline: 1px solid $rose-bud;
    color: $black;
  }

  &__toggle {
    all: unset;
    height: 28px;
    width: 28px;
  }

  &__toggle-icon {
    transform: rotate(180deg);
    transition: transform 0.2s ease;

    @include tablet {
      display: none;
    }

    &--minimized {
      transform: rotate(0deg);
    }
  }

  &__content {
    display: flex;
  }

  &__icon {
    font-size: 28px;
    margin-right: 14px;
  }

  &__title {
    font-weight: bold;
  }

  &__text {
    @include mobile {
      margin-top: 8px;
      margin-left: -39px;
      margin-right: -30px;
    }
  }
}
</style>
