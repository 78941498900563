import enGb from '../../locales/countries/en-GB-countries.json';
import it from '../../locales/countries/it-countries.json';

const dict: any = {
  'en-GB': enGb.countries,
  it: it.countries,
};

export default {
  getCountryCodes(): string[] {
    return Object.keys(dict['en-GB']);
  },
  getCountryNameFromCode(code: string, locale: string): string {
    if (dict[locale] && dict[locale][code]) {
      return dict[locale][code];
    }
    return code;
  },
  getCountryNameFromEnGb(name: string, locale: string): string {
    let code = '';
    Object.keys(dict['en-GB']).some((key) => {
      if (dict['en-GB'][key] === name) {
        code = key;
        return true;
      }
      return false;
    });
    return this.getCountryNameFromCode(code, locale);
  },
};
