import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { formatDistanceStrict } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import { SalesOrderAll as SalesOrder } from '@/logic/orders/SalesOrderAll';
import { DateFnsLocales } from '@/DateFnsLocales';

export default class Helpers {
  private static getCreatedDateFromOrder(order: SalesOrder): Date | null {
    if (order.CreatedOn == null) {
      return null;
    }

    const inputFormat = order.CreatedAt != null ? 'yyyyMMddHHmmss' : 'yyyyMMdd';
    const createdAt = order.CreatedAt ? `${order.CreatedAt}` : '';
    const dateString = `${order.CreatedOn}${createdAt}`;

    return parse(dateString, inputFormat, new Date());
  }

  public static getReadableCreatedFullDate(order: SalesOrder): string | null {
    const date = this.getCreatedDateFromOrder(order);
    if (!date) {
      return null;
    }

    return format(date, 'eee, dd-MM-yyyy - HH:mm');
  }

  public static getReadableCreatedDate(order: SalesOrder): string | null {
    const date = this.getCreatedDateFromOrder(order);
    if (!date) {
      return null;
    }

    return this.formatDate(date);
  }

  public static getDateFromSapDate(sapDate: string): Date {
    return parse(sapDate, 'yyyyMMdd', new Date());
  }

  public static getDateFromEtaDate(sapDate: string): Date {
    return parse(sapDate, 'yyyy-MM-dd', new Date());
  }

  public static formatSapDate(sapDate: string): string {
    if (!sapDate) {
      return '';
    }
    const date = Helpers.getDateFromSapDate(sapDate);
    if (date instanceof Date && !Number.isNaN(date.getDate())) {
      return Helpers.formatDate(date);
    }
    return '';
  }

  public static formatEtaDate(etaDate: string): string {
    if (!etaDate) {
      return '';
    }
    const d = Helpers.getDateFromEtaDate(etaDate);
    return Helpers.formatDate(d);
  }

  public static formatDate(date: Date): string {
    return format(date, 'dd-MM-yyyy');
  }

  public static eta(order: SalesOrder): string | null | undefined {
    if (!order.OriginalEta) {
      return null;
    }

    const currentEta = order.UpdatedEta ? order.UpdatedEta : order.OriginalEta;
    const parsed = this.getDateFromEtaDate(currentEta);
    return currentEta ? Helpers.formatDate(parsed) : currentEta;
  }

  public static delay(order: SalesOrder, locale: any, t: any): string | null {
    if (
      !order.OriginalEta ||
      !order.UpdatedEta ||
      order.UpdatedEta === order.OriginalEta
    ) {
      return null;
    }

    const original = this.getDateFromEtaDate(order.OriginalEta);
    const updated = this.getDateFromEtaDate(order.UpdatedEta);
    const distance = formatDistanceStrict(original, updated, {
      unit: 'day',
      addSuffix: false,
      locale: DateFnsLocales[locale.value],
    });

    const earlyOrDelayed =
      compareAsc(original, updated) === 1 ? 'early' : 'delayed';
    const suffix = t(`orders.shipping.eta.${earlyOrDelayed}`);
    return `${distance} ${suffix}`;
  }
}
