<template>
  <a
    v-if="block.file && block.file.url"
    class="block eat-this-download form-card"
    :href="$helpers.getFileUrl(block.file.url)"
    target="_blank"
    rel="noopener"
  >
    <div v-if="block.image" class="eat-this-download__column">
      <img
        class="eat-this-download__image"
        :src="$helpers.getApiImageThumbnail(block.image)"
        alt="eat-this file thumbnail"
      />
    </div>
    <div class="eat-this-download__column">
      <h2 v-if="block.title" class="title title--h2 block__title">
        {{ block.title }}
      </h2>
      <p v-if="block.text" class="eat-this-download__text">
        {{ block.text }}
      </p>
      <span v-if="block.file" class="eat-this-download__link">
        {{ $t('eatThis.download') }}
        <img
          class="eat-this-download__arrow"
          src="/img/icons/arrow.svg"
          alt="arrow"
        />
      </span>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
$breakpoint-card: 650px;

.eat-this-download {
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  padding: 0;
  transition: box-shadow 0.3s ease-in, transform 0.3s ease-in;

  @media screen and (min-width: $breakpoint-card) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &:hover {
    box-shadow: 0px 24px 44px rgba($black, 0.1);
    transform: scale(1.01);
  }

  &__link,
  &__text {
    color: $prussian-blue-light;
  }

  &__text {
    font-size: 20px;
    font-weight: lighter;
    margin-bottom: 20px;

    @media screen and (min-width: $breakpoint-card) {
      font-size: 24px;
      margin-bottom: auto;
    }
  }

  &__link {
    font-size: 20px;
    margin-left: auto;
  }

  &__arrow {
    vertical-align: middle;
    margin-left: 8px;
  }

  &__image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media screen and (min-width: $breakpoint-card) {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0;
      height: 100%;
      object-fit: cover;
    }
  }

  .eat-this-download__column .title.title--h2.block__title {
    flex-basis: inherit;
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 15px;

    @media screen and (min-width: $breakpoint-card) {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.eat-this-download__column:nth-child(1) {
  @media screen and (min-width: $breakpoint-card) {
    display: flex;
    flex: 0 0 275px;
    min-height: 237px;
    width: 275px;
  }
}

.eat-this-download__column:nth-child(2) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;

  @media screen and (min-width: $breakpoint-card) {
    padding: 30px;
  }
}
</style>
