import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MfaWarning = _resolveComponent("MfaWarning")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", `layout__content layout__content--${_ctx.mod}`])
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(["header__title", `title title--${_ctx.titleMod}`])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("p", {
      class: _normalizeClass(["header__intro-text", `header__intro-text--${_ctx.introMod}`])
    }, [
      _renderSlot(_ctx.$slots, "intro", {}, undefined, true)
    ], 2),
    (_ctx.checkMfa && !_ctx.hasMfa)
      ? (_openBlock(), _createBlock(_component_MfaWarning, { key: 0 }))
      : _createCommentVNode("", true)
  ], 2))
}