import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b4128e90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-e-results" }
const _hoisted_2 = { class: "results__intro layout__content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "results__intro-text" }
const _hoisted_5 = { class: "button-row" }
const _hoisted_6 = { class: "results__restaurant layout__content" }
const _hoisted_7 = { class: "subtitle" }
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "results__matrix"
}
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OSelect = _resolveComponent("OSelect")!
  const _component_OField = _resolveComponent("OField")!
  const _component_ResultCategory = _resolveComponent("ResultCategory")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('menuEngineering.results.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('menuEngineering.label.categoryResult.intro')) + " " + _toDisplayString(_ctx.$t('menuEngineering.label.categoryResult.link')) + ". " + _toDisplayString(_ctx.$t('menuEngineering.label.categoryResult.outro')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_OButton, {
              tag: "router-link",
              class: "button button-row__button",
              rounded: "",
              variant: "primary",
              to: "/menu-engineering/add-dish"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('menuEngineering.overview.addDish')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.restaurants, (restaurant) => {
          return (_openBlock(), _createElementBlock("section", {
            class: "results__restaurants",
            key: restaurant.id
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(restaurant.restaurantName), 1),
              _createVNode(_component_OField, null, {
                default: _withCtx(() => [
                  _createVNode(_component_OSelect, {
                    modelValue: _ctx.course,
                    placeholder: _ctx.$t('menuEngineering.label.selectCourseLabel'),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCourse($event))),
                    expanded: "",
                    required: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: course,
                          value: course
                        }, _toDisplayString(_ctx.$t(`menuEngineering.label.course.${course}`)), 9, _hoisted_8))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              (_ctx.getCourseDishCount(restaurant.id) >= 5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                      return (_openBlock(), _createBlock(_component_ResultCategory, {
                        key: category,
                        category: category,
                        "restaurant-id": restaurant.id,
                        "dish-count": _ctx.getDishCount(restaurant.id, category),
                        class: "results__category"
                      }, null, 8, ["category", "restaurant-id", "dish-count"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('menuEngineering.label.insufficientDishes', {
                count: 5 - _ctx.getCourseDishCount(restaurant.id),
              })), 1))
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}