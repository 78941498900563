/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PdfDownloadResponse } from '../model';
// @ts-ignore
import { ProductInformationDetail } from '../model';
/**
 * ProductInformationApi - axios parameter creator
 * @export
 */
export const ProductInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Product information of the requested product
         * @summary Get detailed product information of product
         * @param {string} id Id of the product to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productInformationDetailsGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productInformationDetailsGet', 'id', id)
            const localVarPath = `/product-information-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the download link or the product spec sheet PDF, link will be valid for 5 minutes
         * @summary Get pdf product spec sheet link
         * @param {string} shortCode ShortCode of the requested product spec sheet
         * @param {string} language Language in format \&quot;en\&quot;,\&quot;fr\&quot;,\&quot;nl\&quot; etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productInformationPdfDownloadLinkGet: async (shortCode: string, language: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shortCode' is not null or undefined
            assertParamExists('productInformationPdfDownloadLinkGet', 'shortCode', shortCode)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('productInformationPdfDownloadLinkGet', 'language', language)
            const localVarPath = `/product-information-pdf-download-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (shortCode !== undefined) {
                localVarQueryParameter['short_code'] = shortCode;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductInformationApi - functional programming interface
 * @export
 */
export const ProductInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * Product information of the requested product
         * @summary Get detailed product information of product
         * @param {string} id Id of the product to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productInformationDetailsGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductInformationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productInformationDetailsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the download link or the product spec sheet PDF, link will be valid for 5 minutes
         * @summary Get pdf product spec sheet link
         * @param {string} shortCode ShortCode of the requested product spec sheet
         * @param {string} language Language in format \&quot;en\&quot;,\&quot;fr\&quot;,\&quot;nl\&quot; etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productInformationPdfDownloadLinkGet(shortCode: string, language: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfDownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productInformationPdfDownloadLinkGet(shortCode, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductInformationApi - factory interface
 * @export
 */
export const ProductInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductInformationApiFp(configuration)
    return {
        /**
         * Product information of the requested product
         * @summary Get detailed product information of product
         * @param {string} id Id of the product to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productInformationDetailsGet(id: string, options?: any): AxiosPromise<ProductInformationDetail> {
            return localVarFp.productInformationDetailsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the download link or the product spec sheet PDF, link will be valid for 5 minutes
         * @summary Get pdf product spec sheet link
         * @param {string} shortCode ShortCode of the requested product spec sheet
         * @param {string} language Language in format \&quot;en\&quot;,\&quot;fr\&quot;,\&quot;nl\&quot; etc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productInformationPdfDownloadLinkGet(shortCode: string, language: string, options?: any): AxiosPromise<PdfDownloadResponse> {
            return localVarFp.productInformationPdfDownloadLinkGet(shortCode, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductInformationApi - object-oriented interface
 * @export
 * @class ProductInformationApi
 * @extends {BaseAPI}
 */
export class ProductInformationApi extends BaseAPI {
    /**
     * Product information of the requested product
     * @summary Get detailed product information of product
     * @param {string} id Id of the product to obtain details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductInformationApi
     */
    public productInformationDetailsGet(id: string, options?: any) {
        return ProductInformationApiFp(this.configuration).productInformationDetailsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the download link or the product spec sheet PDF, link will be valid for 5 minutes
     * @summary Get pdf product spec sheet link
     * @param {string} shortCode ShortCode of the requested product spec sheet
     * @param {string} language Language in format \&quot;en\&quot;,\&quot;fr\&quot;,\&quot;nl\&quot; etc.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductInformationApi
     */
    public productInformationPdfDownloadLinkGet(shortCode: string, language: string, options?: any) {
        return ProductInformationApiFp(this.configuration).productInformationPdfDownloadLinkGet(shortCode, language, options).then((request) => request(this.axios, this.basePath));
    }
}
