<template>
  <div>
    <section class="claim-overview__products">
      <BaseHeader :check-mfa="true">
        2.1 {{ $t('claims.new.steps.summary.header') }}
      </BaseHeader>
      <NewClaimOverviewProducts />
    </section>

    <NewClaimOverviewDetails />
  </div>
</template>

<script lang="ts">
import BaseHeader from '@/components/BaseHeader.vue';
import { SalesQuote } from '@/api';
import NewClaimOverviewDetails from '@/components/claims/new-claim/NewClaimOverviewDetails.vue';
import NewClaimOverviewProducts from '@/components/claims/new-claim/NewClaimOverviewProducts.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    BaseHeader,
    NewClaimOverviewDetails,
    NewClaimOverviewProducts,
  },
  computed: {
    agreement(): SalesQuote | undefined {
      return this.$store.getters['claim/agreement'];
    },
  },
});
</script>
<style scoped type="text/css">
.claim-overview__products {
  margin-bottom: 50px;
}
</style>
