import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "new-order" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsBar = _resolveComponent("StepsBar")!
  const _component_SelectProducts = _resolveComponent("SelectProducts")!
  const _component_OrderReferenceAndShipping = _resolveComponent("OrderReferenceAndShipping")!
  const _component_OrderSummaryAndConfirmation = _resolveComponent("OrderSummaryAndConfirmation")!
  const _component_Form = _resolveComponent("Form")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_StepsBar, {
          labelPrefix: "orders",
          steps: _ctx.steps,
          "overview-link": { name: 'orders' },
          activeStep: _ctx.$store.state.order.orderStep
        }, null, 8, ["steps", "activeStep"]),
        _createVNode(_component_Form, null, {
          default: _withCtx(({ meta }) => [
            (_ctx.step === 'select-products')
              ? (_openBlock(), _createBlock(_component_SelectProducts, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.step === 'order-reference-and-shipping')
              ? (_openBlock(), _createBlock(_component_OrderReferenceAndShipping, {
                  key: 1,
                  "is-invalid": !meta.valid
                }, null, 8, ["is-invalid"]))
              : _createCommentVNode("", true),
            (_ctx.step === 'order-summary-and-confirmation')
              ? (_openBlock(), _createBlock(_component_OrderSummaryAndConfirmation, { key: 2 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}