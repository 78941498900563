enum AuthGroup {
  None = 'none', // Use to check if user has no groups
  Direct = 'direct-customers', // Cognito group
  Indirect = 'indirect-customers', // Cognito group
  Admin = 'administrators', // Cognito group
  EndUsers = 'end-users', // Cognito group
  Any = 'any', // Any of the groups
  Multinational = 'multinationals', // This group is set for multinational
}

export default AuthGroup;
