<template>
  <LayoutDefault>
    <div class="content-page">
      <OModal
        :active="$store.getters['auth/showMFAOnboarding']"
        trap-focus
        :cancelable="false"
        aria-role="dialog"
      >
        <Onboarding />
      </OModal>
      <Hero
        :title="
          $t('home.title', {
            fullname: fullName,
          })
        "
        :subtitle="
          customerName
            ? $t('home.subtitle', { customerName: customerName })
            : ''
        "
        :image="{
          formatUri: '/img/fries.{extension}',
          formatPreferredExtension: 'jpg',
          focusPointX: 2,
          focusPointY: 0,
        }"
        :gradient="true"
        :pageName="'dashboard'"
      />

      <div class="dashboard--content">
        <ContentBlocks
          v-if="!isLoading"
          :blocks="blocks.filter((block) => block.type === 'news')"
        />
        <OSkeleton
          v-else
          :animated="true"
          rootClass="is-centered"
          width="50%"
          height="140px"
        />

        <div
          v-if="hasMfa && (hasAccessOrders || hasAccessInvoices)"
          key="statuses-blocks"
          class="statuses layout__content"
          :class="{
            'statuses--spacer': !hasAccessOrders && !hasAccessInvoices,
          }"
        >
          <DashboardOrdersBlock v-if="hasAccessOrders" />
          <DashboardInvoicesBlock v-if="hasAccessInvoices" />
        </div>

        <DashboardMultinationalSectionCta
          v-if="$store.getters['auth/memberOf']('multinationals')"
          key="multinational-blocks"
          class="layout__content calltoaction-blocks calltoaction-blocks--multinational"
        />

        <ContentBlocks
          key="dashboardcalltoaction"
          v-if="!isLoading"
          class="layout__content calltoaction-blocks"
          :hasLayoutContent="false"
          :blocks="
            blocks.filter((block) => block.type === 'dashboardcalltoaction')
          "
        />
        <OSkeleton
          v-else
          key="dashboardcalltoaction-loading"
          :animated="true"
          rootClass="is-centered"
          width="50%"
          height="340px"
          class="skeleton--spacing"
        />

        <ContentBlocks
          v-if="!isLoading"
          key="dashboardtext"
          :blocks="blocks.filter((block) => block.type === 'dashboardtext')"
        />
        <OSkeleton
          v-else
          key="dashboardtext-loading"
          :animated="true"
          rootClass="is-centered"
          width="50%"
          height="200px"
        />
      </div>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import Hero from '@/components/AppHero.vue';
import ContentBlocks from '@/components/content/ContentBlocks.vue';
import CmsContentMixin from '@/mixins/CmsContentMixin';
import DashboardOrdersBlock from '@/components/dashboard/DashboardOrdersBlock.vue';
import DashboardInvoicesBlock from '@/components/dashboard/DashboardInvoicesBlock.vue';
import DashboardMultinationalSectionCta from '@/components/dashboard/DashboardMultinationalSectionCta.vue';
import AuthGroup from '@/logic/auth/AuthGroup';
import Onboarding from '@/components/onboarding/TheOnboarding.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LayoutDefault,
    Hero,
    ContentBlocks,
    DashboardOrdersBlock,
    DashboardInvoicesBlock,
    DashboardMultinationalSectionCta,
    Onboarding,
  },
  mixins: [CmsContentMixin],
  computed: {
    multinationalDashboard(): boolean {
      return this.$store.getters['auth/memberOf'](AuthGroup.Multinational);
    },
    hasMfa(): boolean {
      return this.$store.getters['auth/hasMfa'];
    },
    fullName(): string {
      return this.$store.getters['profile/fullName']
        ? ` ${this.$store.getters['profile/fullName']}`
        : '';
    },
    customerName(): string {
      let customerName = ` ${this.$store.getters['profile/getSapCompany']}`;

      if (this.$store.getters['profile/getSapCompany'] === '-') {
        customerName = '';
      }

      return customerName;
    },
    hasAccessOrders(): boolean {
      return this.$store.getters['auth/hasAccess']({
        action: 'GET',
        resource: '/orders',
      });
    },
    hasAccessInvoices(): boolean {
      return this.$store.getters['auth/hasAccess']({
        action: 'GET',
        resource: '/invoices',
      });
    },
  },
  created() {
    this.fetchDashboardContent();
  },
  methods: {
    async fetchDashboardContent() {
      try {
        this.isLoading = true;
        await this.fetchContent();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard--content {
  position: relative;
  margin-bottom: 60px;
  margin-top: -63px;

  :deep(.layout__content) {
    .layout__content {
      padding: 0 13px;
    }

    &:first-child {
      .column:first-child {
        margin-top: 0;
      }
    }
  }

  .calltoaction-blocks:not(&--multinational) {
    :deep(.column:first-child) {
      margin-top: 0;
    }
  }

  @include tablet {
    :deep(.layout__content) {
      max-width: 832px;
      padding: 0 32px;
    }

    .statuses {
      display: flex;
      justify-content: space-between;

      &--spacer {
        margin-top: 16px;
      }
    }

    .calltoaction-blocks {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &--multinational > :deep(div) {
        flex: 0 0 48%;
        overflow: hidden;
      }

      :deep(.block__text) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

<style lang="scss">
.dashboard--content {
  .b-skeleton {
    margin-bottom: 0.5rem;

    & + .b-skeleton {
      margin-top: 0;
    }
  }
}
</style>
