import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-70c08044"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "totals" }
const _hoisted_2 = {
  key: 0,
  class: "totals__label"
}
const _hoisted_3 = { class: "totals__values" }
const _hoisted_4 = {
  key: 0,
  class: "totals__value"
}
const _hoisted_5 = { class: "totals__count" }
const _hoisted_6 = { class: "totals__unit" }
const _hoisted_7 = {
  key: 1,
  class: "totals__value"
}
const _hoisted_8 = { class: "totals__count" }
const _hoisted_9 = { class: "totals__unit" }
const _hoisted_10 = {
  key: 2,
  class: "totals__value"
}
const _hoisted_11 = { class: "totals__count" }
const _hoisted_12 = { class: "totals__unit" }
const _hoisted_13 = {
  key: 3,
  class: "totals__value"
}
const _hoisted_14 = { class: "totals__count" }
const _hoisted_15 = { class: "totals__unit" }
const _hoisted_16 = {
  key: 4,
  class: "totals__value"
}
const _hoisted_17 = { class: "totals__count" }
const _hoisted_18 = { class: "totals__unit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.totals.pal)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.totals.pal), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('orders.order-item-list.totals.total-pallets', _ctx.totals.pal)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.totals.lay)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.totals.lay), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('orders.order-item-list.totals.total-layers', _ctx.totals.lay)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.totals.car)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.totals.car), 1),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('orders.order-item-list.totals.total-cartons', _ctx.totals.car)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.totals.grossWeight && _ctx.showWeight)
        ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.totals.grossWeight) + " " + _toDisplayString(_ctx.$t('orders.order-item-list.totals.kg')), 1),
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('orders.order-item-list.totals.total-gross-weight')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.totals.netWeight && _ctx.showWeight)
        ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.totals.netWeight) + " " + _toDisplayString(_ctx.$t('orders.order-item-list.totals.kg')), 1),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('orders.order-item-list.totals.total-net-weight')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}