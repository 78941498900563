import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step2" }
const _hoisted_2 = { class: "shipping layout__content layout__content--large" }
const _hoisted_3 = { class: "layout__header layout__header--title-only" }
const _hoisted_4 = { class: "shipping__title" }
const _hoisted_5 = { class: "title title--direct" }
const _hoisted_6 = { class: "shipping__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderReferenceField = _resolveComponent("OrderReferenceField")!
  const _component_ShipToField = _resolveComponent("ShipToField")!
  const _component_DeliveryDateField = _resolveComponent("DeliveryDateField")!
  const _component_NewOrderFooter = _resolveComponent("NewOrderFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_OrderReferenceField),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('orders.shipping.title')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ShipToField),
        _createVNode(_component_DeliveryDateField)
      ]),
      _createVNode(_component_NewOrderFooter, {
        "is-invalid": _ctx.isInvalid,
        step: "order-reference-and-shipping"
      }, null, 8, ["is-invalid"])
    ])
  ]))
}