import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { value: "" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OSelect = _resolveComponent("OSelect")!
  const _component_OField = _resolveComponent("OField")!

  return (_openBlock(), _createBlock(_component_OField, { class: "filter" }, {
    default: _withCtx(() => [
      _createVNode(_component_OSelect, {
        "root-class": "select--search",
        icon: "filter",
        expanded: "",
        placeholder: _ctx.placeholder,
        modelValue: _ctx.filterValue,
        "onUpdate:modelValue": _ctx.filter,
        "data-test": "table-filter"
      }, {
        default: _withCtx(() => [
          _createElementVNode("option", _hoisted_1, _toDisplayString(_ctx.allLabel), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: `eq(${_ctx.field},${option})`,
              key: option
            }, _toDisplayString(_ctx.$t(`${_ctx.optionPrefix}.${option}`)), 9, _hoisted_2))
          }), 128))
        ]),
        _: 1
      }, 8, ["placeholder", "modelValue", "onUpdate:modelValue"])
    ]),
    _: 1
  }))
}