<template>
  <div class="restaurant-form">
    <h1 class="title layout__content">
      {{ $t('menuEngineering.overview.addDishEquation') }}
    </h1>

    <form @submit.prevent="submit">
      <div class="layout__content">
        <p class="restaurant-form__intro">
          {{ $t('registration.label.restaurantName') }}
        </p>
        <OField
          :label="$t('registration.placeholder.restaurantName')"
          class="mobile-padding"
        >
          <OInput
            v-model="restaurant.restaurantName"
            :placeholder="$t('registration.placeholder.restaurantName')"
            required
          ></OInput>
        </OField>
        <div class="buttonm-row mobile-padding">
          <OButton
            rounded
            variant="primary"
            native-type="submit"
            :disabled="saving || !restaurant.restaurantName"
            class="button-row__button"
          >
            {{ $t('general.interface.save') }}
          </OButton>
        </div>
        <OLoading :active="saving" :full-page="false"></OLoading>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { Restaurant } from '@/api/model';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [MessageToastMixin],
  data() {
    const restaurant: Restaurant = {
      restaurantName: '',
    };

    return {
      restaurant,
      saving: false,
    };
  },
  methods: {
    async submit() {
      this.saving = true;
      try {
        await this.$store.dispatch(
          'restaurant/saveRestaurant',
          this.restaurant,
        );
        this.saving = false;
      } catch (e) {
        this.saving = false;
        this.showError(this.$t('general.messages.error'));
      }
    },
  },
});
</script>

<style lang="scss">
.restaurant-form {
  &__intro {
    line-height: 1.67;
    margin-bottom: 16px;
  }
}
</style>
