<template>
  <LayoutDefault>
    <div class="category">
      <h1 class="title layout__content">
        {{ $t('menuEngineering.overview.categoryResult.intro') }}
        <span class="category-title">{{
          $t(`menuEngineering.label.${this.category}`).toLowerCase()
        }}</span>
        {{ $t('menuEngineering.overview.categoryResult.outro') }}
      </h1>

      <p v-if="introText" class="intro-text layout__content">{{ introText }}</p>

      <DishList
        :dishes="dishes"
        :course="course"
        :score="score"
        :show-header="true"
        @delete-dish="deleteDish($event)"
        class="dish-list--category"
      />

      <section class="category__blocks">
        <ContentBlocks :blocks="blocks" class="layout__content" />

        <div class="category__button layout__content">
          <OButton
            rounded
            variant="primary"
            tag="router-link"
            to="/menu-engineering/results"
          >
            {{ $t('menuEngineering.results.back-to-results') }}
          </OButton>
        </div>
      </section>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import DishList from '@/components/menu-engineering/DishList.vue';
import { Dish, DishCourseEnum } from '@/api/model';
import { ScoredDish } from '@/logic/menu-engineering/ScoredDish';
import Calculator from '@/logic/menu-engineering/Calculator';
import MenuEngineeringLogic from '@/logic/menu-engineering/MenuEngineeringLogic';
import Score from '@/logic/menu-engineering/Score';
import ContentBlocks from '@/components/content/ContentBlocks.vue';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import CmsContentMixin from '@/mixins/CmsContentMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ContentBlocks, LayoutDefault, DishList },
  mixins: [MessageToastMixin, CmsContentMixin],
  computed: {
    category(): string {
      return this.$route.params.category.toString();
    },
    score(): Score {
      const index = this.category.slice(0, -1).toUpperCase();
      const mapped = index !== 'LAGGARD' ? index : 'LOSER';
      return Score[mapped as keyof typeof Score];
    },
    state(): any {
      return this.$store.state.menuEngineering;
    },
    course(): DishCourseEnum {
      return this.state.activeCourse;
    },
    restaurantId(): string {
      return this.state.activeRestaurantId;
    },
    dishes(): ScoredDish[] {
      const allDishes = Calculator.calculate(this.state.dishes);
      const filteredDishes = MenuEngineeringLogic.filterDishes(
        allDishes,
        this.restaurantId,
        this.course,
      );
      return filteredDishes.filter(
        (dish: ScoredDish) => dish.score === this.score,
      );
    },
  },
  created() {
    this.getDishes();
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    getDishes() {
      this.$store.dispatch('menuEngineering/getDishes');
    },
    async deleteDish(deleteDish: Dish) {
      try {
        await this.$store.dispatch('menuEngineering/deleteDish', deleteDish);
      } catch (e) {
        this.showError(this.$t('menuEngineering.label.dishNotDeleted'));
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.category {
  &__blocks {
    background: rgba(0, 56, 90, 0.03);
    padding: 1px 0 32px 0;

    @include tablet {
      padding: 24px 0 54px 0;
    }
  }

  &__button {
    margin-top: 40px;

    @include mobile {
      text-align: center;
    }
  }
}
</style>
