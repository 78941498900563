/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasePaginationResponse } from '../model';
// @ts-ignore
import { InvoiceOpenAmountsResponse } from '../model';
// @ts-ignore
import { PdfDownloadResponse } from '../model';
/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the download link or the invoice PDF, link will be valid for 5 minutes
         * @summary Get pdf download link
         * @param {string} id Id of the requested invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceDownloadLinkGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceDownloadLinkGet', 'id', id)
            const localVarPath = `/invoice-download-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invoices will be returned for the logged in (Cognito) user
         * @summary Get overview of all invoices
         * @param {number} [perPage] Number of items per page (default&#x3D;25)
         * @param {number} [page] Requested page (default&#x3D;1)
         * @param {string} [filter] Filter invoice fields, parameter may be repeated to add multiple filters.
         * @param {string} [search] Search invoice fields.
         * @param {string} [sort] Fieldname to sort results on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet: async (perPage?: number, page?: number, filter?: string, search?: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invoice amounts will be returned for the logged in (Cognito) user
         * @summary Get overview of total amount of open invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openAmountInvoicesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/open-amount-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the download link or the invoice PDF, link will be valid for 5 minutes
         * @summary Get pdf download link
         * @param {string} id Id of the requested invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceDownloadLinkGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfDownloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceDownloadLinkGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invoices will be returned for the logged in (Cognito) user
         * @summary Get overview of all invoices
         * @param {number} [perPage] Number of items per page (default&#x3D;25)
         * @param {number} [page] Requested page (default&#x3D;1)
         * @param {string} [filter] Filter invoice fields, parameter may be repeated to add multiple filters.
         * @param {string} [search] Search invoice fields.
         * @param {string} [sort] Fieldname to sort results on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesGet(perPage?: number, page?: number, filter?: string, search?: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePaginationResponse & object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesGet(perPage, page, filter, search, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invoice amounts will be returned for the logged in (Cognito) user
         * @summary Get overview of total amount of open invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openAmountInvoicesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceOpenAmountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openAmountInvoicesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * Retrieve the download link or the invoice PDF, link will be valid for 5 minutes
         * @summary Get pdf download link
         * @param {string} id Id of the requested invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceDownloadLinkGet(id: string, options?: any): AxiosPromise<PdfDownloadResponse> {
            return localVarFp.invoiceDownloadLinkGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Invoices will be returned for the logged in (Cognito) user
         * @summary Get overview of all invoices
         * @param {number} [perPage] Number of items per page (default&#x3D;25)
         * @param {number} [page] Requested page (default&#x3D;1)
         * @param {string} [filter] Filter invoice fields, parameter may be repeated to add multiple filters.
         * @param {string} [search] Search invoice fields.
         * @param {string} [sort] Fieldname to sort results on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesGet(perPage?: number, page?: number, filter?: string, search?: string, sort?: string, options?: any): AxiosPromise<BasePaginationResponse & object> {
            return localVarFp.invoicesGet(perPage, page, filter, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Invoice amounts will be returned for the logged in (Cognito) user
         * @summary Get overview of total amount of open invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openAmountInvoicesGet(options?: any): AxiosPromise<InvoiceOpenAmountsResponse> {
            return localVarFp.openAmountInvoicesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * Retrieve the download link or the invoice PDF, link will be valid for 5 minutes
     * @summary Get pdf download link
     * @param {string} id Id of the requested invoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceDownloadLinkGet(id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceDownloadLinkGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invoices will be returned for the logged in (Cognito) user
     * @summary Get overview of all invoices
     * @param {number} [perPage] Number of items per page (default&#x3D;25)
     * @param {number} [page] Requested page (default&#x3D;1)
     * @param {string} [filter] Filter invoice fields, parameter may be repeated to add multiple filters.
     * @param {string} [search] Search invoice fields.
     * @param {string} [sort] Fieldname to sort results on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoicesGet(perPage?: number, page?: number, filter?: string, search?: string, sort?: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoicesGet(perPage, page, filter, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invoice amounts will be returned for the logged in (Cognito) user
     * @summary Get overview of total amount of open invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public openAmountInvoicesGet(options?: any) {
        return InvoiceApiFp(this.configuration).openAmountInvoicesGet(options).then((request) => request(this.axios, this.basePath));
    }
}
