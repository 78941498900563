<template>
  <span class="show-sales-order" v-if="invoice && invoice.SalesOrderId">
    <a class="show-sales-order__link" @click="showSalesOrder">
      <span class="show-sales-order__label">{{ label }}</span>
      <OIcon class="show-sales-order__icon" icon="arrow_back" />
    </a>
    <OModal :active="showSalesOrderModal" trap-focus aria-role="dialog">
      <template #default>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{
                $t('invoices.detail.sales-order.title', {
                  invoice: invoice.BillDoc,
                })
              }}
            </p>
            <button type="button" class="delete" @click="close()" />
          </header>
          <section class="modal-card-body">
            <OrderCard
              v-if="salesOrder"
              :order="salesOrder"
              type="modal"
            ></OrderCard>
          </section>
          <footer class="modal-card-foot">
            <OButton
              :label="$t('invoices.detail.sales-order.order-overview')"
              variant="primary"
              rounded
              outlined
              tag="router-link"
              :to="{ name: 'orders' }"
            />
            <OButton
              :label="$t('invoices.detail.sales-order.close')"
              variant="primary"
              rounded
              @click="close()"
            />
          </footer>
        </div>
      </template>
    </OModal>
  </span>
</template>

<script lang="ts">
import { SapInvoice } from '@/api/model';
import OrderCard from '@/components/orders/OrderCard.vue';
import { defineComponent } from 'vue';
import { SalesOrderAll as SalesOrder } from '@/logic/orders/SalesOrderAll';

export default defineComponent({
  components: { OrderCard },
  data() {
    return {
      showSalesOrderModal: false,
    };
  },
  computed: {
    salesOrder(): SalesOrder | undefined {
      return this.$store.getters['order/findOrderById'](
        this.invoice?.SalesOrderId,
      );
    },
  },
  methods: {
    async showSalesOrder() {
      this.showSalesOrderModal = true;
      try {
        await this.$store.dispatch(
          'order/fetchOrder',
          this.invoice?.SalesOrderId,
        );
      } catch {
        this.showSalesOrderModal = false;
      }
    },
    close() {
      this.showSalesOrderModal = false;
    },
  },
  props: {
    invoice: {
      type: Object as () => SapInvoice,
    },
    label: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.show-sales-order {
  display: flex;
  align-items: center;
  height: 19px;

  &__link {
    color: $prussian-blue-light;
  }

  &__label {
    text-decoration: underline;
  }

  &__icon {
    font-size: 12px;
    transform: rotate(180deg) translateY(1px);
  }
}
</style>
