import { InlineObject2, Profile, ProfileManagement } from '@/api/model';
import { UserManagementApi, ProfileApi } from '@/api';

import getConfiguration from '@/store/getConfiguration';

async function getApi() {
  const configuration = await getConfiguration();
  return new UserManagementApi(configuration, '');
}

interface ProfileManagementArray {
  // eslint-disable-next-line camelcase
  current_page?: number;
  pages?: number;
  // eslint-disable-next-line camelcase
  per_page?: number;
  total?: number;
  items: ProfileManagement[];
}

const initialState = () => ({
  profiles: {
    current_page: 1,
    items: [],
    pages: 0,
    per_page: 10,
    total: 0,
  } as ProfileManagementArray, // all profiles (only for admin)
  query: '',
  filter: '',
  currentPage: 1,
  perPage: 10,
  isLoading: false,
});
export type UserMgtState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: UserMgtState) {
      Object.assign(state, initialState());
    },
    setProfiles(state: UserMgtState, profiles: ProfileManagementArray) {
      state.profiles = profiles;
    },

    toggleActive(state: UserMgtState, options: any) {
      if (state.profiles.items === undefined) {
        return;
      }
      const { clientId, active } = options;
      const profile = state.profiles.items.find((p) => p.clientId === clientId);
      if (profile !== undefined) {
        profile.deletedAt = active ? null : 'deleted';
        state.profiles = { ...state.profiles };
      }
    },

    // For now, this only needs to work for sapId and sapCustomer.
    setProfile(state: UserMgtState, updateProfile: ProfileManagement) {
      if (state.profiles.items === undefined) {
        return;
      }
      const {
        clientId,
        sapId,
        sapCustomer,
        enabledSapCustomers,
        enabledSapCustomerNumbers,
      } = updateProfile;
      const profile = state.profiles.items.find((p) => p.clientId === clientId);
      if (profile !== undefined) {
        profile.sapId = sapId;
        profile.sapCustomer = sapCustomer;
        profile.enabledSapCustomers = enabledSapCustomers;
        profile.enabledSapCustomerNumbers = enabledSapCustomerNumbers;
        state.profiles = { ...state.profiles };
      }
    },

    setCurrentPage(state: UserMgtState, currentPage: number) {
      state.currentPage = currentPage;
    },

    setQuery(state: UserMgtState, query: string) {
      const trimmed = query.trim();
      if (trimmed === state.query) {
        return;
      }

      state.query = trimmed.length >= 3 ? trimmed : '';
      state.currentPage = 1;
    },

    setFilter(state: UserMgtState, filter: string) {
      state.filter = filter;
      state.currentPage = 1;
    },

    setLoading(state: UserMgtState, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },

  actions: {
    async getProfiles(
      { commit, state }: { commit: Function; state: UserMgtState },
      options?: any,
    ) {
      commit('setLoading', true);
      const { perPage, currentPage, disabled, filter, query } = options || {};
      if (currentPage !== undefined) {
        commit('setCurrentPage', currentPage);
      }

      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      const { data } = await api.profilesGet(
        perPage || state.perPage,
        state.currentPage,
        disabled,
        filter || state.filter,
        query || state.query,
      );
      commit('setLoading', false);
      commit('setProfiles', data);
    },

    async search(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      query: string,
    ) {
      commit('setQuery', query);
      dispatch('getProfiles');
    },

    async filter(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      filter: string,
    ) {
      commit('setFilter', filter);
      dispatch('getProfiles');
    },

    // Toggle user disabled
    async toggleActive({ commit }: { commit: Function }, options: any) {
      const { clientId, active } = options;
      if (!clientId) {
        return;
      }
      const api = await getApi();
      if (active) {
        await api.enableUserPost(clientId);
      } else {
        await api.disableUserPost(clientId);
      }
      commit('toggleActive', { clientId, active });
    },

    async resetMfa(_: any, profile: Profile) {
      if (!profile.clientId) {
        return;
      }
      const api = await getApi();
      await api.resetMfaPost(profile.clientId);
    },

    async resetPassword(_: any, profile: Profile) {
      if (!profile.clientId) {
        return;
      }
      const api = await getApi();
      // Reset password gives 502 when email is not verified
      await api.resetPasswordPost(profile.clientId);
    },

    async addUser(_: any, user: InlineObject2) {
      const api = await getApi();
      await api.userPost(user);
    },

    async changeUserGroup(_: any, profile: Profile) {
      if (!profile.clientId) {
        return;
      }
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      await api.profilePut(profile, profile.clientId);
    },

    // Save profile of other user
    async saveProfile(
      { commit }: { commit: Function },
      { profile, id }: { profile: Profile; id: string },
    ) {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      const { data } = await api.profilePut(profile, id);
      commit('setProfile', data);
    },

    async saveSapids(
      { commit }: { commit: Function },
      { sapIds, clientId }: { sapIds: any[]; clientId: string },
    ) {
      const configuration = await getConfiguration();
      const api = new ProfileApi(configuration, '');
      const ids = [...new Set(sapIds.map((sapId) => sapId.updated))];

      await api.profileUpdateEnabledSapCustomersPost(clientId, ids);

      const { data } = await api.profileGet(clientId);
      commit('setProfile', data);
    },
  },
  getters: {
    isLoading: (state: UserMgtState): boolean => state.isLoading,
  },
};
