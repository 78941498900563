// Get the rules for the expected delivery date per country

import { addDays, isSaturday, isSunday } from 'date-fns';

// Number of business days
interface DaysRange {
  [hour: number]: number;
}

interface CountrySetting {
  min: DaysRange;
  max?: number;
}

interface CountrySettings {
  [country: string]: CountrySetting;
}

export default class DeliveryDates {
  static countrySettings: CountrySettings = {
    NL: {
      min: { 0: 1, 10: 2 },
    },
    BE: {
      min: { 0: 1, 10: 2 },
    },
    GB: {
      min: { 0: 4, 14: 5 },
    },
    IE: {
      min: { 0: 4, 12: 4 },
    },
    DE: {
      min: { 0: 2, 12: 3 },
    },
    AT: {
      min: { 0: 3, 12: 4 },
    },
    FI: {
      min: { 0: 4, 12: 5 },
    },
    NO: {
      min: { 0: 4, 12: 5 },
    },
    SE: {
      min: { 0: 4, 12: 5 },
    },
    ES: {
      min: { 0: 4, 12: 5 },
    },
    PT: {
      min: { 0: 4, 12: 5 },
    },
    FR: {
      min: { 0: 2, 12: 3 },
    },
    IT: {
      min: { 0: 4, 12: 5 },
    },
    GR: {
      min: { 0: 5, 12: 6 },
    },
    PL: {
      min: { 0: 5, 12: 6 },
    },
    CZ: {
      min: { 0: 5, 12: 6 },
    },
  };

  static getMin(country?: string): Date {
    let delta = 1;
    const settings = country
      ? DeliveryDates.countrySettings[country]
      : undefined;
    if (settings && settings.min) {
      const range = Object.keys(settings.min).reverse();
      range.some((hour) => {
        const hourNumber = parseInt(hour, 10);
        if (this.getNow().getHours() >= hourNumber) {
          delta = settings.min[hourNumber];
          return true;
        }
        return false;
      });
    }
    const today = DeliveryDates.getNow();
    const target = DeliveryDates.addDays(today, delta);
    // If target falls in weekend, select next Monday
    if (isSaturday(target)) {
      return DeliveryDates.addDays(target, 2);
    }
    if (isSunday(target)) {
      return DeliveryDates.addDays(target, 1);
    }
    return target;
  }

  static getMax(country?: string): Date | undefined {
    let delta;
    const range = country ? DeliveryDates.countrySettings[country] : undefined;
    if (range && range.max !== undefined) {
      delta = range.max;
    }
    if (!delta) {
      return undefined;
    }
    const today = DeliveryDates.getNow();
    return DeliveryDates.addDays(today, delta);
  }

  static addDays(date: Date, delta: number) {
    return addDays(date, delta);
  }

  static getNow(): Date {
    return new Date(Date.now());
  }
}
