<template>
  <div>
    <OSelect :modelValue="modelValue" @update:modelValue="input">
      <option :value="''"></option>
      <option
        v-for="code in $profile.getCountryCodes()"
        :key="code"
        :value="$profile.getCountryNameFromCode(code, 'en-GB')"
      >
        {{ $profile.getCountryNameFromCode(code, $i18n.locale) }}
      </option>
    </OSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  methods: {
    input(value: string) {
      this.$emit('update:modelValue', value);
    },
  },
});
</script>
