import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dc83d9c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-card modal-card--small-content" }
const _hoisted_2 = { class: "modal-card-head" }
const _hoisted_3 = { class: "modal-card-title" }
const _hoisted_4 = { class: "modal-card-body update-dialog" }
const _hoisted_5 = {
  key: 0,
  class: "subtitle update-dialog__subtitle"
}
const _hoisted_6 = {
  key: 1,
  class: "update-dialog__text"
}
const _hoisted_7 = {
  key: 2,
  class: "update-dialog__image"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(`updates-modal.scopes.${_ctx.scope}.title`)), 1)
    ]),
    _createElementVNode("section", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm(`updates-modal.scopes.${_ctx.scope}.content`), (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "update-dialog__paragraph",
          key: index
        }, [
          (item.title)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(item.title), 1))
            : _createCommentVNode("", true),
          (item.content)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.content), 1))
            : _createCommentVNode("", true),
          (item.image)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                _createElementVNode("img", {
                  src: item.image,
                  alt: ""
                }, null, 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("footer", _hoisted_9, [
      _createVNode(_component_OButton, {
        rounded: "",
        variant: "primary",
        class: "button-row__button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('updates-modal.general.close')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}