import { OrderItem1 as OrderItem, SapProduct } from '@/api/model';

export default class Reorder {
  static convertOrderItemsToCartItems(
    products: SapProduct[],
    orderItems?: OrderItem[],
  ): OrderItem[] {
    // Discard all items not given in Cartons
    const cartonItems = orderItems
      ? orderItems.filter((item) => item.SalesUnit === 'CAR')
      : [];

    // Convert cartons to layers / pallets
    const newItems: OrderItem[] = [];
    cartonItems.forEach((item) => {
      this.convertItem(item, products, newItems);
    });

    return newItems;
  }

  static convertItem(
    item: OrderItem,
    products: SapProduct[],
    newItems: OrderItem[],
  ) {
    // Find the product
    const product = products.find((p) => p.ProductNr === item.ProductNr);
    if (!product) {
      return; // Product not available
    }

    const cartons = this.toNumber(item.Quantity);
    let layers = 0;
    let pallets = 0;
    const { AltUnit, BoxesPerLayer, LayersPerPallet, BoxesPerPallet } = product;

    if (AltUnit === 'LAY') {
      const boxesPerLayer = this.toNumber(BoxesPerLayer);
      const layersPerPallet = this.toNumber(LayersPerPallet);

      // Convert cartons to layers (rounded up to whole layer)
      layers = boxesPerLayer !== 0 ? Math.ceil(cartons / boxesPerLayer) : 0;
      // Convert layers to full pallets and keep remaining layers
      pallets =
        layersPerPallet !== 0 ? Math.floor(layers / layersPerPallet) : 0;
      layers %= layersPerPallet;
    } else if (AltUnit === 'PAL') {
      const boxesPerPallet = this.toNumber(BoxesPerPallet);
      pallets = Math.ceil(cartons / boxesPerPallet);
    }

    const baseItem: OrderItem = {
      ProductNr: product.ProductNr,
      ItemText: product.ProductText,
      ShortCode: product.ShortCode,
    };
    if (layers > 0) {
      newItems.push({
        ...baseItem,
        SalesUnit: 'LAY',
        Quantity: `${layers}`,
      });
    }
    if (pallets > 0) {
      newItems.push({
        ...baseItem,
        SalesUnit: 'PAL',
        Quantity: `${pallets}`,
      });
    }
  }

  static toNumber(input?: string | null): number {
    return typeof input === 'string' ? parseInt(input, 10) : 0;
  }
}
