import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-07fcd9ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "restaurant-form" }
const _hoisted_2 = {
  key: 0,
  class: "form-card"
}
const _hoisted_3 = { class: "form-card__header" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "form-card__body" }
const _hoisted_6 = { class: "form-card__details" }
const _hoisted_7 = { class: "form-card__group" }
const _hoisted_8 = { class: "form-card__label" }
const _hoisted_9 = { class: "profile__value" }
const _hoisted_10 = {
  class: "profile__label profile__label--restaurantName",
  "data-test": "field-restaurantName"
}
const _hoisted_11 = { class: "profile__value" }
const _hoisted_12 = { class: "profile__label" }
const _hoisted_13 = { class: "profile__value" }
const _hoisted_14 = { class: "profile__label" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "profile__value" }
const _hoisted_17 = { class: "profile__label" }
const _hoisted_18 = { class: "profile__value" }
const _hoisted_19 = { class: "profile__label" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "form-card__group form-card__group--labeled" }
const _hoisted_22 = { class: "form-card__label" }
const _hoisted_23 = { class: "profile__value" }
const _hoisted_24 = { class: "profile__label" }
const _hoisted_25 = { class: "profile__value" }
const _hoisted_26 = { class: "profile__label" }
const _hoisted_27 = { class: "profile__value" }
const _hoisted_28 = { class: "profile__label" }
const _hoisted_29 = {
  key: 1,
  class: "form-card form-card"
}
const _hoisted_30 = { class: "form-card__header form-card__header--edit" }
const _hoisted_31 = { class: "subtitle" }
const _hoisted_32 = { class: "label" }
const _hoisted_33 = {
  key: 0,
  class: "form-card__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_OLoading = _resolveComponent("OLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.formGroup.active)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('profile.restaurant.title')), 1),
            (
            _ctx.$store.getters['auth/hasAccess']({
              action: 'DELETE',
              resource: 'restaurant',
            }) && _ctx.restaurant
          )
              ? (_openBlock(), _createBlock(_component_OIcon, {
                  key: 0,
                  icon: "delete",
                  class: "form-card__button-icon",
                  alt: _ctx.$t('profile.delete'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteRestaurant(_ctx.restaurant.id)))
                }, null, 8, ["alt"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.$t('profile.restaurant.basic')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantName' })), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.getDisplayValue({
                    name: 'restaurantType',
                    type: 'restaurant-type',
                  })), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, [
                    (_ctx.restaurant && _ctx.restaurant.restaurantSeats)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantSeats' })) + " " + _toDisplayString(_ctx.$t('profile.restaurant.seats')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantTelephone' })), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, [
                    _createElementVNode("a", {
                      href: _ctx.restaurant && _ctx.restaurant.restaurantWebsite,
                      class: "form-card__link",
                      target: "_blank"
                    }, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantWebsite' })), 9, _hoisted_20)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("h4", _hoisted_22, _toDisplayString(_ctx.$t('profile.restaurant.address')), 1),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantStreet' })) + " " + _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantNumber' })), 1)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantPostalcode' })) + " " + _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantCity' })), 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.getDisplayValue({ name: 'restaurantCountry' })), 1)
                ])
              ])
            ]),
            _createVNode(_component_OIcon, {
              icon: "edit",
              class: "form-card__button-icon",
              alt: _ctx.$t('profile.edit'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit('restaurant')))
            }, null, 8, ["alt"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("h2", _hoisted_31, _toDisplayString(_ctx.$t('profile.restaurant.title')), 1),
            _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('profile.restaurant.edit')), 1)
          ]),
          (_ctx.restaurant)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createVNode(_component_FormGroup, {
                  name: "restaurant",
                  onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel())),
                  onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save())),
                  onSetField: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setField($event.name, $event.value))),
                  group: _ctx.formGroup,
                  profile: _ctx.restaurant
                }, null, 8, ["group", "profile"]),
                _createVNode(_component_OLoading, {
                  active: _ctx.saving,
                  "full-page": false
                }, null, 8, ["active"])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}