import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content-page" }
const _hoisted_2 = {
  key: 2,
  class: "title layout__content"
}
const _hoisted_3 = {
  key: 4,
  class: "intro-text layout__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!
  const _component_ContentBlocks = _resolveComponent("ContentBlocks")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ((_ctx.headerTitle || _ctx.headerImage) && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_Hero, {
              key: 0,
              title: _ctx.headerTitle,
              image: _ctx.headerImage,
              pageName: _ctx.pageName
            }, null, 8, ["title", "image", "pageName"]))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_OSkeleton, {
              key: 1,
              animated: true,
              height: "300px"
            }))
          : _createCommentVNode("", true),
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_OSkeleton, {
              key: 3,
              animated: true,
              width: "50%",
              "root-class": "is-centered",
              height: "60px"
            }))
          : _createCommentVNode("", true),
        (_ctx.introText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.introText), 1))
          : _createCommentVNode("", true),
        (!_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ContentBlocks, {
              key: 5,
              blocks: _ctx.blocks,
              "has-background-color": _ctx.hasBackgroundColor
            }, null, 8, ["blocks", "has-background-color"]))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_OSkeleton, {
              key: 6,
              animated: true,
              width: "50%",
              height: "400px",
              "root-class": "is-centered",
              count: 6
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}