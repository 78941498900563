import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from '@/views/TheDashboard.vue';
import Authentication from '@/views/TheAuthentication.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import MenuEngineeringAddDish from '@/views/menu-engineering/MenuEngineeringAddDish.vue';
import MenuEngineeringEditDish from '@/views/menu-engineering/MenuEngineeringEditDish.vue';
import GoogleTagManagerLogic from '@/logic/google-tag-manager/GoogleTagManagerLogic';
import ContentPage from '@/views/content/ContentPage.vue';
import MenuEngineeringResults from '@/views/menu-engineering/MenuEngineeringResults.vue';
import MenuEngineeringCategory from '@/views/menu-engineering/MenuEngineeringCategory.vue';
import MenuEngineeringWelcome from '@/views/menu-engineering/try-out/MenuEngineeringWelcome.vue';
import MenuEngineeringWelcomeResult from '@/views/menu-engineering/try-out/MenuEngineeringWelcomeResult.vue';
import MyProfile from '@/views/profile/MyProfile.vue';
import MyOrders from '@/views/orders/MyOrders.vue';
import NewOrder from '@/views/orders/NewOrder.vue';
import NewClaim from '@/views/claims/NewClaim.vue';
import AuthGroup from '@/logic/auth/AuthGroup';
import store from '@/store';
import TrackingService from '@/logic/profile/TrackingService';
import MyInvoices from '@/views/MyInvoices.vue';

const genericMultinationalPages = [
  'lwm-company-information',
  'trend-information',
  'sustainability',
];
const multinationalRoutes: RouteRecordRaw[] = [
  'lwm-company-information',
  'partnership',
  'new-products', // innovations & trends
  'approved-products', // portfolio
  'trend-information',
  'market-activations',
  'sustainability',
  'best-practices',
  'training',
  'lwm-contact',
].map(
  (name: string): RouteRecordRaw => ({
    name,
    path: `/${name}`,
    component: () =>
      import(
        /* webpackChunkName: "multinational" */ '@/views/content/MultinationalPage.vue'
      ),
    meta: {
      requiresGroup: AuthGroup.Multinational,
      generic: genericMultinationalPages.includes(name),
    },
  }),
);

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'authentication',
    component: Authentication as any,
    meta: { requiresGroup: AuthGroup.None },
  },

  {
    path: '/password-reset',
    name: 'passwordreset',
    component: Authentication as any,
    meta: { requiresGroup: AuthGroup.None },
  },

  // Administrators
  {
    path: '/user-management',
    name: 'user-management',
    component: () =>
      import(
        /* webpackChunkName: "user-mgt" */ '@/views/user-management/UserManagement.vue'
      ),
    meta: { requiresAccess: { action: 'GET', resource: '/profiles' } },
  },
  {
    path: '/permission-mgt',
    name: 'permission-mgt',
    component: () =>
      import(
        /* webpackChunkName: "permission-mgt" */ '@/views/permission-management/PermissionManagement.vue'
      ),
    meta: { requiresAccess: { action: 'PUT', resource: '/role' } },
  },

  // General, logged in
  {
    path: '/',
    name: 'home',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard as any,
    meta: { requiresGroup: AuthGroup.Any },
  },
  {
    path: '/eat-this',
    name: 'eat-this',
    component: ContentPage as any,
    meta: { requiresGroup: AuthGroup.Any },
  },

  // Direct customer
  {
    path: '/orders',
    name: 'orders',
    component: MyOrders as any,
    meta: { requiresAccess: { action: 'GET', resource: '/orders' } },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: MyInvoices as any,
    meta: { requiresAccess: { action: 'GET', resource: '/invoices' } },
  },
  {
    path: '/orders/new',
    name: 'new-order',
    redirect: { name: 'new-order-steps', params: { step: 'select-products' } },
  },
  {
    path: '/orders/new/:step',
    name: 'new-order-steps',
    component: NewOrder as any,
    meta: { requiresAccess: { action: 'POST', resource: '/new-order' } },
  },
  {
    path: '/product-information',
    name: 'product-information',
    component: () =>
      import(
        /* webpackChunkName: "direct" */ '@/views/product-information/ProductInformation.vue'
      ),
    meta: { requiresAccess: { action: 'GET', resource: '/products' } },
  },
  {
    path: '/product-information/:productId',
    name: 'product-information-detail',
    component: () =>
      import(
        /* webpackChunkName: "direct" */ '@/views/product-information/AppProductInformationDetail.vue'
      ),
    meta: {
      requiresAccess: {
        action: 'GET',
        resource: '/product-information-details',
      },
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: () =>
      import(/* webpackChunkName: "direct" */ '@/views/MyContracts.vue'),
    meta: { requiresAccess: { action: 'GET', resource: '/contracts' } },
  },
  {
    path: '/container-calculation',
    name: 'container-calculation',
    redirect: {
      name: 'container-calculation-steps',
      params: { step: 'select-products' },
    },
  },
  {
    path: '/container-calculation/:step',
    name: 'container-calculation-steps',
    component: () =>
      import(
        /* webpackChunkName: "container" */ '@/views/container-calculation/ContainerCalculation.vue'
      ),
    meta: {
      requiresAccess: { action: 'POST', resource: '/calculate-container' },
    },
  },

  // Menu engineering
  {
    path: '/menu-engineering/add-dish',
    component: MenuEngineeringAddDish as any,
    meta: { requiresAccess: { action: 'PUT', resource: '/dish' } },
  },
  {
    path: '/menu-engineering/edit-dish/:dishId',
    component: MenuEngineeringEditDish as any,
    meta: { requiresAccess: { action: 'PUT', resource: '/dish' } },
  },
  {
    path: '/menu-engineering/results',
    component: MenuEngineeringResults as any,
    meta: { requiresAccess: { action: 'GET', resource: '/dishes' } },
  },
  {
    path: '/menu-engineering/category/:category',
    component: MenuEngineeringCategory as any,
    meta: { requiresAccess: { action: 'GET', resource: '/dishes' } },
  },
  {
    path: '/menu-engineering/calculate-your-dishes',
    component: MenuEngineeringWelcome as any,
    meta: { requiresGroup: AuthGroup.None },
  },
  {
    path: '/menu-engineering/result/:category',
    component: MenuEngineeringWelcomeResult as any,
    meta: { requiresGroup: AuthGroup.None },
  },

  {
    path: '/e-learning',
    name: 'e-learning',
    component: () =>
      import(
        /* webpackChunkName: "e-learning" */ '@/views/ELearningOverviewPage.vue'
      ),
    meta: {
      requiresAccess: {
        action: 'GET',
        // Locale is not known at time of routing, so {locale} is later
        // replaced in the access checker itself
        resource: '/content/customer_portal/{locale}/e-learning',
      },
    },
  },
  {
    path: '/e-learning/:courseRoute(.*)',
    name: 'e-learning-course',
    component: () =>
      import(
        /* webpackChunkName: "e-learning" */ '@/views/ELearningCoursePage.vue'
      ),
    meta: {
      requiresAccess: {
        action: 'GET',
        resource: '/content/customer_portal/{locale}/e-learning',
      },
    },
  },
  // End-users
  {
    path: '/agreements',
    name: 'agreements',
    component: () =>
      import(/* webpackChunkName: "end-user" */ '@/views/MyAgreements.vue'),
    meta: { requiresAccess: { action: 'GET', resource: '/agreements' } },
  },
  {
    path: '/claims',
    name: 'claims',
    component: () =>
      import(/* webpackChunkName: "end-user" */ '@/views/MyClaims.vue'),
    meta: { requiresAccess: { action: 'GET', resource: '/claims' } },
  },
  {
    path: '/claims/new',
    name: 'new-claims-select-agreement',
    props: {
      filterAvailable: true,
    },
    component: () =>
      import(/* webpackChunkName: "end-user" */ '@/views/MyAgreements.vue'),
    meta: { requiresAccess: { action: 'POST', resource: '/claims' } },
  },
  {
    path: '/claims/new/:agreementId',
    name: 'new-claim',
    redirect: (to) => ({
      name: 'new-claims-steps',
      params: { step: 'claim-details', agreementId: to.params.agreementId },
    }),
  },
  {
    path: '/claims/new/:agreementId/:step',
    name: 'new-claims-steps',
    component: NewClaim as any,
    meta: { requiresAccess: { action: 'POST', resource: '/claims' } },
  },

  {
    path: '/settings/profile',
    name: 'profile',
    component: MyProfile as any,
    meta: { requiresAccess: { action: 'GET', resource: '/profile' } },
  },

  // Multinationals
  ...multinationalRoutes,
  {
    path: '/approved-products/:catchAll(.*)',
    name: 'approved-product',
    component: () =>
      import(
        /* webpackChunkName: "multinational" */ '@/views/content/MultinationalPage.vue'
      ),
    meta: {
      requiresGroup: AuthGroup.Multinational,
    },
  },
  {
    path: '/new-products/:catchAll(.*)',
    name: 'new-product',
    component: () =>
      import(
        /* webpackChunkName: "multinational" */ '@/views/content/MultinationalPage.vue'
      ),
    meta: {
      requiresGroup: AuthGroup.Multinational,
    },
  },

  // General public
  {
    path: '/privacy',
    component: ContentPage as any,
    meta: { requiresGroup: AuthGroup.None },
  },
  {
    path: '/terms-and-conditions',
    component: ContentPage as any,
    meta: { requiresGroup: AuthGroup.None },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: ErrorPage as any,
    meta: { requiresGroup: AuthGroup.None },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeResolve(async (to, from, next) => {
  TrackingService.storeUtmParameters(to);
  if (
    to.matched.some(
      (record) =>
        record.meta.requiresGroup !== AuthGroup.None ||
        record.meta.requiresAccess !== undefined,
    )
  ) {
    try {
      await store.dispatch('auth/getUser');
      // @ts-ignore
      const profile = { ...store.state.profile.profile };
      if (Object.keys(profile).length === 0) {
        await store.dispatch('profile/getProfile');
      }
      if (
        !store.getters['auth/hasAccess'](
          to.meta?.requiresAccess,
          to.meta?.requiresGroup,
        )
      ) {
        throw new Error('Unauthenticated');
      }
      next();
      return;
    } catch (e) {
      next({
        path: '/auth',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }
  }
  next();
});

router.afterEach(async (to) => {
  GoogleTagManagerLogic.pushEvent('viewpage', to.fullPath);
});

export default router;
