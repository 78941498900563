import {
  BasePaginationResponse,
  ClaimApi,
  OrderItem,
  SalesQuote,
  SalesQuoteClaim,
  SalesQuoteItem,
  SapProduct,
  SapProductUnit,
} from '@/api';
import getConfiguration from '@/store/getConfiguration';
import axios from 'axios';
import { ProductGroup } from '@/store/OrderStore';
import { addMonths, format, subDays } from 'date-fns';
import { ClaimTotals } from '@/types/ClaimTotals';

export type BaseClaim = {
  wholesaleParty: string;
  period: Date[];
  creditReference: string;
  items: OrderItem[];
  agreementId: string;
  uploadedProof: File[];
};

async function getApi() {
  const configuration = await getConfiguration();
  return new ClaimApi(configuration, '');
}

async function getUploadUrl(
  filename: string,
  // eslint-disable-next-line camelcase
  sales_quote_sap_id: string,
): Promise<any> {
  const api = await getApi();
  const { data } = await api.claimsCreateAttachmentPost({
    filename,
    sales_quote_sap_id,
  });
  return data;
}

async function uploadAttachment(
  file: File,
  SalesQuoteSapId: string,
): Promise<string> {
  const url = await getUploadUrl(file.name, SalesQuoteSapId);
  const formData = new FormData();
  Object.keys(url.fields).forEach((key) => {
    formData.append(key, url.fields[key]);
  });
  formData.append('file', file);
  await axios.post(url.url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return url.fields.key;
}

function findAgreementItem(
  product: SapProduct,
  agreement: SalesQuote,
): SalesQuoteItem {
  return agreement?.items?.find(
    (item: SalesQuoteItem) => item.sap_product_id === product.Id,
  ) as SalesQuoteItem;
}

const initialClaim = (): BaseClaim => ({
  wholesaleParty: '',
  period: [],
  creditReference: '',
  items: [] as OrderItem[],
  agreementId: '',
  uploadedProof: [] as File[],
});

const initialState = () => ({
  claims: {
    current_page: 1,
    items: [] as SalesQuoteClaim[],
    pages: 0,
    per_page: 13,
    total: 0,
    claim_totals: {},
  } as BasePaginationResponse,
  claimData: { ...initialClaim() },
  query: '',
  filter: '',
  currentPage: 1,
  perPage: 13,
  sort: 'submission_date+desc',
  isLoading: false,
  // Claim products state
  products: {
    closedGroups: new Set(),
    filterGroups: new Set(),
    searchQuery: '',
  },
});
export type ClaimState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: ClaimState) {
      Object.assign(state, initialState());
    },

    resetCart(state: ClaimState) {
      state.claimData = { ...initialClaim() };
    },

    setClaims(state: ClaimState, claims: BasePaginationResponse) {
      state.claims = claims;
    },

    setCurrentPage(state: ClaimState, currentPage: number) {
      state.currentPage = currentPage;
    },

    setQuery(state: ClaimState, query: string) {
      const trimmed = query.trim();
      if (trimmed === state.query) {
        return;
      }

      state.query = trimmed.length >= 3 ? trimmed : '';
      state.currentPage = 1;
    },

    setFilter(state: ClaimState, filter: string) {
      state.filter = filter;
      state.currentPage = 1;
    },

    setSort(state: ClaimState, sort: string) {
      state.sort = sort;
    },

    setLoading(state: ClaimState, isLoading: boolean) {
      state.isLoading = isLoading;
    },

    setAgreementId(state: ClaimState, id: string) {
      state.claimData.agreementId = id;
    },

    setNewClaimWholesaleParty(state: ClaimState, wholesaleParty: string) {
      state.claimData.wholesaleParty = wholesaleParty;
    },

    setNewClaimPeriod(state: ClaimState, period: Date[]) {
      state.claimData.period = period;
    },

    setNewClaimCreditReference(state: ClaimState, creditReference: string) {
      state.claimData.creditReference = creditReference;
    },

    setNewClaimUploadedProof(state: ClaimState, uploadedProof: File[]) {
      state.claimData.uploadedProof = [
        ...state.claimData.uploadedProof,
        ...uploadedProof,
      ];
    },

    setRemoveClaimUploadedProof(state: ClaimState, fileToRemove: File) {
      state.claimData.uploadedProof = state.claimData.uploadedProof.filter(
        (file) => file.name !== fileToRemove.name,
      );
    },

    setCartItemQuantity(state: ClaimState, options: any) {
      const { product, quantity, unit } = options;
      if (!product.ProductNr || !unit) {
        return;
      }

      const roundedQuantity = quantity ? parseInt(quantity, 10) : 0;

      const item: OrderItem = {
        ProductNr: product.ProductNr,
        Quantity: `${roundedQuantity}`,
        SalesUnit: unit,
        ItemText: product.ProductText,
        ShortCode: product.ShortCode,
      };

      const shouldDelete = roundedQuantity === 0;

      // Check if product already exists in this order items
      if (!state.claimData.items) {
        state.claimData.items = [];
      }
      const index = state.claimData.items.findIndex(
        (i: OrderItem) =>
          i.ProductNr === item.ProductNr && i.SalesUnit === item.SalesUnit,
      );
      if (index !== -1) {
        if (shouldDelete) {
          state.claimData.items.splice(index, 1);
        } else {
          state.claimData.items[index] = item;
        }
      } else if (!shouldDelete) {
        state.claimData.items.push(item);
      }
      state.claimData.items = [...state.claimData.items];
      state.claimData = { ...state.claimData };
    },

    toggleActiveGroup(state: ClaimState, options: any) {
      const { id, active } = options;
      if (active) {
        state.products.closedGroups.delete(id);
      } else {
        state.products.closedGroups.add(id);
      }
      state.products.closedGroups = new Set(state.products.closedGroups);
    },

    openAllProductGroups(state: ClaimState) {
      state.products.closedGroups = new Set();
    },

    setProductSearchQuery(state: ClaimState, query: string) {
      const trimmed = query.trim();
      state.products.searchQuery = trimmed.length >= 3 ? trimmed : '';
    },

    toggleFilterGroup(state: ClaimState, { group }: any) {
      if (!state.products.filterGroups.has(group)) {
        state.products.filterGroups.add(group);
        state.products.closedGroups.delete(group);
        state.products.closedGroups = new Set(state.products.closedGroups);
      } else {
        state.products.filterGroups.delete(group);
      }
      state.products.filterGroups = new Set(state.products.filterGroups);
    },
  },

  actions: {
    async fetchClaims(
      { commit, state }: { commit: Function; state: ClaimState },
      options?: any,
    ) {
      commit('setLoading', true);
      const { perPage, currentPage, filter, query, sort } = options || {};
      if (currentPage !== undefined) {
        commit('setCurrentPage', currentPage);
      }

      const api = await getApi();
      const { data } = await api.claimsGet(
        perPage || state.perPage,
        state.currentPage,
        filter || state.filter,
        query || state.query,
        sort || state.sort,
      );

      commit('setLoading', false);
      commit('setClaims', data);
    },

    async search(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      query: string,
    ) {
      commit('setQuery', query);
      dispatch('fetchClaims');
    },

    async filter(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      filter: string,
    ) {
      commit('setFilter', filter);
      dispatch('fetchClaims');
    },

    async sort(
      { commit, dispatch }: { commit: Function; dispatch: Function },
      sort: string,
    ) {
      commit('setSort', sort);
      dispatch('fetchClaims');
    },

    async postClaim({
      commit,
      state,
      getters,
    }: {
      commit: Function;
      state: any;
      getters: any;
    }) {
      const formatDate = (d: Date): string => format(d, 'yyyy-MM-dd');
      // Add month and subtract a day
      const claimPeriodTo = subDays(addMonths(state.claimData.period[1], 1), 1);
      // Create post object from the agreement, combined with the claim data
      const claim: SalesQuote = {
        ...getters.agreement,
        wholesale_party: state.claimData.wholesaleParty,
        claim_period_from: formatDate(state.claimData.period[0]),
        claim_period_to: formatDate(claimPeriodTo),
        credit_reference: state.claimData.creditReference,
      };

      // Convert the cart items to "SalesQuoteItem" items
      claim.items = claim.items?.map((item: SalesQuoteItem) => {
        const orderItem: OrderItem = getters.getCartItem(item.sap_product);
        const cartItem = { ...item };
        if (orderItem) {
          if (orderItem.SalesUnit === 'BAG') {
            cartItem.bags = parseInt(orderItem.Quantity || '0', 10);
          } else if (orderItem.SalesUnit === 'CAR') {
            cartItem.boxes = parseInt(orderItem.Quantity || '0', 10);
          }
        }
        return cartItem;
      });

      try {
        claim.attachments = await Promise.all(
          state.claimData.uploadedProof.map(async (upload: File) =>
            uploadAttachment(upload, claim.sales_quote_sap_id as string),
          ),
        );
      } catch (e) {
        console.error(e);
      }

      const api = await getApi();
      await api.claimsPost(claim);
      commit('resetCart');
    },

    productSearch({ commit }: { commit: Function }, query: string) {
      commit('setProductSearchQuery', query);
      if (query) {
        commit('openAllProductGroups');
      }
    },
  },

  getters: {
    isLoading: (state: ClaimState): boolean => state.isLoading,

    getCartItem:
      (state: ClaimState) =>
      (product: SapProduct, unit?: string): OrderItem | undefined => {
        if (!state.claimData.items) {
          return undefined;
        }

        return state.claimData.items.find(
          (i: OrderItem) =>
            i.ProductNr === product.ProductNr &&
            (!unit || i.SalesUnit === unit),
        );
      },

    getItemCalculation:
      (state: ClaimState, getters: any) =>
      (product: SapProduct): ClaimTotals => {
        const { agreement } = getters;
        const emptyResult = {
          volume: 0,
          billbackAmount: 0,
        };

        if (!product) {
          return emptyResult;
        }

        // Get the item from the cart
        const item = getters.getCartItem(product);

        if (item) {
          const quantity = parseInt(item.Quantity, 10);
          const salesUnit = item.SalesUnit;

          // Find the net weight for the unit
          const selectedUnit = product.Units?.find(
            (u: SapProductUnit) => u.UnitOfMeasure === salesUnit,
          );
          if (selectedUnit?.NetWeight) {
            const volume = quantity * parseFloat(selectedUnit.NetWeight);
            const agreementItem = findAgreementItem(product, agreement);
            const rate = parseFloat(agreementItem?.billback_rate || '0');
            const billbackAmount = rate * volume;
            return {
              volume,
              billbackAmount,
            };
          }
        }

        return emptyResult;
      },

    calculateClaimTotals: (state: ClaimState, getters: any): ClaimTotals => {
      const { agreement } = getters;
      const totals = {
        volume: 0,
        billbackAmount: 0,
      };
      // eslint-disable-next-line no-unused-expressions
      agreement?.items?.forEach((item: SalesQuoteItem) => {
        const itemTotals = getters.getItemCalculation(item.sap_product);
        totals.volume += itemTotals.volume;
        totals.billbackAmount += itemTotals.billbackAmount;
      });
      return totals;
    },

    agreement: (
      state: ClaimState,
      getters: any,
      rootState: any,
      rootGetters: any,
    ): SalesQuote | undefined =>
      rootGetters['agreement/findAgreementById'](state.claimData.agreementId),

    // claim products

    agreementProducts: (state: ClaimState, getters: any): SapProduct[] =>
      getters.agreement?.items?.map((i: any) => i.sap_product as SapProduct) ||
      [],

    agreementProductGroups:
      (state: ClaimState, getters: any) =>
      (search = false): ProductGroup[] => {
        const sortByIdOrLabel = (a: SapProduct, b: SapProduct): number => {
          if (a.Index && a.Label && b.Index && b.Label) {
            if (a.Index === b.Index) {
              return a.Label >= b.Label ? 1 : -1;
            }
            return a.Index > b.Index ? 1 : -1;
          }
          return 0;
        };
        const groupBy = (items: any[], key: string) =>
          items.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
              currentValue,
            );
            return result;
          }, {});
        const products = search
          ? getters.productSearchResult
          : getters.agreementProducts;
        return groupBy(products.sort(sortByIdOrLabel), 'Label');
      },

    isActiveGroup:
      (state: ClaimState) =>
      ({ group }: any): boolean =>
        state.products.closedGroups
          ? !state.products.closedGroups.has(group)
          : true,

    isFilterGroup:
      (state: ClaimState) =>
      (groupLabel: string): boolean =>
        state.products.filterGroups.has(groupLabel),

    productSearchResult: (state: ClaimState, getters: any): SapProduct[] => {
      if (!state.products.searchQuery) {
        return getters.agreementProducts;
      }
      function escapeRegex(string: string) {
        return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      }
      const query = new RegExp(escapeRegex(state.products.searchQuery), 'i');
      const fields = [
        'ProductNr',
        'ShortCode',
        'ProductText',
        'HierarchyLabel',
      ];
      return getters.agreementProducts.filter((product: SapProduct) => {
        if (product === undefined) {
          return false;
        }
        return fields.some((field) => {
          const p: any = product;
          return p[field] && p[field].search(query) !== -1;
        });
      });
    },
  },
};
