import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a6cba52c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "authenticator-custom" }
const _hoisted_2 = { class: "amplify-heading amplify-heading--3" }
const _hoisted_3 = { class: "authenticator-custom__forgot-your-password" }
const _hoisted_4 = { class: "authenticator-custom__sign-up-in-link" }
const _hoisted_5 = { class: "amplify-heading amplify-heading--3" }
const _hoisted_6 = { class: "authenticator-custom__sign-up-in-link" }
const _hoisted_7 = { class: "amplify-heading amplify-heading--3" }
const _hoisted_8 = { class: "amplify-heading amplify-heading--3" }
const _hoisted_9 = { class: "amplify-text authentication__mfa-code-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OCheckbox = _resolveComponent("OCheckbox")!
  const _component_Authenticator = _resolveComponent("Authenticator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Authenticator, {
      formFields: _ctx.formFields,
      "login-mechanisms": ['email']
    }, {
      "sign-in-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('amplify.Sign in to your account')), 1)
      ]),
      "sign-in-footer": _withCtx(() => [
        _createElementVNode("span", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t('amplify.Forgot your password? ')) + " ", 1),
          _createElementVNode("a", {
            "data-test": "sign-in-forgot-password-link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toForgotPassword()))
          }, _toDisplayString(_ctx.$t('amplify.Reset password')), 1)
        ]),
        _createElementVNode("span", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('amplify.No account? ')) + " ", 1),
          _createElementVNode("a", {
            "data-test": "sign-in-create-account-link",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toSignUp()))
          }, _toDisplayString(_ctx.$t('amplify.Create account')), 1)
        ])
      ]),
      "sign-up-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('amplify.Create a new account')), 1)
      ]),
      "sign-up-footer": _withCtx(() => [
        _createElementVNode("span", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.$t('amplify.Have an account? ')) + " ", 1),
          _createElementVNode("a", {
            "data-test": "sign-in-link",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toSignIn()))
          }, _toDisplayString(_ctx.$t('amplify.Sign in')), 1)
        ])
      ]),
      "forgot-password-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('amplify.Reset your password')), 1)
      ]),
      "confirm-sign-in-header": _withCtx(() => [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('amplify.Confirm Sign In')), 1),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('amplify.Apply code from MFA app')), 1)
      ]),
      "confirm-sign-in-footer": _withCtx(() => [
        _createVNode(_component_OCheckbox, {
          modelValue: _ctx.rememberDevice,
          "onUpdate:modelValue": _ctx.toggleRememberDevice,
          "root-class": "amplify-text authenticator-custom__checkbox"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('amplify.Remember this device')), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "onUpdate:modelValue"])
      ]),
      _: 1
    }, 8, ["formFields"])
  ]))
}