<template>
  <transition name="fade">
    <div class="dish-list layout__content" v-if="dishes.length">
      <div class="dish-list__arrow" v-if="!showHeader">
        <OIcon icon="chevron_up" />
      </div>

      <CategoryHeader v-if="showHeader" :score="score" />
      <h2
        v-if="showTitle && restaurantName"
        class="dish-list__title layout__content layout__content--small subtitle"
      >
        {{ $t('menuEngineering.label.course.' + course) }}
        {{ $t('general.for') }}
        {{ restaurantName }}
        ({{ dishes.length }})
      </h2>

      <transition-group name="list" tag="div" class="dish-list__list">
        <div v-for="dish in dishes" :key="dish.id" class="dish list-item">
          <span class="dish__name">{{ dish.name }}</span>
          <span class="dish__actions">
            <RouterLink
              :to="`/menu-engineering/edit-dish/${dish.id}`"
              class="dish__action dish__action--edit action-button"
            >
              <template #default>
                <span>
                  <OIcon class="action-button__icon" icon="edit" />
                </span>
              </template>
            </RouterLink>
            <span
              v-if="
                $store.getters['auth/hasAccess']({
                  action: 'DELETE',
                  resource: '/dish',
                })
              "
              class="dish__action dish__action--delete action-button"
              @click="deleteDish(dish)"
            >
              <OIcon class="action-button__icon" icon="delete" />
            </span>
          </span>
        </div>
      </transition-group>
    </div>
  </transition>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { Dish } from '@/api/model/dish';
import CategoryHeader from '@/components/menu-engineering/CategoryHeader.vue';
import Score from '@/logic/menu-engineering/Score';

export default defineComponent({
  components: { CategoryHeader },
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
    course: {
      type: String,
      default: '',
    },
    restaurantName: {
      type: String,
      default: '',
    },
    dishes: {
      type: Array as () => Dish[],
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    showDishCount: {
      type: Boolean,
      default: false,
    },
    score: {
      type: String as () => Score,
      default: '',
    },
  },
  methods: {
    deleteDish(dish: Dish) {
      this.$emit('deleteDish', dish);
    },
  },
});
</script>

<style lang="scss" scoped>
$arrow-size: 70px;

.dish-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @include tablet {
    margin-top: 80px - $arrow-size;
    margin-bottom: 80px;
  }

  &--category {
    @include mobile {
      margin-top: 10px;
    }

    .dish {
      max-width: 783px;
    }
  }

  &__arrow {
    display: none;

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $arrow-size;
      height: $arrow-size;
      border-radius: 100%;
      font-size: 16px;
      background: $primary;
      color: $white;
      transform: translateY(-80px + 0.5 * $arrow-size) scaleY(-1);
    }
  }

  &__title {
    width: 100%;
  }

  &__list {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dish {
  display: flex;
  max-width: 687px;
  width: 100%;
  background: $wild-sand;
  margin-bottom: 4px;
  padding: 20px;
  border-radius: 4px;

  &__name {
    flex: 1;
    font-weight: bold;
    color: $primary;
  }

  &__actions {
    display: flex;
  }
}

.action-button {
  background: $primary;
  color: $white;
  width: 30px;
  height: 30px;
  margin-left: 8px;
  border-radius: 100%;
  font-size: 12px;
  cursor: pointer;

  &__icon {
    width: 100%;
    height: 100%;
  }
}
</style>
