<template>
  <div class="block block--textimage" v-if="block">
    <div
      v-if="block.text"
      v-html="$helpers.parseRichText(block.text)"
      class="block__text rich-text"
    />
    <template v-if="block.images.length > 0">
      <img
        v-for="image in block.images"
        :key="image.id"
        :src="$helpers.getApiImageThumbnail(image)"
        class="block__image"
        :alt="image.title"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
<style lang="scss">
.block__image {
  margin-top: 20px;
}
</style>
