<template>
  <section
    class="status-pill status-pill__border"
    :class="`status-pill__border--${statusColor}`"
  >
    <header
      class="status-pill__header status-pill__value"
      :class="`status-pill__value--${statusColor}`"
      v-if="$slots.header"
    >
      <OIcon
        v-if="icon"
        :icon="icon"
        :class="`status-pill__icon status-pill__icon--${icon}`"
      />
      <div class="status-pill__header-content vag-rounded">
        <slot name="header" />
      </div>
    </header>
    <main class="status-pill__body" v-if="$slots.main">
      <slot name="main" />
    </main>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
    },
    statusColor: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.status-pill {
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 5px;
  min-height: 30px;
  min-width: 105px;

  &__header {
    padding: 2px 8px;
    flex: 1 1 auto;
    min-height: 30px;
    display: flex;
    align-items: center;
  }

  &__icon {
    &--clipboard {
      width: 1em;
      margin-right: 8px;
    }
  }

  &__body {
    background-color: $alabaster;
    padding: 2px 15px;
    flex: 1 1 auto;
    min-height: 30px;
    line-height: 30px;
  }

  &__border {
    border: 1px solid $black;
    border-radius: 6px;
    overflow: hidden;
  }

  // Empty comments are to avoid the code-fixer to put everything on one line.
  $colors:
    //
    'received' $primary $white,
    //
    'cancelled' $monza $white,
    //
    'accepted' $buddha-gold $white,
    //
    'processing' $selective-yellow $black,
    //
    'processing-part' $selective-yellow $black,
    //
    'shipped' $lime $black,
    //
    'shipped-part' $lime $black,
    //
    'delivered' $green-approx $white,
    //
    'unknown' $swamp $white;

  @each $name, $background, $foreground in $colors {
    &__border--#{$name} {
      border-color: $background;
    }

    &__value--#{$name} {
      background-color: $background;
      color: $foreground;

      :deep(strong) {
        color: $foreground;
      }
    }
  }
}
</style>
