import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-447e945c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content has-text-centered" }
const _hoisted_2 = { class: "field-upload__placeholder uniek" }
const _hoisted_3 = ["horizontal"]
const _hoisted_4 = ["onClose"]
const _hoisted_5 = { class: "field-upload__file-name" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OUpload = _resolveComponent("OUpload")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.$attrs.name,
    rules: _ctx.$attrs.rules
  }, {
    default: _withCtx(({ errors, validate, field }) => [
      _createVNode(_component_OField, {
        label: _ctx.$attrs.label,
        message: errors.length ? errors[0] : '',
        class: _normalizeClass([_ctx.classNames(errors), "field-upload"]),
        variant: errors.length ? 'danger' : '',
        horizontal: _ctx.$attrs.horizontal
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OUpload, {
            name: field.name,
            modelValue: field.value,
            "onUpdate:modelValue": _ctx.onInput,
            disabled: _ctx.$attrs.disabled,
            expanded: "",
            "icon-pack": _ctx.$attrs.iconPack,
            multiple: "",
            "drag-drop": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$attrs.placeholder), 1)
              ])
            ]),
            _: 2
          }, 1032, ["name", "modelValue", "onUpdate:modelValue", "disabled", "icon-pack"])
        ]),
        _: 2
      }, 1032, ["label", "message", "class", "variant", "horizontal"]),
      (_ctx.$attrs.modelValue.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "field-upload__files-list",
            horizontal: _ctx.$attrs.horizontal
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$attrs.modelValue, (file, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "field-upload__file",
                key: index,
                onClose: ($event: any) => (_ctx.removeFile(index, validate))
              }, [
                _createVNode(_component_OIcon, {
                  icon: "attachment",
                  class: "field-upload__icon"
                }),
                _createElementVNode("span", _hoisted_5, _toDisplayString(file.name), 1),
                _createElementVNode("button", {
                  class: "field-upload__remove-button",
                  onClick: ($event: any) => (_ctx.removeFile(index, validate))
                }, _toDisplayString(_ctx.$t('general.interface.remove')), 9, _hoisted_6)
              ], 40, _hoisted_4))
            }), 128))
          ], 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "rules"]))
}