<template>
  <LayoutDefault>
    <div class="menu-engineering">
      <h1 class="title layout__content">
        Edit <span>{{ dish.name }}</span>
      </h1>
      <DishForm
        v-model="dish"
        :restaurants="restaurants"
        :show-results-button="false"
        @submit="save"
        :saving="saving"
      />
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import { Dish, Restaurant } from '@/api/model';
import DishForm from '@/components/menu-engineering/DishForm.vue';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { LayoutDefault, DishForm },
  mixins: [MessageToastMixin],
  data() {
    const dish: Dish = {};

    return {
      dish,
      saving: false,
    };
  },
  computed: {
    state(): any {
      return this.$store.state.menuEngineering;
    },
    dishes(): Dish[] {
      return this.state.dishes;
    },
    restaurants(): Restaurant[] {
      return this.$store.state.restaurant.restaurants;
    },
  },
  created() {
    const editDish = this.dishes.find(
      (dish) => dish.id === this.$route.params.dishId,
    );
    this.dish = { ...editDish };
  },
  methods: {
    async save() {
      this.saving = true;
      try {
        await this.$store.dispatch('menuEngineering/saveDish', this.dish);
        this.saving = false;
        await this.$router.push('/menu-engineering/add-dish');
      } catch (e) {
        this.saving = false;
        this.showError(this.$t('general.messages.error'));
      }
    },
  },
});
</script>
