<template>
  <section class="hero" :class="'hero--' + pageName">
    <div class="hero__column hero__column--text">
      <h1 v-if="title" class="hero__title">{{ title }}</h1>
      <h2 v-if="subtitle" class="hero__title hero__title--subtitle">
        {{ subtitle }}
      </h2>
    </div>
    <div class="hero__column hero__column--image-container">
      <div :class="{ 'hero__image-mask': hasImageMask }">
        <div :style="imageStyle" class="hero__image" />
      </div>
    </div>
    <div
      class="hero__column hero__column--foreground-image"
      v-if="pageName === 'eat-this'"
    >
      <img
        class="foreground-image"
        src="/img/eat-this.svg"
        :alt="$t('navigation.eat-this')"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { SuluMedia } from '@/logic/cms/sulu';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    const $helpers: any = undefined;

    return {
      $helpers,
    };
  },
  computed: {
    hasImageMask(): boolean {
      if (!this.mask) {
        return false;
      }
      const pageWithoutMask = [
        'dashboard',
        'e-learning',
        'eat-this',
        'multinational',
      ];
      return !pageWithoutMask.includes(this.pageName);
    },
    imageUrl(): string | object {
      if (!this.image) {
        return '';
      }

      if (typeof this.image === 'string') {
        return this.image;
      }

      return this.$helpers.getApiImageThumbnail(this.image);
    },
    imagePosition(): string {
      if (!this.image) {
        return '';
      }

      return this.$helpers.getApiImageFocuspoint(this.image);
    },
    imageStyle(): string {
      const imagePosition = `background-position: ${this.imagePosition};`;

      if (this.gradient) {
        return `${imagePosition} background-image: linear-gradient(108.19deg, #003764 12.06%, rgba(0, 55, 100, 0.36) 94.52%),url('${this.imageUrl}')`;
      }

      return `${imagePosition} background-image: url('${this.imageUrl}')`;
    },
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    image: { type: [String, Object as () => SuluMedia] },
    pageName: {
      type: String,
      default: '',
    },
    mask: { type: Boolean, default: true },
    gradient: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.hero {
  background: $primary;
  color: $white;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;

  @include until($desktop) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @include desktop {
    height: 300px;
  }

  &__column {
    width: 100%;

    &--text {
      max-width: 453px;
      padding: $gap ($gap * 0.5);
      z-index: 1;

      @include tablet {
        padding: $gap;
      }

      @include desktop {
        max-width: 552px;
        width: 45%;
        margin-right: 0;
        margin-bottom: 41px;
      }
    }

    &--image-container {
      height: 230px;
      overflow: hidden;

      @include desktop {
        height: 100%;
        width: 55%;
      }
    }

    &--foreground-image {
      bottom: 0;
      left: 50%;
      max-width: 798px;
      padding-left: 4px;
      position: absolute;
      transform: translate(-50%, 19px);

      @include tablet {
        padding-left: 0;
      }

      .foreground-image {
        max-width: 40%;
      }
    }
  }

  &__title {
    color: $white;
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;

    @include desktop {
      font-size: 48px;
      line-height: 60px;
    }

    &--subtitle {
      margin-top: 12px;
      font-size: 18px;
      line-height: 26px;

      @include desktop {
        margin-top: 37px;
        font-size: 24px;
        font-weight: 100;
        line-height: 32px;
      }
    }
  }

  &__image-mask {
    height: 100%;
    margin: -40px -40px 0px 0px;
    overflow: hidden;
    position: relative;
    transform: skewY(-4deg);

    @include desktop {
      margin: 0 -27px 0px 27px;
      transform: skew(-4deg);
    }

    .hero__image {
      top: 40px;
      transform: skewY(4deg);

      @include desktop {
        top: 0;
        left: -29px;
        transform: skew(4deg);
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: url('~/public/img/hero-image-landingpage-menuengineering.jpg')
      center center;
    background-size: cover;
  }

  &--dashboard,
  &--e-learning,
  &--product-information-detail {
    align-items: center;
    height: 394px;
    justify-content: center;

    @include desktop {
      height: 400px;
      justify-content: inherit;
    }

    .hero__column--text {
      margin: 0 auto;
      max-width: 832px;
      width: 100%;
      z-index: 1;
    }

    .hero__column--image-container {
      width: 0;
      height: 0;
    }

    .hero__image {
      background-position: right;

      @include tablet {
        background-position: center;
      }
    }

    .hero__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 140%;

      @include tablet {
        font-size: 60px;
        line-height: 68px;
      }

      &--subtitle {
        font-size: 24px;
        font-weight: 300;
        line-height: 140%;

        @include desktop {
          font-size: 40px;
          line-height: 68px;
          margin: 0;
        }
      }
    }
  }

  &--e-learning,
  &--product-information-detail {
    height: 286px;

    @include desktop {
      height: 400px;
    }
  }
}
</style>
