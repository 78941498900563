/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Restaurant
 */
export interface Restaurant {
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    deletedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantPostalcode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    restaurantSeats?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantStreet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantTelephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantType?: RestaurantRestaurantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantTypeOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    restaurantWebsite?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum RestaurantRestaurantTypeEnum {
    Pub = 'pub',
    Bar = 'bar',
    HamburgerRestaurant = 'hamburger Restaurant',
    FishBurger = 'fish Burger',
    Steakhouse = 'steakhouse',
    Bistro = 'bistro',
    PizzaRestaurant = 'pizza restaurant',
    GrillRestaurant = 'grill restaurant',
    AmericanDiner = 'american diner',
    GourmetRestaurant = 'gourmet restaurant',
    SeafoodRestaurant = 'seafood restaurant',
    SnackBar = 'snack bar',
    MountainHuts = '(mountain) huts',
    CampSites = 'camp sites',
    BeachResorts = 'beach resorts',
    ChickenRestaurant = 'chicken restaurant',
    FastFood = 'fast food',
    Other = 'other'
}



