/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SapContractReturn } from '../model';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * SapContract will be returned for the logged in (Cognito) user
         * @summary Get overview of all SapContract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * SapContract will be returned for the logged in (Cognito) user
         * @summary Get overview of all SapContract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SapContractReturn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * SapContract will be returned for the logged in (Cognito) user
         * @summary Get overview of all SapContract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsGet(options?: any): AxiosPromise<Array<SapContractReturn>> {
            return localVarFp.contractsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * SapContract will be returned for the logged in (Cognito) user
     * @summary Get overview of all SapContract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractsGet(options?: any) {
        return ContractApiFp(this.configuration).contractsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
