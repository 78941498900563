<template>
  <div class="restaurant-form">
    <div class="form-card" v-if="!formGroup.active">
      <div class="form-card__header">
        <h2 class="subtitle">{{ $t('profile.restaurant.title') }}</h2>
        <OIcon
          v-if="
            $store.getters['auth/hasAccess']({
              action: 'DELETE',
              resource: 'restaurant',
            }) && restaurant
          "
          icon="delete"
          class="form-card__button-icon"
          :alt="$t('profile.delete')"
          @click="deleteRestaurant(restaurant.id)"
        />
      </div>
      <div class="form-card__body">
        <div class="form-card__details">
          <div class="form-card__group">
            <h4 class="form-card__label">
              {{ $t('profile.restaurant.basic') }}
            </h4>
            <div class="profile__value">
              <label
                class="profile__label profile__label--restaurantName"
                data-test="field-restaurantName"
              >
                {{ getDisplayValue({ name: 'restaurantName' }) }}
              </label>
            </div>
            <div class="profile__value">
              <label class="profile__label">
                {{
                  getDisplayValue({
                    name: 'restaurantType',
                    type: 'restaurant-type',
                  })
                }}
              </label>
            </div>
            <div class="profile__value">
              <label class="profile__label">
                <span v-if="restaurant && restaurant.restaurantSeats">
                  {{ getDisplayValue({ name: 'restaurantSeats' }) }}
                  {{ $t('profile.restaurant.seats') }}
                </span>
              </label>
            </div>
            <div class="profile__value">
              <label class="profile__label">
                {{ getDisplayValue({ name: 'restaurantTelephone' }) }}
              </label>
            </div>
            <div class="profile__value">
              <label class="profile__label">
                <a
                  :href="restaurant && restaurant.restaurantWebsite"
                  class="form-card__link"
                  target="_blank"
                >
                  {{ getDisplayValue({ name: 'restaurantWebsite' }) }}
                </a>
              </label>
            </div>
          </div>

          <div class="form-card__group form-card__group--labeled">
            <h4 class="form-card__label">
              {{ $t('profile.restaurant.address') }}
            </h4>
            <div class="profile__value">
              <label class="profile__label">
                {{ getDisplayValue({ name: 'restaurantStreet' }) }}
                {{ getDisplayValue({ name: 'restaurantNumber' }) }}
              </label>
            </div>
            <div class="profile__value">
              <label class="profile__label">
                {{ getDisplayValue({ name: 'restaurantPostalcode' }) }}
                {{ getDisplayValue({ name: 'restaurantCity' }) }}
              </label>
            </div>
            <div class="profile__value">
              <label class="profile__label">
                {{ getDisplayValue({ name: 'restaurantCountry' }) }}
              </label>
            </div>
          </div>
        </div>
        <OIcon
          icon="edit"
          class="form-card__button-icon"
          :alt="$t('profile.edit')"
          @click="edit('restaurant')"
        />
      </div>
    </div>
    <div class="form-card form-card" v-else>
      <div class="form-card__header form-card__header--edit">
        <h2 class="subtitle">{{ $t('profile.restaurant.title') }}</h2>
        <span class="label">{{ $t('profile.restaurant.edit') }}</span>
      </div>
      <div v-if="restaurant" class="form-card__body">
        <FormGroup
          name="restaurant"
          @cancel="cancel()"
          @submit="save()"
          @setField="setField($event.name, $event.value)"
          :group="formGroup"
          :profile="restaurant"
        />
        <OLoading :active="saving" :full-page="false" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Restaurant } from '@/api/model';
import FormGroup from '@/components/profile/FormGroup.vue';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import { defineComponent } from 'vue';
import BaseDialog from '@/components/base/BaseDialog.vue';

export default defineComponent({
  components: { FormGroup },
  mixins: [MessageToastMixin],
  data() {
    const formGroup: any = {
      active: false,
      fields: [
        { name: 'restaurantName' },
        { name: 'restaurantType', type: 'restaurant-type' },
        { name: 'restaurantSeats' },
        { name: 'restaurantTelephone' },
        { name: 'restaurantWebsite' },
        { name: 'restaurantStreet' },
        { name: 'restaurantNumber' },
        { name: 'restaurantPostalcode' },
        { name: 'restaurantCity' },
        { name: 'restaurantCountry', type: 'country' },
      ],
    };

    return {
      formGroup,
      saving: false,
    };
  },
  created() {
    if (this.initActive) {
      this.edit();
    }
  },
  methods: {
    deleteRestaurant(id: string) {
      this.$oruga.modal.open({
        component: BaseDialog,
        props: {
          title: this.$t('profile.restaurant.delete.title') as string,
          message: this.$t('profile.restaurant.delete.message') as string,
          confirmText: this.$t('profile.restaurant.delete.confirm') as string,
          icon: 'delete',
          cancelText: this.$t('profile.label.cancel') as string,
          onConfirm: () => {
            this.$store.dispatch('restaurant/deleteRestaurant', id);
            this.showSuccess(this.$t('profile.restaurant.delete.success'));
          },
        },
        trapFocus: true,
      });
    },
    edit() {
      this.$store.commit(
        'restaurant/setActiveRestaurantId',
        this.restaurant?.id,
      );
      this.formGroup.active = true;
    },
    cancel() {
      if (this.restaurant?.id === undefined) {
        this.$store.commit('restaurant/deleteRestaurant');
      }
      this.formGroup.active = false;
    },
    setField(fieldName: string, value: string) {
      const field = this.formGroup.fields.find(
        (f: any) => f.name === fieldName,
      );
      field.value = value;
    },
    async save(): Promise<void> {
      this.saving = true;
      try {
        const newFields: any = {};
        this.formGroup.fields.forEach((f: any) => {
          if (f.value !== undefined) {
            newFields[f.name] = f.value;
          }
        });
        const newRestaurant = Object.assign(
          this.restaurant as Restaurant,
          newFields,
        );
        await this.$store.dispatch('restaurant/saveRestaurant', newRestaurant);
        this.showSuccess(this.$t('profile.restaurant.save-success'));
        this.formGroup.active = false;
      } catch (e) {
        this.showError(this.$t('general.messages.error'));
      }
      this.saving = false;
    },
    getDisplayValue(field: any): string {
      const fieldName: any = field.name;
      // @ts-ignore
      const value: string = this.restaurant[fieldName];
      if (field.type === 'restaurant-type' && value) {
        return this.$t(`profile.restaurant.type.${value}`) as string;
      }
      return value;
    },
    activeChanged() {
      if (this.active === false) {
        this.cancel();
      }
    },
  },
  props: {
    restaurant: {
      type: Object as () => Restaurant,
    },
    initActive: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
  },
  watch: {
    active: [
      {
        handler: 'activeChanged',
      },
    ],
  },
});
</script>

<style lang="scss" scoped>
:deep(.restaurantCountry),
:deep(.restaurantType) {
  width: 100%;
}
</style>
