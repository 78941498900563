import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b1f5c0b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table__details order-details" }
const _hoisted_2 = {
  key: 2,
  class: "order-details__subheader"
}
const _hoisted_3 = {
  key: 0,
  class: "order-details__bol"
}
const _hoisted_4 = { class: "order-details__container-etas" }
const _hoisted_5 = {
  key: 0,
  class: "order-details__eta order-details__eta--req"
}
const _hoisted_6 = {
  key: 1,
  class: "order-details__eta order-details__eta--booked"
}
const _hoisted_7 = {
  key: 3,
  class: "order-details__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderItemList = _resolveComponent("OrderItemList")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OrderContainerList = _resolveComponent("OrderContainerList")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading && _ctx.order)
      ? (_openBlock(), _createBlock(_component_OrderItemList, {
          key: 0,
          items: _ctx.order.OrderItems,
          "sort-by-group": true,
          striped: false
        }, null, 8, ["items"]))
      : (_openBlock(), _createBlock(_component_OSkeleton, {
          key: 1,
          height: 150
        })),
    (
        _ctx.order.BillOfLading ||
        (_ctx.order.OrderContainers && _ctx.order.OrderContainers.length) ||
        _ctx.$store.getters['profile/isContainer']
      )
      ? (_openBlock(), _createElementBlock("footer", _hoisted_2, [
          (_ctx.order.BillOfLading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_OIcon, {
                  class: "order-details__icon order-details__icon--bol",
                  icon: "text-document"
                }),
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t('orders.card.header.bill-of-lading')), 1),
                _createTextVNode(_toDisplayString(_ctx.order.BillOfLading), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.order.OrderContainers && _ctx.order.OrderContainers.length)
            ? (_openBlock(), _createBlock(_component_OrderContainerList, {
                key: 1,
                containers: _ctx.order.OrderContainers,
                class: "order-details__containers"
              }, null, 8, ["containers"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_OIcon, {
              class: "order-details__icon",
              icon: "container_ship"
            }),
            (_ctx.order.ReqDlvDate)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('orders.overview.header.eta-requested')) + ": " + _toDisplayString(_ctx.Helpers.formatSapDate(_ctx.order.ReqDlvDate)), 1))
              : _createCommentVNode("", true),
            (_ctx.order.OriginalEta)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createVNode(_component_OIcon, {
                    class: "order-details__chevron",
                    icon: "chevron_right"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('orders.overview.header.eta-booked')) + ": " + _toDisplayString(_ctx.Helpers.formatEtaDate(_ctx.order.OriginalEta)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.UpdatedEta)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 2,
                  class: _normalizeClass(["order-details__eta order-details__eta--actual", _ctx.etaActualClass])
                }, [
                  _createVNode(_component_OIcon, {
                    class: "order-details__chevron",
                    icon: "chevron_right"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('orders.overview.header.eta-actual')) + ": " + _toDisplayString(_ctx.Helpers.formatEtaDate(_ctx.order.UpdatedEta)), 1)
                ], 2))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.$store.getters['auth/hasAccess']({
          action: 'POST',
          resource: '/new-order',
        })
      )
      ? (_openBlock(), _createElementBlock("footer", _hoisted_7, [
          _createVNode(_component_OButton, {
            class: "order-details__reorder",
            rounded: "",
            outlined: "",
            variant: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reOrder())),
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('orders.card.footer.order-again')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}