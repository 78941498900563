import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["value-cell", { 'not-available': !_ctx.hasValue }])
  }, [
    (_ctx.hasValue)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$slots.default)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.value), 1))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.$t('general.table.not-available')), 1)
        ], 64))
  ], 2))
}