<template>
  <section class="restaurants layout__content">
    <div v-for="(restaurant, index) in restaurants" :key="index">
      <RestaurantForm
        :restaurant="restaurant"
        :init-active="!restaurant.id"
        :active="isActive(restaurant.id)"
      />
    </div>

    <div
      class="form-card"
      v-if="
        $store.getters['auth/hasAccess']({
          action: 'PUT',
          resource: '/restaurant',
        })
      "
    >
      <div class="form-card__body">
        <OButton
          rounded
          outlined
          variant="primary"
          @click="addRestaurant()"
          data-test="add-restaurant"
        >
          {{ $t('profile.restaurant.add') }}
        </OButton>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Restaurant } from '@/api/model';
import RestaurantForm from '@/components/profile/RestaurantForm.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { RestaurantForm },
  computed: {
    state() {
      return this.$store.state.restaurant;
    },
    restaurants(): Restaurant[] {
      return this.state.restaurants;
    },
  },
  created() {
    this.$store.dispatch('restaurant/getRestaurants');
  },
  methods: {
    addRestaurant() {
      const restaurant: Restaurant = {};
      this.$store.commit('restaurant/updateRestaurant', restaurant);
    },
    isActive(restaurantId: string): boolean {
      return this.state.activeRestaurantId === restaurantId;
    },
  },
});
</script>

<style lang="scss" scoped>
.restaurants {
  .form-card__body {
    display: flex;
    justify-content: center;
    padding: 24px;
    border-radius: 8px;

    @include desktop {
      padding: 40px;
    }
  }
}
</style>
