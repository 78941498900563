/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SapCustomer1
 */
export interface SapCustomer1 {
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    City?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    ContainerLoadBase?: SapCustomer1ContainerLoadBaseEnum;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    CountryKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    CustName1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    CustName2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    CustomerNr?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SapCustomer1
     */
    IsContainerCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SapCustomer1
     */
    Language?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SapCustomer1ContainerLoadBaseEnum {
    Pallet = 'PALLET',
    Sheet = 'SHEET'
}



