import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vue_tel_input, {
      modelValue: _ctx.phone,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
        _ctx.input
      ],
      onValidate: _ctx.validate,
      mode: "international",
      preferredCountries: ['de', 'gb', 'es', 'fr', 'it', 'nl'],
      "input-options": { placeholder: _ctx.$t('profile.label.phone'), required: _ctx.required },
      "style-classes": { input: true, 'is-danger': !_ctx.valid },
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onValidate", "input-options", "style-classes", "disabled"])
  ]))
}