import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardCallToActionBlock = _resolveComponent("DashboardCallToActionBlock")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.hasAccess({ action: 'GET', resource: _ctx.multinationalSlug(block) }))
          ? (_openBlock(), _createElementBlock("div", {
              key: block.link
            }, [
              _createVNode(_component_DashboardCallToActionBlock, { block: block }, null, 8, ["block"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}