<template>
  <div class="footer-bar">
    <div class="footer-bar__content">
      <div class="footer-bar__copyright">
        {{ $t('footer.copyright', { year }) }}
      </div>
      <router-link
        v-for="link in navigation"
        :key="link.to"
        :to="link.to"
        :target="link.target || '_self'"
        class="footer-bar__nav-link"
      >
        {{ $t(link.label) }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    const navigation: any[] = [
      {
        to: '/terms-and-conditions',
        label: 'footer.navigation.terms-and-conditions',
      },
      {
        to: '/privacy',
        label: 'footer.navigation.privacy',
      },
      {
        to: '/settings/profile',
        label: 'footer.navigation.profile',
      },
    ];

    return {
      navigation,
    };
  },
  computed: {
    year(): number {
      const now = new Date(Date.now());
      return now.getFullYear();
    },
  },
});
</script>

<style lang="scss" scoped>
.footer-bar {
  background: $concrete;
  padding: 24px 0 42px;

  @include tablet {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 40px;
  }

  &__content {
    color: $prussian-blue-light;
    font-size: 14px;
    padding: 0 16px 23px;

    @include tablet {
      padding: 0;
      display: flex;
    }
  }

  &__copyright {
    font-weight: bold;
  }

  &__nav-link {
    display: block;
    color: $prussian-blue-light;
    margin-top: 24px;

    &:hover {
      text-decoration: underline;
    }

    @include tablet {
      margin-top: 0;
      margin-left: 40px;
    }
  }
}
</style>
