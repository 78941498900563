<template>
  <div class="table__details order-details">
    <OrderItemList
      v-if="!loading && order"
      :items="order.OrderItems"
      :sort-by-group="true"
      :striped="false"
    />
    <OSkeleton v-else :height="150" />

    <footer
      class="order-details__subheader"
      v-if="
        order.BillOfLading ||
        (order.OrderContainers && order.OrderContainers.length) ||
        $store.getters['profile/isContainer']
      "
    >
      <div v-if="order.BillOfLading" class="order-details__bol">
        <OIcon
          class="order-details__icon order-details__icon--bol"
          icon="text-document"
        />
        <strong>{{ $t('orders.card.header.bill-of-lading') }} </strong
        >{{ order.BillOfLading }}
      </div>
      <OrderContainerList
        v-if="order.OrderContainers && order.OrderContainers.length"
        :containers="order.OrderContainers"
        class="order-details__containers"
      />
      <div class="order-details__container-etas">
        <OIcon class="order-details__icon" icon="container_ship" />
        <span
          class="order-details__eta order-details__eta--req"
          v-if="order.ReqDlvDate"
        >
          {{ $t('orders.overview.header.eta-requested') }}:
          {{ Helpers.formatSapDate(order.ReqDlvDate) }}
        </span>
        <span
          class="order-details__eta order-details__eta--booked"
          v-if="order.OriginalEta"
        >
          <OIcon class="order-details__chevron" icon="chevron_right" />
          {{ $t('orders.overview.header.eta-booked') }}:
          {{ Helpers.formatEtaDate(order.OriginalEta) }}
        </span>
        <span
          class="order-details__eta order-details__eta--actual"
          :class="etaActualClass"
          v-if="order.UpdatedEta"
        >
          <OIcon class="order-details__chevron" icon="chevron_right" />
          {{ $t('orders.overview.header.eta-actual') }}:
          {{ Helpers.formatEtaDate(order.UpdatedEta) }}
        </span>
      </div>
    </footer>

    <footer
      class="order-details__footer"
      v-if="
        $store.getters['auth/hasAccess']({
          action: 'POST',
          resource: '/new-order',
        })
      "
    >
      <OButton
        class="order-details__reorder"
        rounded
        outlined
        variant="primary"
        @click="reOrder()"
        :disabled="loading"
      >
        {{ $t('orders.card.footer.order-again') }}
      </OButton>
    </footer>
  </div>
</template>

<script lang="ts">
import Helpers from '@/logic/orders/helpers';
import OrderItemList from '@/components/orders/OrderItemList.vue';
import OrderContainerList from '@/components/orders/OrderContainerList.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { OrderItemList, OrderContainerList },
  data() {
    return {
      loading: false,
      Helpers,
    };
  },
  computed: {
    etaActualClass(): any {
      if (!this.order.OriginalEta || !this.order.UpdatedEta) {
        return {};
      }
      return {
        'order-details__eta--early':
          this.order.OriginalEta > this.order.UpdatedEta,
        'order-details__eta--late':
          this.order.OriginalEta < this.order.UpdatedEta,
      };
    },
  },
  async created() {
    if (this.order.Id && !this.order.OrderItems) {
      try {
        this.loading = true;
        await this.$store.dispatch('order/fetchOrder', this.order.Id);
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    async reOrder() {
      this.loading = true;
      try {
        await this.$store.dispatch('order/reOrder', this.order);
        this.$router.push({ name: 'new-order' });
      } finally {
        this.loading = false;
      }
    },
  },
  props: {
    order: { type: Object, default: () => {} },
  },
});
</script>

<style lang="scss" scoped>
.order-details {
  @include tablet {
    padding: 5px 32px 32px 32px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $heather;
    padding-top: 24px;
  }

  &__reorder.button.is-outlined {
    background-color: $white;
  }

  &__subheader {
    font-size: 14px;
    border-top: 1px solid $heather;
    padding-top: 16px;
  }

  &__container-etas {
    border-block: 1px solid $heather;
    margin-block: 16px;
    padding-block: 16px;
    display: flex;
    flex-direction: column;
    position: relative;

    @include mobile {
      padding-left: 27px;

      .order-details__icon {
        position: absolute;
        left: 0;
        top: 12px;
      }
    }

    @include tablet {
      flex-direction: row;
      align-items: center;
    }
  }

  &__container &__icon {
    font-size: 20px;

    &--bol {
      font-size: 16px;
    }
  }

  &__chevron {
    font-size: 7px;
    height: 14px;
    width: 14px;
  }

  &__eta {
    display: flex;
    align-items: center;

    &--req {
      color: $gray-steel;
    }

    &--early {
      font-weight: bold;
      color: $primary;
    }

    &--late {
      font-weight: bold;
      color: $trinidad;
    }
  }
}
</style>
