<template>
  <div class="block">
    <div
      v-for="(columns, index) in block.double_columns"
      :key="index"
      class="column"
    >
      <div
        v-for="(blocks, indexBlocks) in columns.column"
        :key="indexBlocks"
        class="column__item"
      >
        <component
          v-if="blocks.type === 'textimage'"
          :is="blocks.type + 'Block'"
          :block="blocks"
        ></component>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TextimageBlock from './TextimageBlock.vue';

export default defineComponent({
  components: {
    textimageBlock: TextimageBlock,
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
.block {
  .column {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include tablet {
      flex-direction: row;
    }

    &__item {
      flex-basis: 50%;
    }
  }
}
</style>
