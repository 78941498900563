/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SapCustomerLookup } from '../model';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List customer details
         * @param {string} customerNr customer_nr of the customer to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapCustomerLookupGet: async (customerNr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNr' is not null or undefined
            assertParamExists('sapCustomerLookupGet', 'customerNr', customerNr)
            const localVarPath = `/sap-customer-lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (customerNr !== undefined) {
                localVarQueryParameter['customer_nr'] = customerNr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List customer details
         * @param {string} customerNr customer_nr of the customer to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sapCustomerLookupGet(customerNr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SapCustomerLookup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sapCustomerLookupGet(customerNr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary List customer details
         * @param {string} customerNr customer_nr of the customer to obtain details for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sapCustomerLookupGet(customerNr: string, options?: any): AxiosPromise<SapCustomerLookup> {
            return localVarFp.sapCustomerLookupGet(customerNr, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary List customer details
     * @param {string} customerNr customer_nr of the customer to obtain details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public sapCustomerLookupGet(customerNr: string, options?: any) {
        return CustomerApiFp(this.configuration).sapCustomerLookupGet(customerNr, options).then((request) => request(this.axios, this.basePath));
    }
}
