<template>
  <span
    v-if="language"
    class="language-label"
    :class="{ 'language-label--show-label': !!label }"
  >
    <img
      :src="`/img/icons/country/${language.flag}.svg`"
      :alt="language.flag"
      class="language-label__flag"
    />
    <span v-if="label">{{ label }}</span>
    {{ language.label }}
  </span>
</template>

<script lang="ts">
import { Languages, LocaleOption } from '@/logic/locale/Languages';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    language(): LocaleOption | undefined {
      return Languages.getLanguageById(this.locale);
    },
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.language-label {
  &__flag {
    position: relative;
    top: 2px;
    margin-right: 12px;
  }
}
</style>
