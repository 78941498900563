import { RestaurantApi } from '@/api';
import { Restaurant } from '@/api/model';
import getConfiguration from '@/store/getConfiguration';

const initialState = () => ({
  restaurants: [] as Restaurant[],
  activeRestaurantId: '',
});
export type RestaurantState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: RestaurantState) {
      Object.assign(state, initialState());
    },

    setRestaurants(state: RestaurantState, restaurants: Restaurant[]) {
      state.restaurants = restaurants;
    },

    updateRestaurant(state: RestaurantState, updateRestaurant: Restaurant) {
      const index = state.restaurants.findIndex(
        (restaurant: Restaurant) => restaurant.id === updateRestaurant.id,
      );
      if (index !== -1) {
        state.restaurants[index] = updateRestaurant;
      } else {
        if (updateRestaurant.id) {
          // Prune restaurants without id
          state.restaurants = state.restaurants.filter(
            (r: Restaurant) => !!r.id,
          );
        }
        state.restaurants.push(updateRestaurant);
      }
    },

    deleteRestaurant(state: RestaurantState, restaurantId?: string) {
      if (restaurantId === undefined) {
        state.restaurants = state.restaurants.filter((r: Restaurant) => !!r.id);
        return;
      }
      state.restaurants = state.restaurants.filter(
        (restaurant: Restaurant) => restaurant.id !== restaurantId,
      );
    },

    setActiveRestaurantId(state: RestaurantState, restaurantId: string) {
      state.activeRestaurantId = restaurantId;
    },
  },

  actions: {
    async getRestaurants({ commit }: { commit: Function }) {
      const configuration = await getConfiguration();
      const api = new RestaurantApi(configuration, '');
      const { data } = await api.restaurantsGet();
      commit('setRestaurants', data);
    },

    async saveRestaurant(
      { commit }: { commit: Function },
      restaurant: Restaurant,
    ) {
      const configuration = await getConfiguration();
      const api = new RestaurantApi(configuration, '');
      const { data } = await api.restaurantPut(restaurant);
      commit('updateRestaurant', data);
      commit('setActiveRestaurantId', data.id);
    },

    async deleteRestaurant(
      { commit }: { commit: Function },
      restaurantId: string,
    ) {
      const configuration = await getConfiguration();
      const api = new RestaurantApi(configuration, '');
      await api.restaurantDelete(restaurantId);
      commit('deleteRestaurant', restaurantId);
    },
  },
};
