<template>
  <div class="filter" v-on-click-outside="onClickOutside">
    <div
      class="toggle-button toggle-button--expanded toggle-button--select"
      @click="toggle(!active)"
      role="button"
      data-test="filter-toggle"
    >
      <OIcon class="toggle-button__icon-left" icon="filter" />
      <span class="toggle-button__label">
        {{ $t('orders.search-bar.placeholder.filter-products') }}
      </span>
      <div
        class="toggle-button__icon"
        :class="{ 'toggle-button__icon--active': active }"
      >
        <OIcon icon="chevron_up" />
      </div>
    </div>
    <transition name="fade">
      <div v-if="active" class="filter__dropdown">
        <OCheckbox
          v-for="group in groupLabels"
          :key="group"
          :native-value="group"
          :modelValue="groupActive(group)"
          @update:modelValue="toggleGroupActive(group)"
          data-test="filter-checkbox"
        >
          {{ group }}
        </OCheckbox>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

export default defineComponent({
  data() {
    return {
      active: false,
    };
  },
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  computed: {
    groupLabels(): string[] {
      const groups =
        this.type === 'order'
          ? this.$store.getters['order/productGroups']()
          : this.$store.getters['claim/agreementProductGroups']();
      return Object.keys(groups);
    },
  },
  methods: {
    toggle(active: boolean) {
      this.active = active;
    },
    groupActive(group: string): boolean {
      return this.type === 'order'
        ? this.$store.getters['order/isFilterGroup'](group)
        : this.$store.getters['claim/isFilterGroup'](group);
    },
    toggleGroupActive(group: string) {
      const user = this.$store.state.auth.user.username;
      this.$store.commit(`${this.type}/toggleFilterGroup`, { group, user });
    },
    onClickOutside() {
      this.active = false;
    },
  },
  props: {
    type: { type: String, default: 'order' },
  },
});
</script>

<style lang="scss" scoped>
.filter {
  width: 100%;

  &__dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 45px + 26px;
    left: 0;
    max-height: 450px;
    width: 100%;
    padding: 15px 25px;
    overflow-y: auto;
    background-color: $white;
    border: 1px solid $alto;
    box-sizing: border-box;
    box-shadow: 0 70px 34px -50px rgba($black, 0.0863746);
    border-radius: 8px;
    z-index: 60;

    > * {
      margin: 10px 0;
    }
  }
}
</style>
