import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "order-card__header" }
const _hoisted_2 = {
  key: 0,
  class: "order-card__header-item order-card__header-item--orderno"
}
const _hoisted_3 = { class: "order-card__nowrap" }
const _hoisted_4 = { class: "order-card__header-item-value" }
const _hoisted_5 = {
  key: 1,
  class: "order-card__header-item order-card__header-item--ref"
}
const _hoisted_6 = { class: "order-card__nowrap" }
const _hoisted_7 = { class: "order-card__header-item-value" }
const _hoisted_8 = {
  key: 2,
  class: "order-card__header-item order-card__header-item--city"
}
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = ["title"]
const _hoisted_12 = { class: "order-card__toggle-text" }
const _hoisted_13 = { class: "order-card__subheader" }
const _hoisted_14 = {
  key: 0,
  class: "order-card__bol"
}
const _hoisted_15 = {
  key: 1,
  class: "order-card__header order-card__header--summary"
}
const _hoisted_16 = { class: "order-card__header-item order-card__header-item--summary" }
const _hoisted_17 = { class: "order-card__title" }
const _hoisted_18 = {
  key: 0,
  class: "order-card__header-item"
}
const _hoisted_19 = { class: "order-card__header-item-value" }
const _hoisted_20 = {
  key: 0,
  class: "order-card__content"
}
const _hoisted_21 = { class: "order-card__details" }
const _hoisted_22 = {
  key: 0,
  class: "order-card__footer"
}
const _hoisted_23 = { class: "order-card__footer-text" }
const _hoisted_24 = {
  key: 1,
  class: "order-card__footer order-card__footer--is-new"
}
const _hoisted_25 = { class: "order-card__toggle-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_OrderStatus = _resolveComponent("OrderStatus")!
  const _component_OrderContainerList = _resolveComponent("OrderContainerList")!
  const _component_OrderItemList = _resolveComponent("OrderItemList")!
  const _component_OSkeleton = _resolveComponent("OSkeleton")!
  const _component_OButton = _resolveComponent("OButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["order-card", {
      'order-card--is-new': _ctx.isNewOrder,
      'order-card--is-modal': _ctx.type === 'modal',
    }])
  }, [
    (!_ctx.isNewOrder)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("header", _hoisted_1, [
            (_ctx.orderNo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('orders.card.header.order-no')), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.orderNo), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.orderRef)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('orders.card.header.order-ref')), 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.orderRef), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.orderCity)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("span", {
                    class: "order-card__header-item-value",
                    title: _ctx.$t('orders.card.header.order-city') + ' ' + _ctx.orderCity
                  }, _toDisplayString(_ctx.orderCity), 9, _hoisted_9)
                ]))
              : _createCommentVNode("", true),
            (_ctx.orderDate)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: "order-card__header-item order-card__header-item--date",
                  title: _ctx.fullOrderDate
                }, [
                  _createElementVNode("span", {
                    class: "order-card__header-item-value order-card__nowrap",
                    title: _ctx.$t('orders.card.header.order-date') + ' ' + _ctx.orderDate
                  }, _toDisplayString(_ctx.orderDate), 9, _hoisted_11)
                ], 8, _hoisted_10))
              : _createCommentVNode("", true),
            (_ctx.type !== 'modal')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleActive(!_ctx.active))),
                  class: "order-card__toggle order-card__header-item order-card__toggle--desktop"
                }, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.toggleText), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["order-card__toggle-icon", { 'order-card__toggle-icon--active': _ctx.active }])
                  }, [
                    _createVNode(_component_OIcon, { icon: "chevron_up" })
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("header", _hoisted_13, [
            _createVNode(_component_OrderStatus, {
              status: _ctx.order.OrderStatus,
              "delivery-date": _ctx.order.DeliveryDate,
              eta: _ctx.Helpers.eta(_ctx.order),
              delay: _ctx.Helpers.delay(_ctx.order, _ctx.$i18n.locale, _ctx.$t),
              class: "order-card__status"
            }, null, 8, ["status", "delivery-date", "eta", "delay"]),
            (_ctx.order.BillOfLading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_component_OIcon, { icon: "text-document" }),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t('orders.card.header.bill-of-lading')), 1),
                  _createTextVNode(_toDisplayString(_ctx.order.BillOfLading), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.order.OrderContainers && _ctx.order.OrderContainers.length)
              ? (_openBlock(), _createBlock(_component_OrderContainerList, {
                  key: 1,
                  containers: _ctx.order.OrderContainers,
                  class: "order-card__containers"
                }, null, 8, ["containers"]))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("header", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("h2", _hoisted_17, _toDisplayString(_ctx.$t('orders.card.header.products-to-order')), 1)
          ]),
          (_ctx.orderRef)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('orders.card.header.order-reference')) + " ", 1),
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.orderRef), 1)
              ]))
            : _createCommentVNode("", true)
        ])),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createElementVNode("main", _hoisted_21, [
                (_ctx.order.OrderItems)
                  ? (_openBlock(), _createBlock(_component_OrderItemList, {
                      key: 0,
                      items: _ctx.order.OrderItems,
                      details: false,
                      "sort-by-cart": true,
                      "order-card-items-is-new": _ctx.isNewOrder
                    }, null, 8, ["items", "order-card-items-is-new"]))
                  : (_openBlock(), _createBlock(_component_OSkeleton, {
                      key: 1,
                      animated: true,
                      height: "194px"
                    }))
              ]),
              (
            !_ctx.isNewOrder &&
            this.type !== 'modal' &&
            _ctx.$store.getters['auth/hasAccess']({
              action: 'POST',
              resource: '/new-order',
            })
          )
                ? (_openBlock(), _createElementBlock("footer", _hoisted_22, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('orders.card.footer.reorder')), 1),
                    _createVNode(_component_OButton, {
                      rounded: "",
                      outlined: "",
                      variant: "primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reOrder())),
                      disabled: _ctx.loading
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('orders.card.footer.order-again')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isNewOrder)
                ? (_openBlock(), _createElementBlock("footer", _hoisted_24, _toDisplayString(_ctx.$t('orders.card.footer.summary-info')), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isNewOrder)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleActive(!_ctx.active))),
          class: "order-card__toggle order-card__toggle--mobile"
        }, [
          _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.toggleText), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["order-card__toggle-icon", { 'order-card__toggle-icon--active': _ctx.active }])
          }, [
            _createVNode(_component_OIcon, { icon: "chevron_up" })
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}