/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CalculateContainerProducts } from './calculate-container-products';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * ProductNr of the fill up product
     * @type {string}
     * @memberof InlineObject
     */
    FillUpProduct?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    LoadBase: InlineObjectLoadBaseEnum;
    /**
     * 
     * @type {Array<CalculateContainerProducts>}
     * @memberof InlineObject
     */
    Products: Array<CalculateContainerProducts>;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectLoadBaseEnum {
    Pallet = 'PALLET',
    Sheet = 'SHEET'
}



