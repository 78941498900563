import { TranslateResult } from 'vue-i18n';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    showSuccess(message: string | TranslateResult): void {
      this.showToast('success', message as string);
    },
    showError(message: string | TranslateResult): void {
      this.showToast('danger', message as string);
    },
    showToast(variant: string, message: string): void {
      this.$oruga.notification.open({
        message,
        rootClass: 'toast toast-notification',
        position: 'bottom',
        variant,
      });
    },
  },
});
