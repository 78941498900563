<template>
  <div>
    <div
      class="block block--contact form-card"
      v-for="contact in block.contacts"
      :key="contact.id"
    >
      <img
        v-if="contact.avatar"
        :src="getAvatarUrl(contact.avatar.url)"
        alt="Contact avatar"
        class="block--contact__avatar"
      />
      <div v-else class="block--contact__avatar block--contact__avatar--empty">
        <OIcon icon="avatar" />
      </div>
      <div class="block--contact__info">
        <h3 v-if="contact.fullName" class="block--contact__name">
          {{ contact.fullName }}
        </h3>
        <div v-if="contact.title" class="block--contact__title">
          {{ contact.title }}
        </div>
        <div class="block--contact__contact">
          <a
            v-if="contact.mainEmail"
            class="block--contact__email"
            :href="`mailto:${contact.mainEmail}`"
          >
            <OIcon
              icon="envelope"
              class="block--contact__icon block--contact__icon--email"
            />{{ contact.mainEmail }}
          </a>
          <a
            v-if="contact.mainPhone"
            class="block--contact__phone"
            :href="getPhone(contact.mainPhone)"
          >
            <OIcon
              icon="phone"
              class="block--contact__icon block--contact__icon--phone"
            />{{ contact.mainPhone }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    const $helpers: any = undefined;

    return {
      $helpers,
    };
  },
  methods: {
    getPhone(telephoneNumber: string): string {
      return 'tel:'.concat(telephoneNumber.split(' ').join(''));
    },
    getAvatarUrl(url: string): string {
      return this.$helpers.getFileUrl(url);
    },
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
.block--contact {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;

  &__avatar {
    border-radius: 50%;
    height: 210px;
    object-fit: cover;
    margin: 0 auto;
    width: 210px;

    &--empty {
      background-color: $primary;

      .icon {
        height: 100%;
        position: relative;
        width: 100%;
      }

      :deep(.lwm-avatar) {
        bottom: 0;
        color: $white;
        font-size: 12em;
        opacity: 0.17;
        position: absolute;
      }
    }
  }

  &__info {
    padding: 20px;
    text-align: center;
  }

  &__email,
  &__phone {
    color: $primary;
    display: block;
    transition: color 0.2s ease;

    &:hover {
      color: $trinidad;
    }
  }

  &__email {
    margin-top: 20px;
  }

  &__icon {
    display: none;
  }

  @media screen and (min-width: 460px) {
    &__icon {
      display: inline-block;
      font-size: 20px;
      margin-right: 8px;
      position: relative;
      top: 3px;

      &--email {
        font-size: 14px;
      }
    }
  }

  @include tablet {
    flex-direction: row;
    padding: 0;

    &__avatar {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
      height: 237px;
      width: 275px;
    }

    &__info {
      border: 1px solid $mercury;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      flex: 1;
      padding: 40px;
      position: relative;
      text-align: left;
    }

    &__name {
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 7px;
    }

    &__contact {
      bottom: 30px;
      position: absolute;
    }

    &__email {
      margin-bottom: 20px;
    }

    &__icon {
      font-size: 24px;
      margin-right: 12px;
      top: 5px;

      &--email {
        font-size: 18px;
      }
    }
  }
}
</style>
