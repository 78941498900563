const awsExports = {
  Auth: {
    Cognito: {
      region: process.env.VUE_APP_AWS_REGION,
      userPoolId: process.env.VUE_APP_USER_POOL_ID,
      userPoolClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
  },
};

export default awsExports;
