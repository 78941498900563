import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layout__content layout__content--large order-overview"
}
const _hoisted_2 = { class: "order-overview__search-bar action-bar" }
const _hoisted_3 = { class: "action-bar__content" }
const _hoisted_4 = { value: "" }
const _hoisted_5 = ["label"]
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OSelect = _resolveComponent("OSelect")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OrderOverviewTable = _resolveComponent("OrderOverviewTable")!

  return (_ctx.hasOrders)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_OField, { class: "filter action-bar__filter" }, {
              default: _withCtx(() => [
                _createVNode(_component_OSelect, {
                  "root-class": "select--search",
                  icon: "filter",
                  expanded: "",
                  placeholder: _ctx.$t('orders.overview.search-bar.status.placeholder'),
                  modelValue: _ctx.statusFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.statusFilter) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.$t('orders.overview.search-bar.status.all')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.statusOptions), (group) => {
                      return (_openBlock(), _createElementBlock("optgroup", {
                        label: _ctx.$t(`orders.overview.${group}-orders-title`),
                        key: group
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions[group], (option) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: option,
                            key: option
                          }, _toDisplayString(_ctx.getStatusLabel(option)), 9, _hoisted_6))
                        }), 128))
                      ], 8, _hoisted_5))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_OField, { class: "action-bar__search" }, {
              default: _withCtx(() => [
                _createVNode(_component_OInput, {
                  modelValue: _ctx.searchQuery,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
                  type: "search",
                  "icon-right": "search",
                  size: "medium",
                  "root-class": "input--search",
                  placeholder: _ctx.$t('orders.overview.search-bar.query.placeholder')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_OrderOverviewTable, {
          orders: _ctx.orders,
          "per-page": 20,
          loading: _ctx.loading
        }, null, 8, ["orders", "loading"])
      ]))
    : _createCommentVNode("", true)
}