import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout__content layout__content--large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_BaseFieldUpload = _resolveComponent("BaseFieldUpload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseHeader, { "check-mfa": false }, {
      default: _withCtx(() => [
        _createTextVNode(" 1.2 " + _toDisplayString(_ctx.$t('claims.new.steps.proof.header')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BaseFieldUpload, {
        name: "attachments",
        label: _ctx.$t('claims.new.steps.proof.attachment.label'),
        placeholder: _ctx.$t('claims.new.steps.proof.attachment.placeholder'),
        horizontal: "",
        modelValue: _ctx.$store.state.claim.claimData.uploadedProof,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.$store.commit('claim/setNewClaimUploadedProof', $event)
        )),
        onRemoveFile: _cache[1] || (_cache[1] = ($event: any) => (
          _ctx.$store.commit('claim/setRemoveClaimUploadedProof', $event)
        )),
        rules: _ctx.uploadRules
      }, null, 8, ["label", "placeholder", "modelValue", "rules"])
    ])
  ]))
}