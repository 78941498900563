import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-64892c84"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.language)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: _normalizeClass(["language-label", { 'language-label--show-label': !!_ctx.label }])
      }, [
        _createElementVNode("img", {
          src: `/img/icons/country/${_ctx.language.flag}.svg`,
          alt: _ctx.language.flag,
          class: "language-label__flag"
        }, null, 8, _hoisted_1),
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.language.label), 1)
      ], 2))
    : _createCommentVNode("", true)
}