import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OField = _resolveComponent("OField")!
  const _component_button_row = _resolveComponent("button-row")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
    class: "form form--profile"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.fields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        key: field.name
      }, [
        _createVNode(_component_OField, {
          label: _ctx.$t(`profile.label.${field.name}`),
          "data-test": `field-${field.name}`
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getInputComponent(field)), {
              modelValue: _ctx.profile[field.name],
              "onUpdate:modelValue": ($event: any) => (_ctx.setField(field.name, $event)),
              onValidate: ($event: any) => (_ctx.onValidate(field.name, $event)),
              type: field.type ? field.type : 'text',
              class: _normalizeClass(field.name)
            }, null, 40, ["modelValue", "onUpdate:modelValue", "onValidate", "type", "class"]))
          ]),
          _: 2
        }, 1032, ["label", "data-test"])
      ]))
    }), 128)),
    _createVNode(_component_button_row, {
      group: "profile",
      onCancel: _ctx.cancel,
      valid: _ctx.valid
    }, null, 8, ["onCancel", "valid"])
  ], 32))
}