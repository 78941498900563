import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product-select" }
const _hoisted_2 = { class: "layout__content layout__content--large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductSearchBar = _resolveComponent("ProductSearchBar")!
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_o_table = _resolveComponent("o-table")!
  const _component_SelectProductGroup = _resolveComponent("SelectProductGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProductSearchBar, {
      class: "select-products__search",
      type: "claim"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_o_table, {
        data: [],
        "root-class": "product-item-list product-item-list--no-body"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('orders.order-item-list.header.product')
          }, null, 8, ["label"]),
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('orders.order-item-list.header.code'),
            width: 110
          }, null, 8, ["label"]),
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('claims.new.items.bags'),
            width: 90
          }, null, 8, ["label"]),
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('claims.new.items.boxes'),
            width: 90
          }, null, 8, ["label"]),
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('claims.new.items.volume'),
            width: 90
          }, null, 8, ["label"]),
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('claims.new.items.billback-rate'),
            width: 120
          }, null, 8, ["label"]),
          _createVNode(_component_o_table_column, {
            label: _ctx.$t('claims.new.items.billback-amount'),
            width: 155
          }, null, 8, ["label"])
        ]),
        _: 1
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupLabels, (groupLabel) => {
      return (_openBlock(), _createBlock(_component_SelectProductGroup, {
        key: groupLabel,
        "group-label": groupLabel,
        products: _ctx.groups[groupLabel],
        agreement: _ctx.agreement,
        type: "claim",
        "cart-type": "claim"
      }, null, 8, ["group-label", "products", "agreement"]))
    }), 128))
  ]))
}