import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-301db37e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "new-claim" }
const _hoisted_2 = {
  key: 0,
  class: "new-claim__claim-details"
}
const _hoisted_3 = {
  key: 1,
  class: "new-claim__claim-summary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsBar = _resolveComponent("StepsBar")!
  const _component_NewClaimDetailForm = _resolveComponent("NewClaimDetailForm")!
  const _component_NewClaimProductSelect = _resolveComponent("NewClaimProductSelect")!
  const _component_NewClaimProofUpload = _resolveComponent("NewClaimProofUpload")!
  const _component_NewClaimOverview = _resolveComponent("NewClaimOverview")!
  const _component_NewClaimFooter = _resolveComponent("NewClaimFooter")!
  const _component_Form = _resolveComponent("Form")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_StepsBar, {
          labelPrefix: "claims",
          steps: _ctx.steps,
          "overview-link": { name: 'claims' },
          "active-step": _ctx.step
        }, null, 8, ["steps", "active-step"]),
        _createVNode(_component_Form, { ref: "form" }, {
          default: _withCtx(({ meta }) => [
            (_ctx.step === 'claim-details' && _ctx.agreement)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_NewClaimDetailForm),
                  (_ctx.agreement)
                    ? (_openBlock(), _createBlock(_component_NewClaimProductSelect, {
                        key: 0,
                        agreement: _ctx.agreement,
                        class: "new-claim__product-select"
                      }, null, 8, ["agreement"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_NewClaimProofUpload)
                ]))
              : _createCommentVNode("", true),
            (_ctx.step === 'claim-summary')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_NewClaimOverview)
                ]))
              : _createCommentVNode("", true),
            (_ctx.agreement)
              ? (_openBlock(), _createBlock(_component_NewClaimFooter, {
                  key: 2,
                  step: _ctx.step,
                  "next-active": meta.valid,
                  agreement: _ctx.agreement
                }, null, 8, ["step", "next-active", "agreement"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 512)
      ])
    ]),
    _: 1
  }))
}