<template>
  <div>
    <div class="onboarding__wrapper">
      <form @submit.prevent="setPhoneNumber()">
        <OField label="Phonenumber">
          <PhoneSelect
            v-model="phone"
            :required="true"
            @validate="onValidatePhone"
            :disabled="step !== steps.UNKNOWN"
          />
        </OField>
        <div class="button-row button-row--flex">
          <a
            class="onboarding__reset-phone"
            @click="step = steps.UNKNOWN"
            v-if="step === steps.UNVERIFIED"
            >{{ $t('onboarding.sms.change-phone') }}</a
          >
          <OButton
            variant="primary"
            rounded
            native-type="submit"
            :disabled="!valid || step !== steps.UNKNOWN"
            class="onboarding__button"
            :loading="loading.request"
            >{{ $t('onboarding.sms.request-code') }}</OButton
          >
        </div>
      </form>
      <form @submit.prevent="checkCode()">
        <OField label="MFA-field">
          <OInput
            v-model="code"
            :placeholder="'Verify MFA'"
            :disabled="step !== steps.UNVERIFIED"
          />
        </OField>
        <div class="button-row">
          <OButton
            variant="primary"
            rounded
            native-type="submit"
            :disabled="code.length !== 6 || step !== steps.UNVERIFIED"
            class="onboarding__button"
            :loading="loading.check"
            >{{ $t('onboarding.sms.finish') }}</OButton
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import {
  updateMFAPreference,
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
  updateUserAttributes,
} from 'aws-amplify/auth';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import PhoneSelect from '@/components/form/PhoneSelect.vue';
import { Profile } from '@/api/model';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { PhoneSelect },
  mixins: [MessageToastMixin],
  data() {
    return {
      steps: {
        UNKNOWN: 'unknown',
        UNVERIFIED: 'unverified',
        DIRECT: 'direct',
      },
      step: 'unknown',
      phone: '',
      code: '',
      valid: false,
      loading: {
        request: false,
        check: false,
      },
    };
  },
  computed: {
    user(): any {
      return this.$store.state.auth.user;
    },
    profile(): Profile {
      return this.$store.state.profile.profile;
    },
    cleanPhoneNumber(): string {
      return this.phone.replaceAll(' ', '');
    },
  },
  created() {
    if (this.profile.phone === this.user.attributes.phone_number) {
      this.phone = this.user.attributes.phone_number
        ? this.user.attributes.phone_number
        : '';
    }
  },
  methods: {
    async sendCode() {
      this.loading.request = true;
      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number',
      })
        .then(() => {
          this.showSuccess(this.$t('onboarding.sms.code-sent'));
          this.setStep(this.steps.UNVERIFIED);
        })
        .catch((e: Error) => this.showError(e.message))
        .finally(() => {
          this.loading.request = false;
        });
    },
    async checkCode() {
      this.loading.check = true;
      confirmUserAttribute({
        userAttributeKey: 'phone_number',
        confirmationCode: this.code,
      })
        .then(() => {
          this.setMFA();
        })
        .catch((e: Error) => this.showError(e.message))
        .finally(() => {
          this.loading.check = false;
        });
    },
    async setPhoneNumber() {
      updateUserAttributes({
        userAttributes: {
          phone_number: this.cleanPhoneNumber,
        },
      })
        .then(() => {
          const { profile } = this;
          profile.phone = this.phone;
          this.$store.dispatch('profile/saveProfile', profile);
          this.sendCode();
        })
        .catch((e: Error) => {
          this.showError(e.message);
        });
    },
    async setMFA() {
      updateMFAPreference({ sms: 'PREFERRED' })
        .then(() => {
          this.$emit('finished');
        })
        .catch((e: Error) => this.showError(e.message));
    },
    onValidatePhone(valid: boolean) {
      this.valid = valid;
    },
    setStep(step: string) {
      if (Object.values(this.steps).includes(step)) {
        this.step = step;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.button-row {
  margin-bottom: 20px;

  &--flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.onboarding {
  &__reset-phone {
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }

  &__button {
    margin-left: auto;
  }
}

:deep(.input:disabled) {
  border: 1px solid $alto;
}
</style>
