<template>
  <div
    class="sap-switcher"
    v-if="enabledSapCustomers && enabledSapCustomers.length > 1"
  >
    <div
      class="sap-switcher__current"
      :class="{ 'sap-switcher__current--active': active }"
      v-on-click-outside="
        () => {
          active = false;
        }
      "
      @click="
        () => {
          active = !active;
        }
      "
    >
      <h2 v-if="sapCustomer" class="sap-switcher__title">
        {{ sapCustomer.CustName1 }}
      </h2>
      <OIcon icon="chevron_up" class="sap-switcher__icon" />
    </div>
    <div
      class="sap-switcher__other"
      :class="{ 'sap-switcher__other--active': active }"
    >
      <div
        v-for="option in getOtherOptions"
        :key="option.CustomerNr"
        @click="setActiveSAPID(option.CustomerNr || '')"
        class="sap-switcher__option"
      >
        <h2 v-if="option.CustName1" class="sap-switcher__title">
          {{ option.CustName1 }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { SapCustomer2 } from '@/api';
import { defineComponent } from 'vue';

export default defineComponent({
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    enabledSapCustomers(): SapCustomer2[] {
      return this.$store.state.profile?.profile?.enabledSapCustomers;
    },
    sapCustomer(): SapCustomer2 {
      return this.$store.state.profile?.profile?.sapCustomer;
    },
    getOtherOptions(): SapCustomer2[] {
      return this.enabledSapCustomers?.filter(
        (customer: any) => customer.CustomerNr !== this.sapCustomer.CustomerNr,
      );
    },
  },
  methods: {
    async setActiveSAPID(id: string) {
      this.active = false;
      await this.$store.dispatch('profile/changeSapId', id);
    },
  },
});
</script>

<style lang="scss" scoped>
.sap-switcher {
  $self: &;
  $transitions: 200ms ease;
  position: relative;

  &__option,
  &__current {
    padding: 16px 24px;
    background-color: $white;
  }

  &__option:hover {
    cursor: pointer;
    #{$self}__title {
      color: $trinidad;
    }
  }

  &__other {
    z-index: 1;
    border-top: 1px solid $gallery;
  }

  &__current {
    border-block: 1px solid $gallery;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 5;

    &--active {
      #{$self}__title {
        color: $trinidad;
      }

      #{$self}__icon {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      #{$self}__icon {
        opacity: 1;
      }
    }
  }

  &__icon {
    opacity: 0;
    transition: opacity $transitions, transform $transitions;
    margin-left: auto;
    font-size: 12px;
    height: 12px;

    :deep(.lwm-chevron_up) {
      display: block;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    margin-right: 16px;
    transform: translateY(2px);
  }

  &__other {
    position: absolute;
    bottom: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity $transitions;
    visibility: hidden;
    &--active {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
