<template>
  <div
    class="switch-locale"
    :class="active ? 'switch-locale--active' : ''"
    v-on-click-outside="onClickOutside"
  >
    <button class="trigger" @click="toggle">
      <img
        :src="`/img/icons/country/${currentLocale.flag}.svg`"
        :alt="currentLocale.flag"
        class="trigger__flag"
      />
      <span class="trigger__label">
        {{ currentLocale.shortLabel }}
      </span>
      <OIcon class="trigger__icon" icon="chevron_up" />
    </button>
    <transition name="fade">
      <div v-if="active" class="switch-locale__dropdown">
        <div
          v-for="(locale, i) in getAvailableLanguages()"
          :key="`lang-${i}`"
          @click="setLanguage(locale.id)"
          class="switch-locale__dropdown__item"
          :class="
            locale.id === currentLocale.id
              ? 'switch-locale__dropdown__item--active'
              : ''
          "
        >
          <img
            :src="`/img/icons/country/${locale.flag}.svg`"
            :alt="locale.flag"
            class="switch-locale__dropdown__item__flag"
          />
          <span class="switch-locale__dropdown__item__label">
            {{ locale.label }}
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { Languages, LocaleOption } from '@/logic/locale/Languages';
import { defineComponent } from 'vue';

export default defineComponent({
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  data() {
    return {
      locales: Languages.getLanguages(),
      active: false,
    };
  },
  computed: {
    currentLocale(): LocaleOption {
      const locale = this.locales.find((loc) => loc.id === this.$i18n.locale);
      return locale || this.locales[0];
    },
  },
  methods: {
    getAvailableLanguages(): any {
      if (this.limitLanguages.length === 0) {
        return this.locales;
      }

      return this.locales.filter((lang) =>
        this.limitLanguages.includes(lang.id),
      );
    },
    toggle() {
      this.active = !this.active;
    },
    setLanguage(id: string) {
      this.$i18n.locale = id;
      this.active = false;
    },
    onClickOutside() {
      this.active = false;
    },
  },
  props: {
    limitLanguages: { type: Array, required: false, default: () => [] },
  },
});
</script>

<style lang="scss" scoped>
.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $mercury;
  height: 34px;
  border-radius: 17px;
  font-size: 14px;
  line-height: 16px;
  border: 0;
  padding: 0 16px;
  cursor: pointer;
  text-transform: uppercase;
  outline: 0;

  &,
  & * {
    transition: all 0.2s ease;
  }

  &:hover {
    background-color: $white;
  }

  &__flag {
    margin-right: 10px;
  }

  &__label {
    height: 15px;
  }

  &__icon {
    font-size: 10px;
    flex: 0 1 10px;
    margin-left: 16px;
    transform: rotate(180deg);
  }
}

.switch-locale__dropdown {
  position: absolute;
  background-color: $white;
  color: $primary;
  border-radius: 8px;
  min-width: 210px;
  top: 50px;
  right: 0;
  padding: 16px 0 24px;
  z-index: 10;
  box-shadow: 0 0 50px 0 rgba($black, 0.14);

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 24px;
    font-size: 16px;

    &__flag {
      margin-right: 16px;
    }

    &__label {
      transform: translateY(2px); // font is not aligned in center
    }

    &:hover {
      color: $trinidad;
    }

    &--active {
      font-weight: bold;
    }
  }
}

.switch-locale {
  position: relative;

  &--active {
    .trigger {
      &__icon {
        transform: rotate(0);
      }
    }
  }
}
</style>
