<template>
  <div v-if="block.text" class="block intro-text">
    <div
      class="block__text rich-text"
      v-html="$helpers.parseRichText(block.text)"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
