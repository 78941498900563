import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-41273450"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile layout__content" }
const _hoisted_2 = {
  class: "form-card profile-card",
  key: "p-inactive"
}
const _hoisted_3 = { class: "form-card__header" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "form-card__body" }
const _hoisted_6 = { class: "form-card__details" }
const _hoisted_7 = {
  key: 0,
  class: "profile__value profile__value--no-data"
}
const _hoisted_8 = {
  key: 1,
  class: "profile__value profile__value--title"
}
const _hoisted_9 = {
  key: 2,
  class: "profile__value profile__value--subtitle"
}
const _hoisted_10 = { class: "profile__label" }
const _hoisted_11 = {
  key: 3,
  class: "profile__value"
}
const _hoisted_12 = { class: "profile__label" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  class: "form-card account-card",
  key: "p-active"
}
const _hoisted_15 = { class: "form-card__header form-card__header--edit" }
const _hoisted_16 = { class: "subtitle" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "form-card__body" }
const _hoisted_19 = { class: "form-card" }
const _hoisted_20 = {
  key: 0,
  class: "form-card__header"
}
const _hoisted_21 = { class: "subtitle" }
const _hoisted_22 = {
  key: 1,
  class: "form-card__header form-card__header--edit"
}
const _hoisted_23 = { class: "subtitle" }
const _hoisted_24 = { class: "label" }
const _hoisted_25 = { class: "form-card__body" }
const _hoisted_26 = { class: "form-card__details" }
const _hoisted_27 = { class: "form-card__group" }
const _hoisted_28 = {
  key: 0,
  class: "profile__value"
}
const _hoisted_29 = { class: "profile__label" }
const _hoisted_30 = { class: "form-card__group" }
const _hoisted_31 = {
  key: 0,
  class: "profile__value"
}
const _hoisted_32 = { class: "profile__label" }
const _hoisted_33 = {
  key: 0,
  class: "form-card__group form-card__group--labeled"
}
const _hoisted_34 = {
  key: 0,
  class: "form-card__label"
}
const _hoisted_35 = {
  key: 0,
  class: "profile__value"
}
const _hoisted_36 = { class: "profile__label" }
const _hoisted_37 = { class: "form-card__group form-card__group--labeled" }
const _hoisted_38 = { class: "form-card__label" }
const _hoisted_39 = {
  key: 0,
  class: "profile__value"
}
const _hoisted_40 = { class: "profile__label" }
const _hoisted_41 = {
  key: 2,
  class: "profile__value"
}
const _hoisted_42 = { class: "profile__label" }
const _hoisted_43 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_form_group = _resolveComponent("form-group")!
  const _component_LanguageLabel = _resolveComponent("LanguageLabel")!
  const _component_AuthenticatorSetMfa = _resolveComponent("AuthenticatorSetMfa")!
  const _component_MfaInfo = _resolveComponent("MfaInfo")!
  const _component_OLoading = _resolveComponent("OLoading")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.canEditProfile)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "fade"
        }, {
          default: _withCtx(() => [
            (!_ctx.formGroups.profile.active)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('profile.title')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      (!_ctx.formGroupHasData('profile'))
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('profile.no-profile-placeholder')), 1))
                        : _createCommentVNode("", true),
                      (_ctx.fullName)
                        ? (_openBlock(), _createElementBlock("h3", _hoisted_8, _toDisplayString(_ctx.fullName), 1))
                        : _createCommentVNode("", true),
                      (_ctx.profile.functionTitle)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.profile.functionTitle), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.profile.city || _ctx.profile.country)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("label", _hoisted_12, [
                              _createTextVNode(_toDisplayString(_ctx.profile.city), 1),
                              (_ctx.profile.city)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_13, ", "))
                                : _createCommentVNode("", true),
                              _createTextVNode(" " + _toDisplayString(_ctx.$profile.getCountryNameFromEnGb(_ctx.profile.country, _ctx.$i18n.locale)), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.isEditable('profile'))
                      ? (_openBlock(), _createBlock(_component_OIcon, {
                          key: 0,
                          icon: "edit",
                          class: "form-card__button-icon edit-button--profile",
                          alt: _ctx.$t('profile.edit'),
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit('profile')))
                        }, null, 8, ["alt"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("h2", _hoisted_16, _toDisplayString(_ctx.$t('profile.title')), 1),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('profile.label.edit')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_form_group, {
                      name: "profile",
                      onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel('profile'))),
                      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.save('profile')), ["prevent"])),
                      onSetField: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setField('profile', $event.name, $event.value))),
                      group: _ctx.formGroups.profile,
                      profile: _ctx.profile
                    }, null, 8, ["group", "profile"])
                  ])
                ]))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_19, [
      (
          !_ctx.formGroups.email.active &&
          !_ctx.formGroups.password.active &&
          !_ctx.formGroups.locale.active
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("h2", _hoisted_21, _toDisplayString(_ctx.$t('profile.account-settings.title')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("h2", _hoisted_23, _toDisplayString(_ctx.$t('profile.account-settings.title')), 1),
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('profile.account-settings.edit')), 1)
          ])),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (!_ctx.formGroups.email.active)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                      _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.profile.email), 1),
                      (_ctx.isEditable('email'))
                        ? (_openBlock(), _createBlock(_component_OIcon, {
                            key: 0,
                            icon: "edit",
                            class: "form-card__button-icon edit-button--email",
                            alt: _ctx.$t('profile.label.edit'),
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.edit('email')))
                          }, null, 8, ["alt"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createBlock(_component_form_group, {
                      key: 1,
                      name: "email",
                      onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancel('email'))),
                      onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.save('email')), ["prevent"])),
                      onSetField: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setField('email', $event.name, $event.value))),
                      group: _ctx.formGroups.email,
                      profile: _ctx.profile
                    }, null, 8, ["group", "profile"]))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (!_ctx.formGroups.password.active)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('profile.label.change-password')), 1),
                      (_ctx.isEditable('password'))
                        ? (_openBlock(), _createBlock(_component_OIcon, {
                            key: 0,
                            icon: "edit",
                            class: "form-card__button-icon edit-button--password",
                            alt: _ctx.$t('profile.label.edit'),
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.edit('password')))
                          }, null, 8, ["alt"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createBlock(_component_form_group, {
                      key: 1,
                      name: "password",
                      onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.cancel('password'))),
                      onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.save('password')), ["prevent"])),
                      onSetField: _cache[11] || (_cache[11] = ($event: any) => (_ctx.setField('password', $event.name, $event.value))),
                      group: _ctx.formGroups.password,
                      profile: _ctx.profile
                    }, null, 8, ["group", "profile"]))
              ]),
              _: 1
            })
          ]),
          (!_ctx.hasPosessionOfLanguage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                (!_ctx.formGroups.locale.active)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_34, _toDisplayString(_ctx.$t('profile.label.locale')), 1))
                  : _createCommentVNode("", true),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    (!_ctx.formGroups.locale.active)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createElementVNode("label", _hoisted_36, [
                            _createVNode(_component_LanguageLabel, {
                              locale: _ctx.profile.locale
                            }, null, 8, ["locale"])
                          ]),
                          (_ctx.isEditable('locale'))
                            ? (_openBlock(), _createBlock(_component_OIcon, {
                                key: 0,
                                icon: "edit",
                                class: "form-card__button-icon edit-button--locale",
                                alt: _ctx.$t('profile.label.edit'),
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.edit('locale')))
                              }, null, 8, ["alt"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createBlock(_component_form_group, {
                          key: 1,
                          name: "locale",
                          onCancel: _cache[13] || (_cache[13] = ($event: any) => (_ctx.cancel('locale'))),
                          onSubmit: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.save('locale')), ["prevent"])),
                          onSetField: _cache[15] || (_cache[15] = ($event: any) => (_ctx.setField('locale', $event.name, $event.value))),
                          group: _ctx.formGroups.locale,
                          profile: _ctx.profile
                        }, null, 8, ["group", "profile"]))
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("h4", _hoisted_38, _toDisplayString(_ctx.$t('profile.mfa.setup-mfa')), 1),
            (!_ctx.formGroups.phone.active)
              ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                  _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('profile.label.phone')) + ": " + _toDisplayString(_ctx.profile.phone), 1),
                  (_ctx.isEditable('phone'))
                    ? (_openBlock(), _createBlock(_component_OIcon, {
                        key: 0,
                        icon: "edit",
                        class: "form-card__button-icon edit-button--phone",
                        alt: _ctx.$t('profile.label.edit'),
                        onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.edit('phone')))
                      }, null, 8, ["alt"]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock(_component_form_group, {
                  key: 1,
                  name: "phone",
                  onCancel: _cache[17] || (_cache[17] = ($event: any) => (_ctx.cancel('phone'))),
                  onSubmit: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (_ctx.save('phone')), ["prevent"])),
                  onSetField: _cache[19] || (_cache[19] = ($event: any) => (_ctx.setField('phone', $event.name, $event.value))),
                  group: _ctx.formGroups.phone,
                  profile: _ctx.profile
                }, null, 8, ["group", "profile"])),
            (!_ctx.formGroups.mfa.active)
              ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                  _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('profile.mfa.current-mfa')) + " " + _toDisplayString(_ctx.$t(`profile.mfa.method.${_ctx.preferredMFA}`)), 1),
                  (_ctx.isEditable('mfa'))
                    ? (_openBlock(), _createBlock(_component_OIcon, {
                        key: 0,
                        icon: "edit",
                        class: "form-card__button-icon",
                        alt: _ctx.$t('profile.label.edit'),
                        onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.edit('mfa')))
                      }, null, 8, ["alt"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_Transition, { name: "slide" }, {
              default: _withCtx(() => [
                (_ctx.formGroups.mfa.active)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                      _createVNode(_component_AuthenticatorSetMfa, {
                        mfaConfig: _ctx.mfaConfig,
                        onClose: _cache[21] || (_cache[21] = ($event: any) => {
                    _ctx.formGroups.mfa.active = false;
                    _ctx.save('mfa');
                  })
                      }, null, 8, ["mfaConfig"]),
                      _createVNode(_component_MfaInfo)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createVNode(_component_OLoading, {
      active: _ctx.loading,
      "full-page": true
    }, null, 8, ["active"])
  ]))
}