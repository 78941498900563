import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import nl from 'date-fns/locale/nl';

// Locales for use by date-fns
// eslint-disable-next-line import/prefer-default-export
export const DateFnsLocales: { [id: string]: Locale } = {
  de,
  'en-GB': enGB,
  es,
  fr,
  it,
  nl,
};
