<template>
  <div class="update-button" v-if="isActive">
    <OIcon class="update-button--icon" icon="info_circle" variant="secondary" />
    <div class="update-button--button-content">
      {{ $t(`updates-modal.scopes.${scope}.button-text`) }}
      <OButton
        class="update-button--button"
        @click="openUpdateModal"
        size="small"
        >{{ $t(`updates-modal.scopes.${scope}.read-more`) }}</OButton
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import UpdateDialog from '@/components/updates/UpdateDialog.vue';

export default defineComponent({
  props: {
    scope: { type: String, required: true },
    from: { type: String, required: false },
    till: { type: String, required: false },
    promotedTill: { type: String, required: false },
  },
  mounted() {
    if (this.scope && this.isPromoted) {
      const previouslyLoaded = localStorage.getItem(
        `updates-modal-${this.scope}`,
      );

      if (!previouslyLoaded) {
        this.openUpdateModal();
        localStorage.setItem(`updates-modal-${this.scope}`, 'true');
      }
    }
  },
  computed: {
    isActive(): boolean {
      const now = Date.now();
      const from = new Date(this.from ?? now).getTime();
      const till = new Date(this.till ?? now).getTime();

      return from <= now && now <= till;
    },
    isPromoted(): boolean {
      if (this.isActive && this.promotedTill) {
        const promoted = new Date(this.promotedTill);
        return new Date() < promoted;
      }
      return false;
    },
  },
  methods: {
    openUpdateModal() {
      this.$oruga.modal.open({
        component: UpdateDialog as any,
        props: {
          scope: this.scope,
        },
        trapFocus: true,
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.update-button {
  display: flex;
  flex: 0 0 250px;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  background-color: $white;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  margin: 10px;
  box-shadow: 0 2px 10px 0 rgba($black, 0.3);

  @include mobile {
    flex: 1;
  }

  &--button-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &--button {
    align-self: flex-end;
    background: transparent;
    border: 0;
    text-decoration: underline;
  }
}
</style>
