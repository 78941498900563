/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProfileCourseProgress
 */
export interface ProfileCourseProgress {
    /**
     * 
     * @type {string}
     * @memberof ProfileCourseProgress
     */
    course_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileCourseProgress
     */
    progress_status?: ProfileCourseProgressProgressStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileCourseProgressProgressStatusEnum {
    InProgress = 'in_progress',
    Completed = 'completed'
}



