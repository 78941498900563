<template>
  <component :is="layout">
    <div class="content-page">
      <Hero
        v-if="(headerTitle || headerImage) && !isLoading"
        :title="headerTitle"
        :image="headerImage"
        :pageName="pageName"
      />
      <OSkeleton v-if="isLoading" :animated="true" height="300px" />

      <h1 v-if="!isLoading" class="title layout__content">{{ title }}</h1>
      <OSkeleton
        v-if="isLoading"
        :animated="true"
        width="50%"
        root-class="is-centered"
        height="60px"
      />

      <p v-if="introText" class="intro-text layout__content">{{ introText }}</p>

      <ContentBlocks
        :blocks="blocks"
        :has-background-color="hasBackgroundColor"
        v-if="!isLoading"
      />
      <OSkeleton
        v-if="isLoading"
        :animated="true"
        width="50%"
        height="400px"
        root-class="is-centered"
        :count="6"
      />
    </div>
  </component>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutPublic from '@/layouts/LayoutPublic.vue';
import ContentBlocks from '@/components/content/ContentBlocks.vue';
import Hero from '@/components/AppHero.vue';
import CmsContentMixin from '@/mixins/CmsContentMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { LayoutDefault, LayoutPublic, ContentBlocks, Hero },
  mixins: [CmsContentMixin],
  data() {
    return {
      pageName: '',
      hasBackgroundColor: false,
    };
  },
  computed: {
    layout(): string {
      return this.$store.getters['auth/signedIn']
        ? 'LayoutDefault'
        : 'LayoutPublic';
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.fetchContent();
      this.isLoading = false;
    } catch (e) {
      await this.$router.replace({ name: '404' });
    } finally {
      this.isLoading = false;
    }
  },
  watch: {
    $route: [{ immediate: true, handler: 'onUrlChange' }],
  },
  methods: {
    onUrlChange(route: any) {
      if (route.name === 'eat-this') {
        this.pageName = 'eat-this';
        this.hasBackgroundColor = true;
      } else {
        this.pageName = '';
        this.hasBackgroundColor = false;
      }

      this.fetchContent();
    },
  },
});
</script>
