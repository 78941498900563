<template>
  <LayoutDefault>
    <div class="my-orders">
      <header class="layout__content layout__content--large">
        <h1 class="title title--direct">
          <span>{{ $t('orders.header.title') }}</span>
        </h1>
        <p class="layout__intro-text layout__intro-text--orders">
          {{ $t('orders.header.intro') }}
        </p>
        <OButton
          rounded
          variant="primary"
          tag="router-link"
          :to="{ name: 'new-order' }"
          icon-right="truck"
          class="my-orders__new-order"
          v-if="
            hasMfa &&
            $store.getters['auth/hasAccess']({
              action: 'POST',
              resource: '/new-order',
            })
          "
        >
          {{ $t('orders.header.new-order-button') }}
        </OButton>
        <MfaWarning v-if="!hasMfa" />
      </header>
      <OrderOverview v-if="hasMfa" />
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import OrderOverview from '@/components/orders/OrderOverview.vue';
import MfaWarning from '@/components/authentication/MfaWarning.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { LayoutDefault, OrderOverview, MfaWarning },
  computed: {
    hasMfa(): boolean {
      return this.$store.getters['auth/hasMfa'];
    },
  },
});
</script>

<style lang="scss" scoped>
.my-orders {
  &__new-order {
    margin-bottom: 36px;

    :deep(.icon .lwm) {
      font-size: 18px;
    }
  }
}
</style>
