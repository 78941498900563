<template>
  <div class="sidebar-container">
    <div class="top-bar">
      <div class="top-bar__content">
        <RouterLink to="/" class="sidebar__logo sidebar__logo--small">
          <img
            :src="logo"
            alt="Lamb Weston - Seeing Possibilities In Potatoes"
          />
        </RouterLink>
        <a
          @click="toggleMenu()"
          role="button"
          aria-label="menu"
          class="top-bar__burger navbar-burger burger"
          :class="{ 'is-active': menuActive }"
          ><span aria-hidden="true" /> <span aria-hidden="true" />
          <span aria-hidden="true"
        /></a>
      </div>
      <ColorBox />
    </div>

    <OSidebar
      class="sidebar"
      :class="
        menuActive
          ? 'sidebar--active'
          : '' || isImpersonating
          ? 'sidebar--impersonating'
          : ''
      "
      mobile="fullwidth"
      :mobile-breakpoint="`${TABLET_BREAKPOINT.toString()}px`"
      fullheight
      inline
      :fullwidth="false"
      :active="isWidescreen ? true : menuActive"
      :cancelable="false"
    >
      <RouterLink
        to="/"
        class="sidebar__logo sidebar__logo--large"
        :class="{ 'sidebar__logo--multinational': !!logo }"
      >
        <img :src="logo" alt="Lamb Weston - Seeing Possibilities In Potatoes" />
      </RouterLink>
      <OMenu id="nav" class="navigation">
        <!-- old style direct/indirect customers, administrators -->
        <div
          v-if="!hasAccess(undefined, AuthGroup.Multinational)"
          aria-role="menu"
          class="menu-list"
        >
          <OMenuItem
            v-if="hasAccess({ action: 'PUT', resource: '/role' })"
            icon="keyhole"
            :label="$t('navigation.permission-mgt')"
            tag="router-link"
            :to="{ name: 'permission-mgt' }"
            :class="{ 'is-active': isActive('permission-mgt') }"
            class="menu-item"
          />
          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/profiles' })"
            icon="user_management"
            :label="$t('navigation.user-management')"
            tag="router-link"
            to="/user-management"
            :class="{ 'is-active': isActive('/user-management') }"
            class="menu-item"
          />

          <OMenuItem
            v-if="signedIn"
            icon="dashboard"
            :label="$t('navigation.dashboard')"
            tag="router-link"
            to="/dashboard"
            :class="{ 'is-active': isActive('/dashboard') }"
            class="menu-item"
          />

          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/orders' })"
            :icon="
              $store.getters['profile/isContainer'] ? 'container_ship' : 'truck'
            "
            :label="$t('navigation.my-orders')"
            tag="router-link"
            to="/orders"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/orders') }"
          />

          <OMenuItem
            v-if="
              hasAccess({ action: 'POST', resource: '/calculate-container' })
            "
            icon="calculator"
            :label="$t('navigation.container-calculation')"
            tag="router-link"
            to="/container-calculation"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/container-calculation') }"
          />

          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/invoices' })"
            icon="invoice"
            :label="$t('navigation.my-invoices')"
            tag="router-link"
            to="/invoices"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/invoices') }"
          />

          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/contracts' })"
            icon="contracts"
            :label="$t('navigation.my-volume-contracts')"
            tag="router-link"
            :to="{ name: 'contracts' }"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/contracts') }"
          />

          <!-- end users -->
          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/agreements' })"
            icon="contracts"
            :label="$t('navigation.my-agreements')"
            tag="router-link"
            :to="{ name: 'agreements' }"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/agreements') }"
          />
          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/claims' })"
            icon="document-eye"
            :label="$t('navigation.my-claims')"
            tag="router-link"
            :to="{ name: 'claims' }"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/claims') }"
          />

          <OMenuItem
            v-if="signedIn"
            icon="eat-this"
            :label="$t('navigation.eat-this')"
            tag="router-link"
            to="/eat-this"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/eat-this') }"
          />

          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/products' })"
            icon="info_circle"
            :label="$t('navigation.product-information')"
            tag="router-link"
            :to="{ name: 'product-information' }"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/product-information') }"
          />

          <OMenuItem
            v-if="hasAccess({ action: 'GET', resource: '/dishes' })"
            icon="flask"
            :expanded="activeMenuExpansions.has('/menu-engineering')"
            class="menu-item menu-item--has-sub"
            :class="{
              'is-active': activeMenuExpansions.has('/menu-engineering'),
              'is-expanded': activeMenuExpansions.has('/menu-engineering'),
            }"
            @click="toggleActiveMenuExpansion('/menu-engineering')"
          >
            <template #label="{ expanded }">
              {{ $t('navigation.menu-engineering.main') }}
              <OIcon
                class="is-pulled-right"
                icon="chevron_up"
                :class="{ 'is-expanded': expanded }"
              />
            </template>
            <OMenuItem
              v-if="hasAccess({ action: 'PUT', resource: '/dish' })"
              icon="add_circle"
              :label="$t('navigation.menu-engineering.add-dish')"
              tag="router-link"
              to="/menu-engineering/add-dish"
              :class="{ 'is-active': isActive('/menu-engineering/add-dish') }"
              class="menu-item menu-item--sub"
            />
            <OMenuItem
              v-if="hasAccess({ action: 'GET', resource: '/dishes' })"
              icon="my_results"
              :label="$t('navigation.menu-engineering.my-results')"
              tag="router-link"
              to="/menu-engineering/results"
              :class="{ 'is-active': isActive('/menu-engineering/results') }"
              class="menu-item menu-item--sub"
            />
          </OMenuItem>

          <OMenuItem
            v-if="
              hasAccess({
                action: 'GET',
                resource: `/content/customer_portal/{locale}/e-learning`,
              })
            "
            icon="e-learning"
            :label="$t('navigation.e-learning')"
            tag="router-link"
            :to="{ name: 'e-learning' }"
            class="menu-item"
            :class="{ 'is-active': isExpanded('/e-learning') }"
          />

          <OMenuItem
            v-if="signedIn"
            icon="settings"
            class="menu-item menu-item--has-sub"
            :expanded="activeMenuExpansions.has('/settings')"
            :class="{
              'is-active': activeMenuExpansions.has('/settings'),
              'is-expanded': activeMenuExpansions.has('/settings'),
            }"
            @click="toggleActiveMenuExpansion('/settings')"
          >
            <template #label="{ expanded }">
              {{ $t('navigation.settings.main') }}
              <OIcon
                class="is-pulled-right"
                icon="chevron_up"
                :class="{ 'is-expanded': expanded }"
              />
            </template>
            <OMenuItem
              v-if="hasAccess({ action: 'GET', resource: '/profile' })"
              icon="profile"
              :label="$t('navigation.settings.my-profile')"
              tag="router-link"
              to="/settings/profile"
              class="menu-item menu-item--sub"
              :class="{ 'is-active': isActive('/settings/profile') }"
            />
          </OMenuItem>

          <OMenuItem
            v-if="signedIn"
            icon="sign_out"
            :label="$t('navigation.sign-out')"
            class="menu-item"
            @click="signOut()"
          />
        </div>

        <!-- new style multinational customers -->
        <div v-else aria-role="menu" class="menu-list">
          <template v-for="item in items">
            <OMenuItem
              :key="item.route"
              v-if="
                hasAccess({
                  action: 'GET',
                  resource: multinationalSlug(item),
                })
              "
              :icon="item.icon"
              :label="renderMenuItemLabel(item)"
              tag="router-link"
              :to="{ name: item.route }"
              :active="isActive(item.route)"
              class="menu-item"
              :class="{ 'is-active': isActive(item.route) }"
            />
          </template>
          <OMenuItem
            v-if="signedIn"
            icon="sign_out"
            :label="$t('navigation.sign-out')"
            class="menu-item"
            @click="signOut()"
          />
        </div>
      </OMenu>
      <SapSwitcher class="sap-switcher" />
    </OSidebar>
    <OLoading :active="loading" :full-page="true" />
  </div>
</template>

<script lang="ts">
import { signOut } from 'aws-amplify/auth';
import { TranslateResult } from 'vue-i18n';
import AuthGroup from '@/logic/auth/AuthGroup';
import ColorBox from '@/components/sidebar/ColorBox.vue';
import { Endpoint } from '@/logic/auth/Endpoint';
import SapSwitcher from '@/components/sidebar/SapSwitcher.vue';
import { defineComponent, ref } from 'vue';
import useScreenWidths from '@/composables/useScreenWidths';
import { useRoute } from 'vue-router';

interface MenuItem {
  route: string;
  icon: string;
  label?: string;
  meta?: any;
}

export default defineComponent({
  components: { SapSwitcher, ColorBox },
  setup() {
    const { isWidescreen, TABLET_BREAKPOINT } = useScreenWidths();
    const activeMenuExpansions = ref(new Set());

    const toggleActiveMenuExpansion = (menuPath: string): void => {
      if (activeMenuExpansions.value.has(menuPath)) {
        activeMenuExpansions.value.delete(menuPath);
      } else {
        activeMenuExpansions.value.add(menuPath);
      }
    };

    const { path } = useRoute();
    const expandablePaths = ['/settings', '/menu-engineering'];

    expandablePaths.forEach((expandablePath: string) => {
      if (path.startsWith(expandablePath)) {
        activeMenuExpansions.value.add(expandablePath);
      }
    });

    return {
      TABLET_BREAKPOINT,
      isWidescreen,
      toggleActiveMenuExpansion,
      activeMenuExpansions,
    };
  },
  data() {
    return {
      AuthGroup,
      menuActive: false,
      loading: false,
    };
  },
  computed: {
    items(): MenuItem[] {
      const dashboard: MenuItem = {
        route: 'dashboard',
        icon: 'dashboard',
      };
      const eatThis: MenuItem = {
        route: 'eat-this',
        icon: 'eat-this',
      };
      const profile: MenuItem = {
        route: 'profile',
        icon: 'settings',
        label: 'settings.main',
      };
      const items: Record<string, MenuItem[]> = {};
      items[AuthGroup.Multinational] = [
        dashboard,
        {
          route: 'lwm-company-information',
          icon: 'home',
          meta: { generic: true },
        },
        {
          route: 'partnership',
          icon: 'partnerships',
        },
        {
          route: 'approved-products', // portfolio
          icon: 'folder-check',
        },
        {
          route: 'market-activations',
          icon: 'megaphone',
        },
        {
          route: 'trend-information',
          icon: 'graph',
          meta: { generic: true },
        },
        {
          route: 'new-products', // innovations & trends
          icon: 'flask',
        },
        {
          route: 'training', // ops and training
          icon: 'training',
        },
        {
          route: 'sustainability',
          icon: 'trees',
          meta: { generic: true },
        },
        {
          route: 'lwm-contact',
          icon: 'phone',
        },
        {
          route: 'best-practices',
          icon: 'book-open',
        },
        eatThis,
        profile,
      ];

      if (this.hasAccess(undefined, AuthGroup.Multinational)) {
        return items[AuthGroup.Multinational];
      }

      return [dashboard, eatThis];
    },
    logo(): string {
      if (this.hasAccess(undefined, AuthGroup.Multinational)) {
        const img =
          this.$store.state.content.multinationalContent?.content?.logo;
        if (img) {
          return this.$helpers.getApiImageThumbnail(img);
        }
      }
      return '/img/logo.svg';
    },
    isImpersonating(): boolean {
      return this.$store.getters['profile/isImpersonated'];
    },
    signedIn(): boolean {
      return this.$store.getters['auth/signedIn'];
    },
  },
  methods: {
    isActive(menuTo: string): boolean {
      return this.$route.path === menuTo || this.$route.name === menuTo;
    },
    renderMenuItemLabel(item: any): TranslateResult {
      return this.$t(`navigation.${item.label || item.route}`, {
        company: this.$store.state.content.multinationalContent?.content?.title,
      });
    },
    isExpanded(startsWith: string): boolean {
      return this.$route.path.startsWith(startsWith);
    },
    hasAccess(endpoint?: Endpoint, group?: string): boolean {
      return this.$store.getters['auth/hasAccess'](endpoint, group);
    },
    multinationalSlug(item: any): string {
      const slug = item.meta?.generic
        ? 'generic'
        : this.$store.getters['auth/multinationalSlug'];
      return `/content/multinational_portal/*/${slug}/${item.route}`;
    },
    toggleMenu(active?: boolean) {
      this.menuActive = active !== undefined ? active : !this.menuActive;
    },
    async signOut() {
      this.loading = true;
      await signOut({ global: true });
      this.loading = false;
      this.toggleMenu(false);
    },
    routeChanged() {
      this.toggleMenu(false);
    },
    groupsChange() {
      if (this.hasAccess(undefined, AuthGroup.Multinational)) {
        this.$store.dispatch('content/fetchMultinationalContent', {
          i18n: this.$i18n,
          multinational: this.$store.getters['auth/multinationalSlug'],
        });
      }
    },
  },
  watch: {
    $route: [
      {
        handler: 'routeChanged',
      },
    ],
    '$store.state.auth.userRoles': [
      { immediate: true, handler: 'groupsChange' },
    ],
  },
});
</script>

<style lang="scss" scoped>
.top-bar {
  position: fixed;
  width: 100%;
  display: block;
  z-index: 20;
  height: 50px;

  @include widescreen {
    display: none;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 47px;
    background: $white;
  }
}

:deep(.sidebar.b-sidebar) {
  z-index: 1;
  top: 30px;

  .sidebar-content {
    border: 1px solid $gallery;
  }

  @include until($widescreen) {
    .sidebar-content {
      margin-top: 50px;
    }

    .sidebar--impersonating {
      .sidebar-content {
        margin-top: 65px;
      }
    }
  }
}

.sidebar {
  position: relative;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    &--small {
      > img {
        height: 24px;
      }
    }

    &--large {
      height: 118px;
      flex: 0 0 118px;
      border-bottom: 1px solid $gallery;
      display: none;

      @include tablet {
        display: flex;
        justify-content: center;
      }

      > img {
        max-height: 62px;
      }

      &.sidebar__logo--multinational {
        height: 140px;
        flex: 0 0 140px;
      }
    }
  }
}

.sap-switcher {
  margin-top: auto;
  width: 100%;
}

.navigation {
  padding-top: 20px;
}

.menu-list {
  list-style-type: none;

  :deep(.menu-item) {
    border-radius: 4px;
    color: $tundora;
    padding: 20px 24px 10px;
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    width: 100%;

    > span {
      &.icon {
        flex: 0 0 auto;
      }
    }

    &:hover {
      transition: all 0.2s ease;
      opacity: 0.75;
    }

    .icon:first-child {
      font-size: 20px;
      margin-top: -3px;
      margin-right: 24px;
    }

    &.is-active,
    &.is-expanded {
      color: $trinidad;
    }
  }

  :deep(.menu-item--sub) {
    padding-left: 0;

    &:first-child {
      padding-top: 10px;
    }
  }

  :deep(.menu-item--has-sub) {
    .is-pulled-right {
      position: relative;
      bottom: 2px;
      margin-left: auto;
      transition: all 0.2s ease;
      font-size: 10px;
      transform: rotate(180deg);

      &.is-expanded {
        transform: rotate(0deg);
      }
    }

    ~ ul {
      margin-left: 73px;
    }
  }
}

.navbar-burger {
  width: 45px;
  height: 45px;

  &:hover {
    background-color: transparent;
  }

  > span {
    display: inline-block;
    height: 3px;
    border-radius: 3px;
    background: $prussian-blue;

    &:nth-child(2) {
      background: $trinidad;
    }

    &:nth-child(3) {
      background: $web-orange;
    }
  }

  &.is-active {
    > span {
      height: 2px;
      background: $trinidad;
    }
  }
}
</style>
