import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "calculation-totals__title" }
const _hoisted_2 = { class: "calculation-totals__weight" }
const _hoisted_3 = { class: "calculation-totals__boxes" }
const _hoisted_4 = { class: "calculation-totals__value" }
const _hoisted_5 = { class: "calculation-totals__pallets" }
const _hoisted_6 = { class: "calculation-totals__value" }
const _hoisted_7 = {
  key: 0,
  class: "calculation-totals__button button-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`calculation-totals calculation-totals--${_ctx.mod} calculation-totals--${_ctx.status}`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["calculation-totals__content", 
        _ctx.mod === 'select-products'
          ? 'layout__content layout__content--large'
          : ''
      ])
    }, [
      _createElementVNode("strong", _hoisted_1, _toDisplayString(_ctx.$t('container-calculation.result.totals.title')), 1),
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('container-calculation.result.totals.weight')), 1),
        _createElementVNode("strong", null, _toDisplayString(_ctx.grossWeight ? _ctx.$n(_ctx.grossWeight) : '-') + _toDisplayString(_ctx.$t('container-calculation.result.totals.kg')), 1)
      ]),
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('container-calculation.result.totals.boxes')), 1),
        _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.boxes ? _ctx.$n(_ctx.boxes) : '-'), 1)
      ]),
      _createElementVNode("span", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('container-calculation.result.totals.pallets')), 1),
        _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.pallets || '-'), 1)
      ]),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}