<template>
  <div class="select-products">
    <header class="layout__header layout__content layout__content--large">
      <div class="select-products__title">
        <h1 class="title title--direct">{{ $t('orders.new.title') }}</h1>
        <p class="layout__intro-text layout__intro-text--orders">
          {{ $t('orders.new.intro') }}
        </p>
      </div>
      <UpdateButton
        scope="new-product-cold-store"
        form="2024-07-01"
        till="2024-09-30"
        promoted-till="2024-09-15"
      />
    </header>

    <ProductSearchBar class="select-products__search" />

    <div
      class="select-products__preferred-warehouse-warning layout__content layout__content--large"
      v-if="
        $store.getters['order/activePreferredWarehouse'] !== '' &&
        $store.getters['order/warehouseCount'] > 1
      "
    >
      <div class="select-products__preferred-warehouse-warning__content">
        <o-icon
          icon="info_circle"
          :type="'is-secondary'"
          class="select-products__preferred-warehouse-warning__icon"
        />
        <p
          class="select-products__preferred-warehouse-warning__text"
          v-html="preferredWarehouseMessage"
        />
      </div>
    </div>
    <div
      class="select-products__pre-table-header select-products__pre-table-header--mobile layout__content"
    >
      {{ $t('orders.order-item-list.header.products') }}
      <OTooltip :label="$t('orders.new.help-text')" variant="light" multiline>
        <OIcon icon="info_circle" variant="primary" />
      </OTooltip>
      <CustomLabelSwitch
        class="select-products__custom-label-switch"
        @changed="handleLabelChange"
        :positionLeft="true"
      />
    </div>

    <a id="results" />

    <div class="select-products__table">
      <div class="layout__content layout__content--large">
        <o-table
          :data="[]"
          root-class="product-item-list product-item-list--no-body"
        >
          <o-table-column :label="$t('orders.order-item-list.header.product')">
            <template v-slot:header="{ column }"
              >{{ column.label }}
              <OTooltip
                :label="$t('orders.new.help-text')"
                variant="light"
                multiline
              >
                <OIcon icon="info_circle" variant="primary" />
              </OTooltip>
              <CustomLabelSwitch
                class="select-products__custom-label-switch"
                @changed="handleLabelChange"
              />
            </template>
          </o-table-column>
          <o-table-column
            :label="$t('orders.order-item-list.header.code')"
            :width="110"
          />
          <o-table-column
            :label="$t('orders.order-item-list.header.warehouse')"
            :width="120"
          />
          <o-table-column
            :label="$t('orders.order-item-list.header.art')"
            :width="100"
          />
          <o-table-column
            :label="$t('orders.order-item-list.header.pallets')"
            :width="90"
            root-class="is-centered"
          />
          <o-table-column
            :label="$t('orders.order-item-list.header.layers')"
            :width="90"
            root-class="is-centered"
          />
          <o-table-column
            :label="$t('orders.order-item-list.header.boxes')"
            :width="80"
            root-class="is-centered"
          />
        </o-table>
      </div>
      <SelectProductGroup
        v-for="groupLabel in groupLabels"
        :key="groupLabel"
        :group-label="groupLabel"
        :products="groups[groupLabel]"
        :custom-text="!LWText"
        @change="handleCartChanged"
      />
      <OLoading :active="loading" :full-page="false" cancelable />
    </div>
    <NewOrderFooter step="select-products" />
  </div>
</template>

<script lang="ts">
import { OrderItem, SapProduct } from '@/api/model';
import SelectProductGroup from '@/components/orders/SelectProductGroup.vue';
import ProductSearchBar from '@/components/orders/ProductSearchBar.vue';
import CustomLabelSwitch from '@/components/orders/new-order/CustomLabelSwitch.vue';
import { ProductGroup } from '@/store/OrderStore';
import NewOrderFooter from '@/components/orders/new-order/NewOrderFooter.vue';
import { defineComponent } from 'vue';
import UpdateButton from '@/components/updates/UpdateButton.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';

export default defineComponent({
  components: {
    UpdateButton,
    NewOrderFooter,
    SelectProductGroup,
    ProductSearchBar,
    CustomLabelSwitch,
  },
  data: () => ({
    LWText: false,
  }),
  computed: {
    groups(): ProductGroup[] {
      return this.$store.getters['order/productGroups'](true);
    },
    groupLabels(): string[] {
      const all = Object.keys(this.groups);
      const filter = this.$store.state.order.filterGroups;
      if (filter.size === 0) {
        return all;
      }
      return all.filter((group) => filter.has(group));
    },
    unavailableProducts(): OrderItem[] {
      const availableProducts = this.$store.state.order.products;
      const items = this.$store.state.order.cart.OrderItems;
      if (!items) {
        return [];
      }
      return items.filter(
        (item: OrderItem) =>
          !availableProducts.find(
            (p: SapProduct) => p.ProductNr === item.ProductNr,
          ),
      );
    },
    preferredWarehouseMessage(): string {
      const activeWarehouse =
        this.$store.getters['order/activePreferredWarehouse'];
      const warehouse =
        this.$store.getters['order/getWarehouseLabel'](activeWarehouse);

      const text = this.$t('orders.new.preferred-warehouse', {
        code: warehouse,
      }).toString();

      if (text) {
        return text.replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>');
      }

      return '';
    },
    loading(): boolean {
      return (
        this.groupLabels.length === 0 &&
        this.$store.state.order.productSearchQuery === ''
      );
    },
  },
  methods: {
    handleLabelChange(event: any) {
      this.LWText = event;
    },
    handleCartChanged() {
      // Set flag so suggestion can be reset after next is clicked
      this.$store.commit('order/setCartChanged', true);
    },
    unavailableProductsChanged(items: OrderItem[]) {
      if (!items || !items.length) {
        return;
      }
      // Show a dialog if there are unavailable products in the cart
      const title = this.$t(
        'orders.new.products-unavailable.title',
        items.length,
      );
      const label = this.$t('orders.new.products-unavailable.item-label');
      const listContent = items
        .map((i) => `<li>${i.ItemText} - ${label}</li>`)
        .join('');
      const itemList = `<ul class="has-text-danger">${listContent}</ul>`;
      const message = `<div class="content">${itemList}
          <p>${this.$t('orders.new.products-unavailable.message') as string}</p>
          </div>`;

      this.$oruga.modal.open({
        component: BaseDialog,
        props: {
          title,
          message,
          confirmText: this.$t(
            'orders.new.products-unavailable.button',
          ) as string,
          onConfirm: () => {
            // Remove unavailable items from cart
            this.$store.commit('order/removeCartItems', items);
          },
        },
        trapFocus: true,
      });
    },
  },
  watch: {
    unavailableProducts: [
      { immediate: true, handler: 'unavailableProductsChanged' },
    ],
  },
});
</script>

<style lang="scss" scoped>
.select-products {
  &__help-text {
    display: none;
    background-color: $white;
    border: 1px solid $gallery;
    box-shadow: 0 4px 14px rgba($black, 0.06);
    border-radius: 10px;
    padding: 16px;
    font-size: 14px;
    color: $swamp;

    @include tablet {
      display: block;
      max-width: 250px;
      flex: 0 0 50%;
      margin-top: 20px;
    }
  }

  &__search {
    margin-bottom: 40px;
  }

  #results {
    position: relative;
    bottom: 80px;

    @include desktop {
      bottom: 40px;
    }
  }

  &__groups {
    min-height: 250px;
    position: relative;
  }

  &__table {
    margin-bottom: 80px;

    :deep(.table-wrapper) {
      th {
        span:has(.custom-label-switch__action) {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  &__table-header {
    th:first-child {
      padding-left: 40px !important;
    }
  }

  &__pre-table-header {
    background-color: $wild-sand;
    display: flex;
    align-items: center;
    height: 49px;
    padding-left: 15px;
    padding-right: 8px;

    &--mobile {
      @include tablet {
        display: none;
      }
    }
  }

  &__preferred-warehouse-warning {
    margin-block: 26px;

    &__content {
      display: flex;
      align-items: center;
      gap: 16px;
      border: 1px solid $rose-bud;
      border-radius: 4px;
      background-color: $bridesmaid;
      padding: 16px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

:deep(.custom-label-switch__action) {
  margin-left: auto;
}

:deep(.b-tooltip) .b-tooltip-content.is-multiline {
  width: 240px;
  text-align: left;
}

:deep(.b-tooltip-trigger) {
  display: flex;

  .icon {
    margin-left: 0 !important;
  }
}
</style>
