export interface LocaleOption {
  id: string;
  shortLabel: string;
  label: string;
  flag: string;
}

export class Languages {
  static languages: LocaleOption[] = [
    {
      id: 'de',
      shortLabel: 'de',
      label: 'Deutsch',
      flag: 'de',
    },
    {
      id: 'en-GB',
      shortLabel: 'en',
      label: 'English',
      flag: 'uk',
    },
    {
      id: 'es',
      shortLabel: 'es',
      label: 'Espanol',
      flag: 'es',
    },
    {
      id: 'fr',
      shortLabel: 'fr',
      label: 'Français',
      flag: 'fr',
    },
    {
      id: 'it',
      shortLabel: 'it',
      label: 'Italiano',
      flag: 'it',
    },
    {
      id: 'nl',
      shortLabel: 'nl',
      label: 'Nederlands',
      flag: 'nl',
    },
  ];

  static getLanguages() {
    return Languages.languages;
  }

  static getLanguageById(id: string): LocaleOption | undefined {
    const language = Languages.languages.find((lang) => lang.id === id);
    if (language) {
      return language;
    }
    return undefined;
  }
}
