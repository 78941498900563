import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block block--textimage"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.block)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.block.text)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              innerHTML: _ctx.$helpers.parseRichText(_ctx.block.text),
              class: "block__text rich-text"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.block.images.length > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.block.images, (image) => {
              return (_openBlock(), _createElementBlock("img", {
                key: image.id,
                src: _ctx.$helpers.getApiImageThumbnail(image),
                class: "block__image",
                alt: image.title
              }, null, 8, _hoisted_3))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}