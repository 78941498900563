import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e29a01ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "block--contact__avatar block--contact__avatar--empty"
}
const _hoisted_3 = { class: "block--contact__info" }
const _hoisted_4 = {
  key: 0,
  class: "block--contact__name"
}
const _hoisted_5 = {
  key: 1,
  class: "block--contact__title"
}
const _hoisted_6 = { class: "block--contact__contact" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block.contacts, (contact) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "block block--contact form-card",
        key: contact.id
      }, [
        (contact.avatar)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.getAvatarUrl(contact.avatar.url),
              alt: "Contact avatar",
              class: "block--contact__avatar"
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_OIcon, { icon: "avatar" })
            ])),
        _createElementVNode("div", _hoisted_3, [
          (contact.fullName)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(contact.fullName), 1))
            : _createCommentVNode("", true),
          (contact.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(contact.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (contact.mainEmail)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "block--contact__email",
                  href: `mailto:${contact.mainEmail}`
                }, [
                  _createVNode(_component_OIcon, {
                    icon: "envelope",
                    class: "block--contact__icon block--contact__icon--email"
                  }),
                  _createTextVNode(_toDisplayString(contact.mainEmail), 1)
                ], 8, _hoisted_7))
              : _createCommentVNode("", true),
            (contact.mainPhone)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "block--contact__phone",
                  href: _ctx.getPhone(contact.mainPhone)
                }, [
                  _createVNode(_component_OIcon, {
                    icon: "phone",
                    class: "block--contact__icon block--contact__icon--phone"
                  }),
                  _createTextVNode(_toDisplayString(contact.mainPhone), 1)
                ], 8, _hoisted_8))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    }), 128))
  ]))
}