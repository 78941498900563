import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "restaurant-form" }
const _hoisted_2 = { class: "title layout__content" }
const _hoisted_3 = { class: "layout__content" }
const _hoisted_4 = { class: "restaurant-form__intro" }
const _hoisted_5 = { class: "buttonm-row mobile-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OInput = _resolveComponent("OInput")!
  const _component_OField = _resolveComponent("OField")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_OLoading = _resolveComponent("OLoading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('menuEngineering.overview.addDishEquation')), 1),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('registration.label.restaurantName')), 1),
        _createVNode(_component_OField, {
          label: _ctx.$t('registration.placeholder.restaurantName'),
          class: "mobile-padding"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_OInput, {
              modelValue: _ctx.restaurant.restaurantName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.restaurant.restaurantName) = $event)),
              placeholder: _ctx.$t('registration.placeholder.restaurantName'),
              required: ""
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_OButton, {
            rounded: "",
            variant: "primary",
            "native-type": "submit",
            disabled: _ctx.saving || !_ctx.restaurant.restaurantName,
            class: "button-row__button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.interface.save')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createVNode(_component_OLoading, {
          active: _ctx.saving,
          "full-page": false
        }, null, 8, ["active"])
      ])
    ], 32)
  ]))
}