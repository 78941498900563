import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-invoices" }
const _hoisted_2 = { class: "layout__content layout__content--large" }
const _hoisted_3 = { class: "title title--direct" }
const _hoisted_4 = { class: "layout__intro-text" }
const _hoisted_5 = {
  key: 0,
  class: "layout__content layout__content--large"
}
const _hoisted_6 = { class: "my-invoices__status" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "table__empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MfaWarning = _resolveComponent("MfaWarning")!
  const _component_InvoicesActionBar = _resolveComponent("InvoicesActionBar")!
  const _component_ValueCell = _resolveComponent("ValueCell")!
  const _component_o_table_column = _resolveComponent("o-table-column")!
  const _component_BaseCurrencyAmount = _resolveComponent("BaseCurrencyAmount")!
  const _component_ShowSalesOrder = _resolveComponent("ShowSalesOrder")!
  const _component_InvoiceStatus = _resolveComponent("InvoiceStatus")!
  const _component_OIcon = _resolveComponent("OIcon")!
  const _component_InvoiceDetails = _resolveComponent("InvoiceDetails")!
  const _component_o_table = _resolveComponent("o-table")!
  const _component_OLoading = _resolveComponent("OLoading")!
  const _component_OPagination = _resolveComponent("OPagination")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('invoices.header.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('invoices.header.intro')), 1),
          (!_ctx.hasMfa)
            ? (_openBlock(), _createBlock(_component_MfaWarning, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasMfa)
          ? (_openBlock(), _createElementBlock("main", _hoisted_5, [
              _createVNode(_component_InvoicesActionBar),
              _createVNode(_component_o_table, {
                data: _ctx.invoices.items,
                "show-detail-icon": false,
                "opened-detailed": _ctx.defaultOpenedDetails,
                detailed: "",
                "detail-key": _ctx.detailKey,
                onDetailsOpen: _cache[0] || (_cache[0] = (row, index) => _ctx.closeAllOtherDetails(row, index)),
                striped: "",
                "backend-sorting": "",
                "default-sort": [_ctx.sortField, _ctx.sortOrder],
                "default-sort-direction": "'desc'",
                onSort: _ctx.onSort,
                class: "table--detail-column",
                "sort-icon": "chevron_up",
                "sort-icon-size": "is-small"
              }, {
                detail: _withCtx((props) => [
                  _createVNode(_component_InvoiceDetails, {
                    invoice: props.row
                  }, null, 8, ["invoice"])
                ]),
                empty: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('invoices.empty')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_o_table_column, {
                    label: _ctx.$t('invoices.column.invoice-nr'),
                    sortable: "",
                    field: "BillDoc"
                  }, {
                    default: _withCtx((props) => [
                      _createVNode(_component_ValueCell, {
                        value: props.row.BillDoc
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_o_table_column, {
                    "header-class": "is-hidden-mobile",
                    "td-attrs": () => ({ class: 'is-hidden-mobile' }),
                    label: _ctx.$t('invoices.column.posting-date'),
                    sortable: "",
                    field: "BillDate"
                  }, {
                    default: _withCtx((props) => [
                      _createVNode(_component_ValueCell, {
                        value: props.row.BillDate
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Helpers.formatSapDate(props.row.BillDate)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_o_table_column, {
                    "header-class": "is-hidden-mobile",
                    "td-attrs": () => ({ class: 'is-hidden-mobile' }),
                    label: _ctx.$t('invoices.column.expire-date'),
                    sortable: "",
                    field: "DueDate"
                  }, {
                    default: _withCtx((props) => [
                      _createVNode(_component_ValueCell, {
                        value: props.row.DueDate
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Helpers.formatSapDate(props.row.DueDate)), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_o_table_column, {
                    label: _ctx.$t('invoices.column.amount'),
                    sortable: "",
                    field: "NetValue"
                  }, {
                    default: _withCtx((props) => [
                      _createVNode(_component_ValueCell, {
                        value: 
                props.row.Currency !== undefined &&
                props.row.NetValue !== undefined
              
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BaseCurrencyAmount, {
                            currency: props.row.Currency,
                            amount: props.row.NetValue
                          }, null, 8, ["currency", "amount"])
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_o_table_column, {
                    "header-class": "is-hidden-mobile",
                    "td-attrs": () => ({ class: 'is-hidden-mobile' }),
                    label: _ctx.$t('invoices.column.sales-order')
                  }, {
                    default: _withCtx((props) => [
                      _createVNode(_component_ValueCell, {
                        value: props.row.SalesOrderId
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ShowSalesOrder, {
                            label: _ctx.$t('invoices.column.sales-order-show'),
                            invoice: props.row
                          }, null, 8, ["label", "invoice"])
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_o_table_column, {
                    label: _ctx.$t('invoices.column.status'),
                    "td-attrs": () => ({ class: 'table__cell--no-padding' }),
                    sortable: "",
                    field: "AccStatusText"
                  }, {
                    default: _withCtx((props) => [
                      _createVNode(_component_ValueCell, {
                        value: props.row.AccStatusText
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_InvoiceStatus, {
                              invoice: props.row
                            }, null, 8, ["invoice"])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_o_table_column, {
                    label: _ctx.$t('invoices.column.details'),
                    "td-attrs": () => ({ class: 'table__cell--no-padding' })
                  }, {
                    default: _withCtx((props) => [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (props.toggleDetails(props.row)),
                        class: "toggle-details"
                      }, [
                        _createVNode(_component_OIcon, {
                          class: _normalizeClass(["toggle-details__icon", {
                  'toggle-details__icon--active': _ctx.isDetailed(props.row.Id),
                }]),
                          icon: "arrow_back"
                        }, null, 8, ["class"])
                      ], 8, _hoisted_7)
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["data", "opened-detailed", "detail-key", "default-sort", "onSort"]),
              _createVNode(_component_OLoading, {
                active: _ctx.isLoading,
                "full-page": false
              }, null, 8, ["active"]),
              _createVNode(_component_OPagination, {
                "root-class": "table__pagination",
                total: _ctx.invoices.total,
                "range-after": 3,
                "range-before": 3,
                current: _ctx.currentPage,
                "per-page": _ctx.perPage,
                "onUpdate:current": _ctx.changePage
              }, null, 8, ["total", "current", "per-page", "onUpdate:current"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}