<template>
  <section>
    <div class="container-list" @click="detailActive = !detailActive">
      <strong class="container-list__label">
        <OIcon icon="container" class="container-list__icon" />{{
          $t('orders.card.header.container-numbers-short')
        }}</strong
      ><span>
        <span class="container-list__numbers"
          ><span
            v-for="container in shortList"
            :key="container.id"
            class="container-list__number"
            >{{ container.container_number }}</span
          ></span
        ><span v-if="remainingCount > 0">…</span>
      </span>
      <span class="container-list__remaining" v-if="remainingCount > 0"
        >+{{ remainingCount }}</span
      >
    </div>
    <BaseMessage
      v-model="detailActive"
      class="container-list__detail"
      :title="
        $t('orders.card.header.container-numbers-long', containers.length)
      "
      size="small"
    >
      <div class="container-list__detail-grid">
        <span
          v-for="container in containers"
          :key="container.id"
          class="container-list__detail-item"
        >
          {{ container.container_number }}</span
        >
      </div>
    </BaseMessage>
  </section>
</template>

<script lang="ts">
import { SapOrderContainer } from '@/api';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      detailActive: false,
    };
  },
  computed: {
    shortCount(): number {
      return 2;
    },
    shortList(): SapOrderContainer[] {
      return this.containers.slice(0, this.shortCount);
    },
    remainingCount(): number {
      return this.containers.length - this.shortCount;
    },
  },
  props: {
    containers: { type: Array as () => SapOrderContainer[], default: () => [] },
  },
});
</script>

<style lang="scss" scoped>
.container-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  > * {
    flex: 1;
  }

  &__label {
    flex: 0 1 60px;
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: 16px;
  }

  &__number:not(:last-child) {
    &::after {
      content: ', ';
    }
  }

  &__remaining {
    flex: 0 1 40px;
    border-radius: 40px;
    padding: 0 6px;
    background-color: $tundora;
    color: $white;
    text-align: center;
  }

  &__detail {
    position: absolute;
    right: 0;
    z-index: 3;
    max-width: 274px;
    box-shadow: $card-box-shadow;
  }

  &__detail-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
  }
}
</style>
