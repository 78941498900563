import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-58590316"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category" }
const _hoisted_2 = { class: "title layout__content" }
const _hoisted_3 = { class: "category-title" }
const _hoisted_4 = {
  key: 0,
  class: "intro-text layout__content"
}
const _hoisted_5 = { class: "category__blocks" }
const _hoisted_6 = { class: "category__button layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DishList = _resolveComponent("DishList")!
  const _component_ContentBlocks = _resolveComponent("ContentBlocks")!
  const _component_OButton = _resolveComponent("OButton")!
  const _component_LayoutDefault = _resolveComponent("LayoutDefault")!

  return (_openBlock(), _createBlock(_component_LayoutDefault, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('menuEngineering.overview.categoryResult.intro')) + " ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`menuEngineering.label.${this.category}`).toLowerCase()), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('menuEngineering.overview.categoryResult.outro')), 1)
        ]),
        (_ctx.introText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.introText), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_DishList, {
          dishes: _ctx.dishes,
          course: _ctx.course,
          score: _ctx.score,
          "show-header": true,
          onDeleteDish: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteDish($event))),
          class: "dish-list--category"
        }, null, 8, ["dishes", "course", "score"]),
        _createElementVNode("section", _hoisted_5, [
          _createVNode(_component_ContentBlocks, {
            blocks: _ctx.blocks,
            class: "layout__content"
          }, null, 8, ["blocks"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_OButton, {
              rounded: "",
              variant: "primary",
              tag: "router-link",
              to: "/menu-engineering/results"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('menuEngineering.results.back-to-results')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}