import { SuluDocument } from '@/logic/cms/sulu';
import getConfiguration from '@/store/getConfiguration';
import { ContentApi } from '@/api';

async function getApi() {
  const configuration = await getConfiguration();
  return new ContentApi(configuration, '');
}

const initialState = () => ({
  content: undefined as unknown as SuluDocument,
  multinationalContent: undefined as unknown as SuluDocument,
});
export type ContentState = ReturnType<typeof initialState>;

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state: ContentState) {
      Object.assign(state, initialState());
    },

    setContent(state: ContentState, content: SuluDocument) {
      state.content = content;
    },
    setMultinationalContent(state: ContentState, content: SuluDocument) {
      state.multinationalContent = content;
    },
  },
  actions: {
    async getContent({ commit }: { commit: Function }, params: any) {
      const { route, i18n, webspace, slug } = params;
      const locale = i18n.locale.toLowerCase();
      const webspaceEndpoint = webspace || 'customer_portal';
      const contentPath = route.fullPath || '';
      commit('setContent', undefined);
      const api = await getApi();
      const path = `${slug || ''}${contentPath}`.replace(/^\/+/g, '');
      const { data } = await api.contentWebspaceLocalePathGet(
        webspaceEndpoint,
        locale,
        path,
      );
      commit('setContent', data);
    },

    async fetchMultinationalContent(
      { commit }: { commit: Function },
      params: any,
    ) {
      const { i18n, multinational } = params;
      const locale = i18n.locale.toLowerCase();
      const api = await getApi();
      const path = `${multinational}`.replace(/^\/+/g, '');
      const { data } = await api.contentWebspaceLocalePathGet(
        'multinational_portal',
        locale,
        path,
      );
      commit('setMultinationalContent', data);
    },
  },
};
