<template>
  <LayoutDefault>
    <div class="add-dish">
      <transition name="fade">
        <div class="menu-engineering" v-if="restaurants.length" key="add-dish">
          <h1 class="title layout__content">
            {{ $t('menuEngineering.overview.addDishEquation') }}
            {{ $t('general.for') }} “{{ restaurant.restaurantName }}”
          </h1>

          <DishForm
            v-model="dish"
            :restaurants="restaurants"
            :show-results-button="minimumAmountOfDishesReached"
            @submit="save"
            :saving="saving"
          />

          <DishList
            :dishes="filteredDishes"
            :restaurant-name="restaurant.restaurantName"
            :course="course"
            :show-title="true"
            @delete-dish="deleteDish($event)"
          />
        </div>
        <div v-else-if="!loadingRestaurants" key="add-restaurant">
          <RestaurantNameForm />
        </div>
        <div v-else key="loading">
          <OLoading :active="loadingRestaurants" :full-page="false" />
        </div>
      </transition>
    </div>
  </LayoutDefault>
</template>

<script lang="ts">
import LayoutDefault from '@/layouts/LayoutDefault.vue';
import DishForm from '@/components/menu-engineering/DishForm.vue';
import DishList from '@/components/menu-engineering/DishList.vue';
import MenuEngineeringLogic from '@/logic/menu-engineering/MenuEngineeringLogic';
import { Dish, DishCourseEnum, Restaurant } from '@/api/model';
import RestaurantNameForm from '@/components/profile/RestaurantNameForm.vue';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { RestaurantNameForm, LayoutDefault, DishForm, DishList },
  mixins: [MessageToastMixin],
  data() {
    const dish: Dish = {};

    return {
      dish,
      saving: false,
      loadingRestaurants: false,
    };
  },
  computed: {
    state(): any {
      return this.$store.state.menuEngineering;
    },
    dishes(): Dish[] {
      return this.state.dishes;
    },
    restaurants(): Restaurant[] {
      return this.$store.state.restaurant.restaurants;
    },
    filteredDishes(): Dish[] {
      return MenuEngineeringLogic.filterDishes(
        this.dishes,
        this.dish.restaurantId,
        this.dish.course,
      );
    },
    minimumAmountOfDishesReached(): boolean {
      return MenuEngineeringLogic.minimumAmountOfDishesReached(
        this.filteredDishes,
      );
    },
    restaurant(): Restaurant | undefined {
      if (!this.restaurants || this.restaurants.length === 0) {
        return undefined;
      }

      return (
        this.restaurants.find(
          (restaurant) => restaurant.id === this.dish.restaurantId,
        ) || this.restaurants[0]
      );
    },
    course(): string {
      return this.dish.course || DishCourseEnum.Breakfast;
    },
  },
  created() {
    this.resetDish();
    this.getRestaurants();
    this.getDishes();
  },
  methods: {
    resetDish() {
      this.dish = {
        restaurantId: this.$store.state.restaurant.activeRestaurantId,
        course: this.state.activeCourse,
        name: '',
        popularity: 0,
        costPrice: 0,
        chefTime: 0,
        staffTime: 0,
        price: 0,
      };
    },
    async getRestaurants() {
      this.loadingRestaurants = true;
      await this.$store.dispatch('restaurant/getRestaurants');
      this.loadingRestaurants = false;
    },
    getDishes() {
      this.$store.dispatch('menuEngineering/getDishes');
    },
    async save() {
      this.saving = true;
      try {
        await this.$store.dispatch('menuEngineering/saveDish', this.dish);
        this.saving = false;
        this.showSuccess(this.$t('menuEngineering.label.addDishEquation'));
        this.resetDish();
      } catch (e) {
        this.saving = false;
        this.showError(this.$t('general.messages.error'));
      }
    },
    async deleteDish(deleteDish: Dish) {
      try {
        await this.$store.dispatch('menuEngineering/deleteDish', deleteDish);
      } catch (e) {
        this.showError(this.$t('menuEngineering.label.dishNotDeleted'));
      }
    },
  },
});
</script>
