import { createApp } from 'vue';
import JsonCSV from 'vue-json-csv';
import * as Sentry from '@sentry/vue';
import Oruga, { useOruga } from '@oruga-ui/oruga-next';
import VueTelInput from 'vue-tel-input';
import { bulmaConfig } from '@oruga-ui/theme-bulma';
import { createGtm } from '@gtm-support/vue-gtm';
import { Amplify } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import CmsHelpers from '@/logic/cms/helpers';
import ProfileHelpers from '@/logic/profile/helpers';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './vee-validate';
import '@/scss/app.scss';
import awsExports from './aws-exports';
import './unregisterServiceWorker';
import 'vue-tel-input/vue-tel-input.css';

Amplify.configure(awsExports);

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
});

app.use(router);

app.use(
  createGtm({
    id: 'GTM-5Q8FNJX',
    vueRouter: router,
  }),
);

app.use(AmplifyVue);

axios.defaults.baseURL = process.env.VUE_APP_BASE_PATH;
app.use(VueAxios, axios);

app.config.globalProperties.$helpers = CmsHelpers;
app.config.globalProperties.$profile = ProfileHelpers;

app.use(VueTelInput);

require('./icons/icon.font');

const customConfig = {
  customIconPacks: {
    lwm: {
      sizes: {
        default: 'is-small',
        'is-small': 'is-size-5',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1',
      },
      iconPrefix: 'lwm-',
    },
  },
  iconPack: 'lwm',
};

const config = { ...bulmaConfig, ...customConfig };

const { oruga } = useOruga();
app.provide('$oruga', oruga);

app.component('downloadCsv', JsonCSV);

app.use(Oruga, config);
app.use(store);
app.use(i18n);

app.mount('#app');
