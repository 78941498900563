<template>
  <Field :name="$attrs.name" :rules="$attrs.rules" v-slot="{ errors, field }">
    <OField
      :label="$attrs.label"
      :message="errors.length ? errors[0] : ''"
      :class="classNames(errors)"
      v-bind="$attrs"
      :variant="errors.length ? 'danger' : ''"
      :horizontal="$attrs.horizontal"
    >
      <OInput
        v-bind="field"
        :modelValue="value"
        @update:modelValue="onInput"
        :type="inputType"
        :placeholder="$attrs.placeholder"
        :disabled="$attrs.disabled"
        step="1"
        :min="min"
        expanded
        :password-reveal="$attrs.passwordReveal"
        :icon-pack="$attrs.iconPack"
        :icon-right="unit !== '' ? 'unit' : undefined"
        :style="unit ? { '--unit': `'${unit}'` } : undefined"
      />
    </OField>
  </Field>
</template>

<script lang="ts">
import { Field } from 'vee-validate';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Field },
  computed: {
    rules(): any {
      return this.$attrs.rules;
    },
    inputType(): string {
      if (this.hasRule('quantity')) {
        return 'number';
      }
      const validTypes = ['text', 'email', 'password'];
      if (this.$attrs.rules) {
        const type = validTypes.find((rule) => this.hasRule(rule));
        if (type) {
          return type;
        }
      }
      return 'text';
    },
    min(): number | undefined {
      if (typeof this.$attrs.min !== 'string') {
        return undefined;
      }

      return this.hasRule('quantity') ? 0 : parseInt(this.$attrs.min, 10);
    },
  },
  methods: {
    classNames(errors: any[]): any {
      return {
        'field--has-error': errors.length,
        'field--required': this.hasRule('required'),
        'field--has-unit': !!this.unit,
      };
    },
    onInput(value: string | number) {
      const emit = this.nullable && !value ? null : value;
      this.$emit('update:modelValue', emit);
    },
    hasRule(rule: string): boolean {
      return this.rules && this.rules.includes(rule);
    },
  },
  props: {
    value: { type: [String, Number], default: '' },
    unit: { type: String, default: '' },
    nullable: { type: Boolean, default: false },
  },
});
</script>
