<template>
  <div v-if="isImpersonating" class="impersonate-bar">
    <div class="impersonate-bar__label">
      {{ $t('user-management.impersonate.bar.impersonating_as') }}
      <span class="impersonate-bar__email">{{ email }}</span>
    </div>
    <div class="impersonate-bar__stop" @click="stopImpersonate()">
      <OIcon icon="close_circle" class="impersonate-bar__icon" />
      <span class="impersonate-bar__label">
        {{ $t('user-management.impersonate.bar.stop') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isImpersonating(): boolean {
      return this.$store.getters['profile/isImpersonated'];
    },
    email(): string | null | undefined {
      return this.$store.getters['profile/email'];
    },
  },
  methods: {
    async stopImpersonate(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch('profile/stopImpersonate');
      this.loading = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.impersonate-bar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  padding: 0 24px;
  background-color: $bizarre;
  border: 1px solid $trinidad;
  box-shadow: 0 4px 14px rgba($black, 0.06);
  z-index: 999;

  @include until($tablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 92px;
  }

  &__label {
    font-size: 14px;
    line-height: 18px;
    color: $swamp;
  }

  &__email {
    font-weight: bold;
  }

  &__stop {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include until($tablet) {
      margin: 6px 0 0 -6px;
    }

    &:hover {
      .impersonate-bar__label {
        text-decoration: underline;
      }

      .impersonate-bar__icon {
        color: $primary;
        transition: color 0.1s ease-in-out;
      }
    }
  }

  &__icon {
    color: $trinidad;
  }
}
</style>
