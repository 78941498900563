<template>
  <OSelect
    :modelValue="value"
    @update:modelValue="input"
    v-bind="{ ...$attrs }"
  >
    <option v-for="locale in locales" :value="locale.value" :key="locale.value">
      {{ locale.label }}
    </option>
  </OSelect>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AuthGroup from '@/logic/auth/AuthGroup';
import { Languages } from '@/logic/locale/Languages';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    group: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    input(value: string) {
      this.$emit('input', value);
    },
    unlocalisedLabel(loc: string): string {
      const lang = Languages.getLanguageById(loc);
      return lang !== undefined
        ? lang.label
        : (this.$t(`general.locale.${loc}`) as string);
    },
  },
  computed: {
    isLocalised(): boolean {
      // If group property is given, this is the add user form, so
      // we should localise
      return this.group !== undefined;
    },
    isIndirect(): boolean {
      if (this.$store.getters['auth/memberOf'](AuthGroup.Indirect)) {
        return true;
      }

      return this.group === AuthGroup.Indirect;
    },
    locales(): any[] {
      const locales = this.isIndirect
        ? ['en-GB', 'it']
        : ['de', 'en-GB', 'es', 'fr', 'it', 'nl'];

      if (this.isLocalised) {
        return locales.map((loc) => ({
          value: loc,
          label: this.$t(`general.locale.${loc}`),
        }));
      }

      return locales.map((loc) => ({
        value: loc,
        label: this.unlocalisedLabel(loc),
      }));
    },
  },
});
</script>
