<template>
  <div class="step2">
    <OrderReferenceField />

    <div class="shipping layout__content layout__content--large">
      <header class="layout__header layout__header--title-only">
        <div class="shipping__title">
          <h1 class="title title--direct">{{ $t('orders.shipping.title') }}</h1>
        </div>
      </header>
      <div class="shipping__content">
        <ShipToField />
        <DeliveryDateField />
      </div>
      <NewOrderFooter
        :is-invalid="isInvalid"
        step="order-reference-and-shipping"
      ></NewOrderFooter>
    </div>
  </div>
</template>

<script lang="ts">
import OrderReferenceField from '@/components/orders/new-order/OrderReferenceField.vue';
import ShipToField from '@/components/orders/new-order/ShipToField.vue';
import DeliveryDateField from '@/components/orders/new-order/DeliveryDateField.vue';
import NewOrderFooter from '@/components/orders/new-order/NewOrderFooter.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DeliveryDateField,
    OrderReferenceField,
    ShipToField,
    NewOrderFooter,
  },
  props: {
    isInvalid: {
      type: Boolean,
    },
  },
});
</script>
