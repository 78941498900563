<template>
  <div class="totals">
    <div class="totals__values">
      <span class="totals__item">
        <span class="totals__label">
          {{ $t('claims.new.footer.total-volume') }}
        </span>
        <span class="totals__value">
          {{ totals.volume }}
          {{ $t('claims.kg') }}
        </span>
      </span>

      <span class="totals__item">
        <span class="totals__label">
          {{ $t('claims.new.footer.total-claim-amount') }}
        </span>
        <span class="totals__value">
          <BaseCurrencyAmount
            :currency="agreement.currency"
            :amount="totals.billbackAmount"
          />
        </span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import BaseCurrencyAmount from '@/components/BaseCurrencyAmount.vue';
import { SalesQuote } from '@/api';
import { defineComponent } from 'vue';

interface Totals {
  [key: string]: number;
}

export default defineComponent({
  components: { BaseCurrencyAmount },
  computed: {
    agreement(): SalesQuote | undefined {
      return this.$store.getters['claim/agreement'];
    },
    totals(): Totals {
      return this.$store.getters['claim/calculateClaimTotals'];
    },
  },
});
</script>

<style lang="scss" scoped>
.totals {
  display: flex;
  flex-direction: column;
  color: $primary;

  @include tablet {
    flex-direction: row;
  }

  &__item {
    @include tablet {
      &:not(:first-child) {
        margin-inline-start: 38px;
      }
    }
  }

  &__value {
    font-weight: bold;
  }
}
</style>
