<template>
  <div class="block form-card">
    <h2 class="title">{{ this.block.title }}</h2>
    <div
      v-for="(item, index) in this.block.downloadlist"
      :key="index"
      class="block__downloaditem-wrapper"
    >
      <video
        class="block__video-preview"
        controls
        v-if="item.file && item.file.url && item.file.isVideo"
      >
        <source :src="$helpers.getFileUrl(item.file.url)" type="video/mp4" />
      </video>
      <component
        :is="item.file ? 'a' : 'div'"
        class="block__downloaditem"
        :class="{ 'block__downloaditem--disabled': !item.file }"
        :href="item.file ? $helpers.getFileUrl(item.file.url) : ''"
        target="_blank"
        :download="item.label"
      >
        <OIcon class="block__download-icon" icon="download" />
        {{ item.label }}
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
.block {
  border-radius: 8px;

  &__downloaditem-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__downloaditem {
    border: 1px solid $gallery;
    color: $primary;
    display: block;
    padding: 16px;
    text-decoration: underline;

    @include tablet {
      padding: 16px 37px;
    }

    &:hover:not(&--disabled) {
      background-color: $wild-sand;
    }

    &--disabled {
      background-color: $wild-sand;
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &__download-icon {
    bottom: 2px;
    margin-right: 5px;
    position: relative;
  }

  .title {
    display: inline-block;
    font-size: 20px;
    line-height: 22px;
    margin: 20px 0;
    padding: 0 20px;

    @include tablet {
      font-size: 25px;
      line-height: 28px;
      padding: 0 40px;
    }
  }
}
</style>
