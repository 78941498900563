import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-675996ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block__bottom" }
const _hoisted_2 = {
  key: 0,
  class: "block__title"
}
const _hoisted_3 = {
  key: 1,
  class: "block__text vag-rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OIcon = _resolveComponent("OIcon")!

  return (_ctx.link && _ctx.isVisibleToUser())
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.linkTarget === '_self' ? 'router-link' : 'a'), {
        key: 0,
        class: "block form-card",
        to: _ctx.link,
        href: _ctx.link,
        target: _ctx.linkTarget
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "block__top",
            style: _normalizeStyle(`background-image: url(${_ctx.image});`)
          }, null, 4),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.block && _ctx.block.title)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.block.title), 1))
              : _createCommentVNode("", true),
            (_ctx.block && _ctx.block.text)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.block.text), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_OIcon, {
              icon: "arrow_back",
              class: "block__icon"
            })
          ])
        ]),
        _: 1
      }, 8, ["to", "href", "target"]))
    : _createCommentVNode("", true)
}