<template>
  <section class="order-status">
    <div
      class="order-status__data"
      @click="openDetailedOrderStatus"
      :title="$t('orders.card.status.more-info')"
      role="button"
    >
      <StatusPill icon="clipboard" :status-color="statusModifier">
        <template #header
          ><strong>{{ $t(`orders.status.${status}`) }}</strong
          ><span v-if="showPartly"> | {{ $t(`orders.status.partly`) }}</span
          ><span v-if="status === 'DELIVERED' && !isContainer">
            <span v-if="deliveryDate">&nbsp;{{ deliveryDateFormatted }} </span>
            <span v-else>&nbsp;{{ eta }} </span>
          </span>
        </template>
        <template #main v-if="showEta"
          ><strong v-if="showEta"
            >{{ $t('orders.shipping.eta.eta') }}&nbsp;{{ eta }}</strong
          ><span v-if="delay">&nbsp;{{ delay }}</span></template
        >
      </StatusPill>
    </div>
    <OModal
      :active="detailActive"
      :width="640"
      :cancelable="['escape', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <OIcon
              icon="clipboard"
              class="status__icon status__icon--clipboard"
            />
            <span class="status__label">
              {{ $t('orders.card.status.label') }}
            </span>
            <span
              class="status__value"
              :class="`status__value--${statusModifier}`"
            >
              {{ $t(`orders.status.${status}`) }}
            </span>
          </p>
          <button
            type="button"
            class="delete"
            @click="closeDetailedOrderStatus"
          />
        </header>
        <section class="modal-card-body">
          <p class="status__description">
            {{ $t(`orders.status.more-info.${status}`) }}
          </p>
        </section>
        <footer class="modal-card-foot">
          <OButton
            :label="$t('general.interface.back')"
            variant="primary"
            rounded
            @click="closeDetailedOrderStatus"
          />
        </footer>
      </div>
    </OModal>
  </section>
</template>

<script lang="ts">
import parse from 'date-fns/parse';
import { SalesOrderOrderStatusEnum } from '@/api/model';
import Helpers from '@/logic/orders/helpers';
import StatusPill from '@/components/StatusPill.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { StatusPill },
  data() {
    return {
      detailActive: false,
    };
  },
  computed: {
    statusModifier() {
      switch (this.status) {
        case SalesOrderOrderStatusEnum.AwsCreated:
          return 'received';
        case SalesOrderOrderStatusEnum.SapCancelled:
          return 'cancelled';
        case SalesOrderOrderStatusEnum.SapCreated:
          return 'accepted';
        case SalesOrderOrderStatusEnum.SapProcessing:
          return 'processing';
        case SalesOrderOrderStatusEnum.SapProcessingPart:
          return 'processing-part';
        case SalesOrderOrderStatusEnum.SapShipped:
          return 'shipped';
        case SalesOrderOrderStatusEnum.SapShippedPart:
          return 'shipped-part';
        case SalesOrderOrderStatusEnum.Delivered:
          return 'delivered';
        case SalesOrderOrderStatusEnum.SapUnknown:
          return 'unknown';
        default:
          return 'unknown';
      }
    },
    deliveryDateFormatted(): string {
      if (!this.deliveryDate) {
        return '';
      }
      const parsed = parse(this.deliveryDate, 'yyyy-MM-dd', new Date());
      return Helpers.formatDate(parsed);
    },
    showEta(): boolean {
      if (this.isContainer) {
        return false;
      }
      const showOnStatus = [
        SalesOrderOrderStatusEnum.SapProcessing,
        SalesOrderOrderStatusEnum.SapProcessingPart,
        SalesOrderOrderStatusEnum.SapShipped,
        SalesOrderOrderStatusEnum.SapShippedPart,
      ];

      return !!this.eta && showOnStatus.includes(this.status);
    },
    showPartly(): boolean {
      if (this.isContainer) {
        return false;
      }
      const showOnStatus = [
        SalesOrderOrderStatusEnum.SapProcessingPart,
        SalesOrderOrderStatusEnum.SapShippedPart,
      ];

      return showOnStatus.includes(this.status);
    },
  },
  methods: {
    openDetailedOrderStatus() {
      this.detailActive = true;
    },
    closeDetailedOrderStatus() {
      this.detailActive = false;
    },
  },
  props: {
    status: {
      type: String as () => SalesOrderOrderStatusEnum,
      default: '',
    },
    deliveryDate: {
      type: String,
    },
    eta: {
      type: String,
    },
    delay: {
      type: String,
    },
    isContainer: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.order-status {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
  }

  &__icon {
    color: $primary;

    &--arrow {
      transform: rotate(180deg) scale(0.8);
      transition: all 0.2s ease-in-out;
    }
  }

  &__data {
    cursor: pointer;

    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__delivery-date {
    @include mobile {
      margin-top: 8px;
    }

    @include tablet {
      margin-left: 32px;
    }
  }
}

.status {
  &__icon {
    color: $primary;
    margin-right: 4px;

    &--clipboard {
      font-size: 23px;
    }

    &--box {
      font-size: 27px;
    }
  }

  &__label {
    color: $boulder;
    margin-right: 4px;

    @include mobile {
      flex: 0 0 80%;
    }
  }

  &__description {
    max-width: 500px;
  }

  &__value {
    color: $black;

    &--received {
      color: $primary;
    }

    &--cancelled {
      color: $monza;
    }

    &--accepted {
      color: $buddha-gold;
    }

    &--processing,
    &--processing-part {
      color: $selective-yellow;
    }

    &--shipped,
    &--shipped-part {
      color: $lime;
    }

    &--delivered {
      color: $green-approx;
    }

    &--unknown {
      color: $swamp;
    }
  }
}

.modal-card-head {
  background-color: $alabaster;
  border-bottom: 1px solid $gallery;
}

.modal-card-title {
  font-size: 20px;
}

.modal-card-foot {
  border-top: 0;

  .button {
    width: 200px;
  }
}

.delete {
  background-color: $primary;
}
</style>
