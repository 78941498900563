<template>
  <div class="block image-carousel">
    <OCarousel
      v-if="block"
      :indicators="false"
      icon-next="arrow_forward"
      icon-prev="arrow_back"
      :arrows-hover="false"
      :overlay="gallery"
      @click="switchGallery(true)"
    >
      <OCarouselItem v-for="(images, i) in block.imagecarousel" :key="i">
        <figure class="image">
          <img :src="$helpers.getApiImageThumbnail(images.image)" alt="" />
        </figure>
      </OCarouselItem>
      <template #indicators="{ active, switchTo }">
        <OCarousel
          :arrows="false"
          :model-value="active"
          :indicators="false"
          :items-to-show="2.5"
          :breakpoints="{ 768: { itemsToShow: 4.5 } }"
          @update:model-value="switchTo($event)"
          root-class="carousel-list"
        >
          <OCarouselItem
            v-for="(image, i) in images"
            :key="i"
            clickable
            item-class="img-indicator"
            item-active-class="img-indicator-active"
          >
            <img :src="image.image" alt="" />
          </OCarouselItem>
        </OCarousel>
      </template>
      <template #overlay>
        <span
          v-if="gallery"
          class="modal-close is-large"
          @click="switchGallery(false)"
        ></span>
      </template>
    </OCarousel>
  </div>
</template>

<script lang="ts">
import { SuluMedia } from '@/logic/cms/sulu';
import { defineComponent } from 'vue';
import { OCarousel, OCarouselItem } from '@oruga-ui/oruga-next';

export default defineComponent({
  components: { OCarouselItem, OCarousel },
  data() {
    const $helpers: any = undefined;

    return {
      gallery: false,
      $helpers,
    };
  },
  computed: {
    images(): any[] | undefined {
      if (this.block && this.block.imagecarousel) {
        return this.block.imagecarousel.map(
          (images: { image: SuluMedia; type: string }) => ({
            image: this.$helpers.getApiImageThumbnail(images.image),
          }),
        );
      }
      return [];
    },
  },
  methods: {
    switchGallery(value: boolean) {
      this.gallery = value;
      document.documentElement.classList.toggle('is-clipped', value);
    },
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>

<style lang="scss" scoped>
.block.image-carousel {
  @include until($tablet) {
    width: calc(100vw - 44px);
  }
}
</style>
