import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1802c792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout layout--default", _ctx.isImpersonating ? 'layout--impersonating' : ''])
  }, [
    _createVNode(_component_Navigation, { class: "layout__navigation" }),
    _createElementVNode("main", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createVNode(_component_FooterBar, { class: "layout__footer" })
    ])
  ], 2))
}