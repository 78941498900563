import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block block--youtube"
}
const _hoisted_2 = {
  key: 0,
  class: "title title--h2 block__title"
}
const _hoisted_3 = { class: "image is-16by9" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.block.youtube_identifier != '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.block.title)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.block.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("figure", _hoisted_3, [
          _createElementVNode("iframe", {
            src: `https://www.youtube.com/embed/${_ctx.block.youtube_identifier}`,
            title: "YouTube",
            class: "has-ratio",
            width: "100%",
            height: "100%",
            allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: ""
          }, null, 8, _hoisted_4)
        ])
      ]))
    : _createCommentVNode("", true)
}