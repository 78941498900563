/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SapOrderContainer } from './sap-order-container';

/**
 * 
 * @export
 * @interface SalesOrder
 */
export interface SalesOrder {
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    BillOfLading?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    ChangedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    City?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    CreatedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SalesOrder
     */
    CreatedInPortal?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    CreatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    Currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    CustReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    DeliveryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    DeliveryNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    Id?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    IncoTerms1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    IncoTerms2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    MeansOfTransportation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    MeansOfTransportationDescription?: string | null;
    /**
     * 
     * @type {Array<SapOrderContainer>}
     * @memberof SalesOrder
     */
    OrderContainers?: Array<SapOrderContainer>;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    OrderNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    OrderStatus?: SalesOrderOrderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    OrderType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    OriginalEta?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    PurchOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    PurchOrderDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    ReqDlvDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    SapCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    ShipToParty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    SoldToParty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SalesOrder
     */
    UpdatedEta?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SalesOrderOrderStatusEnum {
    AwsFailed = 'AWS_FAILED',
    AwsCreated = 'AWS_CREATED',
    SapCreated = 'SAP_CREATED',
    SapProcessing = 'SAP_PROCESSING',
    SapProcessingPart = 'SAP_PROCESSING_PART',
    SapShipped = 'SAP_SHIPPED',
    SapShippedPart = 'SAP_SHIPPED_PART',
    SapCancelled = 'SAP_CANCELLED',
    SapUnknown = 'SAP_UNKNOWN',
    Delivered = 'DELIVERED'
}



