<template>
  <router-link
    v-if="isVisibleToUser() && active"
    class="block block--status block--orders"
    :to="{ name: 'orders' }"
  >
    <div class="block__top">
      <div class="block__header">
        <OIcon icon="truck" class="block__icon" />
        <h2 class="block__title">
          {{ $t('dashboard.orders.your-orders-deliveries') }}
        </h2>
      </div>
      <div class="block__separator-wrapper">
        <div class="block__separator"></div>
      </div>
      <div class="block__details" v-if="this.lastOrder">
        <div class="block__details-column">
          <h3 class="block__subtitle">
            {{ $t('dashboard.orders.last-order') }}:
          </h3>
          <span class="block__text">
            {{ this.lastOrder.OrderNumber }}
          </span>
        </div>
        <div class="block__details-column">
          <h3 class="block__subtitle">
            {{ $t('dashboard.orders.order-date') }}:
          </h3>
          <span class="block__text">
            {{ lastOrderDate }}
          </span>
        </div>
      </div>
      <div class="block__details" v-else>
        {{ $t('dashboard.orders.no-last-order') }}
      </div>
      <div class="block__separator-wrapper" v-if="this.lastOrder">
        <div class="block__separator"></div>
      </div>
      <div class="block__details" v-if="this.lastOrder">
        <div class="block__details-column">
          <h3 class="block__subtitle">
            {{ $t('dashboard.orders.order-status') }}:
          </h3>
          <span class="block__text">
            {{ $t(`orders.status.${this.lastOrder.OrderStatus}`) }}
          </span>
        </div>
      </div>
    </div>
    <div class="block__bottom">
      <h2 class="block__title">
        {{ $t('dashboard.orders.my-orders-deliveries') }}
      </h2>
      <span class="block__text">
        {{ $t('dashboard.orders.go-to-orders-overview') }}
      </span>
      <OIcon icon="arrow_back" class="block__icon" />
    </div>
  </router-link>
  <OSkeleton
    v-else-if="isVisibleToUser()"
    :animated="true"
    width="96%"
    height="320px"
  />
</template>

<script lang="ts">
import { format } from 'date-fns';
import { SalesOrder } from '@/api/model/sales-order';
import Helpers from '@/logic/orders/helpers';
import { defineComponent } from 'vue';
import DashboardStatusBlock from '../content/blocks/DashboardStatusBlock.vue';

export default defineComponent({
  extends: DashboardStatusBlock,
  data() {
    return {
      active: false,
    };
  },
  computed: {
    lastOrder(): SalesOrder | undefined {
      return this.$store.getters['order/lastOrder'];
    },
    lastOrderDate(): string {
      if (this.lastOrder && this.lastOrder.PurchOrderDate) {
        const date = Helpers.getDateFromSapDate(this.lastOrder.PurchOrderDate);
        return format(date, 'dd-MM-yyyy');
      }

      return '';
    },
  },
  async created() {
    if (this.lastOrder === undefined) {
      await this.$store.dispatch('order/fetchOrders');
    }
    this.active = true;
  },
  props: {
    block: {
      type: Object,
    },
  },
});
</script>
