<template>
  <footer
    class="new-order-footer"
    :class="{ 'new-order-footer--sticky': isSticky }"
  >
    <NewOrderFooterMessage v-if="step === 'select-products'" />
    <div class="new-order-footer__content">
      <div class="new-order-footer__totals">
        <OrderTotals
          v-if="showTotals"
          :items="items"
          :products="relatedProducts"
        />
      </div>
      <nav class="new-order-footer__nav">
        <OButton
          variant="primary large new-order-footer__button"
          rounded
          outlined
          @click="goPrevious()"
          data-test="previous-step"
        >
          {{ $t(`orders.new.footer.${previousLabel}`) }}
        </OButton>
        <OButton
          variant="primary large new-order-footer__button"
          rounded
          @click="goNext()"
          :disabled="!canGoNext"
          data-test="next-step"
        >
          {{ $t(`orders.new.footer.${nextLabel}`) }}
        </OButton>
      </nav>
    </div>
    <OLoading :active="loading" :full-page="true" />
  </footer>
</template>

<script lang="ts">
import { OrderApi, OrderCalculateProducts } from '@/api';
import { OrderItem, SapProduct } from '@/api/model';
import OrderTotals from '@/components/orders/OrderTotals.vue';
import MessageToastMixin from '@/mixins/MessageToastMixin';
import { SalesOrderAll as SalesOrder } from '@/logic/orders/SalesOrderAll';
import getConfiguration from '@/store/getConfiguration';
import NewOrderFooterMessage from '@/components/orders/new-order/NewOrderFooterMessage.vue';
import { defineComponent } from 'vue';
import BaseDialog from '@/components/base/BaseDialog.vue';

export default defineComponent({
  components: { NewOrderFooterMessage, OrderTotals },
  mixins: [MessageToastMixin],
  data() {
    const steps: string[] = [
      'select-products',
      'order-reference-and-shipping',
      'order-summary-and-confirmation',
    ];

    return {
      loading: false,
      steps,
    };
  },
  computed: {
    cart(): SalesOrder {
      return this.$store.state.order.cart;
    },
    products(): SapProduct[] {
      return this.$store.state.order.products;
    },
    items(): OrderItem[] {
      return this.cart.OrderItems || [];
    },
    relatedProducts(): SapProduct[] {
      if (!this.products) {
        return [];
      }
      return this.products.filter((product: SapProduct) =>
        this.items.some(
          (item: OrderItem) => item.ProductNr === product.ProductNr,
        ),
      );
    },
    canGoNext(): boolean {
      const step1 = this.items.length > 0;
      const step2 =
        !!this.cart.ShipToParty && !!this.cart.ReqDlvDate && !this.isInvalid;
      const step3 = step1 && step2;
      const allowed: any = {
        'select-products': step1,
        'order-reference-and-shipping': step2,
        'order-summary-and-confirmation': step3,
      };

      return allowed[this.step];
    },
    isSticky(): boolean {
      return this.step === 'select-products';
    },
    showTotals(): boolean {
      return this.step === 'select-products';
    },
    nextLabel(): string {
      if (this.step === 'order-summary-and-confirmation') {
        return 'confirm';
      }
      if (
        this.step === 'select-products' &&
        (this.action === 'suggest-remove' ||
          this.action === 'overfill-no-suggestion')
      ) {
        return 'recalculate';
      }
      return 'next';
    },
    previousLabel(): string {
      return this.step === this.steps[0] ? 'cancel' : 'back';
    },
    action(): string {
      return this.$store.getters['order/suggestionAction'];
    },
  },
  methods: {
    goNext() {
      if (!this.canGoNext) {
        return;
      }
      switch (this.step) {
        case 'select-products':
          this.goNextSelectProducts();
          break;
        case 'order-reference-and-shipping':
          this.$router.push({
            name: 'new-order-steps',
            params: { step: 'order-summary-and-confirmation' },
          });
          break;
        case 'order-summary-and-confirmation':
          this.confirmOrder();
          break;
        default:
          break;
      }
    },
    async goNextSelectProducts() {
      if (this.$store.state.order.cartChanged) {
        this.$store.dispatch('order/resetSuggestion');
      }
      const initialAction = this.action;

      await this.calculateTruck();
      if (
        this.action !== 'suggest-remove' &&
        this.action !== 'overfill-no-suggestion'
      ) {
        if (this.action === 'next-step' || initialAction === this.action) {
          this.goToReferenceAndShipping();
        }
      }
    },
    goToReferenceAndShipping() {
      this.$router.push({
        name: 'new-order-steps',
        params: { step: 'order-reference-and-shipping' },
      });
    },
    goPrevious() {
      switch (this.step) {
        case 'select-products':
          this.$store.commit('order/resetCart');
          this.$router.push({ name: 'orders' });
          break;
        case 'order-reference-and-shipping':
          this.$router.push({
            name: 'new-order-steps',
            params: { step: 'select-products' },
          });
          break;
        case 'order-summary-and-confirmation':
          this.$router.push({
            name: 'new-order-steps',
            params: { step: 'order-reference-and-shipping' },
          });
          break;
        default:
          break;
      }
    },
    async confirmOrder() {
      try {
        this.loading = true;
        // @Todo fix cors error (also happens with old version on localhost:8080)
        await this.$store.dispatch('order/postNewOrder', this.cart);
        this.loading = false;
        this.$router.push({ name: 'orders' });
        this.$oruga.modal.open({
          component: BaseDialog,
          props: {
            title: this.$t('orders.new.confirm-dialog.title') as string,
            message: this.$t('orders.new.confirm-dialog.message') as string,
            confirmText: this.$t('orders.new.confirm-dialog.button') as string,
          },
          trapFocus: true,
        });
      } catch (e) {
        this.showError(this.$t('general.messages.error'));
        this.loading = false;
      }
    },
    async getApi() {
      const configuration = await getConfiguration();
      return new OrderApi(configuration, '');
    },
    async calculateTruck(): Promise<any> {
      try {
        this.loading = true;
        this.$store.commit('order/setSuggestion', {});
        const products = this.getCalculationProducts(this.items);
        const api = await this.getApi();
        const { data: result } = await api.orderCalculatePost({
          Products: products,
        });
        this.$store.commit('order/setSuggestion', result);
      } catch (e) {
        // If the calculator doesn't work for some reason, go to the next step in order process
      } finally {
        this.loading = false;
      }
    },
    getCalculationProducts(items: OrderItem[]): OrderCalculateProducts[] {
      // Convert shopping cart items to Products for calculation
      const products: OrderCalculateProducts[] = items.map((i: OrderItem) => {
        const quantity = i.Quantity ? parseInt(i.Quantity, 10) : 0;
        return {
          NumPallets: i.SalesUnit === 'PAL' && quantity ? quantity : 0,
          NumLayers: i.SalesUnit === 'LAY' && quantity ? quantity : 0,
          ProductNr: i.ProductNr,
        } as OrderCalculateProducts;
      });

      // Combine layers and pallets items
      const combined: OrderCalculateProducts[] = products.reduce(
        (acc: OrderCalculateProducts[], cur: OrderCalculateProducts) => {
          const product: OrderCalculateProducts | undefined = acc.find(
            (p) => p.ProductNr === cur.ProductNr,
          );
          if (product === undefined) {
            acc.push(cur);
          } else {
            product.NumLayers += cur.NumLayers;
            product.NumPallets += cur.NumPallets;
          }
          return acc;
        },
        [],
      );
      return combined;
    },
  },
  props: {
    step: {
      type: String,
      default: '',
    },
    isInvalid: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
.new-order-footer {
  &--sticky {
    position: sticky;
    bottom: 0;
    z-index: 35;
    background: $white;

    .new-order-footer__content {
      border-top: 1px solid $alto;
      padding: 16px;

      @include mobile {
        padding-top: 0;
      }

      @include desktop {
        height: 82px;
      }
    }

    .new-order-footer__nav {
      @include mobile {
        flex-direction: row;
        margin: 0;
      }

      @include tablet {
        margin: 0;
      }
    }

    .new-order-footer__button {
      @include mobile {
        flex: 0 0 48%;
        margin-bottom: 0;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mobile {
      flex-direction: column;
    }

    @include tablet {
      align-items: center;
    }
  }

  &__nav {
    @include mobile {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      width: 100%;
      margin: 24px 0;
    }

    @include tablet {
      margin: 40px 0 55px 0;
    }
  }

  &__button {
    @include mobile {
      margin-bottom: 16px;
    }

    @include tablet {
      margin-left: 16px;
      max-width: 210px;
    }
  }
}
</style>
