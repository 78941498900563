/* tslint:disable */
/* eslint-disable */
/**
 * LWM portal
 * Lamb-Weston Meijer Portal API documentation
 *
 * The version of the OpenAPI document: 3.3.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Role } from '../model';
// @ts-ignore
import { RoleAttributes } from '../model';
// @ts-ignore
import { Success } from '../model';
/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * All available roles will be returned.
         * @summary Get overview of all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleAttributesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/role-attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a role
         * @param {string} id Id of the role to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleDelete', 'id', id)
            const localVarPath = `/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a role
         * @param {Role} role Details of the role to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolePut: async (role: Role, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('rolePut', 'role', role)
            const localVarPath = `/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(role, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return overview of available roles.
         * @summary Get overview of all roles
         * @param {string} [filter] Filter role fields, parameter may be repeated to add multiple filters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGet: async (filter?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Assign in bulk a role to users.
         * @summary Adds role to multiple users
         * @param {Array<string>} userIds List of User ids of the requested profile (can only be used by administrators)
         * @param {string} roleId Role id to apply to the list of user ids (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesBulkPut: async (userIds: Array<string>, roleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIds' is not null or undefined
            assertParamExists('userRolesBulkPut', 'userIds', userIds)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('userRolesBulkPut', 'roleId', roleId)
            const localVarPath = `/user-roles-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (userIds) {
                localVarQueryParameter['user_ids'] = userIds;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * All available roles will be returned.
         * @summary Get overview of all roles for a user
         * @param {string} [id] User id of the requested profile (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesGet: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add the specified user to the specified role.
         * @summary Adds a user to a role
         * @param {string} userId User id of the requested profile (can only be used by administrators)
         * @param {Array<string>} roleIds List of role id\&#39;s to apply ot the user (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesPut: async (userId: string, roleIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userRolesPut', 'userId', userId)
            // verify required parameter 'roleIds' is not null or undefined
            assertParamExists('userRolesPut', 'roleIds', roleIds)
            const localVarPath = `/user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication development-5i2kpscjb65ib9kesn4v8t0121 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "development-5i2kpscjb65ib9kesn4v8t0121", [], configuration)

            // authentication production-65i3ire9nqi9c80lduc24r7rjq required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "production-65i3ire9nqi9c80lduc24r7rjq", [], configuration)

            // authentication staging-6ggnttq03clhsfllacr07d2uit required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "staging-6ggnttq03clhsfllacr07d2uit", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (roleIds) {
                localVarQueryParameter['role_ids'] = roleIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * All available roles will be returned.
         * @summary Get overview of all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleAttributesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleAttributes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleAttributesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a role
         * @param {string} id Id of the role to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or update a role
         * @param {Role} role Details of the role to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolePut(role: Role, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolePut(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return overview of available roles.
         * @summary Get overview of all roles
         * @param {string} [filter] Filter role fields, parameter may be repeated to add multiple filters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesGet(filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGet(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Assign in bulk a role to users.
         * @summary Adds role to multiple users
         * @param {Array<string>} userIds List of User ids of the requested profile (can only be used by administrators)
         * @param {string} roleId Role id to apply to the list of user ids (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesBulkPut(userIds: Array<string>, roleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesBulkPut(userIds, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * All available roles will be returned.
         * @summary Get overview of all roles for a user
         * @param {string} [id] User id of the requested profile (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesGet(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add the specified user to the specified role.
         * @summary Adds a user to a role
         * @param {string} userId User id of the requested profile (can only be used by administrators)
         * @param {Array<string>} roleIds List of role id\&#39;s to apply ot the user (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesPut(userId: string, roleIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesPut(userId, roleIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * All available roles will be returned.
         * @summary Get overview of all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleAttributesGet(options?: any): AxiosPromise<Array<RoleAttributes>> {
            return localVarFp.roleAttributesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a role
         * @param {string} id Id of the role to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleDelete(id: string, options?: any): AxiosPromise<Success> {
            return localVarFp.roleDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a role
         * @param {Role} role Details of the role to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolePut(role: Role, options?: any): AxiosPromise<Role> {
            return localVarFp.rolePut(role, options).then((request) => request(axios, basePath));
        },
        /**
         * Return overview of available roles.
         * @summary Get overview of all roles
         * @param {string} [filter] Filter role fields, parameter may be repeated to add multiple filters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGet(filter?: string, options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.rolesGet(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Assign in bulk a role to users.
         * @summary Adds role to multiple users
         * @param {Array<string>} userIds List of User ids of the requested profile (can only be used by administrators)
         * @param {string} roleId Role id to apply to the list of user ids (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesBulkPut(userIds: Array<string>, roleId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.userRolesBulkPut(userIds, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * All available roles will be returned.
         * @summary Get overview of all roles for a user
         * @param {string} [id] User id of the requested profile (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesGet(id?: string, options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.userRolesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Add the specified user to the specified role.
         * @summary Adds a user to a role
         * @param {string} userId User id of the requested profile (can only be used by administrators)
         * @param {Array<string>} roleIds List of role id\&#39;s to apply ot the user (can only be used by administrators)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesPut(userId: string, roleIds: Array<string>, options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.userRolesPut(userId, roleIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * All available roles will be returned.
     * @summary Get overview of all roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleAttributesGet(options?: any) {
        return RoleApiFp(this.configuration).roleAttributesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a role
     * @param {string} id Id of the role to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public roleDelete(id: string, options?: any) {
        return RoleApiFp(this.configuration).roleDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update a role
     * @param {Role} role Details of the role to add or update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public rolePut(role: Role, options?: any) {
        return RoleApiFp(this.configuration).rolePut(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return overview of available roles.
     * @summary Get overview of all roles
     * @param {string} [filter] Filter role fields, parameter may be repeated to add multiple filters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public rolesGet(filter?: string, options?: any) {
        return RoleApiFp(this.configuration).rolesGet(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Assign in bulk a role to users.
     * @summary Adds role to multiple users
     * @param {Array<string>} userIds List of User ids of the requested profile (can only be used by administrators)
     * @param {string} roleId Role id to apply to the list of user ids (can only be used by administrators)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public userRolesBulkPut(userIds: Array<string>, roleId: string, options?: any) {
        return RoleApiFp(this.configuration).userRolesBulkPut(userIds, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All available roles will be returned.
     * @summary Get overview of all roles for a user
     * @param {string} [id] User id of the requested profile (can only be used by administrators)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public userRolesGet(id?: string, options?: any) {
        return RoleApiFp(this.configuration).userRolesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add the specified user to the specified role.
     * @summary Adds a user to a role
     * @param {string} userId User id of the requested profile (can only be used by administrators)
     * @param {Array<string>} roleIds List of role id\&#39;s to apply ot the user (can only be used by administrators)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public userRolesPut(userId: string, roleIds: Array<string>, options?: any) {
        return RoleApiFp(this.configuration).userRolesPut(userId, roleIds, options).then((request) => request(this.axios, this.basePath));
    }
}
