<template>
  <div
    class="order-overview-table"
    :data-test="
      orders && orders.length > 0 ? 'order-overview-table-has-orders' : ''
    "
  >
    <o-table
      :data="orders"
      striped
      :show-detail-icon="false"
      :opened-detailed="openedDetails"
      detailed
      paginated
      :per-page="perPage"
      detail-key="Id"
    >
      <o-table-column
        v-slot="props"
        field="OrderNr"
        sortable
        :label="$t('orders.overview.header.order-no')"
      >
        {{ props.row.OrderNumber }}
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="PurchOrder"
        sortable
        :label="$t('orders.overview.header.order-ref')"
      >
        {{ props.row.PurchOrder }}
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="City"
        sortable
        :label="$t('orders.overview.header.order-city')"
      >
        {{ props.row.City }}
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="CreatedOn"
        sortable
        :label="$t('orders.overview.header.order-date')"
      >
        <span :title="Helpers.getReadableCreatedFullDate(props.row)">{{
          Helpers.getReadableCreatedDate(props.row)
        }}</span>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="OrderStatus"
        sortable
        :label="$t('orders.overview.header.order-status')"
        :td-attrs="() => ({ class: 'table__cell--no-padding' })"
      >
        <OrderStatus
          :status="props.row.OrderStatus"
          :delivery-date="props.row.DeliveryDate"
          :eta="Helpers.eta(props.row)"
          :delay="Helpers.delay(props.row, $i18n.locale, $t)"
          :is-container="$store.getters['profile/isContainer']"
        />
      </o-table-column>
      <!-- Container customer has extra ETA columns -->
      <template v-if="$store.getters['profile/isContainer']">
        <o-table-column
          :label="$t('orders.overview.header.eta-booked')"
          v-slot="props"
        >
          <span v-if="props.row.OriginalEta">{{
            Helpers.formatEtaDate(props.row.OriginalEta)
          }}</span>
          <span v-else>-</span>
        </o-table-column>
        <o-table-column
          :label="$t('orders.overview.header.eta-actual')"
          v-slot="props"
        >
          <span
            v-if="props.row.UpdatedEta"
            class="eta"
            :class="etaActualClass(props.row)"
            >{{ Helpers.formatEtaDate(props.row.UpdatedEta) }}</span
          >
          <span v-else>-</span>
        </o-table-column>
      </template>
      <o-table-column
        :label="$t('orders.overview.header.more')"
        v-slot="props"
        :td-attrs="() => ({ class: 'table__cell--no-padding' })"
        :width="80"
        root-class="is-centered"
      >
        <a @click="toggleActive(props.row)" class="toggle-details">
          <OIcon
            class="toggle-details__icon"
            :class="{
              'toggle-details__icon--active': isDetailed(props.row),
            }"
            icon="arrow_back"
          />
        </a>
      </o-table-column>

      <template #detail="props">
        <OrderOverviewTableDetails :order="props.row" />
      </template>

      <template #empty>
        <div class="table__empty">{{ $t('orders.overview.empty') }}</div>
      </template>
    </o-table>
  </div>
</template>

<script lang="ts">
import { SalesOrder } from '@/api/model';
import Helpers from '@/logic/orders/helpers';
import OrderStatus from '@/components/orders/OrderStatus.vue';
import OrderOverviewTableDetails from '@/components/orders/OrderOverviewTableDetails.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { OrderOverviewTableDetails, OrderStatus },
  data() {
    return {
      Helpers,
    };
  },
  computed: {
    openedDetails(): string[] {
      const active = this.$store.state.order.activeOrders;
      return Array.from(active);
    },
  },
  methods: {
    toggleActive(order: SalesOrder, active?: boolean) {
      if (active === undefined) {
        active = !this.isDetailed(order);
      }
      this.$store.commit('order/toggleActive', {
        id: order.Id,
        active,
      });
    },
    isDetailed(order: SalesOrder) {
      return this.$store.getters['order/isActive'](order.Id);
    },
    etaActualClass(order: SalesOrder): any {
      if (!order.OriginalEta || !order.UpdatedEta) {
        return {};
      }
      return {
        'eta--early': order.OriginalEta > order.UpdatedEta,
        'eta--late': order.OriginalEta < order.UpdatedEta,
      };
    },
  },
  props: {
    perPage: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    orders: { type: Array, default: () => [] },
  },
});
</script>

<style lang="scss" scoped>
.order-overview-table :deep(.level) {
  margin-top: 38px;
}

.eta {
  white-space: nowrap;
}

:deep(.eta--early) {
  font-weight: bold;
  color: $primary;
}

:deep(.eta--late) {
  font-weight: bold;
  color: $trinidad;
}
</style>
