<template>
  <footer class="new-claim-footer new-claim-footer--sticky">
    <div class="new-claim-footer__content">
      <div class="new-order-footer__totals">
        <NewClaimFooterTotals v-if="showTotals && agreement" />
      </div>
      <nav class="new-claim-footer__nav">
        <OButton
          variant="primary large new-claim-footer__button"
          rounded
          outlined
          @click="goPrevious()"
          data-test="previous-step"
        >
          {{ $t('claims.new.footer.back') }}
        </OButton>
        <OButton
          variant="primary large new-claim-footer__button"
          rounded
          @click="goNext()"
          data-test="next-step"
          :disabled="!canGoNext"
        >
          {{ $t(`claims.new.footer.${nextLabel}`) }}
        </OButton>
      </nav>
    </div>
    <OLoading :active="loading" :full-page="true" />
  </footer>
</template>

<script lang="ts">
import MessageToastMixin from '@/mixins/MessageToastMixin';
import NewClaimFooterTotals from '@/components/claims/new-claim/NewClaimFooterTotals.vue';
import { SalesQuote } from '@/api';
import { defineComponent } from 'vue';
import BaseDialog from '@/components/base/BaseDialog.vue';

export default defineComponent({
  components: { NewClaimFooterTotals },
  mixins: [MessageToastMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    agreement(): SalesQuote | undefined {
      return this.$store.getters['claim/agreement'];
    },
    showTotals(): boolean {
      return this.step === 'claim-details';
    },
    canGoNext(): boolean {
      return this.nextActive;
    },
    nextLabel(): string {
      if (this.step === 'claim-summary') {
        return 'submit';
      }
      return 'next';
    },
  },
  methods: {
    goNext() {
      if (!this.canGoNext) {
        return;
      }
      switch (this.step) {
        case 'claim-details':
          this.$router.push({
            name: 'new-claims-steps',
            params: { step: 'claim-summary' },
          });
          break;
        case 'claim-summary':
          this.submitClaim();
          break;
        default:
          break;
      }
    },
    goPrevious() {
      switch (this.step) {
        case 'claim-details':
          this.$router.push({ name: 'agreements' });
          break;
        case 'claim-summary':
          this.$router.push({
            name: 'new-claims-steps',
            params: { step: 'claim-details' },
          });
          break;
        default:
          break;
      }
    },
    async submitClaim() {
      try {
        this.loading = true;
        await this.$store.dispatch('claim/postClaim');
        this.$router.push({ name: 'agreements' });
        this.$oruga.modal.open({
          component: BaseDialog,
          props: {
            title: this.$t('claims.new.confirm-dialog.title') as string,
            message: this.$t('claims.new.confirm-dialog.message') as string,
            confirmText: this.$t('claims.new.confirm-dialog.button') as string,
          },
          trapFocus: true,
        });
      } catch (e) {
        this.showError(this.$t('general.messages.error'));
      } finally {
        this.loading = false;
      }
    },
  },
  props: {
    step: {
      type: String,
    },
    nextActive: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
.new-claim-footer {
  &--sticky {
    position: sticky;
    bottom: 0;
    z-index: 35;
    background: $white;

    .new-claim-footer__content {
      border-top: 1px solid $alto;
      padding: 16px;
      gap: 16px;

      @include desktop {
        height: 82px;
      }
    }

    .new-claim-footer__nav {
      @include mobile {
        flex-direction: row;
        margin: 0;
      }

      @include tablet {
        margin: 0;
      }
    }

    .new-claim-footer__button {
      @include mobile {
        flex: 0 0 48%;
        margin-bottom: 0;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mobile {
      flex-direction: column;
    }

    @include tablet {
      align-items: center;
    }
  }

  &__nav {
    @include mobile {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      width: 100%;
      margin: 24px 0;
    }

    @include tablet {
      margin: 40px 0 55px 0;
    }
  }

  &__button {
    @include mobile {
      margin-bottom: 16px;
    }

    @include tablet {
      margin-left: 16px;
      max-width: 210px;
    }
  }
}
</style>
