<template>
  <section :class="`product-group product-group--${type}`">
    <div class="product-group__content layout__content layout__content--large">
      <div
        v-if="groupLabel"
        class="toggle-button toggle-button--product-group"
        @click="toggleActive(!active)"
        role="button"
        data-test="toggle-product-group"
      >
        <span class="toggle-button__label">
          {{ groupLabel }}
        </span>
        <div
          class="toggle-button__icon"
          :class="{ 'toggle-button__icon--active': active }"
        >
          <OIcon icon="chevron_up" />
        </div>
      </div>
      <transition name="slide">
        <div v-if="active">
          <o-table
            v-if="sortedProducts"
            :data="sortedProducts"
            root-class="product-item-list"
            striped
            :row-class="(row) => isDisabled(row) && 'is-disabled'"
          >
            <o-table-column
              :label="$t('orders.order-item-list.header.product')"
              v-slot="props"
            >
              {{
                customText && props.row.CustomProductDesc
                  ? props.row.CustomProductDesc
                  : props.row.ProductText
              }}
            </o-table-column>
            <o-table-column
              :label="$t('orders.order-item-list.header.code')"
              v-slot="props"
              :width="110"
              >{{ props.row.ShortCode }}</o-table-column
            >
            <o-table-column
              :label="$t('orders.order-item-list.header.warehouse')"
              v-if="type === 'order'"
              v-slot="props"
              :width="120"
              >{{ props.row.DeliveryPlantCity }}</o-table-column
            >
            <o-table-column
              v-if="type !== 'claim'"
              :label="$t('orders.order-item-list.header.art')"
              v-slot="props"
              :width="100"
              >{{
                customText && props.row.CustomProductNr
                  ? props.row.CustomProductNr
                  : props.row.ProductNr
              }}</o-table-column
            >
            <o-table-column
              v-if="type === 'container-calculation'"
              :label="$t('orders.order-item-list.header.boxes')"
              v-slot="props"
              :width="120"
            >
              {{ calculateBoxes(props.row) || '' }}
            </o-table-column>
            <o-table-column
              v-if="type !== 'claim'"
              :label="$t('orders.order-item-list.header.pallets')"
              v-slot="props"
              :width="type === 'container-calculation' ? 209 : 90"
              :position="type !== 'container-calculation' ? 'centered' : null"
              :td-attrs="() => ({ class: 'cell--input' })"
            >
              <div class="product-group__cell-wrapper">
                <BaseFieldQuantity
                  :value="getQuantity(props.row, 'PAL')"
                  @update:modelValue="setQuantity(props.row, $event, 'PAL')"
                  :disabled="isDisabled(props.row)"
                  :max="45"
                  :class="{
                    'product-group__suggestion':
                      hasContainerSuggestion(props.row) ||
                      hasTruckSuggestion(props.row) ||
                      fillStatus === 'overfilled-no-product',
                    'product-group__suggestion--fill':
                      hasTruckSuggestion(props.row) &&
                      $store.getters['order/suggestionAction'].startsWith(
                        'suggest-fill',
                      ),
                  }"
                  data-test="input-pallets"
                />
                <NewOrderTooltip
                  v-if="hasTruckSuggestion(props.row)"
                  :product-nr="props.row.ProductNr"
                  @accept="setTruckSuggestion($event)"
                />
                <span
                  class="product-group__suggestion-button"
                  v-if="hasContainerSuggestion(props.row)"
                  ><OButton
                    rounded
                    variant="secondary"
                    size="small"
                    @click="setContainerSuggestion(props.row)"
                    :loading="$store.state.container.isCalculating"
                    >{{ containerSuggestionLabel }}</OButton
                  >
                </span>
              </div>
            </o-table-column>
            <o-table-column
              v-if="type === 'order'"
              :label="$t('orders.order-item-list.header.layers')"
              v-slot="props"
              :width="90"
              root-class="is-centered"
              :td-attrs="() => ({ class: 'cell--input' })"
            >
              <div v-if="props.row.AltUnit !== 'PAL'">
                <BaseFieldQuantity
                  :value="getQuantity(props.row, 'LAY')"
                  @update:modelValue="setQuantity(props.row, $event, 'LAY')"
                  :disabled="isDisabled(props.row)"
                  :max="750"
                />
              </div>
              <div class="product-group__count" v-else>
                {{ calculateLayers(props.row) || '' }}
              </div>
            </o-table-column>
            <o-table-column
              v-if="type === 'order'"
              :label="$t('orders.order-item-list.header.boxes')"
              v-slot="props"
              :width="100"
              root-class="is-centered"
              >{{ calculateBoxes(props.row) || '' }}</o-table-column
            >
            <!-- begin claim columns  -->
            <o-table-column
              v-if="type === 'claim'"
              :label="$t('claims.new.items.bags')"
              v-slot="props"
              :width="90"
              :td-attrs="() => ({ class: 'cell--input' })"
            >
              <div class="product-group__cell-wrapper">
                <BaseFieldQuantity
                  :value="getQuantity(props.row, 'BAG')"
                  @update:modelValue="setQuantity(props.row, $event, 'BAG')"
                  :disabled="getQuantity(props.row, 'CAR') > 0"
                  data-test="input-bags"
                />
              </div>
            </o-table-column>
            <o-table-column
              v-if="type === 'claim'"
              :label="$t('claims.new.items.boxes')"
              v-slot="props"
              :width="90"
              :td-attrs="() => ({ class: 'cell--input' })"
            >
              <div class="product-group__cell-wrapper">
                <BaseFieldQuantity
                  :value="getQuantity(props.row, 'CAR')"
                  @update:modelValue="setQuantity(props.row, $event, 'CAR')"
                  :disabled="getQuantity(props.row, 'BAG') > 0"
                  data-test="input-cartons"
                />
              </div>
            </o-table-column>
            <o-table-column
              v-if="type === 'claim'"
              :label="$t('claims.new.items.volume')"
              v-slot="props"
              :width="90"
            >
              {{ calculateClaim(props.row).volume }} {{ $t('claims.kg') }}
            </o-table-column>
            <o-table-column
              v-if="type === 'claim'"
              :label="$t('claims.new.items.billback-rate')"
              v-slot="{ row }"
              numeric
              :width="120"
            >
              <BaseCurrencyAmount
                :currency="agreement.currency"
                :amount="agreementItem(row).billback_rate"
              />
            </o-table-column>
            <o-table-column
              v-if="type === 'claim'"
              :label="$t('claims.new.items.billback-amount')"
              v-slot="props"
              numeric
              :width="155"
            >
              <BaseCurrencyAmount
                :currency="agreement.currency"
                :amount="calculateClaim(props.row).billbackAmount"
              />
            </o-table-column>
            <!-- end claim columns  -->
          </o-table>

          <OSkeleton v-else :animated="true" height="194px" />
        </div>
      </transition>
    </div>
  </section>
</template>

<script lang="ts">
import {
  InlineResponse200CorrectionSuggestion,
  SalesQuote,
  SalesQuoteItem,
  SapProduct,
} from '@/api/model';
import BaseFieldQuantity from '@/components/form/BaseFieldQuantity.vue';
import NewOrderTooltip from '@/components/orders/new-order/NewOrderTooltip.vue';
import { OrderSuggestionCorrectionSuggestion } from '@/types/ApiInterfaces';
import BaseCurrencyAmount from '@/components/BaseCurrencyAmount.vue';
import { ClaimTotals } from '@/types/ClaimTotals';
import { defineComponent } from 'vue';
import { SapProductForCustomer } from '@/types/SapProductForCustomer';

export default defineComponent({
  components: { BaseCurrencyAmount, NewOrderTooltip, BaseFieldQuantity },
  computed: {
    active(): boolean {
      return this.$store.getters[`${this.cartType}/isActiveGroup`]({
        group: this.groupLabel,
        user: this.$store.state.auth.user.username,
      });
    },
    sortedProducts(): SapProduct[] | unknown[] {
      const sorted = [...(this.products as SapProduct[])];
      return sorted.sort((a: SapProduct, b: SapProduct) =>
        (a.ProductText || '') > (b.ProductText || '') ? 1 : -1,
      );
    },
    fillStatus(): string {
      return this.$store.getters['container/status'];
    },
    containerSuggestionLabel(): string {
      const amount = this.containerSuggestion?.NumPallets || 0;
      const type = amount > 0 ? 'add' : 'reduce';
      const sign = amount > 0 ? '+' : '';
      return this.$t(`container-calculation.suggestion.${type}`, {
        amount: `${sign}${amount}`,
      }) as string;
    },
  },
  methods: {
    toggleActive(active: boolean): void {
      this.$store.commit(`${this.cartType}/toggleActiveGroup`, {
        id: this.groupLabel,
        active,
        user: this.$store.state.auth.user.username,
      });
    },
    setQuantity(product: SapProduct, quantity: number, unit: string): void {
      this.$store.commit(`${this.cartType}/setCartItemQuantity`, {
        product,
        unit,
        quantity,
      });
      this.$emit('change');
    },
    hasContainerSuggestion(product: SapProduct): boolean {
      return (
        !!this.containerSuggestion &&
        this.containerSuggestion.ProductNumber === product.ProductNr &&
        !!this.containerSuggestion?.NumPallets
      );
    },
    hasTruckSuggestion(product: SapProduct): boolean {
      if (this.$store.state.order.suggestionCancelled) {
        return false;
      }

      const corrSugg = this.$store.state.order.suggestion?.CorrectionSuggestion;
      const suggestion = corrSugg?.ProductNr;

      if (!suggestion) {
        return false;
      }
      return product.ProductNr === suggestion;
    },
    setContainerSuggestion(product: SapProduct) {
      const delta = this.containerSuggestion?.NumPallets || 0;
      this.setSuggestion(product, delta);
    },
    setTruckSuggestion(suggestion: OrderSuggestionCorrectionSuggestion): void {
      const delta = suggestion.NumPallets || 0;

      const product = this.$store.getters['order/productByNr'](
        suggestion.ProductNr,
      );
      this.$store.commit('order/setSuggestionAccepted', true);
      this.setSuggestion(product, delta);
      // Ignore the cart change if it is caused by accepting the suggestion
      this.$store.commit('order/setCartChanged', false);
    },
    setSuggestion(product: SapProduct, delta: number): void {
      if (delta !== 0) {
        const newQuantity = this.getQuantityNumber(product, 'PAL') + delta;
        this.setQuantity(product, newQuantity, 'PAL');
      }
    },
    getQuantityNumber(product: SapProduct, unit: string): number {
      const getCartItem = this.$store.getters[`${this.cartType}/getCartItem`];
      const item = getCartItem(product, unit);
      if (!item) {
        return 0;
      }
      return parseInt(item.Quantity, 10);
    },
    getQuantity(product: SapProduct, unit: string): string {
      const quantity = this.getQuantityNumber(product, unit);
      return quantity ? `${quantity}` : '';
    },
    isDisabled(product: SapProductForCustomer): boolean {
      if (this.type !== 'order') {
        return false;
      }

      const limitWarehouse =
        this.$store.getters['order/activePreferredWarehouse'];
      if (limitWarehouse === '') {
        return false;
      }

      return (
        product.DeliveryPlantCode != null &&
        product.DeliveryPlantCode !== limitWarehouse
      );
    },
    calculateBoxes(product: SapProduct): number {
      const { BoxesPerLayer, BoxesPerPallet } = product;
      let boxes = 0;
      if (BoxesPerLayer !== undefined && BoxesPerLayer !== null) {
        const perLayer = parseInt(BoxesPerLayer, 10);
        const layers = this.getQuantityNumber(product, 'LAY');
        boxes += perLayer * layers;
      }
      if (BoxesPerPallet !== undefined && BoxesPerPallet !== null) {
        const perPallet = parseInt(BoxesPerPallet, 10);
        const pallets = this.getQuantityNumber(product, 'PAL');
        boxes += perPallet * pallets;
      }
      return boxes;
    },
    calculateLayers(product: SapProduct): number {
      const { LayersPerPallet } = product;
      let layers = 0;
      if (LayersPerPallet !== undefined && LayersPerPallet !== null) {
        const perPallet = parseInt(LayersPerPallet, 10);
        const pallets = this.getQuantityNumber(product, 'PAL');
        layers += perPallet * pallets;
      }
      return layers;
    },
    agreementItem(product: SapProduct): SalesQuoteItem {
      return this.agreement?.items?.find(
        (item) => item.sap_product_id === product.Id,
      ) as SalesQuoteItem;
    },
    calculateClaim(product: SapProduct): ClaimTotals {
      return this.$store.getters['claim/getItemCalculation'](product);
    },
  },
  props: {
    groupLabel: {
      type: String,
    },
    products: {
      type: Array,
      required: true,
    },
    customText: {
      type: Boolean,
      default: true,
    },
    agreement: { type: Object as () => SalesQuote, default: () => {} },
    type: { type: String, default: 'order' },
    containerSuggestion: {
      type: Object as () => InlineResponse200CorrectionSuggestion,
      default: undefined,
    },
    filled: { type: Boolean, default: false },
    cartType: { type: String, default: 'order' },
  },
});
</script>

<style lang="scss">
.product-item-list {
  margin-top: 16px;
  border-radius: 8px;

  .table-wrapper {
    // To make tooltips visible
    overflow: visible;
  }

  &--no-body {
    .table {
      border-radius: 8px 8px 0 0 !important;
    }

    tbody {
      display: none;
    }
  }

  // No head, no rounded borders workaround
  .product-group--order &,
  .product-group--claim & {
    margin-top: 0;
    border-radius: 0;

    .table {
      border-radius: 0 !important;
    }

    // Hide the table header, but keep the th's for the column widths
    thead tr th {
      padding: 0 !important;

      * {
        display: none;
      }
    }

    tbody {
      tr {
        border-radius: 0;
      }

      td {
        border-radius: 0 !important;
      }
    }
  }

  .product-group--container & {
    @include tablet {
      margin: 25px 0 80px 0;
      box-shadow: -10px 10px 20px rgba(30, 30, 30, 0.05);
    }
  }

  &.b-table .table-wrapper .table {
    border-radius: 8px;

    @include mobile {
      border: none;
    }

    thead {
      background: $wild-sand;

      th {
        &:first-child {
          padding-left: 40px;
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }

    tbody {
      tr {
        @include mobile {
          border-radius: 8px;
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-bottom-right-radius: 8px;
            }
          }
        }

        &.is-disabled {
          td {
            color: $bali-hai !important;
          }
        }
      }

      &:not(.detail):not(.is-empty):not(.table-footer) td {
        @include mobile {
          &:first-child:before {
            border-top-left-radius: 8px;
          }

          &:last-child:before {
            border-bottom-left-radius: 8px;
          }
        }

        &:first-of-type {
          @include tablet {
            padding-left: 40px;
          }
        }

        &.cell--input,
        .cell--input {
          .field {
            margin: 0;
          }

          @include tablet {
            padding: 11px 12px 0 12px;
          }
        }

        &::before {
          background-color: $wild-sand;
          margin-bottom: -1px;
          color: $black;
        }
      }
    }
  }

  .input {
    background: $concrete;
    border: 1px solid $bombay;
    width: 48px;
    height: 26px;
    padding: 4px 0 0 7px;
  }
}
</style>

<style lang="scss" scoped>
.product-group {
  &--container-calculation {
    .product-item-list {
      margin-bottom: 24px;
    }
  }

  &__count {
    @include tablet {
      padding: 16px;
      padding-top: 5px;
    }
  }

  &__cell-wrapper {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: row-reverse;
    }
  }

  &__suggestion-button {
    margin-left: 20px;
  }

  &__suggestion :deep(input) {
    border: 1px solid $secondary;
  }
  &__suggestion--fill :deep(input) {
    border: 1px solid $primary;
  }
}
</style>
