import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "filter" }
const _hoisted_2 = { class: "toggle-button__label" }
const _hoisted_3 = {
  key: 0,
  class: "filter__dropdown"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "filter__name" }
const _hoisted_6 = { class: "filter__key" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _directive_on_click_outside = _resolveDirective("on-click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["toggle-button toggle-button--expanded toggle-button--select", { 'toggle-button--disabled': _ctx.disabled }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open)),
      role: "button",
      "data-test": "warehouse-filter-toggle"
    }, [
      _createVNode(_component_o_icon, {
        class: "toggle-button__icon-left",
        icon: "filter"
      }),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.activeLabel ||
          _ctx.$t('orders.search-bar.placeholder.set-preferred-warehouse')), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["toggle-button__icon", { 'toggle-button__icon--active': _ctx.open }])
      }, [
        _createVNode(_component_o_icon, { icon: "chevron_up" })
      ], 2)
    ], 2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouses, (warehouse) => {
                return (_openBlock(), _createElementBlock("button", {
                  class: _normalizeClass(["filter__button", { 'filter__button--active': _ctx.activeValue === warehouse.key }]),
                  key: warehouse.key,
                  onClick: ($event: any) => (_ctx.setValue(warehouse.key, $event))
                }, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(warehouse.value), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(warehouse.key), 1)
                ], 10, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_on_click_outside, _ctx.closeFilter]
  ])
}