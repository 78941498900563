import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "product-card__description" }
const _hoisted_3 = {
  key: 0,
  class: "product-card__category"
}
const _hoisted_4 = {
  key: 1,
  class: "product-card__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.link)
    ? (_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        class: "product-card",
        to: _ctx.link
      }, {
        default: _withCtx(() => [
          (_ctx.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.$helpers.getApiImageThumbnail(_ctx.image),
                class: "product-card__image",
                alt: _ctx.image.title
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.category)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.category), 1))
              : _createCommentVNode("", true),
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}