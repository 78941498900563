import { getCurrentUser } from 'aws-amplify/auth';

export default class GoogleTagManagerLogic {
  public static pushEvent(name: string, virtualUrl: string) {
    const { dataLayer } = window as any;
    if (!dataLayer) {
      // GTM not installed
      return;
    }

    // eslint-disable-next-line no-multi-assign, no-underscore-dangle
    const hsq = ((window as any)._hsq = (window as any)._hsq || []);

    GoogleTagManagerLogic.pushEvents(dataLayer, hsq, name, virtualUrl);
  }

  public static async pushEvents(
    dataLayer: any,
    hsq: any,
    name: string,
    virtualURL: string,
  ) {
    let user: any;
    try {
      user = await getCurrentUser();
      const { loginId } = user.signInDetails;
      hsq.push(['identify', { email: loginId }]);
    } catch (e) {
      // Not signed in, no need to identify to Hubspot
    }

    const origTitle = document.title;
    document.title = virtualURL;
    hsq.push(['trackPageView']);
    dataLayer.push({
      event: name,
      virtualURL,
      loggedIn: user !== undefined,
    });
    document.title = origTitle;
  }
}
